export const GET_ALL_CHARACTERISTICS_GROUP_SUCCESS =
  "GET_ALL_CHARACTERISTICS_GROUP_SUCCESS";
export const getAllCharacteristicsGroupSuccess = (
  characteristicsGroup: any,
  characteristicsGroupImages: any
) => ({
  type: GET_ALL_CHARACTERISTICS_GROUP_SUCCESS,
  payload: { characteristicsGroup, characteristicsGroupImages },
});

export const CREATE_CHARACTERISTICS_GROUP_SUCCESS =
  "CREATE_CHARACTERISTICS_GROUP_SUCCESS";
export const createCharacteristicGroupSuccess = (
  newCharacteristicGroup: any,
  newImage?: any
) => ({
  type: CREATE_CHARACTERISTICS_GROUP_SUCCESS,
  payload: { newCharacteristicGroup, newImage },
});

export const EDIT_CHARACTERISTICS_GROUP_SUCCESS =
  "EDIT_CHARACTERISTICS_GROUP_SUCCESS";
export const editCharacteristicGroupSuccess = (
  updatedCharacteristicGroup: any,
  updatedImage?: any
) => ({
  type: EDIT_CHARACTERISTICS_GROUP_SUCCESS,
  payload: { updatedCharacteristicGroup, updatedImage },
});

export const DELETE_CHARACTERISTICS_GROUP_SUCCESS =
  "DELETE_CHARACTERISTICS_GROUP_SUCCESS";
export const deleteCharacteristicGroupSuccess = (characteristicGroupID: any) => ({
  type: DELETE_CHARACTERISTICS_GROUP_SUCCESS,
  payload: characteristicGroupID,
});

export const GET_BEST_MATCHING_CHARACTERISTIC_GROUP_SUCCESS =
  "GET_BEST_MATCHING_CHARACTERISTIC_GROUP_SUCCESS";
export const getBestMatchingCharacteristicGroupSuccess = (
  characteristicGroup: any,
) => ({
  type: GET_BEST_MATCHING_CHARACTERISTIC_GROUP_SUCCESS,
  payload:  characteristicGroup,
});
