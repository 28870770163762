//actions
import {
  GET_ALL_WORKPLACES_SUCCESS,
  CREATE_WORKPLACE_SUCCESS,
  EDIT_WORKPLACE_SUCCESS,
  DELETE_WORKPLACE_SUCCESS,
} from "store/actions/workplaceAction";

const initialState: any = [];

const WorkplaceReducer = (state = initialState, action: any) => {
  let workplaceArray: any = [...state];
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_WORKPLACES_SUCCESS:
      workplaceArray = payload;
      return workplaceArray;
    case CREATE_WORKPLACE_SUCCESS:
      workplaceArray.push(payload);
      return workplaceArray;
    case EDIT_WORKPLACE_SUCCESS:
      workplaceArray.map((workplace: any, index: any) => {
        if (workplace.id === payload.workplaceID) {
          return (workplaceArray[index] = payload.workplace);
        } else return workplaceArray;
      });
      return workplaceArray;
    case DELETE_WORKPLACE_SUCCESS:
      workplaceArray = state.filter(
        (workplace: any) => workplace.id !== payload
      );
      return workplaceArray;
    default:
      return state;
  }
};

export default WorkplaceReducer