import { httpCommon } from "./http-common";
import authHeader from "./authHeader";

const getCategory = (categoryID: string) => {
  return httpCommon.get(`/Category/${categoryID}`, { headers: authHeader() as any });
};

const getAllCategories = () => {
  return httpCommon.get("/Category", { headers: authHeader() as any });
};

const createCategory = (data: any) => {
  return httpCommon.post("/Category", data, { headers: authHeader() as any });
};

const updateCategory = (categoryID: string, data: any) => {
  return httpCommon.put(`/Category/${categoryID}`, data, { headers: authHeader() as any });
};

const deleteCategory = (categoryID: number) => {
  return httpCommon.delete(`/Category/${categoryID}`, { headers: authHeader() as any });
};


const CategoryService = {
    getCategory,
    getAllCategories,
    createCategory,
    updateCategory,
    deleteCategory,
};

export default CategoryService;
