import "./ArticleGroupPage.css";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

//thunks
import { getAllPrintGroupsRequest, deletePrintGroupRequest } from "store/thunks/printGroupThunk";

//Selectors
import { getAllPrintGroups } from "store/selectors/printGroupSelector";

import Loader from "components/Loader/Loader";
import Button from "../../../components/Button/Button";
import DeletePopup from "components/DeletePopup/DeletePopup";
import ArticleGroupPopup from "./ArticleGroupPopup/ArticleGroupPopup";
import MessageNotification from "components/MessageNotification/MessageNotification";

function ArticleGroupPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //redux state
  const printGroups = useSelector((state: any) => getAllPrintGroups(state));
  const { loading } = useSelector((state: any) => state.LoadingReducer);
  const { messageText, messageType } = useSelector(
    (state: any) => state.MessageReducer
  );

  //local state to open the Article group popup
  const [articleGroupPopup, setArticleGroupPopup] = useState({
    isArticleGroupPopup: false,
    printGroupId: "",
  });
  //local state to open the Delete Popup
  const [isDeletePopup, setDeletePopup] = useState({
    isDeletePopup: false,
    printGroupId: "",
  });

  useEffect(() => {
    // change the browser tab title
    document.title = "WA - Article Groups";
      // get all categories print groups request
      printGroups.length < 1 && dispatch(getAllPrintGroupsRequest());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, printGroups.length]);

  // go back to previous page
  const goBack = () => {
    navigate(-1);
  };

  //function to hide popups
  const hidePopup = () => {
    //hide the create edit popup
    setArticleGroupPopup({
      ...articleGroupPopup,
      isArticleGroupPopup: false,
      printGroupId: "",
    });
    // hide the delete popup
    setDeletePopup({
      isDeletePopup: false,
      printGroupId: "",
    });

    let popupContainer = document.getElementById(
      "article-group-popup-main-container"
    ) as any;
    popupContainer && popupContainer.classList.add("hide");
    document.body.classList.remove("article-group-popup-overflow-hidden");
  };

  return (
    <>
      {messageText ? (
        <MessageNotification
          messageText={messageText}
          messageType={messageType}
        />
      ) : null}

      {articleGroupPopup.isArticleGroupPopup ? (
        <ArticleGroupPopup
          hidePopUp={hidePopup}
          printGroupId={articleGroupPopup.printGroupId}
        />
      ) : null}

      {isDeletePopup.isDeletePopup ? (
        <DeletePopup
          hidePopUp={hidePopup}
          id={isDeletePopup.printGroupId}
          deleteRequest={deletePrintGroupRequest}
        />
      ) : null}

      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="article-groups-page-container">
            <div className="article-groups-page-top-section">
              {/* Back button */}
              <div className="article-groups-page-back-btn" onClick={goBack}>
                <Button size="small" icon="back-icon" text="Back" />
              </div>
              {/* Page title */}
              <h2 className="article-groups-page-title">Article Groups</h2>
              {/* Create new button */}
              <div className="create-new-article-group-div">
                <Button
                  text="Create New Article Group"
                  size="medium"
                  icon="add-icon"
                  onClick={() =>
                    setArticleGroupPopup({
                      ...articleGroupPopup,
                      isArticleGroupPopup: true,
                    })
                  }
                />
              </div>
            </div>

            {/* Article Group list container */}
            <div className="article-groups-list-container">
              {printGroups.map((printGroup: any, index: any) => (
                <div className="article-groups-list-item" key={index}>
                  {/* article group title section */}
                  <div className="article-groups-item-title">
                    {printGroup.name}
                  </div>
                  {/* Edit and delete buttons section */}
                  <div className="article-groups-item-buttons">
                    {/* Edit Button */}
                    <Button
                      text="Edit"
                      size="small"
                      icon="edit-icon"
                      onClick={() =>
                        setArticleGroupPopup({
                          ...articleGroupPopup,
                          isArticleGroupPopup: true,
                          printGroupId: printGroup.id,
                        })
                      }
                    />
                    {/* Delete Button */}
                    <Button
                      text="Delete"
                      size="small"
                      icon="delete-icon"
                      redBtn={true}
                      onClick={() => setDeletePopup({
                        ...isDeletePopup,
                        isDeletePopup: true,
                        printGroupId: printGroup.id
                      })}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default ArticleGroupPage;
