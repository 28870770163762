import Select from "react-select";
import "./CharacteristicsGroupPopup.css";
import { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import { useDispatch, useSelector } from "react-redux";

// thunk requests
import {
  createNewCharacteristicGroupRequest,
  editCharacteristicGroupRequest,
} from "store/thunks/characteristicsGroupThunk";

// validations
import { CharacteristicGroupSchema } from "validations/adminValidations";

//images
import close from "assets/icons/close-button.png";
import image from "assets/images/defaultPicture.png";

//components
import Input from "components/Input/Input";
import Button from "components/Button/Button";

// selectors
import { getAllCharacteristics } from "store/selectors/characteristicSelector";
import {
  getAllCharacteristicGroups,
  getAllCharacteristicsGroupImages,
} from "store/selectors/characteristicsGroupSelector";

type CharacteristicsGroupPopupProps = {
  characteristicGroupID?: any;
  hidePopUp?: any;
};

function CharacteristicsGroupPopup(props: CharacteristicsGroupPopupProps) {
  const dispatch = useDispatch<any>();

  // redux state
  const characteristicsGroup = useSelector((state: any) =>
    getAllCharacteristicGroups(state)
  );
  const characteristicsGroupImages = useSelector((state: any) =>
    getAllCharacteristicsGroupImages(state)
  );
  const characteristics = useSelector((state: any) =>
    getAllCharacteristics(state)
  );

  //local state
  //state for saving preview image when selected:
  const [baseImage, setBaseImage] = useState<any>(image);

  const [assignedCharacteristics, setAssignedCharacteristics] =
    useState<any>(image);

  const [characteristicGroupState, setCharacteristicGroupState] = useState({
    name: "",
    articleNumber: "",
    characteristicsIDs: [] as any,
    image: image,
  });
  const [imageIsTooLarge, setImageIsTooLarge] = useState(false)

  // here we convert the naming from react select defaults to options as we need the naming in our state and DB
  let options: any;
  // let selectedCharacteristics: any;
  //get characteristics options
  if (characteristics) {
    options = characteristics.map(function (item: any) {
      return { value: item.id, label: item.name };
    });
  }

  // if the form is on edit, so we get user data from DB:
  const getCharacteristicGroup = (id: string) => {
    // filter the redux categories array and get the selected
    let characteristicGroup = characteristicsGroup.filter(
      (characteristicGroup: any) =>
        characteristicGroup.id === props.characteristicGroupID
    );
    // set the local state from redux data
    setCharacteristicGroupState({
      name: characteristicGroup[0]?.name,
      articleNumber: characteristicGroup[0]?.articleNumber,
      characteristicsIDs: characteristicGroup[0]?.characteristicIds,
      image: characteristicGroup[0]?.image,
    });

    //get saved selected Characteristics options to display them in dropdown
    const saved_options = characteristicGroup[0].characteristics.map(function (
      item: any
    ) {
      return { value: item.id, label: item.name };
    });
    // convert the data to be accepted by the react select
    const saved_functions = [] as any;
    saved_options.forEach((option: any) => {
      saved_functions.push(option.value);
    });
    // set the data in the react select state
    setAssignedCharacteristics(saved_options);

    // filter the redux categories images array
    let characteristicGroupImage = characteristicsGroupImages.filter(
      (image: any) => image.imageID === characteristicGroup[0].imageID
    );
    characteristicGroupImage[0]?.image &&
      setBaseImage(
        "data:image/jpeg;base64," + characteristicGroupImage[0]?.image
      );
  };

  useEffect(() => {
    // if there is categoryID so we call the get category by ID function
    props.characteristicGroupID &&
      getCharacteristicGroup(props.characteristicGroupID);

    // because we are in a popup we should stop scrolling so we add this classes
    let popupContainer = document.getElementById(
      ".characteristic-group-popup-main-container"
    ) as any;
    popupContainer && popupContainer.classList.remove("hide");
    document.body.classList.add("characteristic-group-overflow-hidden");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.characteristicGroupID]);

  // onSubmit we check if the form is created or edited and gives the correct function:
  function onSubmit() {
    const { name, articleNumber, characteristicsIDs, image } =
      characteristicGroupState;
    let data = new FormData();
    data.append("name", name);
    data.append("ArticleNumber", articleNumber);
    for (let i = 0; i < characteristicsIDs.length; i++) {
      data.append(`CharacteristicsIDs[${i}]`, characteristicsIDs[i]);
    }
    data.append("image", image);

    if (!imageIsTooLarge) {
      if (!props.characteristicGroupID) {
        dispatch(createNewCharacteristicGroupRequest(data));
        props.hidePopUp();
      } else {
        dispatch(
          editCharacteristicGroupRequest(props.characteristicGroupID, data)
        );
        props.hidePopUp();
      }
    }
    
  }

  // show image preview when selected:
  const handleImageChange = async (event: any) => {
    const file = event.target.files[0];
    if (file.size > 500000) {
      setImageIsTooLarge(true)
      setBaseImage(image)
    } else {
      const base64 = await convertBase64(file);
      setBaseImage(base64);
      setCharacteristicGroupState({
        ...characteristicGroupState,
        image: file,
      });
      setImageIsTooLarge(false)
    }
    
  };

  // function to convert selected image to base64 to display it as preview:
  const convertBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleInputChange = (event: any) => {
    const target = event.target;
    const value = event.target.value;
    const name = target.name;
    setCharacteristicGroupState({ ...characteristicGroupState, [name]: value });
  };

  // onChange function for the units
  const handleCharacteristicsSelect = (event: any) => {
    let values = Array.isArray(event) ? event.map((x: any) => x) : [];
    // let options: any;
    let test = [] as any;
    if (values) {
      setAssignedCharacteristics(values);
      values.map((val: any) => {
        return test.push(val.value);
      });

      setCharacteristicGroupState({
        ...characteristicGroupState,
        characteristicsIDs: test,
      });
    }
  };

  const handleSubmit = () => {
    document.body.classList.remove("characteristic-group-overflow-hidden");
  };

  return (
    <>
      <div
        id="popup-main-container"
        className="characteristic-group-popup-main-container"
      >
        <div
          className="characteristic-group-popup-overlay"
          onClick={props.hidePopUp}
        ></div>
        <div className="characteristic-group-popup-container">
          <div className="characteristic-group-popup-top-section">
            {props.characteristicGroupID ? (
              <h1>Edit Characteristic Group</h1>
            ) : (
              <h1>New Characteristic Group</h1>
            )}
            <img
              className="characteristic-group-popup-close"
              src={close}
              onClick={props.hidePopUp}
              alt="close Popup"
              title="close Popup"
            />
          </div>

          <>
            <Formik
              initialValues={characteristicGroupState}
              validationSchema={CharacteristicGroupSchema}
              onSubmit={onSubmit}
              enableReinitialize
            >
              {({ values, errors, touched, isSubmitting, setFieldValue }) => {
                return (
                  <>
                    <Form>
                      <div className="characteristic-group-popup-form-container">
                        <div className="characteristic-group-popup-fields-section">
                          {/* Name input */}
                          <h2 className="characteristic-group-popup-input-label">
                            Name
                          </h2>
                          <div className="characteristic-group-popup-input">
                            <div className="characteristic-group-popup-input">
                              <Field
                                className="my-input"
                                type="text"
                                id="name"
                                name="name"
                                value={characteristicGroupState.name}
                                placeholder="Name"
                                onChange={handleInputChange}
                              />
                              {touched.name && errors.name ? (
                                <div
                                  className="characteristic-group-error-message"
                                  role="alert"
                                >
                                  {errors.name}
                                </div>
                              ) : null}
                            </div>
                            <div className="characteristic-group-category-icon"></div>
                          </div>

                          {/* Article Number input */}
                          <h2 className="characteristic-group-popup-input-label">
                            Article Number
                          </h2>
                          <div className="characteristic-group-popup-input">
                            <div className="characteristic-group-popup-input">
                              <Field
                                className="my-input"
                                type="text"
                                id="articleNumber"
                                name="articleNumber"
                                value={characteristicGroupState.articleNumber}
                                placeholder="Article Number"
                                onChange={handleInputChange}
                              />
                              {touched.articleNumber && errors.articleNumber ? (
                                <div
                                  className="characteristic-group-error-message"
                                  role="alert"
                                >
                                  {errors.articleNumber}
                                </div>
                              ) : null}
                            </div>
                            <div className="characteristic-group-article-number-icon"></div>
                          </div>

                          {/* Characteristics input */}
                          <h2 className="characteristic-group-popup-input-label">
                            Characteristics
                          </h2>
                          <div className="characteristic-group-popup-input">
                            <div className="characteristic-group-multi-characteristic-select">
                              <Select
                                name="functions"
                                options={characteristics && options}
                                onChange={handleCharacteristicsSelect}
                                closeMenuOnSelect={false}
                                value={
                                  assignedCharacteristics
                                    ? assignedCharacteristics
                                    : null
                                }
                                isMulti
                                placeholder="Select Characteristic"
                                maxMenuHeight={250}
                              />
                            </div>
                            <div className="characteristic-group-characteristics-icon"></div>
                            {touched.characteristicsIDs &&
                            errors.characteristicsIDs ? (
                              <div
                                className="characteristic-group-error-message-select"
                                role="alert"
                              >
                                {errors.characteristicsIDs}
                              </div>
                            ) : null}
                          </div>
                        </div>

                        {/* Image input */}
                        <div className="characteristic-group-popup-image-section">
                          <img
                            className="characteristic-group-default-picture"
                            src={baseImage ? baseImage : image}
                            alt="Bild hinzufügen"
                            title="Bild hinzufügen"
                          />
                          <Input
                            className="characteristic-group-image-input"
                            type="file"
                            name="image"
                            accept="image/*"
                            placeholder="Durchsuchen.."
                            onChange={handleImageChange}
                          />
                          {imageIsTooLarge ? (
                            <div
                              className="characteristic-group-error-message-image"
                              role="alert"
                            >
                              Image is Too Large
                            </div>
                          ) : null}
                        </div>
                      </div>

                      {/* Submit Button */}
                      <div className="characteristic-group-popup-submit-button-container">
                        {!props.characteristicGroupID ? (
                          <Button
                            size="small"
                            text="+ Create"
                            type="submit"
                            onClick={handleSubmit}
                          ></Button>
                        ) : (
                          <Button
                            icon="edit-icon"
                            size="small"
                            text="Edit"
                            type="submit"
                            onClick={handleSubmit}
                          ></Button>
                        )}
                      </div>
                    </Form>
                  </>
                );
              }}
            </Formik>
          </>
        </div>
      </div>
    </>
  );
}

export default CharacteristicsGroupPopup;
