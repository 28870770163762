//Combine Reducers
import { combineReducers } from "redux";
import AuthReducer from "./authReducer";
import taskReducer from "./taskReducer";
import actionReducer from "./actionReducer";
import LoadingReducer from "./loadingReducer";
import MessageReducer from "./messageReducer";
import CategoryReducer from "./categoryReducer";
import PlacementReducer from "./placementsReducer";
import printGroupReducer from "./printGroupReducer";
import characteristicReducer from "./characteristicReducer";
import damagedItemModelsReducer from "./damagedItemModelReducer";
import CharacteristicsGroupReducer from "./characteristicsGroupReducer";
import TaskReducer from "./taskReducer";
import CommissionedCostumerReducer from "./commissionedCustomerReducer";
import CollectionListReducer from "./collectionListReducer";
import DamageTypeReducer from "./damageTypeReducer";
import TaskSequenceReducer from "./taskSequencesReducer";
import WorkplaceReducer from "./workplaceReducer";
import LocationReducer from "./locationReducer";
import LicenseReducer from "./licenseReducer";
import UserReducer from "./userReducer";

export default combineReducers({
  AuthReducer,
  taskReducer,
  actionReducer,
  LoadingReducer,
  MessageReducer,
  CategoryReducer,
  PlacementReducer,
  printGroupReducer,
  characteristicReducer,
  damagedItemModelsReducer,
  CharacteristicsGroupReducer,
  TaskReducer,
  CommissionedCostumerReducer,
  CollectionListReducer,
  DamageTypeReducer,
  TaskSequenceReducer,
  WorkplaceReducer,
  LocationReducer,
  LicenseReducer,
  UserReducer
});

export type RootState = ReturnType<typeof combineReducers>;
