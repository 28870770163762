import "./AdminMenu.css";
import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

//components
import Button from "components/Button/Button";

function AdminMenu() {
  const navigate = useNavigate();

  //local states
  const [activeMenuName, setActiveMenuName] = useState<any>("");
  const [highlightMenuName, setHighlightMenuName] = useState<any>("Article");

  //function to toggle sub menu of clicked item
  const toggleDrawer = (menuName: any) => {
    setActiveMenuName((prevState: any) => {
      if (prevState === menuName) {
        setActiveMenuName("");
      } else {
        setActiveMenuName(menuName);
      }
    });
  };
  // we get the url
  const currentRoute = useLocation().pathname.toLowerCase();

  //function to handle sub menu click and highlight parent menu
  const handleSubMenuClick = (event: any) => {
    setHighlightMenuName(
      event.target.closest(".menu-item").children[0].innerText
    );
  };

  return (
    <>
      <nav role="navigation" aria-label="Admin Menu">
        <div className="admin-menu-wrapper">
          <div className="menu-item">
            <div
              onClick={() => {
                toggleDrawer("Article");
              }}
            >
              <Button
                size="medium"
                icon="overview-icon"
                text="Article"
                selected={
                  currentRoute.includes("categories") ||
                  currentRoute.includes("characteristics-group") ||
                  currentRoute.includes("characteristics")
                    ? "selected"
                    : ""
                }
              />
            </div>

            {activeMenuName === "Article" ? (
              <div>
                <Button
                  size="medium"
                  icon="category-icon"
                  text="Categories"
                  subMenu={true}
                  selected={
                    currentRoute.includes("categories") ||
                    currentRoute.includes("characteristics/")
                      ? "selected"
                      : ""
                  }
                  onClick={(e: any) => {
                    handleSubMenuClick(e);
                    toggleDrawer("Categories");
                    navigate("/categories");
                  }}
                />
                <Button
                  size="medium"
                  icon="product-icon"
                  text="Characteristics Group"
                  subMenu={true}
                  selected={
                    currentRoute.includes("characteristics-group")
                      ? "selected"
                      : ""
                  }
                  onClick={(e: any) => {
                    handleSubMenuClick(e);
                    toggleDrawer("Characteristics Group");
                    navigate("/characteristics-group");
                  }}
                />
              </div>
            ) : (
              ""
            )}
          </div>

          {/* -------------------------------------------------------------- */}

          {/* Damage */}
          <div className="menu-item">
            <div
              onClick={() => {
                toggleDrawer("Damage");
              }}
            >
              <Button
                size="medium"
                icon="damage-icon"
                text="Damage"
                selected={
                  currentRoute.includes("placement") ||
                  currentRoute.includes("damage-types")
                    ? "selected"
                    : ""
                }
              />
            </div>
            {activeMenuName === "Damage" ? (
              <div>
                <Button
                  size="medium"
                  icon="category-icon"
                  text="Placements"
                  subMenu={true}
                  selected={
                    currentRoute.includes("placement") ? "selected" : ""
                  }
                  onClick={(e: any) => {
                    handleSubMenuClick(e);
                    toggleDrawer("Damage Placements");
                    navigate("/placements");
                  }}
                />
                <Button
                  size="medium"
                  icon="product-icon"
                  text="Damage Type"
                  subMenu={true}
                  selected={
                    currentRoute.includes("damage-types") ? "selected" : ""
                  }
                  onClick={(e: any) => {
                    handleSubMenuClick(e);
                    toggleDrawer("Damage Type");
                    navigate("/damage-types");
                  }}
                />
              </div>
            ) : (
              ""
            )}
          </div>

          {/* -------------------------------------------------------------------- */}

          {/* Tasks */}
          <div className="menu-item">
            <div
              onClick={() => {
                toggleDrawer("Tasks");
              }}
            >
              <Button
                size="medium"
                icon="overview-icon"
                text="Tasks"
                selected={
                  currentRoute.includes("tasks") ||
                  currentRoute.includes("task-sequence")
                    ? "selected"
                    : ""
                }
              />
            </div>
            {activeMenuName === "Tasks" ? (
              <div>
                <Button
                  size="medium"
                  icon="overview-icon"
                  text="Tasks"
                  selected={currentRoute.includes("tasks") ? "selected" : ""}
                  onClick={(e: any) => {
                    handleSubMenuClick(e);
                    toggleDrawer("Tasks");
                    navigate("/tasks");
                  }}
                />
                <Button
                  size="medium"
                  icon="overview-icon"
                  text="Task Sequence"
                  selected={
                    currentRoute.includes("task-sequence") ? "selected" : ""
                  }
                  onClick={(e: any) => {
                    handleSubMenuClick(e);
                    navigate("/task-sequence");
                    toggleDrawer("Tasks");
                  }}
                />
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="menu-item">
            <div
              onClick={() => {
                toggleDrawer("Article Groups");
                navigate("/article-group");
              }}
            >
              <Button
                size="medium"
                icon="good-allocation-icon"
                text="Article Groups"
                selected={
                  currentRoute.includes("article-group") ? "selected" : ""
                }
              />
            </div>
          </div>
          <div className="menu-item">
            <div
              onClick={() => {
                toggleDrawer("Cleaning companies");
                navigate("/companies");
              }}
            >
              <Button
                size="medium"
                icon="clean-icon"
                text="Cleaning companies"
                selected={currentRoute.includes("companies") ? "selected" : ""}
              />
            </div>
          </div>
          <div className="menu-item">
            <div
              onClick={() => {
                toggleDrawer("Statics");
                navigate("/statistic");
              }}
            >
              <Button
                size="medium"
                icon="statistics-icon"
                text="Statistics"
                selected={currentRoute.includes("statistic") ? "selected" : ""}
              />
            </div>
          </div>

          <div className="menu-item">
            <div
              onClick={() => {
                toggleDrawer("Workplace");
                navigate("/workplaces");
              }}
            >
              <Button
                size="medium"
                icon="workplace-icon"
                text="Workplace"
                selected={currentRoute.includes("workplace") ? "selected" : ""}
              />
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}

export default AdminMenu;
