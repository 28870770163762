import "./TasksPage.css";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// thunks requests
import { getAllTasksRequest, deleteTaskRequest } from "store/thunks/taskThunk";

// selectors
import {
  getAllTasksSelector,
  getAllTasksImagesSelector,
} from "store/selectors/taskSelector";

//components
import Loader from "components/Loader/Loader";
import Button from "components/Button/Button";
import TaskPopup from "./TaskPopup/TaskPopup";
// import CategoryCard from "./CategoryCard/CategoryCard";
// import CategoryPopup from "./CategoryPopup/CategoryPopup";
import DeletePopup from "components/DeletePopup/DeletePopup";
import MessageNotification from "components/MessageNotification/MessageNotification";
import TaskCard from "./TaskCard/TaskCard";

function TasksPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // redux state
  const tasks = useSelector((state: any) => getAllTasksSelector(state));
  const tasksImages = useSelector((state: any) =>
    getAllTasksImagesSelector(state)
  );
  const { loading } = useSelector((state: any) => state.LoadingReducer);
  const { messageText, messageType } = useSelector(
    (state: any) => state.MessageReducer
  );

  // local states
  const [isDeletePopup, setDeletePopup] = useState({
    isDeletePopup: false,
    taskID: "",
  });
  // create and edit task popup
  const [isTaskPopup, setTaskPopup] = useState({
    isTaskPopup: false,
    taskID: "",
  });

  useEffect(() => {
    // change the browser tab title
    document.title = "WA - Tasks";
    // get all tasks thunk request
    tasks.length < 1 && dispatch(getAllTasksRequest());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, tasks.length]);

  // go back to previous page
  const goBack = () => {
    navigate(-1);
  };

  // this function hide popups
  const hidePopUp = () => {
    // hide the create edit popup
    setTaskPopup({
      ...isTaskPopup,
      isTaskPopup: false,
      taskID: "",
    });
    // hide the delete popup
    setDeletePopup({
      isDeletePopup: false,
      taskID: "",
    });
    let popupContainer = document.getElementById(
      "task-popup-main-container"
    ) as any;
    popupContainer && popupContainer.classList.add("hide");
    document.body.classList.remove("task-popup-overflow-hidden");
  };

  return (
    <>
      {messageText ? (
        <MessageNotification
          messageText={messageText}
          messageType={messageType}
        />
      ) : null}

      {isDeletePopup.isDeletePopup ? (
        <DeletePopup
          hidePopUp={hidePopUp}
          id={isDeletePopup.taskID}
          deleteRequest={deleteTaskRequest}
        />
      ) : null}

      {isTaskPopup.isTaskPopup ? (
        <TaskPopup taskID={isTaskPopup.taskID} hidePopUp={hidePopUp} />
      ) : null}

      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="task-page-container">
            <div className="task-page-top-section">
              {/* Back button */}
              <div className="task-page-back-btn" onClick={goBack}>
                <Button size="small" icon="back-icon" text="Back" />
              </div>
              {/* Page title */}
              <h2 className="task-page-title">Tasks</h2>
              {/* Create new button */}
              <div className="create-new-task-div">
                <Button
                  text="New Task"
                  size="medium"
                  icon="add-icon"
                  onClick={() =>
                    setTaskPopup({
                      ...isTaskPopup,
                      isTaskPopup: true,
                    })
                  }
                />
              </div>
            </div>

            {/* Categories list */}
            <div className="task-list-container">
              {tasks &&
                tasks.map((task: any, index: any) => (
                  <TaskCard
                    key={index}
                    index={index}
                    task={task}
                    // characteristics={task.characteristics}
                    handleEditCategory={() =>
                      setTaskPopup({
                        ...isTaskPopup,
                        isTaskPopup: true,
                        taskID: task.id,
                      })
                    }
                    handleDeleteCategory={() => {
                      setDeletePopup({
                        isDeletePopup: true,
                        taskID: task.id,
                      });
                    }}
                    taskImages={tasksImages}
                  />
                ))}
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default TasksPage;
