import "./SelectCharacteristicPage.css";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

//Thunks
import { getAllCategoriesRequest } from "store/thunks/categoryThunk";
import { getAllCharacteristicsRequest } from "store/thunks/characteristicThunk";

//Selector
import {
  getAllCategories,
  getFirstCategory,
} from "store/selectors/categorySelector";
import {
  getAllCharacteristics,
  getAllCharacteristicsImages,
} from "store/selectors/characteristicSelector";
import { getPastOrdersActionsSelector } from "store/selectors/actionSelector";

//Components
import Button from "components/Button/Button";
import Loader from "components/Loader/Loader";
import MessageNotification from "components/MessageNotification/MessageNotification";

//images
import image from "assets/images/defaultPicture.png";

function SelectCharacteristicPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //redux state
  const { loading } = useSelector((state: any) => state.LoadingReducer);
  const categories = useSelector((state: any) => getAllCategories(state));
  const firstCategory = useSelector((state: any) => getFirstCategory(state));
  const characteristics = useSelector((state: any) =>
    getAllCharacteristics(state)
  );
  const characteristicsImages = useSelector((state: any) =>
    getAllCharacteristicsImages(state)
  );
  const { messageText, messageType } = useSelector(
    (state: any) => state.MessageReducer
  );
  const pastOrders = useSelector((state: any) =>
    getPastOrdersActionsSelector(state)
  );
  
  // local states
  const [categoryCharacteristicsState, setCategoryCharacteristicsState] =
    useState([]);
  const [selectedCharacteristicsState, setSelectedCharacteristicsState] =
    useState([]);
  const [firstCategoryName, setFirstCategoryName] = useState("")

  // use effect that get categories and characteristics if they are not in redux store
  useEffect(() => {
    // change the browser tab title
    document.title = "WA - Select characteristics";
    //call to get the characteristics of the first category
    getCharacteristicsOfTheFirstCategory();
    //Get The first category name
    getFirstCategoryName()
    // get all categories
    categories.length < 1 &&
      categories.length < 1 &&
      dispatch(getAllCategoriesRequest());
    // get all characteristics
    characteristics.length < 1 &&
      characteristicsImages.length < 1 &&
      dispatch(getAllCharacteristicsRequest());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    categories.length,
    characteristics.length,
    characteristicsImages.length,
  ]);

  //Get the first category name and save it in the state
  const getFirstCategoryName = () => {
    categories.map((category: any) => {
      if (category.firstCategory === true) {
        return setFirstCategoryName(category.name)
      }
    })
  }

  //get the characteristics of the first category function
  const getCharacteristicsOfTheFirstCategory = () => {
    //get the characteristics of the first category
    let categoryCharacteristics = characteristics.filter(
      (characteristic: any) =>
        characteristic.category.id === firstCategory[0]?.id
    );
    //Get the image of each characteristic
    categoryCharacteristics.map((characteristic: any) => {
      return characteristicsImages
        .filter((item: any) => item?.imageID === characteristic?.imageID)
        .map(function (image: any) {
          characteristic.image = image.image;
          return null;
        });
    });
    setCategoryCharacteristicsState(categoryCharacteristics);
  };

  // go back to previous page
  const goBack = () => {
    navigate("/");
  };

  //Get the characteristics of the following of the selected characteristics
  const getFollowingCategoryCharacteristics = (characteristic: any) => {
    let selectedCharacteristics: any = [...selectedCharacteristicsState];
    selectedCharacteristics.push(characteristic);
    //check if their is a following category, if not navigate to capture article page
    if (characteristic.followingCategory !== undefined) {
      //Get the characteristics of the first category
      let categoryCharacteristics = characteristics.filter(
        (item: any) => item.category.id === characteristic.followingCategory.id
      );
      //Get the image of each characteristic
      categoryCharacteristics.map((characteristic: any) => {
        return characteristicsImages
          .filter((item: any) => item?.imageID === characteristic?.imageID)
          .map(function (image: any) {
            characteristic.image = image.image;
            return null;
          });
      });
      setCategoryCharacteristicsState(categoryCharacteristics);
      setSelectedCharacteristicsState(selectedCharacteristics);
    } else {
      setSelectedCharacteristicsState(selectedCharacteristics);
      // navigate to capture article page
      navigate("/capture-article", { state: selectedCharacteristics });
    }
  };

  //When Clicking on the breadCrumb we should get back in the selected characteristic to the chosen one
  const handleBreadCrumbsClick = (index: any) => {
    if (index === 0) {
      let characteristics: any = [];
      setSelectedCharacteristicsState(characteristics);
      getCharacteristicsOfTheFirstCategory();
    } else {
      let selectedCharacteristics: any = [...selectedCharacteristicsState];
      selectedCharacteristics = selectedCharacteristics.slice(0, index);
      setSelectedCharacteristicsState(selectedCharacteristics);
      //Get the characteristics of the first category
      let categoryCharacteristics = characteristics.filter(
        (item: any) =>
          item.category.id ===
          selectedCharacteristics[index - 1].followingCategory.id
      );

      //Get the image of each characteristic
      categoryCharacteristics.map((characteristic: any) => {
        return characteristicsImages
          .filter((item: any) => item?.imageID === characteristic?.imageID)
          .map(function (image: any) {
            characteristic.image = image.image;
            return null;
          });
      });
      setCategoryCharacteristicsState(categoryCharacteristics);
    }
  };

  const duplicateProduct = (pastOrder: any) => {
    // navigate to capture article page
    let selectedCharacteristics: any =[]
    pastOrder.CharacteristicsIDs.map((id: any) => {
      characteristics.map((characteristic: any) => {
        if (id === characteristic.id) {
          selectedCharacteristics.push(characteristic)
        }
      });
    });
    navigate("/capture-article", { state: selectedCharacteristics });
  }

  return (
    <>
      {messageText ? (
        <MessageNotification
          messageText={messageText}
          messageType={messageType}
        />
      ) : null}

      {loading ? (
        <Loader />
      ) : (
        <div className="select-characteristic-page-container">
          {/* top section */}
          <div className="select-characteristic-page-top-section">
            {/* Back button */}
            <div className="select-characteristic-page-back-btn">
              <Button
                size="small"
                icon="back-icon"
                text="Back"
                onClick={goBack}
              />
            </div>
            <div className="select-characteristic-page-title">
              <h1>Select Characteristics</h1>
            </div>
          </div>
          {/* Breadcrumbs section */}
            <div className="select-characteristic-page-breadcrumbs-section">
              <div className="select-characteristic-page-breadcrumbs-item">{ firstCategoryName}</div>
            {selectedCharacteristicsState.map(
              (characteristic: any, index: any) => (
                <div
                  className="select-characteristic-page-breadcrumbs-item"
                  key={index}
                  onClick={() => handleBreadCrumbsClick(index)}
                >
                  {" >>" + characteristic.followingCategory.name}
                </div>
              )
            )}
          </div>
          {/* characteristics section */}
          <div className="select-characteristic-page-characteristics-section">
            {categoryCharacteristicsState &&
              categoryCharacteristicsState.map(
                (characteristic: any, index: any) => (
                  <div
                    className="select-characteristic-page-characteristic-item"
                    key={index}
                    onClick={() =>
                      getFollowingCategoryCharacteristics(characteristic)
                    }
                  >
                    <div className="select-characteristic-page-characteristic-item-name">
                      <Button
                        text={characteristic.name}
                        size="medium"
                        cropped={true}
                        onClick={() =>
                          getFollowingCategoryCharacteristics(characteristic)
                        }
                      />
                    </div>
                    <div className="select-characteristic-page-characteristic-item-image">
                      <img
                        src={
                          characteristic.image !== undefined
                            ? "data:image/jpeg;base64," + characteristic.image
                            : image
                        }
                        alt={characteristic.name}
                        title={characteristic.name}
                      />
                    </div>
                  </div>
                )
              )}
          </div>

          {/* Past orders section */}
          <div className="select-characteristic-page-orders-section">
            <div className="select-characteristic-page-orders-section-title">
              Past Orders
            </div>
            {pastOrders &&
              pastOrders.map((pastOrder: any, index: any) => (
                <div className="select-characteristic-page-orders-container">
                  <div
                    className="select-characteristic-page-orders-list"
                    key={index}
                  >
                    {pastOrder.CharacteristicsNames.map(
                      (name: any, index: any) => (
                        <div className="select-characteristic-page-orders-items">
                          {name}
                        </div>
                      )
                    )}
                  </div>
                  <div className="select-characteristic-page-orders-button">
                    <Button
                      size="small"
                      text="Duplicate Product"
                      icon="duplicate-order-icon"
                      onClick={() => duplicateProduct(pastOrder)}
                    />
                  </div>
                </div>
              ))}
          </div>
        </div>
      )}
    </>
  );
}

export default SelectCharacteristicPage;
