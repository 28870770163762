export const GET_ALL_USERS_SUCCESS = "GET_ALL_USERS_SUCCESS";
export const getAllUsersSuccess = (users: any) => ({
    type: GET_ALL_USERS_SUCCESS,
    payload: users
})

export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const createUserSuccess = (newUser: any) => ({
    type: CREATE_USER_SUCCESS,
    payload: newUser
})

export const EDIT_USER_SUCCESS = "EDIT_USER_SUCCESS";
export const editUserSuccess = (updatedUser: any) => ({
    type: EDIT_USER_SUCCESS,
    payload: updatedUser
})

export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS"
export const deleteUserSuccess = (userID: any) => ({
    type: DELETE_USER_SUCCESS,
    payload: userID
})