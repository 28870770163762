//actions
import {
  GET_ALL_COMMISSIONED_COSTUMER_SUCCESS,
  CREATE_COMMISSIONED_COSTUMER_SUCCESS,
  EDIT_COMMISSIONED_COSTUMER_SUCCESS,
  DELETE_COMMISSIONED_COSTUMER_SUCCESS,
} from "store/actions/commissionedCostumerAction";

//state
const initialState = [] as any;

const commissionedCostumerReducer = (state = initialState, action: any) => {
  let commissionedCostumerArray = state;
  const { type, payload } = action;
  switch (type) {
    case GET_ALL_COMMISSIONED_COSTUMER_SUCCESS:
      commissionedCostumerArray = payload;
      return commissionedCostumerArray;
    case CREATE_COMMISSIONED_COSTUMER_SUCCESS:
      commissionedCostumerArray.push(payload);
      return commissionedCostumerArray;
    case EDIT_COMMISSIONED_COSTUMER_SUCCESS:
      commissionedCostumerArray.map((commissionedCostumer: any, index: any) => {
        if (commissionedCostumer.id === payload.id) {
          return (commissionedCostumerArray[index] = payload);
        } else return commissionedCostumerArray;
      });
      return commissionedCostumerArray;
    case DELETE_COMMISSIONED_COSTUMER_SUCCESS:
      commissionedCostumerArray = state.filter(
        (commissionedCostumer: any) => commissionedCostumer.id !== payload
      );
      return commissionedCostumerArray;
    default:
      return state;
  }
};

export default commissionedCostumerReducer;
