import "./CategoryPopup.css";
import { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import { useDispatch, useSelector } from "react-redux";

// services
// import ImageService from "services/imageService";
// import CategoryService from "services/categoryService";

// thunk requests
import {
  createNewCategoryRequest,
  editCategoryRequest,
} from "store/thunks/categoryThunk";

// selectors
import {
  getAllCategories,
  getAllCategoriesImages,
} from "store/selectors/categorySelector";

// validations
import { CategorySchema } from "validations/adminValidations";

//images
import close from "assets/icons/close-button.png";
import image from "assets/images/defaultPicture.png";

//components
import Input from "components/Input/Input";
import Button from "components/Button/Button";
// import { removeLoading, setLoading } from "store/actions/loadingAction";
// import { setMessage } from "store/actions/messageAction";

type CategoryPopUpProps = {
  categoryID?: any;
  hidePopUp?: any;
};

function CategoryPopup(props: CategoryPopUpProps) {
  const dispatch = useDispatch<any>();

  // redux states
  const categories = useSelector((state: any) => getAllCategories(state));
  const categoriesImages = useSelector((state: any) =>
    getAllCategoriesImages(state)
  );

  // local state
  //state for saving preview image when selected:
  const [baseImage, setBaseImage] = useState<any>(image);

  const [categoryState, setCategoryState] = useState<any>({
    name: "",
    firstCategory: false,
    image: image,
  });
  const [imageIsTooLarge, setImageIsTooLarge] = useState(false)

  // if the form is on edit, so we get user data from DB:
  const getCategory = (id: string) => {
    // filter the redux categories array and get the selected
    let category = categories.filter(
      (category: any) => category.id === props.categoryID
    );
    setCategoryState({
      name: category[0]?.name,
      firstCategory: category[0]?.firstCategory,
      image: category[0]?.image,
    });

    // filter the redux categories images array
    let categoryImage = categoriesImages.filter(
      (image: any) => image.imageID === category[0].imageID
    );
    categoryImage[0]?.image &&
      setBaseImage("data:image/jpeg;base64," + categoryImage[0]?.image);
  };

  useEffect(() => {
    // if there is categoryID so we call the get category by ID function
    props.categoryID && getCategory(props.categoryID);
    // because we are in a popup we should stop scrolling so we add this classes
    let popupContainer = document.getElementById(
      "category-popup-main-container"
    ) as any;
    popupContainer && popupContainer.classList.remove("hide");
    document.body.classList.add("category-popup-overflow-hidden");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.categoryID]);

  // onSubmit we check if the form is created or edited and gives the correct function:
  function onSubmit(
    fields: any,
    { setStatus, setSubmitting }: { setStatus: any; setSubmitting: any }
  ) {
    const { name, firstCategory, image } = categoryState;
    let data = new FormData();
    data.append("name", name);
    data.append("firstCategory", firstCategory.toString());
    data.append("image", image);
    if (!imageIsTooLarge) {
      if (!props.categoryID) {
        dispatch(createNewCategoryRequest(data));
        props.hidePopUp();
        // dispatch(categoryAction(data));
      } else {
        dispatch(editCategoryRequest(props.categoryID, data));
        props.hidePopUp();
        // EditCategory(props.categoryID, data);
      }
    }
  }

  // show image preview when selected:
  const handleImageChange = async (event: any) => {
    const file = event.target.files[0];
    if (file.size > 500000) {
      setImageIsTooLarge(true)
      setBaseImage(image)
    } else {
      const base64 = await convertBase64(file);
      setBaseImage(base64);
      setCategoryState({
        ...categoryState,
        image: file,
      });
      setImageIsTooLarge(false)
    }   
  };

  // function to convert selected image to base64 to display it as preview:
  const convertBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleInputChange = (event: any) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setCategoryState({ ...categoryState, [name]: value });
  };

  const handleSubmit = () => {
    document.body.classList.remove("category-popup-overflow-hidden");
  };

  return (
    <>
      <div
        id="category-popup-main-container"
        className="category-popup-main-container"
      >
        <div className="category-popup-overlay" onClick={props.hidePopUp}></div>
        <div className="category-popup-container">
          {/* Header: title and close button */}
          <div className="category-popup-top-section">
            {props.categoryID ? (
              <h1>Edit New Category</h1>
            ) : (
              <h1> Create New Category</h1>
            )}
            <img
              className="category-popup-close"
              src={close}
              onClick={props.hidePopUp}
              alt="close Popup"
              title="close Popup"
            />
          </div>

          <Formik
            initialValues={categoryState}
            validationSchema={CategorySchema}
            onSubmit={onSubmit}
            enableReinitialize
          >
            {({ values, errors, touched, isSubmitting, setFieldValue }) => {
              return (
                <>
                  <Form>
                    <div className="category-popup-form-container">
                      {/* Section 1: Category Name + first category checkbox section */}
                      <div className="category-popup-fields-section">
                        <div className="category-popup-input">
                          <div className="category-popup-input">
                            <Field
                              className="my-input"
                              type="text"
                              id="name"
                              name="name"
                              value={categoryState.name}
                              placeholder="Name"
                              onChange={handleInputChange}
                            />
                            {touched.name && errors.name ? (
                              <div
                                className="category-popup-error-message"
                                role="alert"
                              >
                                {errors.name}
                              </div>
                            ) : null}
                          </div>
                          <div className="category-icon"></div>
                        </div>

                        <div className="category-popup-first-category-checkbox-container">
                          <Field
                            className="my-checkbox"
                            type="checkbox"
                            id="firstCategory"
                            name="firstCategory"
                            checked={values.firstCategory}
                            onChange={handleInputChange}
                          />
                          <label
                            htmlFor="firstCategory"
                            className="my-checkbox-label"
                          >
                            First Category?
                          </label>
                        </div>
                      </div>

                      {/* Section 2: Image Section */}
                      <div className="category-popup-image-section">
                        <img
                          className="category-popup-image"
                          src={baseImage ? baseImage : image}
                          alt="Bild hinzufügen"
                          title="Bild hinzufügen"
                        />
                        <Input
                          type="file"
                          name="image"
                          accept="image/*"
                          placeholder="Durchsuchen.."
                          onChange={handleImageChange}
                        />
                            {imageIsTooLarge ? (
                              <div
                                className="category-popup-error-message-image"
                                role="alert"
                              >
                                Image is Too Large
                              </div>
                            ) : null}

                      </div>
                    </div>

                    {/* Submit Button: Create or Edit */}
                    <div className="category-popup-submit-button-container">
                      {!props.categoryID ? (
                        <Button
                          size="small"
                          text="Create"
                          icon="add-icon"
                          type="submit"
                          onClick={handleSubmit}
                        ></Button>
                      ) : (
                        <Button
                          icon="edit-icon"
                          size="small"
                          text="Edit"
                          type="submit"
                          onClick={handleSubmit}
                        ></Button>
                      )}
                    </div>
                  </Form>
                </>
              );
            }}
          </Formik>
        </div>
      </div>
    </>
  );
}

export default CategoryPopup;
