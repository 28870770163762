import "./CaptureArticlePage.css";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

//selectors
import {
  getTasksBySequenceChoosersSelector,
  getTasksBySequenceChoosersImagesSelector,
  getAllDismantleTasks,
  getAllDismantleTasksImages,
} from "store/selectors/taskSelector";
import { getMultipleDamagedItemModelsSelector } from "store/selectors/damagedItemModelSelector";

//thunks
import { createActionRequest } from "store/thunks/actionThunk";
import { getBestMatchingCharacteristicGroupRequest } from "store/thunks/characteristicsGroupThunk";

//actions
import { creatPastOrdersActionsSuccess } from "store/actions/actionAction";

//Components
import Button from "components/Button/Button";
import Loader from "components/Loader/Loader";
import MessageNotification from "components/MessageNotification/MessageNotification";

//images
import backIcon from "assets/icons/back-icon.png";
import numberIcon from "assets/icons/article-number.png";
import dropDownIcon from "assets/icons/drop-down-icon.png";
import image from "../../../assets/images/defaultPicture.png";
import dropUpIcon from "assets/icons/userZone/description-up-arrow.png";

function CaptureArticlePage() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //redux state
  let tasks = useSelector((state: any) =>
    getTasksBySequenceChoosersSelector(state)
  );
  let tasksImages = useSelector((state: any) =>
    getTasksBySequenceChoosersImagesSelector(state)
  );
  let damagedItemModels = useSelector((state: any) =>
    getMultipleDamagedItemModelsSelector(state)
  );
  let dismantleTasks = useSelector((state: any) => getAllDismantleTasks(state));
  let dismantleTasksImages = useSelector((state: any) =>
    getAllDismantleTasksImages(state)
  );
  const { loading } = useSelector((state: any) => state.LoadingReducer);
  const { messageText, messageType } = useSelector(
    (state: any) => state.MessageReducer
  );

  let characteristicsIds: any = [];
  let characteristicsNames: any = [];

  // local states
  const [taskID, setTaskID] = useState<any>();
  const [taskState, setTaskState] = useState([]);
  const [dismantleTaskState, setDismantleTaskState] = useState([]);
  const [isOpened, setIsOpened] = useState(false);
  const [damageNumberState, setDamageNumberState] = useState("");
  const [acquisitionState, setAcquisitionState] = useState({
    DamageItemModelsIDs: [],
    CharacteristicsIDs: characteristicsIds,
    CharacteristicsNames: characteristicsNames,
    PrintGroupID: "",
    DamageNumber: "",
  });

  //Get the selected characteristics from the selected characteristics page by props
  const selectedCharacteristics: any = location.state;

  //Get the selected characteristics ids and push them to the array
  const getSelectedCharacteristicsIds = () => {
    selectedCharacteristics &&
      selectedCharacteristics.map((characteristic: any) => {
        //We need to complete the acquisition a print group id. It is the printGroupId of the last selected characteristic
        //Or the last print group found in the characteristic list
        if (characteristic.printGroup) {
          setAcquisitionState({
            ...acquisitionState,
            PrintGroupID: characteristic.printGroup.id,
          });
        }
        // push the id to the ID's array
        return characteristicsIds.push(characteristic.id);
      });
  };

  //Get the Selected characteristics names and push them to the array
  const getSelectedCharacteristicsNames = () => {
    selectedCharacteristics &&
      selectedCharacteristics.map((characteristic: any) => {
        // push the name to names array
        return characteristicsNames.push(characteristic.name);
      });
  };

  useEffect(() => {
    // change the browser tab title
    document.title = "WA - Capture Article";
    //Get the selected characteristics ids
    getSelectedCharacteristicsIds();
    //Get the selected characteristics names
    getSelectedCharacteristicsNames();
    // get the best matching characteristic group by sending the characteristicsIds array
    dispatch(getBestMatchingCharacteristicGroupRequest(characteristicsIds));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  //Get the task from the redux state then join each task with its corresponding damageItemModel if its one
  //Then save the values in a state
  useEffect(() => {
    let newTask: any = [];
    if (tasks.length > 0) {
      tasks.map((task: any) => {
        task.tasksWithSequences.map((sequencesTask: any) => {
          let damageItemModel = "";
          damagedItemModels.map((damage: any) => {
            if (
              damage?.damageType?.id === sequencesTask?.task?.damageType?.id
            ) {
              return (damageItemModel = damage.id);
            }
            return "";
          });

          let taskObject = {
            id: sequencesTask.task.id,
            name: sequencesTask.task.name,
            imageID: sequencesTask.task.imageID,
            image: "",
            description: sequencesTask.task.description,
            damageItemModel: damageItemModel,
          };
          return newTask.push(taskObject);
        });
        return setTaskState(newTask);
      });
    } else {
      setTaskState(newTask);
    }
  }, [tasks, damagedItemModels]);

  //Get the dismantle task from the redux state
  useEffect(() => {
    let newTask: any = [];
    if (dismantleTasks.length > 0) {
      dismantleTasks.map((task: any) => {
        task.tasksWithSequences.map((sequencesTask: any) => {
          let taskObject = {
            id: sequencesTask.task.id,
            name: sequencesTask.task.name,
            imageID: sequencesTask.task.imageID,
            image: "",
            description: sequencesTask.task.description,
          };
          return newTask.push(taskObject);
        });
        return setDismantleTaskState(newTask);
      });
    } else {
      setDismantleTaskState(newTask);
    }
  }, [dismantleTasks]);

  // go back to previous page
  const goBack = () => {
    navigate("/select-characteristic");
  };

  //when clicking on a number on the numpade it will update the state to update the number on the numpad input
  //add the final number to the acquisition state
  const handleNumpadClick = (newNumber: string) => {
    let number = damageNumberState;
    number = number + newNumber;
    setDamageNumberState(number);
    setAcquisitionState({ ...acquisitionState, DamageNumber: number });
  };

  //when clicking on delete on the numpade it will update the state to update the number on the numpad input
  //add the final number to the acquisition state
  const handleDeleteNumberClick = () => {
    let number = damageNumberState;
    number = number.slice(0, -1);
    setDamageNumberState(number);
    setAcquisitionState({
      ...acquisitionState,
      DamageNumber: number,
    });
  };

  const handleEntfClick = () => {
    setDamageNumberState("");
    setAcquisitionState({
      ...acquisitionState,
      DamageNumber: "",
    });
  };

  //Handel the slider. So one the slider is clicked the damage model id will be added to the acquisition state
  //We do a check if the damage model id is already in the acquisition state it will be removed
  const handleSliderClicked = (damageItemModelId: any) => {
    if (acquisitionState.DamageItemModelsIDs.length < 1) {
      let ids: any = [];
      ids.push(damageItemModelId);
      setAcquisitionState({
        ...acquisitionState,
        DamageItemModelsIDs: ids,
      });
    } else {
      let ids: any = [...acquisitionState.DamageItemModelsIDs];
      ids.map((id: any) => {
        if (id === damageItemModelId) {
          return (ids = ids.filter((id: any) => id !== damageItemModelId));
        } else {
          return ids.push(damageItemModelId);
        }
      });
      setAcquisitionState({
        ...acquisitionState,
        DamageItemModelsIDs: ids,
      });
    }
  };

  // Function to submit the acquisition action and navigate to the select characteristic page
  const onSubmit = () => {
    let formData = new FormData();
    for (let i = 0; i < acquisitionState.DamageItemModelsIDs.length; i++) {
      formData.append(
        `DamagedItemModelsIDs[${i}]`,
        acquisitionState.DamageItemModelsIDs[i]
      );
    }
    for (let i = 0; i < acquisitionState.CharacteristicsIDs.length; i++) {
      formData.append(
        `CharacteristicsIDs[${i}]`,
        acquisitionState.CharacteristicsIDs[i]
      );
    }
    formData.append("PrintGroupID", acquisitionState.PrintGroupID);
    formData.append("DamageNumber", acquisitionState.DamageNumber);
    dispatch(creatPastOrdersActionsSuccess(acquisitionState));
    dispatch(createActionRequest(formData, navigate));
  };

  return (
    <>
      {messageText ? (
        <MessageNotification
          messageText={messageText}
          messageType={messageType}
        />
      ) : null}

      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="capture-article-page-container">
            <div className="capture-article-page-top-section">
              {/* Back button */}
              <div className="capture-article-page-back-btn">
                <Button
                  size="small"
                  icon="back-icon"
                  text="Back"
                  onClick={goBack}
                />
              </div>
              {/* Page Title */}
              <div className="capture-article-page-title">
                <h1>Capture Article</h1>
              </div>

              {/* Numpad input area */}
              <div className="capture-article-page-numpad-number-field">
                <img src={numberIcon} alt="Number icon" title="Number icon" />
                {damageNumberState}
              </div>
            </div>

            {/* characteristics column */}
            <div className="capture-article-page-content-section">
              <div className="capture-article-page-characteristics-list">
                {selectedCharacteristics &&
                  selectedCharacteristics.map(
                    (characteristic: any, index: any) => (
                      <div
                        className="capture-article-page-characteristic"
                        key={index}
                      >
                        <div className="capture-article-page-characteristic-image">
                          <img
                            src={
                              characteristic.image !== undefined
                                ? "data:image/jpeg;base64," +
                                  characteristic.image
                                : image
                            }
                            alt={characteristic.name}
                            title={characteristic.name}
                          />
                        </div>

                        <div className="capture-article-page-characteristic-name">
                          {characteristic.name}
                        </div>
                        {index !== selectedCharacteristics.length - 1 ? (
                          <hr></hr>
                        ) : null}
                      </div>
                    )
                  )}
              </div>

              {/*  Tasks */}
              <div className="capture-article-tasks-container">
                <>
                  <div className="capture-article-tasks-section">
                    {dismantleTaskState.map((task: any, index: any) => {
                      // eslint-disable-next-line no-lone-blocks
                      {
                        dismantleTasksImages &&
                          dismantleTasksImages
                            .filter(
                              (item: any) => item.imageID === task.imageID
                            )
                            .map(function (image: any) {
                              task.image = image.image;
                              return null;
                            });
                      }
                      return (
                        <>
                          <div className="capture-article-tasks-section-task">
                            <div className="capture-article-tasks-section-task-image">
                              {task.image ? (
                                <img
                                  src={"data:image/jpeg;base64," + task.image}
                                  alt="task"
                                />
                              ) : null}
                            </div>
                            {isOpened && task.id === taskID ? (
                              <div key={task.id}>
                                <div className="capture-article-task-item-name-icon-open">
                                  <h2>{task.name}</h2>
                                  <img
                                    src={dropUpIcon}
                                    alt="Drop Up Icon"
                                    className="capture-article-task-item-img"
                                    onClick={() => {
                                      setIsOpened(false);
                                      setTaskID("");
                                    }}
                                  />
                                </div>
                                <p className="capture-article-task-item-opened-description">
                                  {task.description}
                                </p>
                              </div>
                            ) : (
                              <div
                                key={task.id}
                                className="capture-article-task-item-name-icon"
                              >
                                <h2>{task.name}</h2>
                                <img
                                  src={dropDownIcon}
                                  alt="Drop Down Icon"
                                  className="capture-article-task-item-img"
                                  onClick={() => {
                                    setTaskID(task.id);
                                    setIsOpened(true);
                                  }}
                                />
                              </div>
                            )}
                          </div>
                        </>
                      );
                    })}
                  </div>
                </>
                {taskState.length >= 1 ? (
                  <>
                    <div className="capture-article-tasks-section">
                      {taskState.map((task: any, index: any) => {
                        // eslint-disable-next-line no-lone-blocks
                        {
                          tasksImages &&
                            tasksImages
                              .filter(
                                (item: any) => item.imageID === task.imageID
                              )
                              .map(function (image: any) {
                                task.image = image.image;
                                return null;
                              });
                        }
                        return (
                          <>
                            <div className="capture-article-tasks-section-task">
                              <div className="capture-article-tasks-section-task-image">
                                {task.image ? (
                                  <img
                                    src={"data:image/jpeg;base64," + task.image}
                                    alt="task"
                                  />
                                ) : null}
                              </div>
                              {isOpened && task.id === taskID ? (
                                <div key={task.id}>
                                  <div className="capture-article-task-item-name-icon-open">
                                    <h2>{task.name}</h2>
                                    <img
                                      src={dropUpIcon}
                                      alt="Drop Up Icon"
                                      className="capture-article-task-item-img"
                                      onClick={() => {
                                        setIsOpened(false);
                                        setTaskID("");
                                      }}
                                    />
                                  </div>
                                  <p className="capture-article-task-item-opened-description">
                                    {task.description}
                                  </p>
                                </div>
                              ) : (
                                <div
                                  key={task.id}
                                  className="capture-article-task-item-name-icon"
                                >
                                  <h2>{task.name}</h2>
                                  <img
                                    src={dropDownIcon}
                                    alt="Drop Down Icon"
                                    className="capture-article-task-item-img"
                                    onClick={() => {
                                      setTaskID(task.id);
                                      setIsOpened(true);
                                    }}
                                  />
                                </div>
                              )}

                              {task.damageItemModel !== "" ? (
                                <div className="capture-article-page-inputs-section-input-slider">
                                  <input type="checkbox" id={task.id} />
                                  <label
                                    htmlFor={task.id}
                                    onClick={() =>
                                      handleSliderClicked(task.damageItemModel)
                                    }
                                  >
                                    Toggle
                                  </label>
                                </div>
                              ) : null}
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </>
                ) : (
                  <h1 className="capture-article-page-no-tasks-found">
                    No Tasks Found
                  </h1>
                )}
              </div>

              {/* Number pad */}
              <div className="capture-article-page-numpad-section">
                <div className="capture-article-page-numpad-section-numbers">
                  <div
                    className="capture-article-page-numpad-section-numbers-input"
                    onClick={() => handleNumpadClick("1")}
                  >
                    1
                  </div>
                  <div
                    className="capture-article-page-numpad-section-numbers-input"
                    onClick={() => handleNumpadClick("2")}
                  >
                    2
                  </div>
                  <div
                    className="capture-article-page-numpad-section-numbers-input"
                    onClick={() => handleNumpadClick("3")}
                  >
                    3
                  </div>
                  <div
                    className="capture-article-page-numpad-section-numbers-input"
                    onClick={() => handleNumpadClick("4")}
                  >
                    4
                  </div>
                  <div
                    className="capture-article-page-numpad-section-numbers-input"
                    onClick={() => handleNumpadClick("5")}
                  >
                    5
                  </div>
                  <div
                    className="capture-article-page-numpad-section-numbers-input"
                    onClick={() => handleNumpadClick("6")}
                  >
                    6
                  </div>
                  <div
                    className="capture-article-page-numpad-section-numbers-input"
                    onClick={() => handleNumpadClick("7")}
                  >
                    7
                  </div>
                  <div
                    className="capture-article-page-numpad-section-numbers-input"
                    onClick={() => handleNumpadClick("8")}
                  >
                    8
                  </div>
                  <div
                    className="capture-article-page-numpad-section-numbers-input"
                    onClick={() => handleNumpadClick("9")}
                  >
                    9
                  </div>
                  <div
                    className="capture-article-page-numpad-section-numbers-input"
                    onClick={handleEntfClick}
                  >
                    Entf
                  </div>
                  <div
                    className="capture-article-page-numpad-section-numbers-input"
                    onClick={() => handleNumpadClick("0")}
                  >
                    0
                  </div>
                  <div
                    className="capture-article-page-numpad-section-numbers-input"
                    onClick={handleDeleteNumberClick}
                  >
                    <img src={backIcon} alt={"Back"} title={"back"} />
                  </div>
                </div>
                <div className="capture-article-page-complete-acquisition-button">
                  <Button
                    size="medium"
                    text="Complete Acquisition"
                    icon="complete-acquisition"
                    onClick={onSubmit}
                  ></Button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default CaptureArticlePage;
