import "./CategoryCard.css";
import Button from "components/Button/Button";

//images
import image from "assets/images/defaultPicture.png";

type CategoryCardProps = {
  categoriesImages?: any;
  characteristics?: any;
  index?: any;
  category?: any;
  handleEditCategory?: any;
  handleDeleteCategory?: any;

  //(Jad) handle click to navigate to characteristics page
  handleOpenCharacteristicsPage?: any;
};

function CategoryCard(props: CategoryCardProps) {
  return (
    <div className="category-item" key={props.index}>
      {/* (Jad) Adding on click handle to navigate to the characteristics page*/}
      <div className="category-item-title">
        <Button
          text={props.category.name}
          size="medium"
          cropped={true}
          onClick={() => props.handleOpenCharacteristicsPage(props.category.id)}
        />
      </div>
      {props.categoriesImages &&
        props.categoriesImages
          .filter((item: any) => item.imageID === props.category.imageID)
          .map(function (image: any) {
            props.category.image = image.image;
            return null;
          })}

      {/* Card content */}
      <div className="category-item-content" key={props.index + 1}>
        <div className="category-image-container">
          <img
            className="category-image"
            src={
              props.category.image !== undefined
                ? "data:image/jpeg;base64," + props.category.image
                : image
            }
            alt={props.category.name}
            title={props.category.name}
          />
        </div>

        {/* Edit Button */}
        <div className="category-item-buttons-container">
          <Button
            text="Edit"
            size="small"
            icon="edit-icon"
            onClick={() => props.handleEditCategory(props.category.id)}
          />

          {/* First category indicator */}
          <div className="first-category">
            {props.category.firstCategory ? "#1" : ""}
          </div>

          {/* Delete Button */}
          <Button
            text="Delete"
            size="small"
            icon="delete-icon"
            redBtn={true}
            onClick={() => props.handleDeleteCategory(props.category.id)}
          />
        </div>

        {/* List of the characteristics */}
        <div className="category-characteristics">
          {props.characteristics && props.characteristics.length > 1 ? (
            props.characteristics.map((characteristic: any, index: any) => {
              return <p key={index}> {characteristic.name}</p>;
            })
          ) : (
            <p>No Characteristics Found</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default CategoryCard;
