import "./PlacementCard.css";
import Button from "components/Button/Button";

//images
// import image from "assets/images/defaultPicture.png";

type PlacementCardProps = {
  index?: any;
  placement?: any;
  handleEditCategory?: any;
  handleDeleteCategory?: any;
};

function PlacementCard(props: PlacementCardProps) {
  // variable to store the characteristics Group Name
  let characteristicsGroupName = props.placement?.characteristicsGroup?.name;
  // array to store the placement Damaged Item Models
  let placementDamagedItemModels = props.placement.damagedItemModels;

  return (
    <div className="placement-item" key={props.index}>
      {/* card title: placement name */}
      <div className="placement-title">
        <h1>{props.placement?.name}</h1>
      </div>
      {/* characteristics Group Name */}
      <div className="placement-sub-title">
        <h1>{characteristicsGroupName}</h1>
      </div>

      <div className="placement-item-content" key={props.index + 1}>
        <div className="placement-image-container">
          {/* damaged types names */}
          {placementDamagedItemModels.map(
            (damagedItemModel: any, index: any) => {
              return (
                <div key={index} className="placement-damage-type">
                  <h1>{damagedItemModel?.damageType?.name}</h1>
                </div>
              );
            }
          )}
        </div>

        {/* edit & delete buttons */}
        <div className="placement-buttons-container">
          <Button
            text="Edit"
            size="small"
            icon="edit-icon"
            onClick={() => props.handleEditCategory(props.placement.id)}
          />

          <Button
            text="Delete"
            size="small"
            icon="delete-icon"
            redBtn={true}
            onClick={() => props.handleDeleteCategory(props.placement.id)}
          />
        </div>

        {/* List of the characteristics */}
        <div className="placement-bottom-blue"></div>
      </div>
    </div>
  );
}

export default PlacementCard;
