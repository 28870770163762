// services
// import ImageService from "services/imageService";
import ActionService from "services/actionService";

// actions
import { setMessage } from "store/actions/messageAction";
import {
  getWorkplacePendingActionsSuccess,
  deleteActionSuccess,
  getActionsFilteredByDateSuccess,
  // createActionSuccess,
} from "store/actions/actionAction";
import { removeLoading, setLoading } from "store/actions/loadingAction";

export const getActionsFilteredByDateRequest =
  (startDate: any, endDate: any) => (dispatch: any) => {
    try {
      // set loading indicator
      dispatch(setLoading());
      // API call to get all categories
      ActionService.getActionsFilteredByDate(startDate, endDate).then(
        (response: any) => {
          dispatch(removeLoading());

          dispatch(getActionsFilteredByDateSuccess(response.data));
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          // show the error to the user
          dispatch(setMessage(message, "error"));
          dispatch(removeLoading());
        }
      );
    } catch (e) {
      console.log("error", e);
      dispatch(removeLoading());
      dispatch(setMessage(e, "error"));
    }
  };

export const getWorkplacePendingActionsRequest = () => (dispatch: any) => {
  try {
    // set loading indicator
    dispatch(setLoading());
    // API call to get all categories
    ActionService.getWorkplacePendingActions().then(
      (response: any) => {
        dispatch(removeLoading());
        dispatch(getWorkplacePendingActionsSuccess(response.data));
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        // show the error to the user
        dispatch(setMessage(message, "error"));
        dispatch(removeLoading());
      }
    );
  } catch (e) {
    console.log("error", e);
    dispatch(removeLoading());
    dispatch(setMessage(e, "error"));
  }
};

export const createActionRequest =
  (action: any, navigate: any) => (dispatch: any) => {
    try {
      // set loading indicator
      dispatch(setLoading());
      // API call to get all categories
      ActionService.createAction(action).then(
        (response: any) => {
          dispatch(removeLoading());
          dispatch(setMessage("Acquisition completed!", "success"));
          navigate("/select-characteristic");
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          // show the error to the user
          dispatch(setMessage(message, "error"));
          dispatch(removeLoading());
        }
      );
    } catch (e) {
      console.log("error", e);
      dispatch(removeLoading());
      dispatch(setMessage(e, "error"));
    }
  };

export const updateActionRequest =
  (action: any, navigate: any) => (dispatch: any) => {
    try {
      // set loading indicator
      dispatch(setLoading());
      // API call to get all categories
      ActionService.updateAction(action).then(
        (response: any) => {
          dispatch(removeLoading());
          dispatch(setMessage("Acquisition Updated!", "success"));
          navigate("/repair-overview");
          dispatch(getWorkplacePendingActionsRequest());
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          // show the error to the user
          dispatch(setMessage(message, "error"));
          dispatch(removeLoading());
        }
      );
    } catch (e) {
      console.log("error", e);
      dispatch(removeLoading());
      dispatch(setMessage(e, "error"));
    }
  };

export const deleteActionRequest = (actionID: any) => (dispatch: any) => {
  try {
    dispatch(setLoading());
    // API call
    ActionService.deleteAction(actionID).then(
      (response: any) => {
        // dispatch the delete action success action and send the actionID for the deleted category
        dispatch(deleteActionSuccess(actionID));
        dispatch(removeLoading());
        dispatch(setMessage("Action deleted successfully", "success"));
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch(setMessage(message, "error"));
        dispatch(removeLoading());
      }
    );
  } catch (e) {
    console.log("error", e);
    dispatch(removeLoading());
    dispatch(setMessage(e, "error"));
  }
};
