import "./UserPopup.css";

import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";

//thunk
import {
  createUserRequest,
  editUserRequest,
  resetPasswordRequest,
} from "store/thunks/userThunk";

//selector
import { getAllUsersSelector } from "store/selectors/userSelector";
import { getAllLocationsSelector } from "store/selectors/locationSelector";

//validation
import {
  UserSchema,
  EditUserSchema,
} from "validations/sensrecAdminValidations";

//date picker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { parseISO } from "date-fns";
import de from "date-fns/locale/de";

//images
import ipIcon from "assets/icons/ip-icon.png";
import close from "assets/icons/close-button.png";
import nameIcon from "assets/icons/name-icon.png";
import cityIcon from "assets/icons/city-icon.png";
import loginIcon from "assets/icons/login-icon.png";

//components
import Button from "components/Button/Button";

type UserPopupProps = {
  userID?: any;
  hidePopUp?: any;
};
function UserPopup(props: UserPopupProps) {
  const dispatch = useDispatch<any>();

  //redux state
  let users = useSelector((state: any) => getAllUsersSelector(state));
  let locations = useSelector((state: any) => getAllLocationsSelector(state));

  //local state
  const [userState, setUserState] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    ip: "",
    locationID: "",
    licenseValidTo: new Date(),
    licenseValidFrom: new Date(),
    roles: [],
  });

  useEffect(() => {
    props.userID && getUser(props.userID);
    // because we are in a popup we should stop scrolling so we add this classes
    let popupContainer = document.getElementById(
      "user-popup-main-container"
    ) as any;
    popupContainer && popupContainer.classList.remove("hide");
    document.body.classList.add("user-popup-overflow-hidden");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.userID]);

  //in case of edit get the value of the commissioned costumer and save it in the state
  const getUser = (userID: any) => {
    let user = users.filter((user: any) => user.id === userID);

    let role: any = [];
    role.push(user[0].roles[0]);

    setUserState({
      ...userState,
      firstName: user[0].firstname,
      lastName: user[0].lastname,
      email: user[0].email,
      password: user[0].password,
      ip: user[0].ip,
      locationID: user[0].location.id,
      licenseValidTo: parseISO(user[0].licenseValidTo),
      licenseValidFrom: parseISO(user[0].licenseValidFrom),
      roles: role,
    });
  };

  const handleInputChange = (event: any) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    setUserState({
      ...userState,
      [name]: value,
    });
  };

  // Handle ValidFrom date Change
  const handleValidFromChange = (date: any) => {
    setUserState({ ...userState, licenseValidFrom: date });
  };

  const handleRoleChange = (event: any) => {
    let role = [] as any;
    role.push(event.target.value);
    setUserState({ ...userState, roles: role });
  };

  // Handle ValidTo date Change
  const handleValidToChange = (date: any) => {
    setUserState({ ...userState, licenseValidTo: date });
  };

  const onSubmit = (
    fields: any,
    { setStatus, setSubmitting }: { setStatus: any; setSubmitting: any }
  ) => {
    if (!props.userID) {
      const data = {
        firstName: userState.firstName,
        lastName: userState.lastName,
        email: userState.email,
        password: userState.password,
        ip: userState.ip,
        locationID: parseInt(userState.locationID),
        licenseValidTo: userState.licenseValidTo.toISOString(),
        licenseValidFrom: userState.licenseValidFrom.toISOString(),
        roles: userState.roles,
      };
      dispatch(createUserRequest(data));
      props.hidePopUp();
    } else {
      const data = {
        firstName: userState.firstName,
        lastName: userState.lastName,
        ip: userState.ip,
        locationID: parseInt(userState.locationID),
        licenseValidTo: userState.licenseValidTo.toISOString(),
        licenseValidFrom: userState.licenseValidFrom.toISOString(),
        roles: userState.roles,
      };
      dispatch(editUserRequest(props.userID, data));
      props.hidePopUp();
    }
  };

  const handleSubmit = () => {
    document.body.classList.remove("user-popup-overflow-hidden");
  };

  console.log(userState)

  return (
    <>
      <div className="user-popup-main-container" id="user-popup-main-container">
        <div className="user-popup-overlay" onClick={props.hidePopUp}></div>
        <div className="user-container">
          {/* Header: title and close button */}
          <div className="user-top-section">
            {props.userID ? <h1>Edit User</h1> : <h1> Create New User</h1>}
            <img
              className="user-popup-close"
              src={close}
              onClick={props.hidePopUp}
              alt="close Popup"
              title="close Popup"
            />
          </div>

          <Formik
            initialValues={userState}
            validationSchema={props.userID ? EditUserSchema : UserSchema}
            onSubmit={onSubmit}
            enableReinitialize
          >
            {({ values, errors, touched, isSubmitting, setFieldValue }) => {
              return (
                <>
                  <Form>
                    <>
                      <div className="user-popup-form-container">
                        {/* Section 1: user first name, last name, role, validation dates */}
                        <div className="user-fields-section">
                          {/* First name input */}
                          <h3 className="user-popup-input-label">First Name</h3>
                          <div className="user-popup-input">
                            <div className="user-popup-input">
                              <Field
                                className="my-input"
                                type="text"
                                id="firstName"
                                name="firstName"
                                value={userState.firstName}
                                placeholder="First Name"
                                onChange={handleInputChange}
                              />
                              {touched.firstName && errors.firstName ? (
                                <div
                                  className="user-popup-error-message"
                                  role="alert"
                                >
                                  {errors.firstName}
                                </div>
                              ) : null}
                            </div>
                            <div className="user-icon">
                              <img src={nameIcon} alt="Name Icon" />
                            </div>
                          </div>
                          {/* last name input input */}
                          <h3 className="user-popup-input-label">Last Name</h3>
                          <div className="user-popup-input">
                            <div className="user-popup-input">
                              <Field
                                className="my-input"
                                type="text"
                                id="lastName"
                                name="lastName"
                                value={userState.lastName}
                                placeholder="Last Name"
                                onChange={handleInputChange}
                              />
                              {touched.lastName && errors.lastName ? (
                                <div
                                  className="user-popup-error-message"
                                  role="alert"
                                >
                                  {errors.lastName}
                                </div>
                              ) : null}
                            </div>
                            <div className="user-icon">
                              <img src={nameIcon} alt="Name Icon" />
                            </div>
                          </div>

                          {/* role input */}
                          <h3 className="user-popup-input-label">Role</h3>
                          <div className="user-popup-input">
                            <div className="user-popup-input">
                              <Field
                                as="select"
                                className="my-input"
                                id="roles"
                                name="roles"
                                value={userState.roles[0]}
                                placeholder="Role"
                                onChange={handleRoleChange}
                              >
                                {props.userID ? (
                                  <>
                                    {userState.roles[0] === "Admin" ? (
                                      <>
                                        <option value="Admin" selected>
                                          Admin
                                        </option>
                                        <option value="SensrecAdmin">
                                          Sensrec Admin
                                        </option>
                                      </>
                                    ) : (
                                      <>
                                        <option value="Admin">Admin</option>
                                        <option value="SensrecAdmin" selected>
                                          Sensrec Admin
                                        </option>
                                      </>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    <option value="">Select a Role</option>
                                    <option value="Admin">Admin</option>
                                    <option value="SensrecAdmin">
                                      Sensrec Admin
                                    </option>
                                  </>
                                )}
                              </Field>
                              {touched.roles && errors.roles ? (
                                <div
                                  className="user-popup-error-message"
                                  role="alert"
                                >
                                  {errors.roles}
                                </div>
                              ) : null}
                              <div className="user-icon">
                                <img src={nameIcon} alt="Name Icon" />
                              </div>
                            </div>
                          </div>

                          {/* Dates section*/}
                          <div className="user-popup-date-picker-section">
                            <div className="user-popup-date-picker-item">
                              <label className="user-popup-date-picker-section-label">
                                License Valid From:
                              </label>
                              <DatePicker
                                name="validFrom"
                                selected={userState.licenseValidFrom}
                                onChange={(date: Date) =>
                                  handleValidFromChange(date)
                                }
                                isClearable
                                dateFormat="dd.MM.yyyy"
                                closeOnScroll={true}
                                showYearDropdown
                                scrollableMonthYearDropdown
                                locale={de}
                              />
                            </div>
                            <div className="user-popup-date-picker-item">
                              <label className="user-popup-date-picker-section-label">
                                License Valid To:
                              </label>
                              <DatePicker
                                name="validTo"
                                selected={userState.licenseValidTo}
                                onChange={(date: Date) =>
                                  handleValidToChange(date)
                                }
                                isClearable
                                dateFormat="dd.MM.yyyy"
                                closeOnScroll={true}
                                minDate={Date.now() as any}
                                showYearDropdown
                                scrollableMonthYearDropdown
                                locale={de}
                              />
                            </div>
                          </div>
                        </div>

                        {/* Section 2: ip, location id, email, password*/}
                        <div className="user-fields-section">
                          {/* ip input */}
                          <h3 className="user-popup-input-label">IP</h3>
                          <div className="user-popup-input">
                            <div className="user-popup-input">
                              <Field
                                className="my-input"
                                type="text"
                                id="ip"
                                name="ip"
                                value={userState.ip}
                                placeholder="255.255.255.255"
                                onChange={handleInputChange}
                              />
                              {touched.ip && errors.ip ? (
                                <div
                                  className="user-popup-error-message"
                                  role="alert"
                                >
                                  {errors.ip}
                                </div>
                              ) : null}
                            </div>
                            <div className="user-icon">
                              <img src={ipIcon} alt="Ip Icon" />
                            </div>
                          </div>
                          {/* location input */}
                          <h3 className="user-popup-input-label">Location</h3>
                          <div className="user-popup-input">
                            <div className="user-popup-input">
                              <Field
                                as="select"
                                className="my-input"
                                id="locationID"
                                name="locationID"
                                value={userState.locationID}
                                placeholder="Locations"
                                onChange={handleInputChange}
                              >
                                {props.userID && userState.locationID ? (
                                  <>
                                    {locations &&
                                      locations.map(
                                        // eslint-disable-next-line array-callback-return
                                        (location: any, index: any) =>
                                          location.id ===
                                          userState.locationID ? (
                                            <option
                                              value={location.id}
                                              key={index}
                                              selected
                                            >
                                              {location.name}
                                            </option>
                                          ) : (
                                            <option
                                              value={location.id}
                                              key={index}
                                            >
                                              {location.name}
                                            </option>
                                          )
                                      )}
                                  </>
                                ) : (
                                  <>
                                    <option value="">Select Location</option>
                                    {locations &&
                                      locations.map(
                                        (location: any, index: any) => (
                                          <option
                                            value={location.id}
                                            key={index}
                                          >
                                            {location.name}
                                          </option>
                                        )
                                      )}
                                  </>
                                )}
                              </Field>
                              {touched.locationID && errors.locationID ? (
                                <div
                                  className="user-popup-error-message"
                                  role="alert"
                                >
                                  {errors.locationID}
                                </div>
                              ) : null}
                              <div className="user-icon">
                                <img
                                  src={cityIcon}
                                  title="City Icon"
                                  alt="City Icon"
                                />
                              </div>
                            </div>
                          </div>

                          {/* email input */}
                          <h3 className="user-popup-input-label">Email</h3>
                          <div className="user-popup-input">
                            <div className="user-popup-input">
                              <input
                                className="my-input"
                                type="text"
                                id="email"
                                name="email"
                                value={userState.email}
                                placeholder="Email"
                                onChange={handleInputChange}
                                readOnly = {props.userID ? true : false}
                              />
                              {touched.email && errors.email ? (
                                <div
                                  className="user-popup-error-message"
                                  role="alert"
                                >
                                  {errors.email}
                                </div>
                              ) : null}
                            </div>
                            <div className="user-icon">
                              <img src={nameIcon} alt="Name Icon" />
                            </div>
                          </div>

                          {/* password input */}
                          {/* check if it is edit than show a rest password button instead of the password input */}
                          {props.userID ? (
                            <>
                              <div className="user-popup-reset-password">
                                <Button
                                  size="medium"
                                  text="Reset Password"
                                  redBtn={true}
                                  // icon="add-icon"
                                  onClick={() =>
                                    dispatch(resetPasswordRequest(props.userID))
                                  }
                                ></Button>
                              </div>
                            </>
                          ) : (
                            <>
                              <h3 className="user-popup-input-label">
                                Password
                              </h3>
                              <div className="user-popup-input">
                                <div className="user-popup-input">
                                  <Field
                                    className="my-input"
                                    type="password"
                                    id="password"
                                    name="password"
                                    value={userState.password}
                                    placeholder="Email"
                                    onChange={handleInputChange}
                                  />
                                  {touched.password && errors.password ? (
                                    <div
                                      className="user-popup-error-message"
                                      role="alert"
                                    >
                                      {errors.password}
                                    </div>
                                  ) : null}
                                </div>
                                <div className="user-icon">
                                  <img src={loginIcon} alt="Login Icon" />
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                      {/* Submit Button: Create or Edit */}
                      <div className="company-submit-button-container">
                        {!props.userID ? (
                          <Button
                            size="small"
                            text="Create"
                            icon="add-icon"
                            type="submit"
                            onClick={handleSubmit}
                          ></Button>
                        ) : (
                          <Button
                            icon="edit-icon"
                            size="small"
                            text="Edit"
                            type="submit"
                            onClick={handleSubmit}
                          ></Button>
                        )}
                      </div>
                    </>
                  </Form>
                </>
              );
            }}
          </Formik>
        </div>
      </div>
    </>
  );
}

export default UserPopup;
