import "./ArticleGroupPopup.css";

import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";

//thunks
import {
  createPrintGroupRequest,
  editPrintGroupRequest,
} from "store/thunks/printGroupThunk";

//selectors
import { getAllPrintGroups } from "store/selectors/printGroupSelector";

//validation
import { ArticleGroupSchema } from "validations/adminValidations";

//components
import Button from "components/Button/Button";

//images
import close from "assets/icons/close-button.png";
import articleGroupIcon from "assets/icons/menu/good-allocation-icon.png";

type ArticleGroupPopupProps = {
  hidePopUp?: any;
  printGroupId?: any;
};

function ArticleGroupPopup(props: ArticleGroupPopupProps) {
  const dispatch = useDispatch();

  //redux state
  const printGroups = useSelector((state: any) => getAllPrintGroups(state));

  //local state
  const [articleGroupState, setArticleGroupState] = useState({
    name: "",
  });

  useEffect(() => {
    props.printGroupId && getPrintGroup(props.printGroupId);
    // because we are in a popup we should stop scrolling so we add this classes
    let popupContainer = document.getElementById(
      "article-group-popup-main-container"
    ) as any;
    popupContainer && popupContainer.classList.remove("hide");
    document.body.classList.add("article-group-popup-overflow-hidden");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.printGroupId]);

  const getPrintGroup = (printGroupId: any) => {
    let printGroup = printGroups.filter(
      (printGroup: any) => printGroup.id === printGroupId
    );

    setArticleGroupState({
      ...articleGroupState,
      name: printGroup[0].name,
    });
  };

  //Handle when the name is changed
  const handleNameInputChange = (event: any) => {
    setArticleGroupState({ ...articleGroupState, name: event.target.value });
  };

  const onSubmit = (
    fields: any,
    { setStatus, setSubmitting }: { setStatus: any; setSubmitting: any }
  ) => {
    const data = {
      name: articleGroupState.name,
    };

    if (!props.printGroupId) {
      dispatch(createPrintGroupRequest(data));
      props.hidePopUp();
    } else {
      dispatch(editPrintGroupRequest(props.printGroupId, data));
      props.hidePopUp();
    }
  };

  const handleSubmit = () => {
    document.body.classList.remove("article-group-popup-overflow-hidden");
  };

  return (
    <>
      <div
        className="article-group-popup-main-container"
        id="article-group-popup-main-container"
      >
        <div
          className="article-group-popup-overlay"
          onClick={props.hidePopUp}
        ></div>
        <div className="article-group-container">
          {/* Header: title and close button */}
          <div className="article-group-top-section">
            {props.printGroupId ? (
              <h1>Edit Article Group</h1>
            ) : (
              <h1> Create New Article Group</h1>
            )}
            <img
              className="article-group-popup-close"
              src={close}
              onClick={props.hidePopUp}
              alt="close Popup"
              title="close Popup"
            />
          </div>

          <Formik
            initialValues={articleGroupState}
            validationSchema={ArticleGroupSchema}
            onSubmit={onSubmit}
            enableReinitialize
          >
            {({ values, errors, touched, isSubmitting, setFieldValue }) => {
              return (
                <>
                  <Form>
                    <div className="article-group-popup-form-container">
                      {/* Article Group name*/}
                      <div className="article-group-fields-section">
                        <h3 className="article-group-popup-input-label">Name</h3>
                        <div className="article-group-popup-input">
                          <div className="article-group-popup-input">
                            <Field
                              className="my-input"
                              type="text"
                              id="name"
                              name="name"
                              value={articleGroupState.name}
                              placeholder="Name"
                              onChange={handleNameInputChange}
                            />
                            {touched.name && errors.name ? (
                              <div
                                className="article-group-popup-error-message"
                                role="alert"
                              >
                                {errors.name}
                              </div>
                            ) : null}
                          </div>
                          <div className="article-group-icon">
                            <img
                              src={articleGroupIcon}
                              title="Article Group Icon"
                              alt="Article Group Icon"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Submit Button: Create or Edit */}
                    <div className="article-group-submit-button-container">
                      {!props.printGroupId ? (
                        <Button
                          size="small"
                          text="Create"
                          icon="add-icon"
                          type="submit"
                          onClick={handleSubmit}
                        ></Button>
                      ) : (
                        <Button
                          icon="edit-icon"
                          size="small"
                          text="Edit"
                          type="submit"
                          onClick={handleSubmit}
                        ></Button>
                      )}
                    </div>
                  </Form>
                </>
              );
            }}
          </Formik>
        </div>
      </div>
    </>
  );
}

export default ArticleGroupPopup;
