export const GET_ALL_CHARACTERISTICS_SUCCESS =
  "GET_ALL_CHARACTERISTICS_SUCCESS";
export const getAllCharacteristicsSuccess = (
  characteristics: any,
  characteristicsImages: any
) => ({
  type: GET_ALL_CHARACTERISTICS_SUCCESS,
  payload: { characteristics, characteristicsImages },
});


export const GET_ALL_CHARACTERISTICS_BY_CATEGORY_ID_SUCCESS =
  "GET_ALL_CHARACTERISTICS_BY_CATEGORY_ID_SUCCESS";
export const getAllCharacteristicsByCategoryIdSuccess = (
  characteristics: any,
  characteristicsImages: any
) => ({
  type: GET_ALL_CHARACTERISTICS_BY_CATEGORY_ID_SUCCESS,
  payload: { characteristics, characteristicsImages },
});

export const CREATE_CHARACTERISTIC_SUCCESS = "CREATE_CHARACTERISTIC_SUCCESS";
export const createCharacteristicSuccess = (
  newCharacteristic: any,
  newCharacteristicImage?: any
) => ({
  type: CREATE_CHARACTERISTIC_SUCCESS,
  payload: { newCharacteristic, newCharacteristicImage },
});

export const EDIT_CHARACTERISTIC_SUCCESS = "EDIT_CHARACTERISTIC_SUCCESS";
export const editCharacteristicSuccess = (
  updateCharacteristic: any,
  updateCharacteristicImage?: any
) => ({
  type: EDIT_CHARACTERISTIC_SUCCESS,
  payload: { updateCharacteristic, updateCharacteristicImage },
});

export const DELETE_CHARACTERISTIC_SUCCESS = "DELETE_CHARACTERISTIC_SUCCESS";
export const deleteCharacteristicSuccess = (characteristicId: any) => ({
  type: DELETE_CHARACTERISTIC_SUCCESS,
  payload: characteristicId,
});
