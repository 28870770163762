//services
import CollectionListService from "services/collectionListService";

//Actions
import { setMessage } from "store/actions/messageAction";
import { setLoading, removeLoading } from "store/actions/loadingAction";
import {
  createCollectionListSuccess,
  getCollectionListFilteredByDateSuccess,
} from "store/actions/collectionListAction";

export const createCollectionListRequest = (data: any) => (dispatch: any) => {
  try {
    //set loading
    dispatch(setLoading());
    //Api call to save collection list
    CollectionListService.createCollectionList(data).then(
      (response: any) => {
        dispatch(createCollectionListSuccess(response.data));
        dispatch(removeLoading());
        dispatch(setMessage("Collection list created successfully", "success"));
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch(setMessage(message, "error"));
        dispatch(removeLoading());
      }
    );
  } catch (e) {
    console.log("error", e);
    dispatch(removeLoading());
    dispatch(setMessage(e, "error"));
  }
};

export const getCollectionListFilteredByDateRequest =
  (startDate: any, endDate: any) => (dispatch: any) => {
    try {
      dispatch(setLoading());
      CollectionListService.getCollectionListFilteredByDate(
        startDate,
        endDate
      ).then(
        (response: any) => {
          if (response.data !== undefined) {
            dispatch(removeLoading());
            dispatch(getCollectionListFilteredByDateSuccess(response.data));
          }
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          dispatch(setMessage(message, "error"));
          dispatch(removeLoading());
        }
      );
    } catch (e) {
      console.log("error", e);
      dispatch(removeLoading());
      dispatch(setMessage(e, "error"));
    }
  };
