import "./CharacteristicsPopup.css";

import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";

//thunks
import {
  createNewCharacteristicRequest,
  editCharacteristicRequest,
} from "store/thunks/characteristicThunk";

//selectors
import { getAllCategories } from "store/selectors/categorySelector";
import { getAllPrintGroups } from "store/selectors/printGroupSelector";
import {
  getAllCharacteristics,
  getAllCharacteristicsImages,
} from "store/selectors/characteristicSelector";

//validations
import { CharacteristicSchema } from "validations/adminValidations";

//images
import close from "assets/icons/close-button.png";
import image from "assets/images/defaultPicture.png";
import helpImage from "assets/icons/help-icon.png";
import helpImageClicked from "assets/icons/help-icon-clicked.png";

//components
import Input from "components/Input/Input";
import Button from "components/Button/Button";

// import { setMessage } from "store/actions/messageAction";

type characteristicsPopUpProps = {
  hidePopUp?: any;
  characteristicID?: any;
  categoryID?: any;
  categoryName?: any;
};

function CharacteristicsPopup(props: characteristicsPopUpProps) {
  const dispatch = useDispatch<any>();

  //set image Id of current function
  // const [imageID, setImageID] = useState(0);

  // const [initialCategoryId, setInitialCategoryId] = useState("");
  const [isHelpButtonClickedState, setIsHelpButtonClickedState] =
    useState(false);
  const [selectedFollowingCategory, setSelectedFollowingCategory] = useState(
    []
  );

  //state for saving preview image when selected:
  const [baseImage, setBaseImage] = useState<any>(image);

  // state for saving the characteristic
  const [characteristicState, setCharacteristicState] = useState({
    name: "",
    articleNumber: "",
    categoryID: props.categoryID,
    followingCategoryID: "",
    printGroupID: "",
    image: image,
  });

  const [imageIsTooLarge, setImageIsTooLarge] = useState(false);

  //redux state
  const categories = useSelector((state: any) => getAllCategories(state));
  const printGroups = useSelector((state: any) => getAllPrintGroups(state));
  let characteristics = useSelector((state: any) =>
    getAllCharacteristics(state)
  );
  let characteristicsImages = useSelector((state: any) =>
    getAllCharacteristicsImages(state)
  );

  //Get the characteristic we want to edit from redux state
  const getCharacteristic = (characteristicId: any) => {
    let characteristic = characteristics.filter(
      (characteristic: any) => characteristic.id === characteristicId
    );

    // setCharacteristicState({name: })
    setCharacteristicState({
      ...characteristicState,
      name: characteristic[0].name,
    });
    setCharacteristicState({
      ...characteristicState,
      articleNumber: characteristic[0].articleNumber,
    });
    const newCharacteristic = { ...characteristicState };
    newCharacteristic["name"] = characteristic[0].name;
    if (characteristic[0].articleNumber) {
      newCharacteristic["articleNumber"] = characteristic[0].articleNumber;
    }
    newCharacteristic["categoryID"] = characteristic[0].category.id;
    if (characteristic[0].followingCategory) {
      newCharacteristic["followingCategoryID"] =
        characteristic[0].followingCategory.id;
    }
    if (characteristic[0].printGroup) {
      newCharacteristic["printGroupID"] = characteristic[0].printGroup?.id;
    }
    setCharacteristicState(newCharacteristic);
    let characteristicImage = characteristicsImages.filter(
      (image: any) => image.imageID === characteristic[0].imageID
    );
    // setInitialCategoryId(characteristic[0].category.id);
    characteristicImage[0]?.image &&
      setBaseImage("data:image/jpeg;base64," + characteristicImage[0]?.image);
  };

  useEffect(() => {
    props.characteristicID && getCharacteristic(props.characteristicID);
    // because we are in a popup we should stop scrolling so we add this classes
    let popupContainer = document.getElementById(
      "characteristic-popup-main-container"
    ) as any;
    popupContainer && popupContainer.classList.remove("hide");
    document.body.classList.add("characteristic-popup-overflow-hidden");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // show image preview when selected:
  const handleImageChange = async (event: any) => {
    const file = event.target.files[0];
    if (file.size > 500000) {
      setImageIsTooLarge(true);
      setBaseImage(image)
    } else {
      const base64 = await convertBase64(file);
      setBaseImage(base64);
      setCharacteristicState({ ...characteristicState, image: file });
      setImageIsTooLarge(false);
    }
  };

  // function to convert selected image to base64 to display it as preview:
  const convertBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleInputChange = (event: any) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    setCharacteristicState({ ...characteristicState, [name]: value });
  };

  // const handleSubmit = () => {
  //   document.body.classList.remove("characteristic-popup-overflow-hidden");
  // };

  //useEffect to catch if their a following category selected to show the help button
  let helpContainer = document.getElementById(
    "characteristic-popup-help"
  ) as any;
  useEffect(() => {
    characteristicState.followingCategoryID &&
      helpContainer.classList.add("show");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [characteristicState.followingCategoryID]);

  const handleHelpButtonClick = () => {
    if (isHelpButtonClickedState) {
      setIsHelpButtonClickedState(false);
      helpContainer.classList.remove("clicked");
    } else {
      let category = categories.filter(
        (cat: any) =>
          cat.id === parseInt(characteristicState.followingCategoryID)
      );
      setSelectedFollowingCategory(category[0].characteristics);
      setIsHelpButtonClickedState(true);
      helpContainer.classList.add("clicked");
    }
  };

  function onSubmit(
    fields: any,
    {
      setStatus,
      setSubmitting,
    }: {
      setStatus: any;
      setSubmitting: any;
    }
  ) {
    const {
      name,
      articleNumber,
      categoryID,
      followingCategoryID,
      printGroupID,
      image,
    } = characteristicState;
    let data = new FormData();
    data.append("Name", name);
    data.append("ArticleNumber", articleNumber);
    data.append("CategoryID", categoryID);
    data.append("FollowingCategoryID", followingCategoryID);
    data.append("PrintGroupID", printGroupID);
    data.append("Image", image);
    if (!imageIsTooLarge) {
      if (props.characteristicID) {
        dispatch(editCharacteristicRequest(props.characteristicID, data));
        props.hidePopUp();
      } else {
        dispatch(createNewCharacteristicRequest(data));
        props.hidePopUp();
      }
    }
  }

  return (
    <>
      <div
        id="characteristic-popup-main-container"
        className="characteristic-popup-main-container"
      >
        <div
          className="characteristic-popup-overlay"
          onClick={props.hidePopUp}
        ></div>
        <div className="characteristic-popup-container">
          {/* top section title and close button */}
          <div className="characteristic-popup-top-section">
            <div>
              <h1>Add New Characteristic</h1>
            </div>
            <img
              className="characteristic-popup-close"
              src={close}
              onClick={props.hidePopUp}
              alt="close Popup"
              title="close Popup"
            />
          </div>
          <>
            <Formik
              initialValues={characteristicState}
              validationSchema={CharacteristicSchema}
              onSubmit={onSubmit}
              enableReinitialize
            >
              {({ values, errors, touched, isSubmitting, setFieldValue }) => {
                return (
                  <>
                    <Form>
                      <div className="characteristic-popup-form-container">
                        <div className="characteristic-popup-fields-section">
                          <div className="characteristic-popup-inputs-wrapper">
                            {/* Name input */}
                            <div className="characteristic-popup-input-label">
                              <h3>Name</h3>
                            </div>
                            <div className="characteristic-popup-input">
                              <Field
                                className="my-input"
                                type="text"
                                id="name"
                                name="name"
                                value={characteristicState.name}
                                placeholder="Name"
                                onChange={handleInputChange}
                              />
                              {touched.name && errors.name ? (
                                <div
                                  className="characteristic-error-message"
                                  role="alert"
                                >
                                  {errors.name}
                                </div>
                              ) : null}
                              <div className="name-icon"></div>
                            </div>
                          </div>

                          <div className="characteristic-popup-input-wrapper">
                            {/* Article Number input */}
                            <div className="characteristic-popup-input-label">
                              <h3>Item Number</h3>
                            </div>
                            <div className="characteristic-popup-input">
                              <Field
                                className="my-input"
                                type="text"
                                id="articleNumber"
                                name="articleNumber"
                                value={characteristicState.articleNumber}
                                placeholder="Optional"
                                onChange={handleInputChange}
                              />
                              <div className="item-number-icon"></div>
                            </div>
                          </div>

                          {/* Category dropdown */}
                          <div className="characteristic-popup-input-wrapper">
                            <div className="characteristic-popup-input-label">
                              <h3>Category</h3>
                            </div>
                            <div className="characteristic-popup-input">
                              <Field
                                as="select"
                                className="my-input"
                                id="categoryID"
                                name="categoryID"
                                value={characteristicState.categoryID}
                                placeholder="Type Of Clothing"
                                onChange={handleInputChange}
                              >
                                {categories &&
                                  categories.map(
                                    // eslint-disable-next-line array-callback-return
                                    (category: any, index: any) =>
                                      category.id === props.categoryID ? (
                                        <option
                                          value={category.id}
                                          key={index}
                                          selected
                                        >
                                          {category.name}
                                        </option>
                                      ) : (
                                        <option value={category.id} key={index}>
                                          {category.name}
                                        </option>
                                      )
                                  )}
                              </Field>
                              {touched.categoryID && errors.categoryID ? (
                                <div
                                  className="characteristic-error-message"
                                  role="alert"
                                >
                                  {errors.categoryID}
                                </div>
                              ) : null}
                              <div className="category-icon"></div>
                            </div>
                          </div>

                          {/* Following Category dropdown */}
                          <div className="characteristic-popup-input-wrapper">
                            <div className="characteristic-popup-input-label">
                              <h3>Following Category</h3>
                            </div>
                            <div className="characteristic-popup-input">
                              <Field
                                as="select"
                                className="my-input"
                                id="followingCategoryID"
                                name="followingCategoryID"
                                value={characteristicState.followingCategoryID}
                                onChange={handleInputChange}
                              >
                                {props.characteristicID &&
                                characteristicState.followingCategoryID ? (
                                  <>
                                    <option value="">
                                      Remove the following category
                                    </option>
                                    {categories &&
                                      categories.map(
                                        // eslint-disable-next-line array-callback-return
                                        (category: any, index: any) =>
                                          category.id ===
                                          characteristicState.followingCategoryID ? (
                                            <option
                                              value={category.id}
                                              key={index}
                                              selected
                                            >
                                              {category.name}
                                            </option>
                                          ) : (
                                            <option
                                              value={category.id}
                                              key={index}
                                            >
                                              {category.name}
                                            </option>
                                          )
                                      )}
                                  </>
                                ) : (
                                  <>
                                    <option value="">
                                      Select a following category
                                    </option>
                                    {categories &&
                                      categories.map(
                                        (category: any, index: any) => (
                                          <option
                                            value={category.id}
                                            key={index}
                                          >
                                            {category.name}
                                          </option>
                                        )
                                      )}
                                  </>
                                )}
                              </Field>
                              <div className="category-icon"></div>
                            </div>
                          </div>

                          {/* Article Group dropdown */}
                          <div className="characteristic-popup-input-wrapper">
                            <div className="characteristic-popup-input-label">
                              <h3>Print Group</h3>
                            </div>
                            <div className="characteristic-popup-input">
                              <Field
                                as="select"
                                className="my-input"
                                id="printGroupID"
                                name="printGroupID"
                                value={characteristicState.printGroupID}
                                onChange={handleInputChange}
                              >
                                {props.characteristicID &&
                                characteristicState.printGroupID ? (
                                  <>
                                    <option value="">
                                      Remove article group
                                    </option>
                                    {printGroups &&
                                      printGroups.map(
                                        // eslint-disable-next-line array-callback-return
                                        (printGroup: any, index: any) =>
                                          printGroup.id ===
                                          characteristicState.printGroupID ? (
                                            <option
                                              value={printGroup.id}
                                              key={index}
                                              selected
                                            >
                                              {printGroup.name}
                                            </option>
                                          ) : (
                                            <option
                                              value={printGroup.id}
                                              key={index}
                                            >
                                              {printGroup.name}
                                            </option>
                                          )
                                      )}
                                  </>
                                ) : (
                                  <>
                                    <option value="">
                                      Select an article group
                                    </option>
                                    {printGroups &&
                                      printGroups.map(
                                        (printGroup: any, index: any) => (
                                          <option
                                            value={printGroup.id}
                                            key={index}
                                          >
                                            {printGroup.name}
                                          </option>
                                        )
                                      )}
                                  </>
                                )}
                              </Field>
                              <div className="article-group-icon"></div>
                            </div>
                          </div>
                        </div>

                        {/* the help icon to show characteristics of the selected following category */}
                        <div
                          className="characteristic-popup-help"
                          id="characteristic-popup-help"
                          onClick={handleHelpButtonClick}
                        >
                          {isHelpButtonClickedState ? (
                            <img
                              className="characteristic-popup-help-image-clicked"
                              src={helpImageClicked}
                              alt="help"
                              title="help"
                            />
                          ) : (
                            <img
                              className="characteristic-popup-help-image"
                              src={helpImage}
                              alt="help"
                              title="help"
                            />
                          )}
                        </div>

                        {/* Image section */}
                        <div className="characteristic-popup-image-section">
                          <div className="characteristic-popup-image">
                            <img
                              className="characteristic-default-picture"
                              src={baseImage ? baseImage : image}
                              alt="Bild hinzufügen"
                              title="Bild hinzufügen"
                            />
                            <Input
                              className="image-input"
                              type="file"
                              name="image"
                              accept="image/*"
                              placeholder="Durchsuchen.."
                              onChange={handleImageChange}
                            />
                            {imageIsTooLarge? (
                              <div
                                className="characteristic-error-message-image"
                                role="alert"
                              >
                                Image is Too Large
                              </div>
                            ) : null}
                          </div>

                          {/* if the help icon is clicked here we show the characteristic */}
                          <div className="characteristic-list">
                            {isHelpButtonClickedState &&
                              selectedFollowingCategory.map(
                                (characteristic: any, index: any) => (
                                  <div
                                    className="characteristic-item"
                                    key={index}
                                  >
                                    <h1>{characteristic.name}</h1>
                                  </div>
                                )
                              )}
                          </div>

                          {/* Submit button */}
                          {!props.characteristicID ? (
                            <Button
                              size="small"
                              text="Create"
                              icon="add-icon"
                              type="submit"
                              // onClick={handleSubmit}
                            ></Button>
                          ) : (
                            <Button
                              icon="edit-icon"
                              size="small"
                              text="Edit"
                              type="submit"
                              // onClick={handleSubmit}
                            ></Button>
                          )}
                        </div>
                      </div>
                    </Form>
                  </>
                );
              }}
            </Formik>
          </>
        </div>
      </div>
    </>
  );
}

export default CharacteristicsPopup;
