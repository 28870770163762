export const getAllCategories: any = (state: any) =>
  state.CategoryReducer.categories;

export const getAllCategoriesImages: any = (state: any) =>
  state.CategoryReducer.categoriesImages;


export const getFirstCategory: any = (state: any) =>
  state.CategoryReducer.categories.filter(
    (category: any) => category.firstCategory === true
  );