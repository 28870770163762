import "./CharacteristicsGroupPage.css";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// thunks requests
import {
  getAllCharacteristicsGroupRequest,
  deleteCharacteristicGroupRequest,
} from "store/thunks/characteristicsGroupThunk";
import { getAllCharacteristicsRequest } from "store/thunks/characteristicThunk";

// selectors
import {
  getAllCharacteristicGroups,
  getAllCharacteristicsGroupImages,
} from "store/selectors/characteristicsGroupSelector";

//components
import Loader from "components/Loader/Loader";
import Button from "components/Button/Button";
import DeletePopup from "components/DeletePopup/DeletePopup";
import MessageNotification from "components/MessageNotification/MessageNotification";
import CharacteristicsGroupCard from "./CharacteristicsGroupCard/CharacteristicsGroupCard";
import CharacteristicsGroupPopup from "./CharacteristicsGroupPopup/CharacteristicsGroupPopup";

function CharacteristicsGroupPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // redux state
  const characteristicsGroup = useSelector((state: any) =>
    getAllCharacteristicGroups(state)
  );
  const characteristicsGroupImages = useSelector((state: any) =>
    getAllCharacteristicsGroupImages(state)
  );
  const { loading } = useSelector((state: any) => state.LoadingReducer);
  const { messageText, messageType } = useSelector(
    (state: any) => state.MessageReducer
  );

  // local states
  const [isDeletePopup, setDeletePopup] = useState({
    isDeletePopup: false,
    characteristicGroupID: "",
  });

  // create and edit category popup
  const [isCharacteristicsGroupPopup, setCharacteristicsGroupPopup] = useState({
    isCharacteristicsGroupPopup: false,
    characteristicGroupID: "",
  });

  useEffect(() => {
    // change the browser tab title
    document.title = "WA - Characteristics Group";
    // get all categories thunk request
    dispatch(getAllCharacteristicsGroupRequest());
    //We want to think if we have another way to handle the api call from the popup without rendering the page
    dispatch(getAllCharacteristicsRequest());
  }, [dispatch]);

  // go back to previous page
  const goBack = () => {
    navigate(-1);
  };

  // this function hide popups
  const hidePopUp = () => {
    // hide the create edit popup
    setCharacteristicsGroupPopup({
      ...isCharacteristicsGroupPopup,
      isCharacteristicsGroupPopup: false,
      characteristicGroupID: "",
    });
    // hide the delete popup
    setDeletePopup({
      isDeletePopup: false,
      characteristicGroupID: "",
    });
    let popupContainer = document.getElementById(
      "characteristic-group-popup-main-container"
    ) as any;
    popupContainer && popupContainer.classList.add("hide");
    document.body.classList.remove("characteristic-group-overflow-hidden");
  };

  return (
    <>
      {messageText ? (
        <MessageNotification
          messageText={messageText}
          messageType={messageType}
        />
      ) : null}

      {isDeletePopup.isDeletePopup ? (
        <DeletePopup
          hidePopUp={hidePopUp}
          id={isDeletePopup.characteristicGroupID}
          deleteRequest={deleteCharacteristicGroupRequest}
        />
      ) : null}

      {isCharacteristicsGroupPopup.isCharacteristicsGroupPopup ? (
        <CharacteristicsGroupPopup
          characteristicGroupID={
            isCharacteristicsGroupPopup.characteristicGroupID
          }
          hidePopUp={hidePopUp}
        />
      ) : null}

      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="characteristics-group-page-wrapper">
            <div className="characteristics-group-page-top-section">
              {/* Back button */}
              <div
                className="characteristics-group-page-back-btn"
                onClick={goBack}
              >
                <Button size="small" icon="back-icon" text="Back" />
              </div>
              {/* Page title */}
              <h2 className="characteristics-group-page-title">
                Characteristics Group
              </h2>
              {/* Create new button */}
              <div className="create-new-characteristics-group-dev">
                <Button
                  text="New Characteristic Group"
                  size="medium"
                  icon="add-icon"
                  onClick={() =>
                    setCharacteristicsGroupPopup({
                      ...isCharacteristicsGroupPopup,
                      isCharacteristicsGroupPopup: true,
                    })
                  }
                />
              </div>
            </div>

            {/* Characteristics Group list */}
            <div className="characteristics-group-list-container">
              {characteristicsGroup &&
                characteristicsGroup.map(
                  (characteristicGroup: any, index: any) => (
                    <CharacteristicsGroupCard
                      key={index}
                      index={index}
                      //   characteristics={characteristicsGroup}
                      characteristicGroup={characteristicGroup}
                      handleEditCharacteristicsGroup={() =>
                        setCharacteristicsGroupPopup({
                          ...isCharacteristicsGroupPopup,
                          isCharacteristicsGroupPopup: true,
                          characteristicGroupID: characteristicGroup.id,
                        })
                      }
                      handleDeleteCharacteristicsGroup={() => {
                        setDeletePopup({
                          isDeletePopup: true,
                          characteristicGroupID: characteristicGroup.id,
                        });
                      }}
                      characteristicsGroupImages={characteristicsGroupImages}
                    />
                  )
                )}
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default CharacteristicsGroupPage;
