//services
import printGroupService from "services/printGroupService";

//actions
import { setMessage } from "store/actions/messageAction";
import { setLoading, removeLoading } from "store/actions/loadingAction";
import {
  getAllPrintGroupSuccess,
  creatPrintGroupSuccess,
  editPrintGroupSuccess,
  deletePrintGroupSuccess,
} from "store/actions/printGroupAction";

export const getAllPrintGroupsRequest = (background? : any) => (dispatch: any) => {
  try {
    //set loading
    if (background !== "background") {
      dispatch(setLoading());
    }
    //API to get All Print Groups
    printGroupService
      .getAllPrintGroups()
      .then(
        (response: any) => {
          dispatch(getAllPrintGroupSuccess(response.data));
          dispatch(removeLoading());
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          // show the error to the user
          dispatch(setMessage(message, "error"));
          dispatch(removeLoading());
        }
      )
      .catch((e: any) => {
        console.log(e);
      });
  } catch (e) {
    console.log("error", e);
    dispatch(removeLoading());
    dispatch(setMessage(e, "error"));
  }
};

export const createPrintGroupRequest = (printGroup: any) => (dispatch: any) => {
  try {
    dispatch(setLoading())
    printGroupService.createPrintGroup(printGroup).then((response: any) => {
      if (response.data !== undefined) {
        dispatch(removeLoading())
        dispatch(creatPrintGroupSuccess(response.data))
        dispatch(setMessage("Print Group created successfully", "success"));
      }
    }, (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // show the error to the user
      dispatch(setMessage(message, "error"));
      dispatch(removeLoading());
    })
  } catch (e) {
    console.log("error", e);
    dispatch(removeLoading());
    dispatch(setMessage(e, "error"));
  }
}

export const editPrintGroupRequest = (printGroupId: any, printGroup: any) => (dispatch: any) => {
  try {
    dispatch(setLoading())
    printGroupService.updatePrintGroup(printGroupId, printGroup).then((response: any) => {
      if (response.data !== undefined) {
        dispatch(removeLoading())
        dispatch(editPrintGroupSuccess(response.data))
        dispatch(setMessage("Print Group updated successfully", "success"));
      }
    }, (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // show the error to the user
      dispatch(setMessage(message, "error"));
      dispatch(removeLoading());
    })
  } catch (e) {
    console.log("error", e);
    dispatch(removeLoading());
    dispatch(setMessage(e, "error"));
  }
}

export const deletePrintGroupRequest = (printGroupID: any) => (dispatch: any) => {
  try {
    dispatch(setLoading())
    printGroupService.deletePrintGroup(printGroupID).then((response: any) => {
      dispatch(deletePrintGroupSuccess(printGroupID))
      dispatch(removeLoading())
      dispatch(setMessage("Print Group deleted successfully", "success"));
    }, (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // show the error to the user
      dispatch(setMessage(message, "error"));
      dispatch(removeLoading());
    })
  } catch (e) {
    console.log("error", e);
    dispatch(removeLoading());
    dispatch(setMessage(e, "error"));
  }
}
