import "./CharacteristicsPage.css";

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

//thunk requests
import {
  getAllCharacteristicsRequest,
  deleteCharacteristicRequest,
} from "store/thunks/characteristicThunk";
import { getAllPrintGroupsRequest } from "store/thunks/printGroupThunk";
import { getAllCategoriesRequest } from "store/thunks/categoryThunk";

//selectors
import {
  getAllCharacteristics,
  getAllCharacteristicsImages,
} from "store/selectors/characteristicSelector";

//Components
import Loader from "components/Loader/Loader";
import Button from "components/Button/Button";
import DeletePopup from "components/DeletePopup/DeletePopup";
import CharacteristicCard from "./CharacteristicCard/CharacteristicCard";
import MessageNotification from "components/MessageNotification/MessageNotification";
import CharacteristicsPopup from "pages/Admin/CharacteristicsPage/CharacteristicsPopup/CharacteristicsPopup";

function CharacteristicsPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //Get the category id from The URL Parameter
  const urlParam = useParams();
  let categoryId: string = urlParam.categoryID?.toString()!;

  //redux state
  let characteristics = useSelector((state: any) =>
    getAllCharacteristics(state)
  );
  let characteristicsImages = useSelector((state: any) =>
    getAllCharacteristicsImages(state)
  );
  const { loading } = useSelector((state: any) => state.LoadingReducer);
  const { messageText, messageType } = useSelector(
    (state: any) => state.MessageReducer
  );

  // characteristics of the specific category id
  let characteristicsByID = characteristics.filter(
    (characteristic: any) => characteristic.category.id === parseInt(categoryId)
  );

  // local states
  //  characteristic popup state
  const [isCharacteristicPopup, setIsCharacteristicPopup] = useState({
    isCharacteristicPopup: false,
    characteristicId: "",
    categoryId: "",
    categoryName: "",
  });

  // general delete popup local state
  const [isDeletePopup, setIsDeletePopup] = useState({
    isDeletePopup: false,
    characteristicId: "",
  });

  useEffect(() => {
    //change the tab browser title
    document.title = "WA - Characteristics";
    //get all characteristics by category id thunk request
    characteristics.length < 1 &&
      characteristicsImages.length < 1 &&
      dispatch(getAllCharacteristicsRequest());
    //We want to think if we have another way to handle the api call from the popup without rendering the page
    dispatch(getAllPrintGroupsRequest());
    dispatch(getAllCategoriesRequest());
  }, [
    dispatch,
    categoryId,
    characteristics.length,
    characteristicsImages.length,
  ]);

  console.log(characteristics)

  // go back to previous page
  const goBack = () => {
    navigate(-1);
  };

  // function that hide the popup and reset the popup local states
  const hidePopUp = () => {
    setIsCharacteristicPopup({
      ...isCharacteristicPopup,
      isCharacteristicPopup: false,
      characteristicId: "",
    });
    let popupContainer = document.getElementById(
      "characteristic-popup-main-container"
    ) as any;
    popupContainer && popupContainer.classList.add("hide");
    document.body.classList.remove("characteristic-popup-overflow-hidden");

    // hide the delete popup
    setIsDeletePopup({
      isDeletePopup: false,
      characteristicId: "",
    });
    popupContainer && popupContainer.classList.add("hide");
    document.body.classList.remove("characteristic-popup-overflow-hidden");
  };

  return (
    <>
      {messageText ? (
        <MessageNotification
          messageText={messageText}
          messageType={messageType}
        />
      ) : null}

      {isDeletePopup.isDeletePopup ? (
        <DeletePopup
          hidePopUp={hidePopUp}
          id={isDeletePopup.characteristicId}
          deleteRequest={deleteCharacteristicRequest}
        />
      ) : null}

      {isCharacteristicPopup.isCharacteristicPopup ? (
        <CharacteristicsPopup
          characteristicID={isCharacteristicPopup.characteristicId}
          hidePopUp={hidePopUp}
          categoryName={isCharacteristicPopup.categoryName}
          categoryID={isCharacteristicPopup.categoryId}
        />
      ) : null}

      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="characteristic-page-container">
            <div className="characteristic-page-top-section">
              <div className="characteristic-page-back-btn" onClick={goBack}>
                <Button size="small" icon="back-icon" text="Back" />
              </div>
              <h2 className="characteristic-page-title">
                {characteristicsByID[0]?.category.name + " - Characteristics"}
              </h2>
              <div className="create-new-characteristic-div">
                <Button
                  text="New Characteristic"
                  size="medium"
                  icon="add-icon"
                  onClick={() =>
                    setIsCharacteristicPopup({
                      ...isCharacteristicPopup,
                      isCharacteristicPopup: true,
                      categoryName: characteristicsByID[0]?.category.name,
                      categoryId: characteristicsByID[0]?.category.id,
                    })
                  }
                />
              </div>
            </div>

            {/* characteristics list */}
            <div className="characteristics-list-container">
              {characteristicsByID &&
                characteristicsByID.map((characteristic: any, index: any) => (
                  <CharacteristicCard
                    index={index}
                    key={index}
                    characteristic={characteristic}
                    characteristicImage={characteristicsImages}
                    handleEditCharacteristicPopup={() =>
                      setIsCharacteristicPopup({
                        ...isCharacteristicPopup,
                        isCharacteristicPopup: true,
                        characteristicId: characteristic.id,
                      })
                    }
                    handleDeleteCharacteristicPopup={() =>
                      setIsDeletePopup({
                        isDeletePopup: true,
                        characteristicId: characteristic.id,
                      })
                    }
                  />
                ))}
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default CharacteristicsPage;
