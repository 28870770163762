export const GET_ALL_DAMAGE_TYPES_SUCCESS = "GET_ALL_DAMAGE_TYPES_SUCCESS";
export const getAllDamageTypesSuccess = (
  damageTypes: any,
  damageTypesImages: any
) => ({
  type: GET_ALL_DAMAGE_TYPES_SUCCESS,
  payload: { damageTypes, damageTypesImages },
});

export const CREATE_DAMAGE_TYPE_SUCCESS = "CREATE_DAMAGE_TYPE_SUCCESS";
export const createDamageTypeSuccess = (
  newDamageType: any,
  newDamageTypeImage?: any
) => ({
  type: CREATE_DAMAGE_TYPE_SUCCESS,
  payload: { newDamageType, newDamageTypeImage },
});

export const EDIT_DAMAGE_TYPE_SUCCESS = "EDIT_DAMAGE_TYPE_SUCCESS";
export const editDamageTypeSuccess = (
  updateDamageType: any,
  updateDamageTypeImage?: any
) => ({
  type: EDIT_DAMAGE_TYPE_SUCCESS,
  payload: { updateDamageType, updateDamageTypeImage },
});

export const DELETE_DAMAGE_TYPE_SUCCESS = "DELETE_DAMAGE_TYPE_SUCCESS";
export const deleteDamageTypeSuccess = (damageTypeID: any) => ({
  type: DELETE_DAMAGE_TYPE_SUCCESS,
  payload: damageTypeID,
});
