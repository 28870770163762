import { httpCommon } from "./http-common";
import authHeader from "./authHeader";

const getImage = (id: number) => {
  return httpCommon.get(`/api/Download/${id}`, {
    headers: authHeader() as any,
    responseType: "blob",
  });
};

const getAllImages = (imageIDs: any) => {
  var imagesQuery = imageIDs //pass array as a URL parameter
    .map(function (item: any) {
      return "imageID=" + item;
    })
    .join("&");
  
  return httpCommon.get(`api/Download?` + imagesQuery, {
    headers: authHeader() as any,
    params: {
      imagesQuery,
    },
    responseType: "json",
  });
};

const ImageService = {
  getImage,
  getAllImages,
};

export default ImageService;
