import "./TaskSequencePage.css";

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

//components
import Loader from "components/Loader/Loader";
import Button from "components/Button/Button";
import MessageNotification from "components/MessageNotification/MessageNotification";
import TaskSequenceCard from "./TaskSequenceCard/TaskSequenceCard";
import TaskSequencePopup from "./TaskSequencePopup/TaskSequencePopup";
import DeletePopup from "components/DeletePopup/DeletePopup";

//thunks
import { getAllCharacteristicsGroupRequest } from "store/thunks/characteristicsGroupThunk";
import { getAllDamagedItemModelsRequest } from "store/thunks/damagedItemModelThunk";
import { getAllPlacementsRequest } from "store/thunks/placementThunk";
import {
  getAllTaskSequencesRequest,
  deleteTaskSequenceRequest,
} from "store/thunks/taskSequenceThunk";
import { getAllTasksRequest } from "store/thunks/taskThunk";

//selectors
import { getAllTaskSequencesSelector } from "store/selectors/taskSequenceSelector";


function TaskSequencePage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // redux state
  const { loading } = useSelector((state: any) => state.LoadingReducer);
  const { messageText, messageType } = useSelector(
    (state: any) => state.MessageReducer
  );
  const taskSequences = useSelector((state: any) =>
    getAllTaskSequencesSelector(state)
  );

  //local state
  //Create and edit popup state
  const [taskSequencePopup, setTaskSequencePopup] = useState({
    isTaskSequencePopup: false,
    taskSequence: {},
  });
  //delete popup
  const [isDeletePopup, setDeletePopup] = useState({
    isDeletePopup: false,
    taskSequence: {},
  });

  useEffect(() => {
    // change the browser tab title
    document.title = "WA - Task Sequence";
    //API calls to get all taskSequences
    dispatch(getAllTaskSequencesRequest());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  // go back to previous page
  const goBack = () => {
    navigate(-1);
  };

  //function to hide popups
  const hidePopup = () => {
    //hide the create edit popup
    setTaskSequencePopup({
      ...taskSequencePopup,
      isTaskSequencePopup: false,
      taskSequence: {},
    });

    //hide the delete popup
    setDeletePopup({
      ...isDeletePopup,
      isDeletePopup: false,
      taskSequence: {},
    });

    let popupContainer = document.getElementById(
      "task-sequence-popup-main-container"
    ) as any;
    popupContainer && popupContainer.classList.add("hide");
    document.body.classList.remove("task-sequence-popup-overflow-hidden");
  };

  // method to open task sequence popup
  const openTaskSequencePopup = (taskSequence?: any) => {
    // get all characteristicGroups thunk request
      dispatch(getAllCharacteristicsGroupRequest("background"));
    // get all damagedItemModels thunk request
      dispatch(getAllDamagedItemModelsRequest("background"));
    // get all placements thunk request
      dispatch(getAllPlacementsRequest("background"));
    //get all tasks thunk request
      dispatch(getAllTasksRequest("background"));
    if (!taskSequence) {
      setTaskSequencePopup({
        ...taskSequencePopup,
        isTaskSequencePopup: true,
      });
    } else {
      setTaskSequencePopup({
        ...taskSequencePopup,
        isTaskSequencePopup: true,
        taskSequence: taskSequence,
      });
    }
  };

  // method to open the delete popup
  const openDeletePopup = (taskSequence: any) => {
    let taskSequenceArray: any = [];
    taskSequence.taskSequences.map((task: any, index: any) => {
      let taskSequence = {
        sequencePosition: task.position,
        taskID: task.taskID,
      };
      return taskSequenceArray.push(taskSequence);
    });
    const data = {
      name: taskSequence.name,
      sequenceChooserID: taskSequence.taskSequences[0].sequenceChooserID,
      taskSequences: taskSequenceArray,
    };
    setDeletePopup({
      ...isDeletePopup,
      isDeletePopup: true,
      taskSequence: data,
    });
  };

  return (
    <>
      {messageText ? (
        <MessageNotification
          messageText={messageText}
          messageType={messageType}
        />
      ) : null}

      {taskSequencePopup.isTaskSequencePopup ? (
        <TaskSequencePopup
          hidePopUp={hidePopup}
          taskSequence={taskSequencePopup.taskSequence}
        />
      ) : null}

      {isDeletePopup.isDeletePopup ? (
        <DeletePopup
          hidePopUp={hidePopup}
          id={isDeletePopup.taskSequence}
          deleteRequest={deleteTaskSequenceRequest}
        />
      ) : null}

      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="task-sequence-page-container">
            {/* Top section */}
            <div className="task-sequence-page-top-section">
              {/* Back button */}
              <div className="task-sequence-page-back-btn" onClick={goBack}>
                <Button size="small" icon="back-icon" text="Back" />
              </div>

              {/* Page title */}
              <h2 className="task-sequence-page-title">Task Sequence</h2>

              {/* Create new button */}
              <div className="create-new-task-sequence-div">
                <Button
                  text="Create New Task Sequence"
                  size="medium"
                  icon="add-icon"
                  onClick={openTaskSequencePopup}
                />
              </div>
            </div>

            {/* Task sequence list */}
            <div className="task-sequence-list">
              {taskSequences &&
                taskSequences.map((taskSequence: any, index: any) => (
                  <TaskSequenceCard
                    TaskSequence={taskSequence}
                    key={index}
                    handleDeleteTaskSequence={() =>
                      openDeletePopup(taskSequence)
                    }
                    handleEditTaskSequence={() =>
                      openTaskSequencePopup(taskSequence)
                    }
                  />
                ))}
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default TaskSequencePage;
