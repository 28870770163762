import * as Yup from "yup";

export const LocationSchema = Yup.object().shape({
  name: Yup.string().max(50).required("Name is Erforderlich!"),
  city: Yup.string().max(50).required("City is Erforderlich!"),
});

export const LicenseSchema = Yup.object().shape({
  locationID: Yup.number().required("LocationID is Erforderlich!"),
});

export const UserSchema = Yup.object().shape({
  firstName: Yup.string().max(50).required("First Name is Erforderlich!"),
  lastName: Yup.string().max(50).required("Last Name is Erforderlich!"),
  email: Yup.string()
    .email("Ungültige E-Mail Adresse.")
    .max(50)
    .required("Email ist Erforderlich!"),

  password: Yup.string()
    .min(8, "Das Passwort muss mindestens 8 Zeichen lang sein.")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])/,
      "Das Passwort muss aus mindestens einem Großbuchstaben, einer Zahl und einem Sonderzeichen bestehen."
    )
    .max(50)
    .required("Passwort ist Erforderlich!"),
  ip: Yup.string()
    .matches(/(^(\d{1,3}\.){3}(\d{1,3})$)/, {
      message: "Ungültige IP Adresse",

      excludeEmptyString: true,
    })
    .test(
      "ipAddress",
      "Ungültige IP Adresse: die Komponenten der IP Adresse müssen im Bereich von 0 bis 255 liegen.",
      (value) => {
        if (value === undefined || value.trim() === "") return true;
        return value.split(".").find((i) => parseInt(i) > 255) === undefined;
      }
    )
    .max(50)
    .required("Ip is Erforderlich!"),
  locationID: Yup.number().required("Location is Erforderlich!"),
  roles: Yup.array()
    .min(1, "Role is Erforderlich!")
    .required("Role is Erforderlich!"),
});

export const EditUserSchema = Yup.object().shape({
  firstName: Yup.string().max(50).required("First Name is Erforderlich!"),
  lastName: Yup.string().max(50).required("Last Name is Erforderlich!"),
  ip: Yup.string()
    .matches(/(^(\d{1,3}\.){3}(\d{1,3})$)/, {
      message: "Ungültige IP Adresse",

      excludeEmptyString: true,
    })
    .test(
      "ipAddress",
      "Ungültige IP Adresse: die Komponenten der IP Adresse müssen im Bereich von 0 bis 255 liegen.",
      (value) => {
        if (value === undefined || value.trim() === "") return true;
        return value.split(".").find((i) => parseInt(i) > 255) === undefined;
      }
    )
    .max(50)
    .required("Ip is Erforderlich!"),
  locationID: Yup.number().required("Location is Erforderlich!"),
  roles: Yup.array()
    .min(1, "Role is Erforderlich!")
    .required("Role is Erforderlich!"),
});

export const WorkplaceSensrecAdminSchema = Yup.object().shape({
  ip: Yup.string()

    .matches(/(^(\d{1,3}\.){3}(\d{1,3})$)/, {
      message: "Ungültige IP Adresse",

      excludeEmptyString: true,
    })

    .test(
      "ipAddress",

      "Ungültige IP Adresse: die Komponenten der IP Adresse müssen im Bereich von 0 bis 255 liegen.",

      (value) => {
        if (value === undefined || value.trim() === "") return true;

        return value.split(".").find((i) => parseInt(i) > 255) === undefined;
      }
    )

    .max(50)

    .required("Ip is Erforderlich!"),
  locationID: Yup.number().required("Location is Erforderlich!"),
});
