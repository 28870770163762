import "./HarmSightPage.css";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

// services
import ImageService from "services/imageService";

// thunks
import { getMultipleDamagedItemModelsRequest } from "store/thunks/damagedItemModelThunk";
import { getMultipleDamagedItemModelsSelector } from "store/selectors/damagedItemModelSelector";
import { getAllCharacteristicsRequest } from "store/thunks/characteristicThunk";

//selectors
import {
  getAllCharacteristics,
  getAllCharacteristicsImages,
} from "store/selectors/characteristicSelector";


// components
import Button from "components/Button/Button";

// images
import image from "../../../assets/images/defaultPicture.png";

function HarmSightPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();


  // get the workplacePendingAction by props
  const workplacePendingAction: any = location.state;


  // redux state
  const multipleDamagedItemModels = useSelector((state: any) =>
    getMultipleDamagedItemModelsSelector(state)
  );
  const characteristics = useSelector((state: any) => getAllCharacteristics(state))
  const characteristicsImages = useSelector((state: any) => getAllCharacteristicsImages(state))

  //local states
  // to manage the get api call time
  const [groupedArray, setGroupedArray] = useState<any>([]);
  const [placementIDs, setPlacementIDs] = useState<any>([]);

  // go back to previous page
  const goBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    // change the browser tab title
    document.title = "WA - Harm Sight";
    //Api to get All characteristics
    characteristics.length < 1 && dispatch(getAllCharacteristicsRequest())
  }, [dispatch, characteristics?.length]);

  useEffect(() => {
    // temp array
    let tempPlacementIDs = [] as any;
    // loop to push the placementIDs in side the array
    multipleDamagedItemModels.map((item: any) => {
      tempPlacementIDs.push(item.placementID);
      // unique is the unique version of tempPlacementIDs without duplicates
      let unique = [...(new Set(tempPlacementIDs) as any)];
      // set the unique array in the final state
      return setPlacementIDs(unique);
    });

    if (multipleDamagedItemModels.length > 0) {
      // this reduce function also separate the main array via the placementID but return object not array
      var finalOrganizedArray = multipleDamagedItemModels.reduce(function (
        r: any,
        a: any
      ) {
        r[a.placementID] = r[a.placementID] || [];
        r[a.placementID].push(a);
        return r;
      },
      Object.create(null));
      // set the groupedArray state with the final organized array
      setGroupedArray(finalOrganizedArray);
    }
  }, [multipleDamagedItemModels]);

  useEffect(() => {
    // array to put in the damaged item models IDs
    let damagedItemModelsIDs = [] as any;
    // check if we have workplacePendingActions
    if (workplacePendingAction) {
      // loop to push them in the array above
      workplacePendingAction?.damagedItemModels.map(
        (damagedItemModelID: any) => {
          return damagedItemModelsIDs.push(damagedItemModelID.id);
        }
      );
      // API call to the damagedItemModel API sending the IDs
      dispatch(
        getMultipleDamagedItemModelsRequest(damagedItemModelsIDs, "harm sight")
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, workplacePendingAction]);


  return (
    <>
      {/* Main Container */}
      <div className="harm-sight-page-container">
        <div className="harm-sight-page-top-section">
          <div className="harm-sight-page-back-btn">
            <Button
              size="small"
              icon="back-icon"
              text="Back"
              onClick={goBack}
            />
          </div>
          <div className="harm-sight-page-title">Harm Sight</div>
        </div>
        <div className="harm-sight-page-content-section">
          {/* Characteristic Column */}
          <div className="harm-sight-page-characteristic-section">
            {workplacePendingAction.characteristics &&
              workplacePendingAction.characteristics.map((characteristic: any, index: any) => {
                // eslint-disable-next-line no-lone-blocks
                {
                  characteristicsImages &&
                    characteristicsImages
                      .filter(
                        (item: any) => item.imageID === characteristic.imageID
                      )
                      .map(function (image: any) {
                        characteristic.image = image.image;
                        return null;
                      });
                }
                return (
                  <div className="repair-page-characteristic" key={index}>
                    <div className="repair-page-characteristic-image">
                      <img
                        src={
                          characteristic.image !== undefined
                            ? "data:image/jpeg;base64," + characteristic.image
                            : image
                        }
                        alt={characteristic.name}
                        title={characteristic.name}
                      />
                    </div>

                    <div className="repair-page-characteristic-name">
                      {characteristic.name}
                    </div>
                    {index !==
                      workplacePendingAction.characteristics.length - 1 && (
                      <hr></hr>
                    )}
                  </div>
                );
              })}
          </div>

          {/* Task content section */}
          {workplacePendingAction.damagedItemModels.length > 0 ? (
            <>
              <div className="harm-sight-page-task-section">
                {/* loop to get each placementID to use it as index bellow */}
                {placementIDs.map((placementID: any) => {
                  return (
                    <>
                      {/* loop inside the grouped array that is indexed by the placements IDs, so i used the placement ID as index */}
                      {groupedArray[placementID].map(
                        (item: any, index2: any) => {
                          return (
                            <div className="harm-sight-placements-damage-types">
                              <h2
                                // here if the index2 === 0 so we are in a new placement loop so we show the blue background for the first time, else we hide it
                                className={
                                  index2 === 0
                                    ? "harm-sight-page-task-section-task-h3"
                                    : "harm-sight-page-task-section-task-hidden"
                                }
                              >
                                {/* same condition, if we are only at the first loop cycle we show the placement name, else we hide it */}
                                {index2 === 0 && item?.placementName}
                              </h2>
                              <h2 className="harm-sight-page-task-section-task-h3">
                                {/* here we show in the other side the damage type names  */}
                                {item.damageType.name}
                              </h2>
                            </div>
                          );
                        }
                      )}
                    </>
                  );
                })}
              </div>
            </>
          ) : <h1 className="harm-sight-page-no-placement-found">No Placement Found</h1>}
        </div>
      </div>
    </>
  );
}

export default HarmSightPage;
