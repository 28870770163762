// actions
import {
  GET_MULTIPLE_DAMAGED_ITEM_MODEL_SUCCESS,
  GET_ALL_DAMAGED_ITEM_MODELS_SUCCESS,
} from "../actions/damagedItemModelAction";

const initialState = {
  damagedItemModels: [],
  multipleDamagedItemModels: [],
};

const damagedItemModelsReducer = (state = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case GET_MULTIPLE_DAMAGED_ITEM_MODEL_SUCCESS:
      return {
        ...state,
        multipleDamagedItemModels: payload,
      };
    
    case GET_ALL_DAMAGED_ITEM_MODELS_SUCCESS:
      return {
        ...state,
        damagedItemModels: payload
      }

    default:
      return state;
  }
};
export default damagedItemModelsReducer;
