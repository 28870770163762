import { httpCommon } from "./http-common";
import authHeader from "./authHeader";

const getPlacement = (categoryID: string) => {
  return httpCommon.get(`/Placement/${categoryID}`, {
    headers: authHeader() as any,
  });
};

const getAllPlacements = () => {
  return httpCommon.get("/Placement", { headers: authHeader() as any });
};

const createPlacement = (data: any) => {
  return httpCommon.post("/Placement", data, { headers: authHeader() as any });
};

const updatePlacement = (categoryID: string, data: any) => {
  return httpCommon.put(`/Placement/${categoryID}`, data, {
    headers: authHeader() as any,
  });
};

const deletePlacement = (categoryID: number) => {
  return httpCommon.delete(`/Placement/${categoryID}`, {
    headers: authHeader() as any,
  });
};

const getPlacementByCharacteristicGroupId = (characteristicGroupID: number) => {
  return httpCommon.get(
    `/Placement/GetPlacementsByCharacteristicsGroup?characteristicsGroupID=${characteristicGroupID}`,
    { headers: authHeader() as any }
  );
};

const DamagePlacementService = {
  getPlacement,
  getAllPlacements,
  createPlacement,
  updatePlacement,
  deletePlacement,
  getPlacementByCharacteristicGroupId
};

export default DamagePlacementService;
