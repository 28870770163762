import { httpCommon } from "./http-common";
import authHeader from "./authHeader";

const getDamageType = (damageTypeID: number) => {
  return httpCommon.get(`/DamageType/${damageTypeID}`, {
    headers: authHeader() as any,
  });
};

const getAllDamageTypes = () => {
  return httpCommon.get("/DamageType", { headers: authHeader() as any });
};

const createDamageType = (data: any) => {
  return httpCommon.post("/DamageType", data, { headers: authHeader() as any });
};

const updateDamageType = (damageTypeID: number, data: any) => {
  return httpCommon.put(`/DamageType/${damageTypeID}`, data, {
    headers: authHeader() as any,
  });
};

const deleteDamageType = (damageTypeID: number) => {
  return httpCommon.delete(`/DamageType/${damageTypeID}`, {
    headers: authHeader() as any,
  });
};

const DamageTypeService = {
  getDamageType,
  getAllDamageTypes,
  createDamageType,
  updateDamageType,
  deleteDamageType,
};

export default DamageTypeService;
