import { httpCommon } from "./http-common";
import authHeader from "./authHeader";

const getAllCommissionedCostumer = () => {
  return httpCommon.get("/CommissionedCustomer", {
    headers: authHeader() as any,
  });
};

const createCommissionedCostumer = (data: any) => {
  return httpCommon.post("/CommissionedCustomer", data, {
    headers: authHeader() as any,
  });
};

const updateCommissionedCostumer = (commissionedCostumerID: any, data: any) => {
  return httpCommon.put(
    `/CommissionedCustomer/${commissionedCostumerID}`,
    data,
    { headers: authHeader() as any }
  );
};

const deleteCommissionedCostumer = (commissionedCostumerID: any) => {
  return httpCommon.delete(`/CommissionedCustomer/${commissionedCostumerID}`, {
    headers: authHeader() as any,
  });
};

const CommissionedCostumerService = {
  getAllCommissionedCostumer,
  createCommissionedCostumer,
  updateCommissionedCostumer,
  deleteCommissionedCostumer
};

export default CommissionedCostumerService;
