import { httpCommon } from "./http-common";
import authHeader from "./authHeader";

const getAllTasks = () => {
  return httpCommon.get("/Task", { headers: authHeader() as any });
};

const createTask = (data: any) => {
  return httpCommon.post("/Task", data, {
    headers: authHeader() as any,
  });
};

const getTaskByID = (TaskID: string) => {
  return httpCommon.get(`/Task/${TaskID}`, {
    headers: authHeader() as any,
  });
};

const updateTask = (TaskID: string, data: any) => {
  return httpCommon.put(`/Task/${TaskID}`, data, {
    headers: authHeader() as any,
  });
};

const deleteTask = (TaskID: number) => {
  return httpCommon.delete(`/Task/${TaskID}`, {
    headers: authHeader() as any,
  });
};

const getTasksBySequenceChoosersService = (sequenceChooserIDs: any) => {
  var sequenceChooserIDsQuery = sequenceChooserIDs
    .map(function (item: any) {
      return "sequenceChooserIDs=" + item;
    })
    .join("&");

  return httpCommon.get(
    `/Task/GetTasksBySequenceChoosers?` + sequenceChooserIDsQuery,
    {
      headers: authHeader() as any,
      responseType: "json",
    }
  );
};

const createSequence = (data: any) => {
  return httpCommon.post("/Task/CreateSequence", data, {
    headers: authHeader() as any,
  });
};

const updateSequence = (data: any) => {
  return httpCommon.put("/Task/UpdateSequence", data, {
    headers: authHeader() as any,
  });
};

const deleteSequence = (data: any) => {
  return httpCommon.post("/Task/DeleteSequence", data, {
    headers: authHeader() as any,
  });
};

const getAllTaskSequences = () => {
  return httpCommon.get("/Task/GetAllTaskSequences", {headers: authHeader() as any});
}

const TaskService = {
  getAllTasks,
  createTask,
  getTaskByID,
  updateTask,
  deleteTask,
  getTasksBySequenceChoosersService,
  createSequence,
  updateSequence,
  deleteSequence,
  getAllTaskSequences
};

export default TaskService;
