// actions
import {
  GET_ALL_CATEGORIES_SUCCESS,
  CREATE_CATEGORY_SUCCESS,
  EDIT_CATEGORY_SUCCESS,
  DELETE_CATEGORY_SUCCESS,
} from "../actions/categoryAction";

const initialState = { categories: [], categoriesImages: [] };

const categoryReducer = (state = initialState, action: any) => {
  const { type, payload } = action;
  let categoriesArray: any = [...state.categories];
  let categoriesImagesArray: any = [...state.categoriesImages];

  switch (type) {
    case GET_ALL_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: payload.categories,
        categoriesImages: payload.categoriesImages,
      };

    case CREATE_CATEGORY_SUCCESS:
      categoriesArray.push(payload.newCategory);
      payload.newImage !== undefined &&
        categoriesImagesArray.push(payload.newImage);
      return {
        ...state,
        categories: categoriesArray,
        categoriesImages: categoriesImagesArray,
      };

    case EDIT_CATEGORY_SUCCESS:
      categoriesArray.map((category: any, index: any) => {
        if (category.id === payload.updatedCategory.id) {
          return (categoriesArray[index] = payload.updatedCategory);
        } else return categoriesArray;
      });
      payload.updatedImage !== undefined &&
        categoriesImagesArray.push(payload.updatedImage);
      return {
        ...state,
        categories: categoriesArray,
        categoriesImages: categoriesImagesArray,
      };

    case DELETE_CATEGORY_SUCCESS:
      categoriesArray = state.categories.filter(
        (category: any) => category.id !== payload
      );
      return {
        ...state,
        categories: categoriesArray,
      };

    default:
      return state;
  }
};
export default categoryReducer;
