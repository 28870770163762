import "./DamageTypePage.css";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

//thunks
import {
  getAllDamageTypesRequest,
  deleteDamageTypeRequest,
} from "store/thunks/damageTypeThunk";

//Selector
import {
  getAllDamageTypesSelector,
  getAllDamageTypesImagesSelector,
} from "store/selectors/damageTypeSelector";

//components
import Loader from "components/Loader/Loader";
import MessageNotification from "components/MessageNotification/MessageNotification";
import Button from "../../../components/Button/Button";
import DamageTypeCard from "./DamageTypeCard/DamageTypeCard";
import DamageTypePopup from "./DamageTypePopup/DamageTypePopup";
import DeletePopup from "components/DeletePopup/DeletePopup";

function DamageTypePage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //redux state
  const damageTypes = useSelector((state: any) =>
    getAllDamageTypesSelector(state)
  );
  const damageTypesImages = useSelector((state: any) =>
    getAllDamageTypesImagesSelector(state)
  );
  const { loading } = useSelector((state: any) => state.LoadingReducer);
  const { messageText, messageType } = useSelector(
    (state: any) => state.MessageReducer
  );

  //Create edit popup state
  const [damageTypePopup, setDamageTypePopup] = useState({
    isDamageTypePopup: false,
    damageTypeId: "",
  });
  //delete popup state
  const [isDeletePopup, setDeletePopup] = useState({
    isDeletePopup: false,
    damageTypeID: "",
  });

  useEffect(() => {
    // change the browser tab title
    document.title = "WA - Damage Types";
    // get all categories thunk request
    damageTypes.length < 1 &&
      damageTypesImages.length < 1 &&
      dispatch(getAllDamageTypesRequest());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, damageTypes.length, damageTypesImages.length]);

  // go back to previous page
  const goBack = () => {
    navigate(-1);
  };

  //function to hide popups
  const hidePopup = () => {
    //hide the create edit popup
    setDamageTypePopup({
      ...damageTypePopup,
      damageTypeId: "",
      isDamageTypePopup: false,
    });
    // hide the delete popup
    setDeletePopup({
      isDeletePopup: false,
      damageTypeID: "",
    });

    let popupContainer = document.getElementById(
      "damage-type-popup-main-container"
    ) as any;
    popupContainer && popupContainer.classList.add("hide");
    document.body.classList.remove("damage-type-popup-overflow-hidden");
  };

  return (
    <>
      {messageText ? (
        <MessageNotification
          messageText={messageText}
          messageType={messageType}
        />
      ) : null}

      {damageTypePopup.isDamageTypePopup ? (
        <DamageTypePopup
          damageTypeId={damageTypePopup.damageTypeId}
          hidePopUp={hidePopup}
        />
      ) : null}
      {isDeletePopup.isDeletePopup ? (
        <DeletePopup
          hidePopUp={hidePopup}
          id={isDeletePopup.damageTypeID}
          deleteRequest={deleteDamageTypeRequest}
        />
      ) : null}

      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="damage-types-page-container">
            <div className="damage-types-page-top-section">
              {/* Back button */}
              <div className="damage-types-page-back-btn" onClick={goBack}>
                <Button size="small" icon="back-icon" text="Back" />
              </div>
              {/* Page title */}
              <h2 className="damage-types-page-title">Damage Types</h2>
              {/* Create new button */}
              <div className="create-new-damage-type-div">
                <Button
                  text="Create New Damage Type"
                  size="medium"
                  icon="add-icon"
                  onClick={() =>
                    setDamageTypePopup({
                      ...damageTypePopup,
                      isDamageTypePopup: true,
                    })
                  }
                />
              </div>
            </div>

            {/* Damage Types list */}
            <div className="damage-type-list-container">
              {damageTypes &&
                damageTypes.map((damageType: any, index: any) => (
                  <DamageTypeCard
                    key={index}
                    index={index}
                    damageType={damageType}
                    damageTypesImage={damageTypesImages}
                    handleEditDamageType={() =>
                      setDamageTypePopup({
                        ...damageType,
                        isDamageTypePopup: true,
                        damageTypeId: damageType.id,
                      })
                    }
                    handleDeleteDamageType={() =>
                      setDeletePopup({
                        ...isDeletePopup,
                        isDeletePopup: true,
                        damageTypeID: damageType.id,
                      })
                    }
                  />
                ))}
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default DamageTypePage;
