import "./ViewDamagesPage.css";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";


// thunks
import { getAllCharacteristicsRequest } from "store/thunks/characteristicThunk";
import { getMultipleDamagedItemModelsRequest } from "store/thunks/damagedItemModelThunk";
import { getMultipleDamagedItemModelsSelector } from "store/selectors/damagedItemModelSelector";


//selectors
import { getAllCharacteristics, getAllCharacteristicsImages } from "store/selectors/characteristicSelector";

// components
import Loader from "components/Loader/Loader";
import Button from "components/Button/Button";

// images
import image from "../../../assets/images/defaultPicture.png";

function ViewDamagesPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  // get the workplacePendingAction by props
  const workplacePendingAction: any = location.state;


  // redux state
  const multipleDamagedItemModels = useSelector((state: any) =>
    getMultipleDamagedItemModelsSelector(state)
  );
  const { loading } = useSelector((state: any) => state.LoadingReducer);
  const characteristicsImages = useSelector((state: any) => getAllCharacteristicsImages(state))
  const characteristics = useSelector((state: any) => getAllCharacteristics(state))

  //local states
  // to manage the get api call time
  // const [characteristics, setCharacteristics] = useState<any>();
  const [groupedArray, setGroupedArray] = useState<any>([]);
  const [placementIDs, setPlacementIDs] = useState<any>([]);

  // go back to previous page
  const goBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    // change the browser tab title
    document.title = "WA - View Damages";
    // get all characteristic thunk
    characteristics.length < 1 && dispatch(getAllCharacteristicsRequest())
  }, [dispatch, characteristics?.length]);

  useEffect(() => {
    // temp array
    let tempPlacementIDs = [] as any;
    // loop to push the placementIDs in side the array
    multipleDamagedItemModels.map((item: any) => {
      tempPlacementIDs.push(item.placementID);
      // unique is the unique version of tempPlacementIDs without duplicates
      let unique = [...(new Set(tempPlacementIDs) as any)];
      // set the unique array in the final state
      return setPlacementIDs(unique);
    });

    if (multipleDamagedItemModels.length > 0) {
      // this reduce function also separate the main array via the placementID but return object not array
      var finalOrganizedArray = multipleDamagedItemModels.reduce(function (
        r: any,
        a: any
      ) {
        r[a.placementID] = r[a.placementID] || [];
        r[a.placementID].push(a);
        return r;
      },
      Object.create(null));
      // set the groupedArray state with the final organized array
      setGroupedArray(finalOrganizedArray);
    }
  }, [multipleDamagedItemModels]);

  useEffect(() => {
    // array to put in the damaged item models IDs
    let damagedItemModelsIDs = [] as any;
    // check if we have workplacePendingActions
    if (workplacePendingAction) {
      // loop to push them in the array above
      workplacePendingAction?.damagedItemModels.map(
        (damagedItemModelID: any) => {
          return damagedItemModelsIDs.push(damagedItemModelID.id);
        }
      );
      // API call to the damagedItemModel API sending the IDs
      dispatch(
        getMultipleDamagedItemModelsRequest(damagedItemModelsIDs, "harm sight")
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, workplacePendingAction]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          {/* Main Container */}
          <div className="view-damages-page-container">
            <div className="view-damages-page-top-section">
              <div className="view-damages-page-back-btn">
                <Button
                  size="small"
                  icon="back-icon"
                  text="Back"
                  onClick={goBack}
                />
              </div>
              <div className="view-damages-page-title">Harm Sight</div>
            </div>
            <div className="view-damages-page-content-section">
              {/* Characteristic Column */}
              <div className="view-damages-page-characteristic-section">
                {workplacePendingAction.characteristics &&
                  workplacePendingAction.characteristics.map(
                    (characteristic: any, index: any) => {
                      // eslint-disable-next-line no-lone-blocks
                      {
                        characteristicsImages &&
                          characteristicsImages
                            .filter(
                              (item: any) =>
                                item.imageID === characteristic.imageID
                            )
                            .map(function (image: any) {
                              characteristic.image = image.image;
                              return null;
                            });
                      }
                      return (
                        <div className="repair-page-characteristic" key={index}>
                          <div className="repair-page-characteristic-image">
                            <img
                              src={
                                characteristic.image !== undefined
                                  ? "data:image/jpeg;base64," +
                                    characteristic.image
                                  : image
                              }
                              alt={characteristic.name}
                              title={characteristic.name}
                            />
                          </div>

                          <div className="repair-page-characteristic-name">
                            {characteristic.name}
                          </div>
                          {index !==
                            workplacePendingAction.characteristics.length -
                              1 && <hr></hr>}
                        </div>
                      );
                    }
                  )}
              </div>

              {/* Task content section */}
              {workplacePendingAction.damagedItemModels.length > 0 ? (
                <>
                  <div className="view-damages-page-task-section">
                    {/* loop to get each placementID to use it as index bellow */}
                    {placementIDs.map((placementID: any) => {
                      return (
                        <>
                          {/* loop inside the grouped array that is indexed by the placements IDs, so i used the placement ID as index */}
                          {groupedArray[placementID].map(
                            (item: any, index2: any) => {
                              return (
                                <div className="view-damages-placements-damage-types">
                                  <h2
                                    // here if the index2 === 0 so we are in a new placement loop so we show the blue background for the first time, else we hide it
                                    className={
                                      index2 === 0
                                        ? "view-damages-page-task-section-task-h3"
                                        : "view-damages-page-task-section-task-hidden"
                                    }
                                  >
                                    {/* same condition, if we are only at the first loop cycle we show the placement name, else we hide it */}
                                    {index2 === 0 && item?.placementName}
                                  </h2>
                                  <h2 className="view-damages-page-task-section-task-h3">
                                    {/* here we show in the other side the damage type names  */}
                                    {item.damageType.name}
                                  </h2>
                                </div>
                              );
                            }
                          )}
                        </>
                      );
                    })}
                  </div>
                </>
              ) : (
                <h1 className="view-damages-page-no-placement-found">
                  No Placement Found
                </h1>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default ViewDamagesPage;
