import "./StatisticPage.css";

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

//date picker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// Recharts
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

//CSV
import { exportToCSV } from "services/exportToCSV";

//thunk
import { getAllTasksRequest } from "store/thunks/taskThunk";
import { getAllCategoriesRequest } from "store/thunks/categoryThunk";
import { getAllWorkplacesRequest } from "store/thunks/workplaceThunk";
import { getAllPlacementsRequest } from "store/thunks/placementThunk";
import { getAllDamageTypesRequest } from "store/thunks/damageTypeThunk";
import { getAllPrintGroupsRequest } from "store/thunks/printGroupThunk";
import { getAllTaskSequencesRequest } from "store/thunks/taskSequenceThunk";
import { getAllCharacteristicsRequest } from "store/thunks/characteristicThunk";
import { getAllDamagedItemModelsRequest } from "store/thunks/damagedItemModelThunk";
import { getAllCharacteristicsGroupRequest } from "store/thunks/characteristicsGroupThunk";
import { getAllCommissionedCostumerRequest } from "store/thunks/commissionedCostumerThunk";
import { getCollectionListFilteredByDateRequest } from "store/thunks/collectionListThunk";
import {
  getActionsFilteredByDateRequest,
  getWorkplacePendingActionsRequest,
} from "store/thunks/actionThunk";


//selectors
import { getAllTasksSelector } from "store/selectors/taskSelector";
import { getAllCategories } from "store/selectors/categorySelector";
import { getAllPlacements } from "store/selectors/placementSelector";
import { getAllWorkplacesSelector } from "store/selectors/workplaceSelector";
import { getAllDamageTypesSelector } from "store/selectors/damageTypeSelector";
import { getAllCharacteristics } from "store/selectors/characteristicSelector";
import { getAllTaskSequencesSelector } from "store/selectors/taskSequenceSelector";
import { getAllDamageTypesImagesSelector } from "store/selectors/damageTypeSelector";
import { getAllCharacteristicGroups } from "store/selectors/characteristicsGroupSelector";
import { getAllCommissionedCostumer } from "store/selectors/commissionedCostumerSelector";
import { getCollectionListFilteredByDateSelector } from "store/selectors/collectionListSelector";
import {
  getActionsFilteredByDateSelector,
  getWorkplacePendingActionsSelector,
} from "store/selectors/actionSelector";


//Components
import Loader from "components/Loader/Loader";
import Button from "../../../components/Button/Button";

//images
import startDateIcon from "assets/icons/start-date-icon.png";
import endDateIcon from "assets/icons/end-date-icon.png";
import { getAllPrintGroups } from "store/selectors/printGroupSelector";

function StatisticPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //redux state
  const { loading } = useSelector((state: any) => state.LoadingReducer);
  const collectionLists = useSelector((state: any) =>
    getCollectionListFilteredByDateSelector(state)
  );
  const actions = useSelector((state: any) =>
    getActionsFilteredByDateSelector(state)
  );
  const pendingActions = useSelector((state: any) =>
    getWorkplacePendingActionsSelector(state)
  );
  const categories = useSelector((state: any) => getAllCategories(state));
  const characteristics = useSelector((state: any) =>
    getAllCharacteristics(state)
  );
  const characteristicGroups = useSelector((state: any) =>
    getAllCharacteristicGroups(state)
  );
  const commissionedCostumer = useSelector((state: any) =>
    getAllCommissionedCostumer(state)
  );
  const damagedItemModels = useSelector((state: any) =>
    getAllDamageTypesImagesSelector(state)
  );
  const damageTypes = useSelector((state: any) =>
    getAllDamageTypesSelector(state)
  );
  const placements = useSelector((state: any) => getAllPlacements(state));
  const printGroups = useSelector((state: any) => getAllPrintGroups(state));
  const tasks = useSelector((state: any) => getAllTasksSelector(state));
  const workplaces = useSelector((state: any) =>
    getAllWorkplacesSelector(state)
  );
    const taskSequences = useSelector((state: any) => getAllTaskSequencesSelector(state));

  //localStates
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [statisticData, setStatisticData] = useState([]);
  const [dataChoice, setDataChoice] = useState("");

  useEffect(() => {
    // change the browser tab title
    document.title = "WA - Statistic";

    collectionLists.length > 1 && actions.length > 1 && getStatisticData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectionLists, actions]);

  // go back to previous page
  const goBack = () => {
    navigate(-1);
  };

  //method to draw the chart
  const changePeriod = () => {
    //Api calls to get the actions and collection filtered by selected dates
    dispatch(
      getActionsFilteredByDateRequest(
        startDate.toISOString(),
        endDate.toISOString()
      )
    );
    dispatch(
      getCollectionListFilteredByDateRequest(
        startDate.toISOString(),
        endDate.toISOString()
      )
    );
  };

  const getStatisticData = () => {
    // we need to check each entry of the collection list if it has any actions with same date
    // and then save each collection list date and number of action in a state to be used by the chart
    let data: any = [];
    // eslint-disable-next-line array-callback-return
    collectionLists.map((collectionList: any) => {
      let dataItem = {};
      let sum = 0;
      let collectionListStartDate = new Date(
        String(collectionList.startDate)
      ).getTime();
      let collectionListEndDate = new Date(
        String(collectionList.endDate)
      ).getTime();
      // eslint-disable-next-line array-callback-return
      actions.map((action: any) => {
        let actionDate = new Date(String(action.timeStampReady)).getTime();
        if (
          actionDate >= collectionListStartDate &&
          actionDate <= collectionListEndDate &&
          action.printGroup.id === collectionList.printGroup.id
        ) {
          sum = sum + 1;
        }
        dataItem = {
          date: collectionList.startDate.slice(0, 10),
          count: sum,
        };
      });
      data.push(dataItem);
    });
    setStatisticData(data);
  };

  const handleDataChoiceChange = (event: any) => {
    let value = event.target.value;
    switch (value) {
      case "actions":
        pendingActions.length < 1 &&
          dispatch(getWorkplacePendingActionsRequest());
        break;
      case "categories":
        categories.length < 1 &&
          dispatch(getAllCategoriesRequest("background"));
        break;
      case "characteristics":
        characteristics.length < 1 &&
          dispatch(getAllCharacteristicsRequest("background"));
        break;
      case "characteristicGroup":
        characteristicGroups.length < 1 &&
          dispatch(getAllCharacteristicsGroupRequest("background"));
        break;
      case "commissionedCustomer":
        commissionedCostumer.length < 1 &&
          dispatch(getAllCommissionedCostumerRequest("background"));
        break;
      case "damagedItemModel":
        damagedItemModels.length < 1 &&
          dispatch(getAllDamagedItemModelsRequest("background"));
        break;
      case "damageTypes":
        damageTypes.length < 1 &&
          dispatch(getAllDamageTypesRequest("background"));
        break;
      case "placement":
        placements.length < 1 &&
          dispatch(getAllPlacementsRequest("background"));
        break;
      case "printGroup":
        printGroups.length < 1 &&
          dispatch(getAllPrintGroupsRequest("background"));
        break;
      case "task":
        tasks.length < 1 && dispatch(getAllTasksRequest("background"));
        break;
      case "taskSequence":
        taskSequences.length < 1 && dispatch(getAllTaskSequencesRequest("background"));
        break;
      case "workplace":
        workplaces.length < 1 &&
          dispatch(getAllWorkplacesRequest("background"));
        break;
      default:
        break;
    }
    setDataChoice(value);
  };

  // Check the data selected if it is not stored in redux call an API to get it from database and saved in redux
  const HandleExportToCsvClicked = () => {
    switch (dataChoice) {
      case "actions":
        exportToCSV(pendingActions, "Pending Actions.csv");
        break;
      case "categories":
        exportToCSV(categories, "Categories.csv");
        break;
      case "characteristics":
        exportToCSV(characteristics, "Characteristics.csv");
        break;
      case "characteristicGroup":
        exportToCSV(characteristicGroups, "Characteristics Groups.csv");
        break;
      case "commissionedCustomer":
        exportToCSV(commissionedCostumer, "Companies.csv");
        break;
      case "damagedItemModel":
        exportToCSV(damagedItemModels, "Damaged Item Models.csv");
        break;
      case "damageTypes":
        exportToCSV(damageTypes, "Damage Types.csv");
        break;
      case "placement":
        exportToCSV(placements, "Placements.csv");
        break;
      case "printGroup":
        exportToCSV(printGroups, "Article Groups.csv");
        break;
      case "task":
        exportToCSV(tasks, "Tasks.csv");
        break;
      case "taskSequence":
        exportToCSV(taskSequences, "Task Sequences.csv");
        break;
      case "workplace":
        exportToCSV(workplaces, "Workplaces.csv");
        break;
      default:
        break;
    }
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="statistic-page-container">
            <div className="statistic-page-top-section">
              {/* Back button */}
              <div className="statistic-page-back-btn" onClick={goBack}>
                <Button size="small" icon="back-icon" text="Back" />
              </div>
              {/* Page title */}
              <h2 className="statistic-page-title">Statistic</h2>
            </div>

            {/* Content section */}
            <div className="statistic-page-content-section">
              {/* Chart section */}
              <div className="statistic-page-chart-section">
                <>
                  {statisticData.length > 1 ? (
                    <BarChart width={900} height={480} data={statisticData}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey={"date"} />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Bar dataKey={"count"} fill="#0775ba" />
                    </BarChart>
                  ) : null}
                </>
              </div>
              {/* Input section: Date Picker and Export CSV */}
              <div className="statistic-page-inputs-section">
                {/* Date picker */}
                <div className="statistic-page-date-picker-section">
                  <div className="statistic-page-date-picker-item">
                    <img
                      src={startDateIcon}
                      alt="Start Date Icon"
                      className="statistic-page-date-picker-section-icon"
                    ></img>
                    <label className="statistic-page-date-picker-section-label">
                      Start Date:
                    </label>
                    <DatePicker
                      name="validFrom"
                      //   locale="de"
                      selected={startDate}
                      onChange={(date: Date) => setStartDate(date)}
                      isClearable
                      dateFormat="dd.MM.yyyy"
                      closeOnScroll={true}
                      maxDate={Date.now() as any}
                      showYearDropdown
                      scrollableMonthYearDropdown
                    />
                  </div>
                  {/* Date picker section */}
                  <div className="statistic-page-date-picker-item">
                    <img
                      src={endDateIcon}
                      alt="End Date Icon"
                      className="statistic-page-date-picker-section-icon"
                    ></img>
                    <label className="statistic-page-date-picker-section-label">
                      End Date:
                    </label>
                    <DatePicker
                      name="validFrom"
                      //   locale="de"
                      selected={endDate}
                      onChange={(date: Date) => setEndDate(date)}
                      isClearable
                      dateFormat="dd.MM.yyyy"
                      closeOnScroll={true}
                      maxDate={Date.now() as any}
                      showYearDropdown
                      scrollableMonthYearDropdown
                    />
                  </div>
                  <div className="statistic-page-date-picker-section-button">
                    <Button
                      text="Show Statistic"
                      size="small"
                      icon="edit-icon"
                      onClick={changePeriod}
                    />
                  </div>
                </div>
                {/* Export CSV section */}
                <div className="statistic-page-export-section">
                  <div className="statistic-page-export-section-input">
                    <h3 className="statistic-page-export-section-label">
                      Data Choice
                    </h3>
                    <div className="statistic-page-export-input">
                      <select
                        name="dataChoice"
                        className="my-input"
                        // options={options}
                        onChange={handleDataChoiceChange}
                        placeholder="Select Data Choice"
                      >
                        <option value="">Select a Data</option>
                        <option value="actions">Actions</option>
                        <option value="categories">Categories</option>
                        <option value="characteristics">Characteristics</option>
                        <option value="characteristicGroup">
                          Characteristic Groups
                        </option>
                        <option value="commissionedCustomer">Companies</option>
                        <option value="damagedItemModel">
                          Damaged Item Models
                        </option>
                        <option value="damageTypes">Damaged Types</option>
                        <option value="placement">Placements</option>
                        <option value="printGroup">Article Groups</option>
                        <option value="task">Tasks</option>
                        <option value="taskSequence">Task Sequences</option>
                        <option value="workplace">Workplaces</option>
                      </select>
                      <div className="statistic-page-export-section-category-icon"></div>
                    </div>
                  </div>
                  <div className="statistic-page-export-section-button">
                    <Button
                      text="Export CSV"
                      size="small"
                      icon="logout-icon"
                      onClick={HandleExportToCsvClicked}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default StatisticPage;
