import "./RepairPage.css";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

// thunks
import {
  getWorkplacePendingActionsRequest,
  updateActionRequest,
} from "store/thunks/actionThunk";
import { getMultipleDamagedItemModelsRequest } from "store/thunks/damagedItemModelThunk";
import { getAllCharacteristicsRequest } from "store/thunks/characteristicThunk";

// selectors
import { getTasksBySequenceChoosersSelector } from "store/selectors/taskSelector";
import { getWorkplacePendingActionsSelector } from "store/selectors/actionSelector";
import { getMultipleDamagedItemModelsSelector } from "store/selectors/damagedItemModelSelector";
import { getAllCharacteristics, getAllCharacteristicsImages } from "store/selectors/characteristicSelector";

// components
import Button from "components/Button/Button";
//images
import dropDownIcon from "assets/icons/drop-down-icon.png";
import image from "../../../assets/images/defaultPicture.png";
import dropUpIcon from "assets/icons/userZone/description-up-arrow.png";

function RepairPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // get the learningModuleID from the url parameter
  let params: any = useParams();
  const { index } = params;

  // redux state
  const workplacePendingActions = useSelector((state: any) =>
    getWorkplacePendingActionsSelector(state)
  );
  const tasksBySequenceChoosers = useSelector((state: any) =>
    getTasksBySequenceChoosersSelector(state)
  );
  const multipleDamagedItemModels = useSelector((state: any) =>
    getMultipleDamagedItemModelsSelector(state)
  );
  const characteristics = useSelector((state: any) => getAllCharacteristics(state))
  const characteristicsImages = useSelector((state:any)=> getAllCharacteristicsImages(state))

  //local states
  const [taskID, setTaskID] = useState<any>();
  const [pageNumber, setPageNumber] = useState(1);
  const [isOpened, setIsOpened] = useState(false);
  const [characteristicsState, setCharacteristicsState] = useState<any>();

  useEffect(() => {
    // change the browser tab title
    document.title = "WA - Repair";
    // get all workplacePendingActions thunk request
    workplacePendingActions.length < 1 &&
      dispatch(getWorkplacePendingActionsRequest());
    characteristics.length < 1 && dispatch(getAllCharacteristicsRequest())
  }, [dispatch, workplacePendingActions.length, characteristics.length]);

  useEffect(() => {
    // array to put in the damaged item models IDs
    let damagedItemModelsIDs = [] as any;
    // check if we have workplacePendingActions
    if (workplacePendingActions.length > 0) {
      // loop to push them in the array above
      workplacePendingActions[index]?.damagedItemModels.map(
        (damagedItemModelID: any) => {
          return damagedItemModelsIDs.push(damagedItemModelID.id);
        }
      );
      // API call to the damagedItemModel API sending the IDs
      dispatch(getMultipleDamagedItemModelsRequest(damagedItemModelsIDs));
      //get the action characteristics
      let myCharacteristics = [
        ...workplacePendingActions[index]?.characteristics,
      ] as any;
      setCharacteristicsState(myCharacteristics);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, index, workplacePendingActions]);


  const onSubmit = () => {
    let damagedItemModelsIDs = [] as any;
    // loop to push them in the array above
    workplacePendingActions[index]?.damagedItemModels.map(
      (damagedItemModelID: any) => {
        return damagedItemModelsIDs.push(damagedItemModelID.id);
      }
    );
    let characteristicsIDs = [] as any;
    // loop to push them in the array above
    workplacePendingActions[index]?.characteristics.map(
      (characteristic: any) => {
        return characteristicsIDs.push(characteristic.id);
      }
    );
    // let formData = new FormData();
    // for (let i = 0; i < damagedItemModelsIDs.length; i++) {
    //   formData.append(`DamagedItemModelsIDs[${i}]`, damagedItemModelsIDs[i]);
    // }
    // for (let i = 0; i < characteristicsIDs.length; i++) {
    //   formData.append(`CharacteristicsIDs[${i}]`, characteristicsIDs[i]);
    // }
    // formData.append(
    //   "PrintGroupID",
    //   workplacePendingActions[index]?.printGroup?.id
    // );
    // formData.append("DamageNumber", "1");
    // this should be PUT not POST
    dispatch(
      updateActionRequest(
        { actionsIDs: [workplacePendingActions[index].id] },
        navigate
      )
    );
  };

  // go back to previous page
  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className="repair-page-container">
      {/* top section */}
      <div className="repair-page-top-section">
        <div className="repair-page-back-btn">
          <Button size="small" icon="back-icon" text="Back" onClick={goBack} />
        </div>
        {/* Page title */}
        <h1 className="repair-page-title">Repair</h1>
      </div>
      {tasksBySequenceChoosers.length > 0 ? (
        <>
          {multipleDamagedItemModels.map((damagedItemModel: any) => {
            return damagedItemModel.sequenceChooser.id ===
              tasksBySequenceChoosers[pageNumber - 1]?.sequenceChooserID ? (
              <h2 className="repair-page-sub-title">
                {damagedItemModel.placementName},{" "}
                {damagedItemModel.damageType.name}
              </h2>
            ) : (
              ""
            );
          })}
        </>
      ) : (
        <h2 className="repair-page-sub-title">No Tasks Found</h2>
      )}

      {/* content container */}
      <div className="repair-page-content-container">
        {/* characteristics column */}
        <div className="repair-page-characteristics-column">
          <div>
            {characteristicsState &&
              characteristicsState.map((characteristic: any, index: any) => {
                // eslint-disable-next-line no-lone-blocks
                {
                  characteristicsImages &&
                    characteristicsImages
                      .filter(
                        (item: any) => item.imageID === characteristic.imageID
                      )
                      .map(function (image: any) {
                        characteristic.image = image.image;
                        return null;
                      });
                }
                return (
                  <div className="repair-page-characteristic" key={index}>
                    <div className="repair-page-characteristic-image">
                      <img
                        src={
                          characteristic.image !== undefined
                            ? "data:image/jpeg;base64," + characteristic.image
                            : image
                        }
                        alt={characteristic.name}
                        title={characteristic.name}
                      />
                    </div>

                    <div className="repair-page-characteristic-name">
                      {characteristic.name}
                    </div>
                    {index !== characteristicsState.length - 1 && <hr></hr>}
                  </div>
                );
              })}
          </div>
        </div>

        {/*  Tasks */}
        <div className="repair-page-tasks-container">
          <div className="repair-page-tasks-section">
            {tasksBySequenceChoosers.length > 0 ? (
              <>
                {tasksBySequenceChoosers[
                  pageNumber - 1
                ]?.tasksWithSequences.map(
                  (tasksWithSequence: any, index: any) => {
                    return (
                      <>
                        {isOpened && tasksWithSequence.task.id === taskID ? (
                          <div key={tasksWithSequence.task.id}>
                            <div className="repair-page-task-item-name-icon-open">
                              <h2>{tasksWithSequence.task.name}</h2>
                              <img
                                src={dropUpIcon}
                                alt="Drop Up Icon"
                                className="repair-page-task-item-img"
                                onClick={() => {
                                  setIsOpened(false);
                                  setTaskID("");
                                }}
                              />
                            </div>
                            <p className="repair-page-task-item-opened-description">
                              {tasksWithSequence.task.description}
                            </p>
                          </div>
                        ) : (
                          <div
                            key={tasksWithSequence.task.id}
                            className="repair-page-task-item-name-icon"
                          >
                            <h2>{tasksWithSequence.task.name}</h2>
                            <img
                              src={dropDownIcon}
                              alt="Drop Down Icon"
                              className="repair-page-task-item-img"
                              onClick={() => {
                                setTaskID(tasksWithSequence.task.id);
                                setIsOpened(true);
                              }}
                            />
                          </div>
                        )}
                      </>
                    );
                  }
                )}
              </>
            ) : null}
          </div>
        </div>

        {/* button section */}
        {tasksBySequenceChoosers.length > 0 ? (
          <>
            {pageNumber === tasksBySequenceChoosers.length ? (
              <div className="repair-page-submit-button-container">
                <Button
                  size="medium"
                  text="Complete Acquisition"
                  icon="complete-acquisition"
                  onClick={onSubmit}
                />
              </div>
            ) : (
              <div className="repair-page-submit-button-container-next">
                <Button
                  size="medium"
                  text="Next"
                  icon="next-icon"
                  onClick={() => {
                    setPageNumber(pageNumber + 1);
                  }}
                />
              </div>
            )}
          </>
        ) : (
          <div className="repair-page-submit-button-container">
            <Button
              size="medium"
              text="Complete Acquisition"
              icon="complete-acquisition"
              onClick={onSubmit}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default RepairPage;
