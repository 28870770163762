export const GET_WORKPLACE_ACTIONS_BY_DATE_SUCCESS =
  "GET_WORKPLACE_ACTIONS_BY_DATE_SUCCESS";
export const getActionsFilteredByDateSuccess = (
  actionFilteredByDates: any
) => ({
  type: GET_WORKPLACE_ACTIONS_BY_DATE_SUCCESS,
  payload: actionFilteredByDates,
});

export const GET_ALL_WORKPLACE_PENDING_ACTIONS_SUCCESS =
  "GET_ALL_WORKPLACE_PENDING_ACTIONS_SUCCESS";
export const getWorkplacePendingActionsSuccess = (
  workplacePendingActions: any
) => ({
  type: GET_ALL_WORKPLACE_PENDING_ACTIONS_SUCCESS,
  payload: workplacePendingActions,
});

export const DELETE_ACTION_SUCCESS = "DELETE_ACTION_SUCCESS";
export const deleteActionSuccess = (actionID: any) => ({
  type: DELETE_ACTION_SUCCESS,
  payload: actionID,
});

export const CREATE_ACTION_SUCCESS = "CREATE_ACTION_SUCCESS";
export const createActionSuccess = (newAction: any) => ({
  type: CREATE_ACTION_SUCCESS,
  payload: newAction,
});

export const CREATE_PAST_ORDERS_ACTIONS_SUCCESS =
  "CREATE_PAST_ORDERS_ACTIONS_SUCCESS";
export const creatPastOrdersActionsSuccess = (newPastOrderAction: any) => ({
  type: CREATE_PAST_ORDERS_ACTIONS_SUCCESS,
  payload: newPastOrderAction,
});
