import './LocationPopup.css'

import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";

//thunks
import { createLocationRequest, editLocationRequest } from 'store/thunks/locationThunk';

//selectors
import { getAllLocationsSelector } from 'store/selectors/locationSelector';

//validation
import { LocationSchema } from 'validations/sensrecAdminValidations';
//components
import Button from "components/Button/Button";

//images
import close from "assets/icons/close-button.png";
import cityIcon from "assets/icons/city-icon.png";

type LocationPopupProps = {
  hidePopUp?: any;
  locationID?: any;
};

function LocationPopup(props: LocationPopupProps) {
  const dispatch = useDispatch();

  //redux state
  const locations = useSelector((state: any) =>
    getAllLocationsSelector(state)
  );

  //local state
  const [locationState, setLocationState] = useState({
      name: "",
      city: "",
  });

  useEffect(() => {
    props.locationID && getLocation(props.locationID);
    // because we are in a popup we should stop scrolling so we add this classes
    let popupContainer = document.getElementById(
      "location-popup-main-container"
    ) as any;
    popupContainer && popupContainer.classList.remove("hide");
    document.body.classList.add("location-popup-overflow-hidden");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.locationID]);

  const getLocation = (locationID: any) => {
    let location = locations.filter(
      (location: any) => location.id === locationID
    );

    setLocationState({
      ...locationState,
        name: location[0].name,
        city: location[0].city
    });
  };

  //Handle when the input is changed
  const handleInputChange = (event: any) => {
     const name = event.target.name
      const value = event.target.value
      setLocationState({...locationState, [name]: value})
    };

  const onSubmit = (
    fields: any,
    { setStatus, setSubmitting }: { setStatus: any; setSubmitting: any }
  ) => {
    const data = {
      name: locationState.name,
      city: locationState.city,
    };

    if (!props.locationID) {
      dispatch(createLocationRequest(data));
      props.hidePopUp();
    } else {
      dispatch(editLocationRequest(props.locationID, data));
      props.hidePopUp();
    }
  };

  const handleSubmit = () => {
    document.body.classList.remove("location-popup-overflow-hidden");
  };
  return (
    <>
      <div
        className="location-popup-main-container"
        id="location-popup-main-container"
      >
        <div
          className="location-popup-overlay"
          onClick={props.hidePopUp}
        ></div>
        <div className="location-container">
          {/* Header: title and close button */}
          <div className="article-group-top-section">
            {props.locationID ? (
              <h1>Edit Location</h1>
            ) : (
              <h1> Create New Location</h1>
            )}
            <img
              className="location-popup-close"
              src={close}
              onClick={props.hidePopUp}
              alt="close Popup"
              title="close Popup"
            />
          </div>

          <Formik
            initialValues={locationState}
            validationSchema={LocationSchema}
            onSubmit={onSubmit}
            enableReinitialize
          >
            {({ values, errors, touched, isSubmitting, setFieldValue }) => {
              return (
                <>
                  <Form>
                    <div className="location-popup-form-container">
                              <div className="location-fields-section">
                                  {/* Location name*/}
                        <h3 className="location-popup-input-label">Name</h3>
                        <div className="location-popup-input">
                          <div className="location-popup-input">
                            <Field
                              className="my-input"
                              type="text"
                              id="name"
                              name="name"
                              value={locationState.name}
                              placeholder="Name"
                              onChange={handleInputChange}
                            />
                            {touched.name && errors.name ? (
                              <div
                                className="location-popup-error-message"
                                role="alert"
                              >
                                {errors.name}
                              </div>
                            ) : null}
                          </div>
                          <div className="location-popup-icon">
                            <img src={cityIcon} title="City Icon" alt="City Icon" />
                          </div>
                                  </div>
                                  {/* city input */}
                                  <h3 className="location-popup-input-label">City</h3>
                        <div className="location-popup-input">
                          <div className="location-popup-input">
                            <Field
                              className="my-input"
                              type="text"
                              id="city"
                              name="city"
                              value={locationState.city}
                              placeholder="City"
                              onChange={handleInputChange}
                            />
                            {touched.city && errors.city ? (
                              <div
                                className="location-popup-error-message"
                                role="alert"
                              >
                                {errors.city}
                              </div>
                            ) : null}
                          </div>
                          <div className="location-popup-icon">
                            <img src={cityIcon} title="City Icon" alt="City Icon" />
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Submit Button: Create or Edit */}
                    <div className="location-submit-button-container">
                      {!props.locationID ? (
                        <Button
                          size="small"
                          text="Create"
                          icon="add-icon"
                          type="submit"
                          onClick={handleSubmit}
                        ></Button>
                      ) : (
                        <Button
                          icon="edit-icon"
                          size="small"
                          text="Edit"
                          type="submit"
                          onClick={handleSubmit}
                        ></Button>
                      )}
                    </div>
                  </Form>
                </>
              );
            }}
          </Formik>
        </div>
      </div>
    </>
  );
}

export default LocationPopup