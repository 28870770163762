import "./DeletePopup.css";
import { useDispatch } from "react-redux";

// images
import close from "assets/icons/close-button.png";

// components
import Button from "components/Button/Button";

//(Jad) Adding characteristic id
type DeletePopupProps = {
  id?: any;
  hidePopUp?: any;
  deleteRequest?: any;
};

function DeletePopup(props: DeletePopupProps) {
  const dispatch = useDispatch();
  return (
    <>
      <div id="popup-main-container" className="delete-popup-main-container">
        <div className="delete-popup-overlay" onClick={props.hidePopUp}></div>
        <div className="delete-popup-container">
          <div className="delete-popup-top-inner">
            <h1> Are you sure?</h1>

            <img
              className="delete-popup-close"
              src={close}
              onClick={props.hidePopUp}
              alt="close Popup"
              title="close Popup"
            />
          </div>
          <div className="delete-buttons">
            <Button
              text="Yes"
              size="small"
              icon="yes-delete-icon"
              redBtn={true}
              onClick={() => {
                dispatch(props.deleteRequest(props.id));
                props.hidePopUp();
              }}
            />
            <Button
              text="No"
              size="small"
              icon="no-delete-icon"
              onClick={props.hidePopUp}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default DeletePopup;
