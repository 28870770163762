import "./AcquisitionOverviewPage.css";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

//date picker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// thunks
import {
  getActionsFilteredByDateRequest,
  deleteActionRequest,
} from "store/thunks/actionThunk";

// selector
import { getActionsFilteredByDateSelector } from "store/selectors/actionSelector";

// components
import Button from "components/Button/Button";
import Loader from "components/Loader/Loader";
import DeletePopup from "components/DeletePopup/DeletePopup";
import MessageNotification from "components/MessageNotification/MessageNotification";

//images
import startDateIcon from "assets/icons/start-date-icon.png";
import endDateIcon from "assets/icons/end-date-icon.png";

function AcquisitionOverviewPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // redux state
  const actionFilteredByDates = useSelector((state: any) =>
    getActionsFilteredByDateSelector(state)
  );
  const { loading } = useSelector((state: any) => state.LoadingReducer);
  const { messageText, messageType } = useSelector(
    (state: any) => state.MessageReducer
  );

  // local states
  const [isDeletePopup, setDeletePopup] = useState({
    isDeletePopup: false,
    actionID: "",
  });

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  useEffect(() => {
    // change the browser tab title
    document.title = "WA - Acquisition Overview";
    // declare start date form today morning
    var start = new Date();
    start.setMonth(start.getMonth() - 1);
    start.setUTCHours(0, 0, 0, 0);
    // declare end date to today midnight
    var end = new Date();
    end.setUTCHours(23, 59, 59, 999);

    // get all workplace Actions by dates thunk request
      dispatch(
        getActionsFilteredByDateRequest(start.toISOString(), end.toISOString())
      );
  }, [dispatch]);

  // go back to previous page
  const goBack = () => {
    navigate(-1);
  };


  const changePeriod = () => {
    dispatch(
      getActionsFilteredByDateRequest(
        startDate.toISOString(),
        endDate.toISOString()
      )
    );
  };
  return (
    <>
      {messageText ? (
        <MessageNotification
          messageText={messageText}
          messageType={messageType}
        />
      ) : null}

      {isDeletePopup.isDeletePopup ? (
        <DeletePopup
          hidePopUp={() => {
            setDeletePopup({
              isDeletePopup: false,
              actionID: "",
            });
          }}
          id={isDeletePopup.actionID}
          deleteRequest={deleteActionRequest}
        />
      ) : null}

      {loading ? (
        <Loader />
      ) : (
        <div className="acquisition-admin-page-main-container">
          <div className="acquisition-admin-page-top-section">
            {/* Back button */}
            <div className="acquisition-admin-page-back-btn" onClick={goBack}>
              <Button size="small" icon="back-icon" text="Back" />
            </div>
            {/* Page title */}
            <h2 className="acquisition-admin-page-title">
              Acquisition Overview
            </h2>
          </div>

          {/* table of actions */}
          <div className="acquisition-admin-page-workplacePendingAction-container">
            <div className="acquisition-admin-page-workplacePendingAction-cell-header">
              <h2 className="acquisition-admin-page-workplacePendingAction-item-cell-header">
                Damage #
              </h2>
              <h2 className="acquisition-admin-page-workplacePendingAction-item-cell-header">
                Date of capture
              </h2>
            </div>
            {/* damage number and characteristics names */}
            {actionFilteredByDates.map(
              (actionFilteredByDate: any, index: any) => {
                return (
                  <div
                    key={index}
                    className="acquisition-admin-page-workplacePendingAction-item-container"
                  >
                    {/* number and characteristic names container*/}
                    <div className="acquisition-admin-page-workplacePendingAction-item-number-name-section">
                      {/* damage numbers */}
                      <h2
                        className={
                          index % 2 !== 0
                            ? "acquisition-admin-page-workplacePendingAction-item-cell  background-blue"
                            : "acquisition-admin-page-workplacePendingAction-item-cell"
                        }
                      >
                        #{actionFilteredByDate.damageNumber}
                      </h2>

                      {/* date of capture */}
                      <h2
                        className={
                          index % 2 !== 0
                            ? "acquisition-admin-page-workplacePendingAction-item-cell  background-blue"
                            : "acquisition-admin-page-workplacePendingAction-item-cell"
                        }
                      >
                        {actionFilteredByDate.timeStampReady.slice(0, 10)}
                      </h2>

                      {/* characteristics names */}
                      <div className="acquisition-admin-page-workplacePendingAction-characteristic-names-container">
                        {actionFilteredByDate.characteristics.map(
                          (characteristic: any, index2: any) => {
                            return (
                              <h2
                                key={index2}
                                className={
                                  index % 2 !== 0
                                    ? "acquisition-admin-page-workplacePendingAction-item-cell background-blue"
                                    : "acquisition-admin-page-workplacePendingAction-item-cell"
                                }
                              >
                                {characteristic.name}
                              </h2>
                            );
                          }
                        )}
                      </div>
                    </div>

                    {/* buttons container */}
                    <div className="acquisition-admin-page-buttons-container">
                      <Button
                        text="View Damages"
                        size="medium"
                        icon="view-damages"
                        onClick={() => navigate(`/view-damages`, {state: actionFilteredByDate})}
                      />
                      <Button
                        text="Clear Capture"
                        size="medium"
                        icon="big-delete-icon"
                        redBtn={true}
                        onClick={() =>
                          setDeletePopup({
                            isDeletePopup: true,
                            actionID: actionFilteredByDate.id,
                          })
                        }
                      />
                    </div>
                  </div>
                );
              }
            )}
          </div>
          <div className="acquisition-admin-page-date-picker-section">
            <div className="acquisition-admin-page-date-picker-item">
              <img
                src={startDateIcon}
                alt="Start Date Icon"
                className="acquisition-admin-page-date-picker-section-icon"
              ></img>
              <label className="acquisition-admin-page-date-picker-section-label">
                From:
              </label>
              <DatePicker
                name="validFrom"
                selected={startDate}
                onChange={(date: Date) => setStartDate(date)}
                isClearable
                dateFormat="dd.MM.yyyy"
                closeOnScroll={true}
                maxDate={Date.now() as any}
                showYearDropdown
                scrollableMonthYearDropdown
              />
            </div>
            {/* Date picker section */}
            <div className="acquisition-admin-page-date-picker-item">
              <img
                src={endDateIcon}
                alt="End Date Icon"
                className="acquisition-admin-page-date-picker-section-icon"
              ></img>
              <label className="acquisition-admin-page-date-picker-section-label">
                To:
              </label>
              <DatePicker
                name="validFrom"
                selected={endDate}
                onChange={(date: Date) => setEndDate(date)}
                isClearable
                dateFormat="dd.MM.yyyy"
                closeOnScroll={true}
                maxDate={Date.now() as any}
                showYearDropdown
                scrollableMonthYearDropdown
              />
            </div>
            <div className="acquisition-admin-page-date-picker-section-button">
              <Button
                text="Change Period"
                size="small"
                icon="edit-icon"
                onClick={changePeriod}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default AcquisitionOverviewPage;
function state(arg0: string, state: any) {
  throw new Error("Function not implemented.");
}

