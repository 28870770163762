//actions
import { CREATE_COLLECTION_LIST_SUCCESS, GET_COLLECTION_LIST_FILTERED_BY_DATE_SUCCESS } from "store/actions/collectionListAction";

const initialState = [] as any

const collectionListReducer = (state = initialState, action: any) => {
    let collectionListArray = state
    const { type, payload } = action;
    switch (type) {
        case CREATE_COLLECTION_LIST_SUCCESS:
            collectionListArray.push(payload)
            return collectionListArray
        case GET_COLLECTION_LIST_FILTERED_BY_DATE_SUCCESS:
            collectionListArray = payload
            return collectionListArray
        default:
            return state
    }
}

export default collectionListReducer