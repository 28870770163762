import { httpCommon } from "./http-common";
import authHeader from "./authHeader";

let test: any = []

const getUser = (id: string) => {
  return httpCommon.get(`/User/${id}`, { headers: authHeader() as any });
};

const getAllUsers = () => {
  return httpCommon.get("/User", { headers: authHeader() as any });
};

const createUser = (data: any) => {
  return httpCommon.post("/User/register", data, { headers: authHeader() as any });
};

const updateUser = (id: string, data: any) => {
  return httpCommon.put(`/User/${id}`, data, { headers: authHeader() as any });
};

const deleteUser = (id: string) => {
  return httpCommon.delete(`/User/${id}`, { headers: authHeader() as any });
};

const resetUserPassword = (userID: any) => {
  return httpCommon.put(`/User/ResetPassword?userID=${userID}`, test, { headers: authHeader() as any });
};

const UserService = {
  getUser,
  getAllUsers,
  createUser,
  updateUser,
  deleteUser,
  resetUserPassword
};

export default UserService;
