import './WorkplaceSensrecAdminPopup.css'

import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";

//thunks
import {
  createWorkplaceRequest,
  editWorkplaceRequest,
} from "store/thunks/workplaceThunk";

//selectors
import { getAllWorkplacesSelector } from "store/selectors/workplaceSelector";
import { getAllLocationsSelector } from 'store/selectors/locationSelector';

//validation
import { WorkplaceSensrecAdminSchema } from 'validations/sensrecAdminValidations';

//components
import Button from "components/Button/Button";

//images
import close from "assets/icons/close-button.png";
import ipIcon from "assets/icons/ip-icon.png";
import cityIcon from "assets/icons/city-icon.png"

type WorkplacePopupProps = {
  hidePopUp?: any;
  workplaceID?: any;
};

function WorkplaceSensrecAdminPopup(props: WorkplacePopupProps) {
 const dispatch = useDispatch();

 //redux state
 const workplaces = useSelector((state: any) =>
   getAllWorkplacesSelector(state)
 );
 const locations = useSelector((state: any) => getAllLocationsSelector(state));

 //local state
 const [workplaceState, setWorkplaceState] = useState({
     ip: "",
     locationID: "",
 });

 useEffect(() => {
   props.workplaceID && getWorkplace(props.workplaceID);
   // because we are in a popup we should stop scrolling so we add this classes
   let popupContainer = document.getElementById(
     "workplace-popup-main-container"
   ) as any;
   popupContainer && popupContainer.classList.remove("hide");
   document.body.classList.add("workplace-popup-overflow-hidden");
   // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [props.workplaceID]);

 const getWorkplace = (workplaceID: any) => {
   let workplace = workplaces.filter(
     (workplace: any) => workplace.id === workplaceID
   );

   setWorkplaceState({
     ...workplaceState,
       ip: workplace[0].ip,
     locationID: workplace[0].location.id
   });
 };

 //Handle when the ip is changed
 const handleIpInputChange = (event: any) => {
     const name = event.target.name
     const value = event.target.value

     setWorkplaceState({...workplaceState, [name]: value})
 };

 const onSubmit = (
   fields: any,
   { setStatus, setSubmitting }: { setStatus: any; setSubmitting: any }
 ) => {
   const data = {
     ip: workplaceState.ip,
     locationID: parseInt(workplaceState.locationID),
   };

   if (!props.workplaceID) {
     dispatch(createWorkplaceRequest(data));
     props.hidePopUp();
   } else {
     dispatch(editWorkplaceRequest(props.workplaceID, data));
     props.hidePopUp();
   }
 };

 const handleSubmit = () => {
   document.body.classList.remove("workplace-popup-overflow-hidden");
 };
 return (
   <>
     <div
       className="workplace-popup-main-container"
       id="workplace-popup-main-container"
     >
       <div className="workplace-popup-overlay" onClick={props.hidePopUp}></div>
       <div className="workplace-container">
         {/* Header: title and close button */}
         <div className="article-group-top-section">
           {props.workplaceID ? (
             <h1>Edit Workplace</h1>
           ) : (
             <h1> Create New Workplace</h1>
           )}
           <img
             className="workplace-popup-close"
             src={close}
             onClick={props.hidePopUp}
             alt="close Popup"
             title="close Popup"
           />
         </div>

         <Formik
           initialValues={workplaceState}
           validationSchema={WorkplaceSensrecAdminSchema}
           onSubmit={onSubmit}
           enableReinitialize
         >
           {({ values, errors, touched, isSubmitting, setFieldValue }) => {
             return (
               <>
                 <Form>
                   <div className="workplace-popup-form-container">
                     {/* Workplace ip*/}
                     <div className="workplace-fields-section">
                       <h3 className="workplace-popup-input-label">IP</h3>
                       <div className="workplace-popup-input">
                         <div className="workplace-popup-input">
                           <Field
                             className="my-input"
                             type="text"
                             id="ip"
                             name="ip"
                             value={workplaceState.ip}
                             placeholder="IP"
                             onChange={handleIpInputChange}
                           />
                           {touched.ip && errors.ip ? (
                             <div
                               className="workplace-popup-error-message"
                               role="alert"
                             >
                               {errors.ip}
                             </div>
                           ) : null}
                         </div>
                         <div className="workplace-popup-icon">
                           <img src={ipIcon} title="IP Icon" alt="IP Icon" />
                         </div>
                       </div>
                       {/* Locations*/}
                       <h3 className="workplace-popup-input-label">
                         Locations
                       </h3>
                       <div className="workplace-popup-input">
                         <Field
                           as="select"
                           className="my-input"
                           id="locationID"
                           name="locationID"
                           value={workplaceState.locationID}
                           placeholder="Locations"
                           onChange={handleIpInputChange}
                         >
                           {props.workplaceID && workplaceState.locationID ? (
                             <>
                               {locations &&
                                 locations.map(
                                   // eslint-disable-next-line array-callback-return
                                   (location: any, index: any) =>
                                     location.id ===
                                     workplaceState.locationID ? (
                                       <option
                                         value={location.id}
                                         key={index}
                                         selected
                                       >
                                         {location.name}
                                       </option>
                                     ) : (
                                       <option value={location.id} key={index}>
                                         {location.name}
                                       </option>
                                     )
                                 )}
                             </>
                           ) : (
                             <>
                               <option value="">Select Location</option>
                               {locations &&
                                 locations.map((location: any, index: any) => (
                                   <option value={location.id} key={index}>
                                     {location.name}
                                   </option>
                                 ))}
                             </>
                           )}
                         </Field>
                         {touched.locationID && errors.locationID ? (
                           <div
                             className="workplace-popup-error-message"
                             role="alert"
                           >
                             {errors.locationID}
                           </div>
                         ) : null}
                         <div className="workplace-popup-icon">
                           <img
                             src={cityIcon}
                             title="City Icon"
                             alt="City Icon"
                           />
                         </div>
                       </div>
                     </div>
                   </div>

                   {/* Submit Button: Create or Edit */}
                   <div className="workplace-submit-button-container">
                     {!props.workplaceID ? (
                       <Button
                         size="small"
                         text="Create"
                         icon="add-icon"
                         type="submit"
                         onClick={handleSubmit}
                       ></Button>
                     ) : (
                       <Button
                         icon="edit-icon"
                         size="small"
                         text="Edit"
                         type="submit"
                         onClick={handleSubmit}
                       ></Button>
                     )}
                   </div>
                 </Form>
               </>
             );
           }}
         </Formik>
       </div>
     </div>
   </>
 );
}

export default WorkplaceSensrecAdminPopup