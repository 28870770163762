//actions
import { stat } from "fs";
import {
  GET_ALL_CHARACTERISTICS_SUCCESS,
  GET_ALL_CHARACTERISTICS_BY_CATEGORY_ID_SUCCESS,
  CREATE_CHARACTERISTIC_SUCCESS,
  EDIT_CHARACTERISTIC_SUCCESS,
  DELETE_CHARACTERISTIC_SUCCESS,
} from "store/actions/characteristicAction";

//state
const initialState = {
  characteristics: [],
  characteristicsImages: [],
};

const characteristicReducer = (state = initialState, action: any) => {
  const { type, payload } = action;
  let characteristicsArray: any = [...state.characteristics];
  let characteristicsImagesArray: any = [...state.characteristicsImages];

  switch (type) {
    case GET_ALL_CHARACTERISTICS_SUCCESS:
      return {
        ...state,
        characteristics: payload.characteristics,
        characteristicsImages: payload.characteristicsImages,
      };

    case GET_ALL_CHARACTERISTICS_BY_CATEGORY_ID_SUCCESS:
      return {
        ...state,
        characteristics: payload.characteristics,
        characteristicsImages: payload.characteristicsImages,
      };

    case CREATE_CHARACTERISTIC_SUCCESS:
      characteristicsArray.push(payload.newCharacteristic);
      payload.newCharacteristicImage !== undefined &&
        characteristicsImagesArray.push(payload.newCharacteristicImage);
      return {
        ...state,
        characteristics: characteristicsArray,
        characteristicsImages: characteristicsImagesArray,
      };

    case EDIT_CHARACTERISTIC_SUCCESS:
      characteristicsArray.map((characteristic: any, index: any) => {
        if (characteristic.id === payload.updateCharacteristic.id) {
          return (characteristicsArray[index] = payload.updateCharacteristic);
        } else {
          return characteristicsArray;
        }
      });
      payload.updateCharacteristicImage !== undefined &&
        characteristicsImagesArray.push(payload.updateCharacteristicImage);
      return {
        ...state,
        characteristics: characteristicsArray,
        characteristicsImages: characteristicsImagesArray,
      };

    case DELETE_CHARACTERISTIC_SUCCESS:
      characteristicsArray = state.characteristics.filter(
        (characteristic: any) => characteristic.id !== payload
      );
      return {
        ...state,
        characteristics: characteristicsArray,
      };

    default:
      return state;
  }
};
export default characteristicReducer;
