import * as exceljs from "exceljs";
import * as FileSaver from "file-saver";

export const exportToCSV = (data: any, filename: any) => {
  const headerList: any = [];
    const arrayLength: any = [];
 
    // we need to get the max length of arrays inside the data
    const getArrayLength = (data: any, counter: any) => {
      for (let [key, value] of Object.entries(data)) {
        if (typeof value === "object") {
          // If the object value, which is an object, is an array, check if the arraylength at the specified position is longer than the previous saved at that position. If true, the new length will be saved
          if (Array.isArray(value)) {
            if (arrayLength[counter] < value.length) {
              arrayLength[counter] = value.length;
            }
            // If no length is existent at the specified array index, save the current array length
            if (
              typeof arrayLength[counter] == "undefined" ||
              arrayLength[counter] === "" ||
              arrayLength[counter] === null
            ) {
              arrayLength[counter] = value.length;
            }
            counter++;
          }
          // If the checked value was an object, get into that one with the getArrayLength method
          getArrayLength(value, counter);
        }
    }
  };

//   takes the first object of the object array as data param
// loops through the data
// if the method gets called at the first level of the object, the key will be taken as a header name
// else, the header name consists of the previous name and _ and the current key name
// checks, if the value is an object
// checks, if the value is an array, if it is an object
// if it is an array, search for the entry in arrayLength array, which isn’t 0 and take the position as index
// check, if the length of the value is smaller or equal to the length of the entry of arrayLength with the previously found index

  const getHeaderValue = (
    data: any,
    firstValues: boolean,
    headerName?: string
  ) => {
    let name = "";
    for (let [key, value] of Object.entries(data)) {
      if (firstValues) {
        name = key;
      } else {
        name = headerName + "_" + key;
      }
      if (typeof value == "object") {
        if (Array.isArray(value)) {
          let index = 0;
          let arr: any = arrayLength;
          for (let i = 0; i < arrayLength; i++) {
            if (arrayLength !== 0) {
              index = i;
              break;
            }
          }
          if (value.length <= arrayLength[index]) {
            for (let i = value.length; i < arrayLength; i++) {
              getHeaderValue({ [i]: value[0] }, false, name);
            }
            arrayLength[index] = 0;
          }
        }
        getHeaderValue(value, false, name);
      }
      if (typeof value != "object") {
        headerList.push({ header: name, key: name });
      }
    }
  };

//   loops through the object data
// the name of the key gets determined, whether we are at the first layer of the object or not. If we are at the first layer, the name is equal to the current key name. If not, the name is set to the name of the previous name and _ and the current key name
// if the current object value is also an object:
// merge the data with the result from mergeJsonObjects. We’re doing this, to go deeper into the structure until the value isn’t another object anymore.
// With the merge we’re adding a new entry into the data with the right key. Since in the current data the “wrong” key also is included, we need to delete this one.
// If it is not an object, we may need to rename it. That’s why we call the method “renameKey”, which adds the value of the old key with a new key.
// In the end, we return the data for either giving the fully merged data or the key and value pair to merge into the data.

  const mergeJsonObjects = (data: any, firstValues: boolean, keyName?: any) => {
    let name = "";
    for (let [key, value] of Object.entries(data)) {
      if (firstValues) {
        name = key;
      } else {
        name = keyName + "_" + key;
      }
      if (typeof value == "object") {
        data = { ...data, ...mergeJsonObjects(value, false, name) };
        delete data[key];
      } else {
        renameKey(data, key, name);
      }
    }
    return data;
  };

  const renameKey = (obj: any, oldKey: any, newKey: any) => {
    obj[newKey] = obj[oldKey];
    if (newKey !== oldKey) {
      delete obj[oldKey];
    }
    };
    
    getArrayLength(data, 0);
    getHeaderValue(data[0], true);
    let workbook = new exceljs.Workbook();
    let sheet = workbook.addWorksheet("My Sheet");
    sheet.columns = headerList;
    for (let item of data) {
      sheet.addRow(mergeJsonObjects(item, true));
    }
    //comment this line out to save as xlsx file
    //let blobType: string = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let blobType = "text/csv;charset=UTF-8;";
    workbook.csv.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: blobType });
      FileSaver.saveAs(blob, filename);
    }); 
};
