import "./PlacementPopup.css";
import Select from "react-select";
import { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import { useDispatch, useSelector } from "react-redux";

// thunk requests
import {
  createPlacementRequest,
  editPlacementRequest,
} from "store/thunks/placementThunk";

// selectors
import {
  getAllCharacteristicGroups,
  // getAllCharacteristicsGroupImages,
} from "store/selectors/characteristicsGroupSelector";

// import { getAllCharacteristics } from "store/selectors/characteristicSelector";
import { getAllDamageTypesSelector } from "store/selectors/damageTypeSelector";
import { getAllPlacements } from "store/selectors/placementSelector";

// validations
import { PlacementSchema } from "validations/adminValidations";

//images
import close from "assets/icons/close-button.png";
import image from "assets/images/defaultPicture.png";

//components
// import Input from "components/Input/Input";
import Button from "components/Button/Button";

type PlacementPopupProps = {
  placementID?: any;
  hidePopUp?: any;
};

function PlacementPopup(props: PlacementPopupProps) {
  const dispatch = useDispatch<any>();

  // redux state
  const placements = useSelector((state: any) => getAllPlacements(state));
  const characteristicGroups = useSelector((state: any) =>
    getAllCharacteristicGroups(state)
  );
  const damageTypes = useSelector((state: any) =>
    getAllDamageTypesSelector(state)
  );

  // local states
  const [placementState, setPlacementState] = useState({
    name: "",
    characteristicGroup: {} as any,
    damageTypeIDs: [] as any,
    // image: image,
  });
  // state used for the damage types multi select dropdown
  const [assignedDamagedTypes, setAssignedDamagedTypes] = useState<any>(image);

  // here we convert the naming from react select defaults to options as we need the naming in our state and DB
  let options: any;
  //get damageTypes options
  if (damageTypes) {
    options = damageTypes.map(function (item: any) {
      return { value: item.id, label: item.name };
    });
  }

  // if the form is on edit, so we get user data from DB:
  const getPlacement = (id: string) => {
    // filter the redux placements array and get the selected
    let placement = placements.filter(
      (placement: any) => placement.id === props.placementID
    );

    // loop over the placement object and get the damage type IDs
    let myDamageTypeIDs = [] as any;
    for (let i = 0; i < placement[0].damagedItemModels.length; i++) {
      myDamageTypeIDs.push(placement[0]?.damagedItemModels[i].damageType.id);
    }
    // set the local state from redux data
    setPlacementState({
      name: placement[0]?.name,
      characteristicGroup: {
        id: placement[0]?.characteristicsGroup.id,
        name: placement[0]?.characteristicsGroup.name,
      },
      damageTypeIDs: myDamageTypeIDs,
      //   image: characteristicGroup[0]?.image,
    });

    //get saved selected Characteristics options to display them in dropdown
    const saved_options = placement[0].damagedItemModels.map(function (
      item: any
    ) {
      return { value: item.damageType.id, label: item.damageType.name };
    });
    // convert the data to be accepted by the react select
    const saved_functions = [] as any;
    saved_options.forEach((option: any) => {
      saved_functions.push(option.value);
    });
    // set the data in the react select state
    setAssignedDamagedTypes(saved_options);

    // // filter the redux categories images array
    // let characteristicGroupImage = characteristicsGroupImages.filter(
    //   (image: any) => image.imageID === characteristicGroup[0].imageID
    // );
    //     characteristicGroupImage[0]?.image &&
    //       setBaseImage(
    //         "data:image/jpeg;base64," + characteristicGroupImage[0]?.image
    //       );
  };

  useEffect(() => {
    // change the browser tab title
    document.title = "WA - Placements";
  }, []);

  useEffect(() => {
    // if there is placementID so we call the get placement by ID function
    props.placementID && getPlacement(props.placementID);

    // because we are in a popup we should stop scrolling so we add this classes
    let popupContainer = document.getElementById(
      ".placement-popup-main-container"
    ) as any;
    popupContainer && popupContainer.classList.remove("hide");
    document.body.classList.add("placement-overflow-hidden");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.placementID]);

  // handle input change
  const handleInputChange = (event: any) => {
    const target = event.target;
    const value = event.target.value;
    const name = target.name;
    setPlacementState({ ...placementState, [name]: value });
  };

  // handle Select change
  const handleSelectChange = (event: any) => {
    const value = event.target.value;
    const name = event.target[value].innerHTML;
    setPlacementState({
      ...placementState,
      characteristicGroup: { id: value, name: name },
    });
  };

  // onChange function for the damage types
  const handleDamageTypesSelect = (event: any) => {
    let values = Array.isArray(event) ? event.map((x: any) => x) : [];
    // let options: any;
    let test = [] as any;
    if (values) {
      setAssignedDamagedTypes(values);
      values.map((val: any) => {
        return test.push(val.value);
      });

      setPlacementState({
        ...placementState,
        damageTypeIDs: test,
      });
    }
  };

  // onSubmit we check if the form is created or edited and gives the correct function:
  function onSubmit() {
    const { name, damageTypeIDs, characteristicGroup } = placementState;
    const { id: characteristicGroupID } = characteristicGroup;
    // let data = new FormData();
    // data.append("name", name);
    // data.append("characteristicsGroupID", characteristicGroupID);
    // for (let i = 0; i < damageTypeIDs.length; i++) {
    //   data.append(`damageTypeIDs[${i}]`, damageTypeIDs[i]);
    // }
    // data.append("image", image);

    // check to know if create or update
    if (!props.placementID) {
      dispatch(
        createPlacementRequest({
          name: name,
          characteristicsGroupID: Number(characteristicGroupID),
          damageTypeIDs: damageTypeIDs,
        })
      );
      props.hidePopUp();
    } else {
      dispatch(
        editPlacementRequest(props.placementID, {
          name: name,
          characteristicsGroupID: Number(characteristicGroupID),
          damageTypeIDs: damageTypeIDs,
        })
      );
      props.hidePopUp();
    }
  }
  // just to return the scroll to the page
  const handleSubmit = () => {
    document.body.classList.remove("placement-overflow-hidden");
  };

  return (
    <>
      <div id="popup-main-container" className="placement-popup-main-container">
        <div
          className="placement-popup-overlay"
          onClick={props.hidePopUp}
        ></div>
        <div className="placement-popup-container">
          {/* top section, title with close button */}
          <div className="placement-popup-top-section">
            {props.placementID ? (
              <h1>Edit Placement</h1>
            ) : (
              <h1>New Placement</h1>
            )}
            <img
              className="placement-popup-close"
              src={close}
              onClick={props.hidePopUp}
              alt="close Popup"
              title="close Popup"
            />
          </div>

          <>
            <Formik
              initialValues={placementState}
              validationSchema={PlacementSchema}
              onSubmit={onSubmit}
              enableReinitialize
            >
              {({ values, errors, touched, isSubmitting, setFieldValue }) => {
                return (
                  <>
                    <Form>
                      <div className="placement-popup-form-container">
                        {/* <div className="placement-popup-fields-section"> */}
                        {/* Name input */}
                        <h2 className="placement-popup-input-label">Name</h2>
                        <div className="placement-popup-input">
                          <div className="placement-popup-input">
                            <Field
                              className="my-input"
                              type="text"
                              id="name"
                              name="name"
                              value={placementState.name}
                              placeholder="Name"
                              onChange={handleInputChange}
                            />
                            {touched.name && errors.name ? (
                              <div
                                className="placement-error-message"
                                role="alert"
                              >
                                {errors.name}
                              </div>
                            ) : null}
                          </div>
                          <div className="placement-category-icon"></div>
                        </div>

                        {/* Article Number input */}
                        <h2 className="placement-popup-input-label">
                          Characteristic Group
                        </h2>
                        <div className="placement-popup-input">
                          <div className="my-select">
                            <Field
                              as="select"
                              className="my-input"
                              id="characteristicGroup"
                              name="characteristicGroup.name"
                              value={placementState.characteristicGroup.id}
                              placeholder=" Characteristic Group"
                              onChange={handleSelectChange}
                            >
                              {props.placementID ? (
                                <option
                                  value={placementState.characteristicGroup.id}
                                  // selected
                                >
                                  {placementState.characteristicGroup.name}
                                </option>
                              ) : (
                                <option value="">
                                  Select a Characteristic Group
                                </option>
                              )}
                              {characteristicGroups &&
                                characteristicGroups.map(
                                  (characteristicGroup: any, index: any) => (
                                    <option
                                      value={characteristicGroup.id}
                                      key={index}
                                    >
                                      {characteristicGroup.name}
                                    </option>
                                  )
                                )}
                            </Field>
                            {
                              // (touched.characteristicGroup as any)?.name &&
                              (errors.characteristicGroup as any)?.name ? (
                                <div
                                  className="placement-error-message"
                                  role="alert"
                                >
                                  {(errors.characteristicGroup as any)?.name}
                                </div>
                              ) : null
                            }
                          </div>
                          <div className="placement-article-number-icon"></div>
                        </div>

                        {/* Damage Types input */}
                        <h2 className="placement-popup-input-label">
                          Damage Types
                        </h2>
                        <div className="placement-popup-input">
                          <div className="placement-multi-characteristic-select">
                            <Select
                              name="functions"
                              options={damageTypes && options}
                              onChange={handleDamageTypesSelect}
                              closeMenuOnSelect={false}
                              value={
                                assignedDamagedTypes
                                  ? assignedDamagedTypes
                                  : null
                              }
                              isMulti
                              placeholder="Damage Types"
                            />
                          </div>
                          <div className="placement-characteristics-icon"></div>
                        </div>
                      </div>
                      {/* </div> */}

                      {/* Submit Button */}
                      <div className="placement-popup-submit-button-container">
                        {!props.placementID ? (
                          <Button
                            size="small"
                            text="+ Create"
                            type="submit"
                            onClick={handleSubmit}
                          ></Button>
                        ) : (
                          <Button
                            icon="edit-icon"
                            size="small"
                            text="Edit"
                            type="submit"
                            onClick={handleSubmit}
                          ></Button>
                        )}
                      </div>
                    </Form>
                  </>
                );
              }}
            </Formik>
          </>
        </div>
      </div>
    </>
  );
}

export default PlacementPopup;
