//services
import TaskService from "services/taskService";

//actions
import {
  getAllTaskSequencesSuccess,
} from "store/actions/taskSequenceAction";
import { setMessage } from "store/actions/messageAction";
import { setLoading, removeLoading } from "store/actions/loadingAction";

export const getAllTaskSequencesRequest = (background?: string) => (dispatch: any) => {
  try {
    if (background !== "background") {
      dispatch(setLoading());
    }
    TaskService.getAllTaskSequences().then(
      (response: any) => {
        if (response.data !== undefined) {
          dispatch(getAllTaskSequencesSuccess(response.data));
          dispatch(removeLoading());
        }
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        // show the error to the user
        dispatch(setMessage(message, "error"));
        dispatch(removeLoading());
      }
    );
  } catch (e) {
    console.log("error", e);
    dispatch(removeLoading());
    dispatch(setMessage(e, "error"));
  }
};

export const createTaskSequenceRequest =
  (taskSequence: any) => (dispatch: any) => {
    try {
      dispatch(setLoading());
      TaskService.createSequence(taskSequence).then(
        (response: any) => {
          dispatch(removeLoading());
          dispatch(getAllTaskSequencesRequest());
          // dispatch(createTaskSequenceSuccess(response.data));
          dispatch(setMessage("Task Sequence created successfully", "success"));
        },
        (error: any) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          // show the error to the user
          dispatch(setMessage(message, "error"));
          dispatch(removeLoading());
        }
      );
    } catch (e) {
      console.log("error", e);
      dispatch(removeLoading());
      dispatch(setMessage(e, "error"));
    }
  };

export const editTaskSequenceRequest = (taskSequence: any) => (dispatch: any) => {
  try {
    dispatch(setLoading());
    TaskService.updateSequence(taskSequence).then(
      (response: any) => {
        dispatch(removeLoading());
        dispatch(getAllTaskSequencesRequest());
        dispatch(setMessage("Task Sequence updated successfully", "success"));
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        // show the error to the user
        dispatch(setMessage(message, "error"));
        dispatch(removeLoading());
      }
    );
  } catch (e) {
    console.log("error", e);
    dispatch(removeLoading());
    dispatch(setMessage(e, "error"));
  }
};

export const deleteTaskSequenceRequest =
  (taskSequence: any) => (dispatch: any) => {
    try {
      dispatch(setLoading());
      TaskService.deleteSequence(taskSequence).then(
        (response: any) => {
          // dispatch(deleteTaskSequenceSuccess(response.data));
          dispatch(getAllTaskSequencesRequest());
          dispatch(removeLoading());
          dispatch(setMessage("Task Sequence deleted successfully", "success"));
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          // show the error to the user
          dispatch(setMessage(message, "error"));
          dispatch(removeLoading());
        }
      );
    } catch (e) {
      console.log("error", e);
      dispatch(removeLoading());
      dispatch(setMessage(e, "error"));
    }
  };
