import "./DamageTypePopup.css";

import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";

//thunks
import {
  createDamageTypeRequest,
  editDamageTypeRequest,
} from "store/thunks/damageTypeThunk";

//selectors
import {
  getAllDamageTypesSelector,
  getAllDamageTypesImagesSelector,
} from "store/selectors/damageTypeSelector";

//validation
import { DamageTypeSchema } from "validations/adminValidations";

//images
import close from "assets/icons/close-button.png";
import image from "assets/images/defaultPicture.png";
import damageIcon from "assets/icons/menu/damage-icon.png";

//components
import Input from "components/Input/Input";
import Button from "components/Button/Button";

type DamageTypePopupProps = {
  damageTypeId?: any;
  hidePopUp?: any;
};

function DamageTypePopup(props: DamageTypePopupProps) {
  const dispatch = useDispatch<any>();

  //redux state
  let damageTypes = useSelector((state: any) =>
    getAllDamageTypesSelector(state)
  );
  let damageTypesImages = useSelector((state: any) =>
    getAllDamageTypesImagesSelector(state)
  );

  //local States
  //state for saving preview image when selected:
  const [baseImage, setBaseImage] = useState<any>(image);

  const [damageTypeState, setDamageTypeState] = useState({
    name: "",
    internRepair: false,
    image: image,
  });

  const [imageIsTooLarge, setImageIsTooLarge] = useState(false);

  useEffect(() => {
    props.damageTypeId && getDamageType(props.damageTypeId);
    // because we are in a popup we should stop scrolling so we add this classes
    let popupContainer = document.getElementById(
      "damage-type-popup-main-container"
    ) as any;
    popupContainer && popupContainer.classList.remove("hide");
    document.body.classList.add("damage-type-popup-overflow-hidden");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.damageTypeId]);

  //In edit case get the damageType in fill it in the state
  const getDamageType = (damageTypeId: any) => {
    damageTypes = damageTypes.filter(
      (damageType: any) => damageType.id === damageTypeId
    );
    setDamageTypeState({
      name: damageTypes[0]?.name,
      internRepair: damageTypes[0]?.internRepair,
      image: damageTypes[0]?.image,
    });
    damageTypesImages = damageTypesImages.filter(
      (image: any) => image.imageID === damageTypes[0].imageID
    );

    damageTypesImages[0]?.image &&
      setBaseImage("data:image/jpeg;base64," + damageTypesImages[0]?.image);
  };
  // show image preview when selected:
  const handleImageChange = async (event: any) => {
    const file = event.target.files[0];
    if (file.size > 500000) {
      setImageIsTooLarge(true);
      setBaseImage(image);
    } else {
      const base64 = await convertBase64(file);
      setBaseImage(base64);
      setDamageTypeState({
        ...damageTypeState,
        image: file,
      });
      setImageIsTooLarge(false);
    }
  };

  // function to convert selected image to base64 to display it as preview:
  const convertBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  //Handle when the name is changed
  const handleNameInputChange = (event: any) => {
    setDamageTypeState({ ...damageTypeState, name: event.target.value });
  };

  //handle when the radio buttons is changed
  const handleInternRepairChange = (event: any) => {
    setDamageTypeState({
      ...damageTypeState,
      internRepair: event.target.name === "internalRepair" ? true : false,
    });
  };

  const onSubmit = (
    fields: any,
    { setStatus, setSubmitting }: { setStatus: any; setSubmitting: any }
  ) => {
    const { name, internRepair, image } = damageTypeState;
    let data = new FormData();
    data.append("name", name);
    data.append("internRepair", internRepair.toString());
    if (!imageIsTooLarge) {
      data.append("image", image);
      if (!props.damageTypeId) {
        dispatch(createDamageTypeRequest(data));
        props.hidePopUp();
      } else {
        dispatch(editDamageTypeRequest(props.damageTypeId, data));
        props.hidePopUp();
      }
    }
  };

  const handleSubmit = () => {
    document.body.classList.remove("damage-type-popup-overflow-hidden");
  };
  return (
    <>
      <div
        className="damage-type-popup-main-container"
        id="damage-type-popup-main-container"
      >
        <div
          className="damage-type-popup-overlay"
          onClick={props.hidePopUp}
        ></div>
        <div className="damage-type-container">
          {/* Header: title and close button */}
          <div className="damage-type-top-section">
            {props.damageTypeId ? (
              <h1>Edit Damage Type</h1>
            ) : (
              <h1> Create New Damage Type</h1>
            )}
            <img
              className="damage-type-popup-close"
              src={close}
              onClick={props.hidePopUp}
              alt="close Popup"
              title="close Popup"
            />
          </div>

          <Formik
            initialValues={damageTypeState}
            validationSchema={DamageTypeSchema}
            onSubmit={onSubmit}
            enableReinitialize
          >
            {({ values, errors, touched, isSubmitting, setFieldValue }) => {
              return (
                <>
                  <Form>
                    <div className="damage-type-popup-form-container">
                      {/* Section 1: Damage type name and intern repair radio buttons section */}
                      <div className="damage-type-fields-section">
                        <div className="damage-type-popup-input">
                          <div className="damage-type-popup-input">
                            <Field
                              className="my-input"
                              type="text"
                              id="name"
                              name="name"
                              value={damageTypeState.name}
                              placeholder="Name"
                              onChange={handleNameInputChange}
                            />
                            {touched.name && errors.name ? (
                              <div
                                className="damage-type-popup-error-message"
                                role="alert"
                              >
                                {errors.name}
                              </div>
                            ) : null}
                          </div>
                          <div className="damage-type-icon">
                            <img src={damageIcon} alt="Damage Icon" />
                          </div>
                        </div>

                        <div className="damage-type-popup-repair-radio-buttons-container">
                          <div className="damage-type-popup-repair-radio-button">
                            <Field
                              className="my-checkbox"
                              type="radio"
                              id="externalRepair"
                              name="externalRepair"
                              value={!damageTypeState.internRepair}
                              checked={!damageTypeState.internRepair}
                              onChange={handleInternRepairChange}
                            />
                            <label
                              htmlFor="externalRepair"
                              className="my-checkbox-label"
                            >
                              External Repair
                            </label>
                          </div>
                          <div className="damage-type-popup-repair-radio-button">
                            <Field
                              className="my-checkbox"
                              type="radio"
                              id="internalRepair"
                              name="internalRepair"
                              value={damageTypeState.internRepair}
                              checked={damageTypeState.internRepair}
                              onChange={handleInternRepairChange}
                            />
                            <label
                              htmlFor="internalRepair"
                              className="my-checkbox-label"
                            >
                              internal Repair
                            </label>
                          </div>
                          {touched.internRepair && errors.internRepair ? (
                            <div
                              className="damage-type-popup-error-message"
                              role="alert"
                            >
                              {errors.internRepair}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      {/* Section 2: Image Section */}
                      <div className="damage-type-popup-image-section">
                        <img
                          className="damage-type-popup-image"
                          src={baseImage ? baseImage : image}
                          alt="Bild hinzufügen"
                          title="Bild hinzufügen"
                        />
                        <Input
                          type="file"
                          name="image"
                          accept="image/*"
                          placeholder="Durchsuchen.."
                          onChange={handleImageChange}
                        />
                        {imageIsTooLarge ? (
                          <div
                            className="damage-type-popup-error-message-image"
                            role="alert"
                          >
                            Image is Too Large
                          </div>
                        ) : null}
                      </div>
                    </div>

                    {/* Submit Button: Create or Edit */}
                    <div className="damage-popup-submit-button-container">
                      {!props.damageTypeId ? (
                        <Button
                          size="small"
                          text="Create"
                          icon="add-icon"
                          type="submit"
                          onClick={handleSubmit}
                        ></Button>
                      ) : (
                        <Button
                          icon="edit-icon"
                          size="small"
                          text="Edit"
                          type="submit"
                          onClick={handleSubmit}
                        ></Button>
                      )}
                    </div>
                  </Form>
                </>
              );
            }}
          </Formik>
        </div>
      </div>
    </>
  );
}

export default DamageTypePopup;
