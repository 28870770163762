import "./PrintPage.css";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

//thunks
import { getAllPrintGroupsRequest } from "store/thunks/printGroupThunk";

//selectors
import { getAllPrintGroups } from "store/selectors/printGroupSelector";

//Components
import Button from "components/Button/Button";
import Loader from "components/Loader/Loader";
import MessageNotification from "components/MessageNotification/MessageNotification";

function PrintPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //redux state
  const printGroups = useSelector((state: any) => getAllPrintGroups(state));
  const { loading } = useSelector((state: any) => state.LoadingReducer);
  const { messageText, messageType } = useSelector(
    (state: any) => state.MessageReducer
  );

  // //state
  const [selectedPrintGroups, setSelectedPrintGroups] = useState([]);

  // go back to previous page
  const goBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    // change the browser tab title
    document.title = "WA - Print Page";
    // get all workplacePendingActions thunk request
    printGroups.length < 1 && dispatch(getAllPrintGroupsRequest());
  }, [dispatch, printGroups.length]);

  // handle check box function
  const handleCheckboxClicked = (printGroup: any) => {
    if (selectedPrintGroups.length < 1) {
      let printGroups: any = [];
      printGroups.push(printGroup);
      setSelectedPrintGroups(printGroups);
    } else {
      let printGroups: any = [...selectedPrintGroups];
      selectedPrintGroups.map((print: any) => {
        if (print.id === printGroup.id) {
          printGroups = printGroups.filter(
            (print: any) => print.id !== printGroup.id
          );
          return setSelectedPrintGroups(printGroups);
        } else {
          printGroups.push(printGroup);
          return setSelectedPrintGroups(printGroups);
        }
      });
    }
  };

  return (
    <>
      {messageText ? (
        <MessageNotification
          messageText={messageText}
          messageType={messageType}
        />
      ) : null}

      {loading ? (
        <Loader />
      ) : (
        <>
          {/* Main container */}
          <div className="print-page-container">
            <div className="print-page-top-section">
              {/* Back button */}
              <div className="print-page-back-btn">
                <Button
                  size="small"
                  icon="back-icon"
                  text="Back"
                  onClick={goBack}
                />
              </div>

              {/* page title */}
              <div className="print-page-title">Print Receipt</div>
            </div>

            {/* Print section */}
            <div className="print-page-print-section">
              <div className="print-page-print-section-title">
                Please select data to be printed
              </div>

              {/* Loop over the print groups */}
              {printGroups.map((printGroup: any, index: any) => (
                <div className="print-page-data" key={index}>
                  <input
                    type="checkbox"
                    className="print-page-checkbox"
                    id={printGroup.id}
                    onClick={() => handleCheckboxClicked(printGroup)}
                  />
                  <label
                    htmlFor={printGroup.id}
                    className="print-page-checkbox-label"
                  >
                    {printGroup.name}
                  </label>
                </div>
              ))}
            </div>

            {/* Next Button */}
            <div className="print-page-button">
              {selectedPrintGroups.length < 1 ? null : (
                <Button
                  size="medium"
                  text="NEXT"
                  icon="next-icon"
                  onClick={() =>
                    navigate("/print-page-2", { state: selectedPrintGroups })
                  }
                />
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default PrintPage;
