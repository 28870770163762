//Services
import LocationService from "services/locationService";

// actions
import { setMessage } from "store/actions/messageAction";
import { removeLoading, setLoading } from "store/actions/loadingAction";
import {
  getAllLocationsSuccess,
  createLocationSuccess,
  editLocationSuccess,
  deleteLocationSuccess,
} from "store/actions/locationAction";

export const getAllLocationsRequest = () => (dispatch: any) => {
  try {
    dispatch(setLoading());
    LocationService.getAllLocations().then(
      (response: any) => {
        if (response.data !== undefined) {
          dispatch(removeLoading());
          dispatch(getAllLocationsSuccess(response.data));
        }
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        // show the error to the user
        dispatch(setMessage(message, "error"));
        dispatch(removeLoading());
      }
    );
  } catch (e) {
    console.log("error", e);
    dispatch(removeLoading());
    dispatch(setMessage(e, "error"));
  }
};

export const createLocationRequest = (location: any) => (dispatch: any) => {
  try {
    dispatch(setLoading());
    LocationService.createLocation(location).then(
      (response: any) => {
        if (response.data !== null) {
          dispatch(removeLoading());
          dispatch(createLocationSuccess(response.data));
          dispatch(setMessage("Location created successfully", "success"));
        }
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        // show the error to the user
        dispatch(setMessage(message, "error"));
        dispatch(removeLoading());
      }
    );
  } catch (e) {
    console.log("error", e);
    dispatch(removeLoading());
    dispatch(setMessage(e, "error"));
  }
};

export const editLocationRequest =
  (locationId: any, location: any) => (dispatch: any) => {
    try {
      dispatch(setLoading());
      LocationService.updateLocation(locationId, location).then(
        (response: any) => {
          if (response.data !== undefined) {
            dispatch(removeLoading());
            dispatch(editLocationSuccess(response.data));
            dispatch(setMessage("Location updated successfully", "success"));
          }
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          // show the error to the user
          dispatch(setMessage(message, "error"));
          dispatch(removeLoading());
        }
      );
    } catch (e) {
      console.log("error", e);
      dispatch(removeLoading());
      dispatch(setMessage(e, "error"));
    }
  };

export const deleteLocationRequest = (locationID: any) => (dispatch: any) => {
  try {
    dispatch(setLoading());
    LocationService.deleteLocation(locationID).then(
      (response: any) => {
        dispatch(removeLoading());
        dispatch(deleteLocationSuccess(locationID));
        dispatch(setMessage("Location deletes successfully", "success"));
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        // show the error to the user
        dispatch(setMessage(message, "error"));
        dispatch(removeLoading());
      }
    );
  } catch (e) {
    console.log("error", e);
    dispatch(removeLoading());
    dispatch(setMessage(e, "error"));
  }
};
