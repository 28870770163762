//services
import CommissionedCostumerService from "services/commissionedCostumerService";

//actions
import { setMessage } from "store/actions/messageAction";
import { setLoading, removeLoading } from "store/actions/loadingAction";
import {
  getAllCommissionedCostumerSuccess,
  createCommissionedCostumerSuccess,
  editCommissionedCostumerSuccess,
  deleteCommissionedCostumerSuccess,
} from "store/actions/commissionedCostumerAction";

export const getAllCommissionedCostumerRequest =
  (background?: string) => (dispatch: any) => {
    try {
      //set loading
      if (background !== "background") {
        dispatch(setLoading());
      }

      //Api call to get all commissioned costumers
      CommissionedCostumerService.getAllCommissionedCostumer().then(
        (response: any) => {
          if (response.data !== undefined) {
            dispatch(getAllCommissionedCostumerSuccess(response.data));
            dispatch(removeLoading());
          }
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          // show the error to the user
          dispatch(setMessage(message, "error"));
          dispatch(removeLoading());
        }
      );
    } catch (e) {
      console.log("error", e);
      dispatch(removeLoading());
      dispatch(setMessage(e, "error"));
    }
  };

export const createCommissionedCostumerRequest =
  (commissionedCostumer: any) => (dispatch: any) => {
    try {
      dispatch(setLoading());
      CommissionedCostumerService.createCommissionedCostumer(
        commissionedCostumer
      ).then(
        (response: any) => {
          if (response.data !== undefined) {
            dispatch(removeLoading());
            dispatch(createCommissionedCostumerSuccess(response.data));
            dispatch(
              setMessage(
                "Commissioned Costumer created successfully",
                "success"
              )
            );
          }
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          // show the error to the user
          dispatch(setMessage(message, "error"));
          dispatch(removeLoading());
        }
      );
    } catch (e) {
      console.log("error", e);
      dispatch(removeLoading());
      dispatch(setMessage(e, "error"));
    }
  };

export const editCommissionedCostumerRequest =
  (commissionedCostumerID: any, commissionedCostumer: any) =>
  (dispatch: any) => {
    try {
      dispatch(setLoading());
      CommissionedCostumerService.updateCommissionedCostumer(
        commissionedCostumerID,
        commissionedCostumer
      ).then(
        (response: any) => {
          if (response.data !== undefined) {
            dispatch(removeLoading());
            dispatch(editCommissionedCostumerSuccess(response.data));
            dispatch(
              setMessage(
                "Commissioned Costumer updated successfully",
                "success"
              )
            );
          }
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          // show the error to the user
          dispatch(setMessage(message, "error"));
          dispatch(removeLoading());
        }
      );
    } catch (e) {
      console.log("error", e);
      dispatch(removeLoading());
      dispatch(setMessage(e, "error"));
    }
  };

export const deleteCommissionedCostumerRequest =
  (commissionedCostumerID: any) => (dispatch: any) => {
    try {
      dispatch(setLoading());
      CommissionedCostumerService.deleteCommissionedCostumer(
        commissionedCostumerID
      ).then(
        (response: any) => {
          dispatch(deleteCommissionedCostumerSuccess(commissionedCostumerID));
          dispatch(removeLoading());
          dispatch(
            setMessage("Commissioned Costumer deleted successfully", "success")
          );
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          // show the error to the user
          dispatch(setMessage(message, "error"));
          dispatch(removeLoading());
        }
      );
    } catch (e) {
      console.log("error", e);
      dispatch(removeLoading());
      dispatch(setMessage(e, "error"));
    }
  };
