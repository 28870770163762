import "./Header.css";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";

// actions
import { logoutAction } from "../../store/actions/authAction";

//components
import Button from "components/Button/Button";

//icons
import hello from "../../assets/icons/hello-icon.png";

function Header() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // redux state
  const isLoggedIn = useSelector((state: any) => state.AuthReducer.isLoggedIn);
  const user = useSelector((state: any) => state.AuthReducer.user);

  //get current path name
  const currentUrl = useLocation();
  const currentPathName = currentUrl.pathname;

  return (
    <>
      <nav role="navigation" aria-label="Primary Menu">
        <div className="header-container">
          <div className="header-wrapper">
            <div
              className="header-logo-container"
              onClick={() => {
                navigate("/");
              }}
            >
              <span className="site-title">
                <h1> Work Assist</h1>
              </span>
            </div>

            {!isLoggedIn ? (
              <div className="header-buttons-container">
                <Button
                  icon="overview-icon"
                  text="Erfassungsubersicht"
                  size="large"
                  selected={
                    currentPathName === "/acquisition-overview"
                      ? "selected"
                      : ""
                  }
                  onClick={() => {
                    navigate("/acquisition-overview");
                  }}
                />
                <Button
                  icon="print-icon"
                  text="Beleg Drucken"
                  size="medium"
                  onClick={() => {
                    navigate("/print-page");
                  }}
                />

                {/* <Link to={{ pathname: "/login" }}> */}
                {/* Check if we are at the capture article page change the login button to repair button */}
                {currentPathName === "/login" ||
                currentPathName === "/" ||
                currentPathName === "*" ? (
                  <Button
                    icon="login-icon"
                    text="Login"
                    size="small"
                    onClick={() => {
                      navigate("/login");
                    }}
                    selected={
                      currentPathName === "/login" && !isLoggedIn
                        ? "selected"
                        : ""
                    }
                  />
                ) : (
                  <Button
                    icon="repair-icon"
                    text="Repair"
                    size="small"
                    selected={
                      currentPathName === "/repair-overview" && !isLoggedIn
                        ? "selected"
                        : ""
                    }
                    onClick={() => {
                      navigate("/repair-overview");
                    }}
                  />
                )}
                {/* </Link> */}
              </div>
            ) : (
              <div className="header-buttons-container">
                <div className="welcome-container">
                  <img className="welcome-icon" src={hello} alt="Welcome" />

                  <span className="welcome-title">
                    Welcome {user.firstName} {user.lastName}
                  </span>
                </div>
                <Link to={{ pathname: "/acquisition-overview" }}>
                  <Button
                    icon="overview-icon"
                    text="Erfassungsubersicht"
                    size="large"
                  />
                </Link>
                <Button icon="print-icon" text="Beleg Drucken" size="medium" />

                <Link to={{ pathname: "/" }}>
                  <Button
                    icon="logout-icon"
                    text="Logout"
                    size="small"
                    selected={
                      currentPathName === "/login" && !isLoggedIn
                        ? "selected"
                        : ""
                    }
                    onClick={() => {
                      dispatch(logoutAction());
                    }}
                  />
                </Link>
              </div>
            )}
          </div>
        </div>
      </nav>
    </>
  );
}

export default Header;
