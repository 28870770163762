// services
// import ImageService from "services/imageService";
// import CategoryService from "services/categoryService";
import TaskService from "services/taskService";

// actions
import { setMessage } from "store/actions/messageAction";
import { removeLoading, setLoading } from "store/actions/loadingAction";
import {
  getTasksBySequenceChoosersSuccess,
  getAllTasksSuccess,
  createNewTaskSuccess,
  updateTaskSuccess,
  deleteTaskSuccess,
  GET_ALL_DISMANTLE_TASKS_SUCCESS,
  getAllDismantleTasksSuccess
} from "store/actions/taskAction";
import ImageService from "services/imageService";

export const getTasksBySequenceChoosersRequest =
  (sequenceChooserIDs: any) => (dispatch: any) => {
    try {
      // set loading indicator
      dispatch(setLoading());
      // API call to get all categories
      TaskService.getTasksBySequenceChoosersService(sequenceChooserIDs).then(
        (response: any) => {
          if (response.data !== undefined) {
            let imageIDs = [];
            for (let item of response.data) {
              for (let task of item.tasksWithSequences) {
                 if (task.task.imageID !== undefined) {
                   imageIDs.push(task.task.imageID);
                 }
              }
            }
            // API call to get the images of the tasks
            ImageService.getAllImages(imageIDs)
              .then((res) => {
                dispatch(removeLoading());
                // dispatch success action
                dispatch(
                  getTasksBySequenceChoosersSuccess(response.data, res.data)
                );
              })
              .catch((e: any) => {
                console.log(e);
              });
          }
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          // show the error to the user
          dispatch(setMessage(message, "error"));
          dispatch(removeLoading());
        }
      );
    } catch (e) {
      console.log("error", e);
      dispatch(removeLoading());
      dispatch(setMessage(e, "error"));
    }
  };

// Get all tasks
export const getAllTasksRequest = (background?: string) => (dispatch: any) => {
  try {
    if (background !== "background") {
      dispatch(setLoading());
    }

    // API call
    TaskService.getAllTasks().then(
      (response: any) => {
        // get the tasks imageID's
        if (response.data !== undefined) {
          let imageIDs = [];
          for (let item of response.data) {
            if (item.imageID !== undefined) {
              imageIDs.push(item.imageID);
            }
          }
          // API call to get the images of the tasks
          ImageService.getAllImages(imageIDs)
            .then((res) => {
              dispatch(removeLoading());
              // dispatch success action
              dispatch(getAllTasksSuccess(response.data, res.data));
            })
            .catch((e: any) => {
              console.log(e);
            });
        }
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        // show the error to the user
        dispatch(setMessage(message, "error"));
        dispatch(removeLoading());
      }
    );
  } catch (e) {
    console.log("error", e);
    dispatch(removeLoading());
    dispatch(setMessage(e, "error"));
  }
};

// create task\
export const createTaskRequest = (task: any) => (dispatch: any) => {
  try {
    dispatch(setLoading());

    // API call
    TaskService.createTask(task).then(
      (response: any) => {
        // get the tasks imageID's
        if (response.data.imageID !== undefined) {
          // API call to get the images of the tasks
          ImageService.getAllImages([response.data.imageID])
            .then((res) => {
              dispatch(removeLoading());
              // dispatch success action
              dispatch(createNewTaskSuccess(response.data, res.data[0]));
              dispatch(setMessage("Task created successfully", "success"));
            })
            .catch((e: any) => {
              console.log(e);
            });
        } else {
          // here we dispatch teh create category success action and we send the created category
          dispatch(createNewTaskSuccess(response.data));
          dispatch(removeLoading());
          dispatch(setMessage("Task created successfully", "success"));
        }
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        // show the error to the user
        dispatch(setMessage(message, "error"));
        dispatch(removeLoading());
      }
    );
  } catch (e) {
    console.log("error", e);
    dispatch(removeLoading());
    dispatch(setMessage(e, "error"));
  }
};

// edit task
export const editTaskRequest = (taskID: any, task: any) => (dispatch: any) => {
  try {
    dispatch(setLoading());

    // API call
    TaskService.updateTask(taskID, task).then(
      (response: any) => {
        // get the tasks imageID's
        if (response.data.imageID !== undefined) {
          // API call to get the images of the tasks
          ImageService.getAllImages([response.data.imageID])
            .then((res) => {
              dispatch(removeLoading());
              // dispatch success action
              dispatch(updateTaskSuccess(response.data, res.data[0]));
              dispatch(setMessage("Task Updated successfully", "success"));
            })
            .catch((e: any) => {
              console.log(e);
            });
        } else {
          // here we dispatch teh create category success action and we send the created category
          dispatch(updateTaskSuccess(response.data));
          dispatch(removeLoading());
          dispatch(setMessage("Task Updated successfully", "success"));
        }
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        // show the error to the user
        dispatch(setMessage(message, "error"));
        dispatch(removeLoading());
      }
    );
  } catch (e) {
    console.log("error", e);
    dispatch(removeLoading());
    dispatch(setMessage(e, "error"));
  }
};

// delete task
export const deleteTaskRequest = (taskID: any) => (dispatch: any) => {
  try {
    dispatch(setLoading());

    // API call
    TaskService.deleteTask(taskID).then(
      (response: any) => {
        // here we dispatch teh create category success action and we send the created category
        dispatch(deleteTaskSuccess(taskID));
        dispatch(removeLoading());
        dispatch(setMessage("Task Deleted successfully", "success"));
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        // show the error to the user
        dispatch(setMessage(message, "error"));
        dispatch(removeLoading());
      }
    );
  } catch (e) {
    console.log("error", e);
    dispatch(removeLoading());
    dispatch(setMessage(e, "error"));
  }
};

// used for the capture article when the characteristic group have dismantle task on the capture article page
export const getAllDismantleTasksRequest =
  (sequenceChooserIDs: any) => (dispatch: any) => {
    try {
      // set loading indicator
      dispatch(setLoading());
      // API call to get all categories
      TaskService.getTasksBySequenceChoosersService(sequenceChooserIDs).then(
        (response: any) => {
          if (response.data !== undefined) {
            let imageIDs = [];
            for (let item of response.data) {
              for (let task of item.tasksWithSequences) {
                if (task.task.imageID !== undefined) {
                  imageIDs.push(task.task.imageID);
                }
              }
            }
            // API call to get the images of the tasks
            ImageService.getAllImages(imageIDs)
              .then((res) => {
                dispatch(removeLoading());
                // dispatch success action
                dispatch(
                  getAllDismantleTasksSuccess(response.data, res.data)
                );
              })
              .catch((e: any) => {
                console.log(e);
              });
          }
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          // show the error to the user
          dispatch(setMessage(message, "error"));
          dispatch(removeLoading());
        }
      );
    } catch (e) {
      console.log("error", e);
      dispatch(removeLoading());
      dispatch(setMessage(e, "error"));
    }
  };
