import "./PrintPage2.css";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

//thunks
import { getAllCommissionedCostumerRequest } from "store/thunks/commissionedCostumerThunk";

//selectors
import { createCollectionListRequest } from "store/thunks/collectionListThunk";
import { getAllCommissionedCostumer } from "store/selectors/commissionedCostumerSelector";

//Components
import Button from "components/Button/Button";
import Loader from "components/Loader/Loader";
import MessageNotification from "components/MessageNotification/MessageNotification";

function PrintPage2() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  //redux state
  const commissionedCostumers = useSelector((state: any) =>
    getAllCommissionedCostumer(state)
  );
  const { loading } = useSelector((state: any) => state.LoadingReducer);
  const { messageText, messageType } = useSelector(
    (state: any) => state.MessageReducer
  );

  //get the selected print groups by prop from the first print page
  const selectedPrintGroups: any = location.state;

  //state
  const [commissionedCostumerState, setCommissionedCostumerState] =
    useState<any>(0);
  const [printNumber, setPrintNumber] = useState(0);
  // go back to previous page
  const goBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    // change the browser tab title
    document.title = "WA - Print Page";
    // get all commissioned costumers
    commissionedCostumers.length < 1 &&
      dispatch(getAllCommissionedCostumerRequest());
  }, [dispatch, commissionedCostumers.length]);

  const handleRadioButtonClicked = (event: any) => {
    setCommissionedCostumerState(event.target.value);
  };

  const onSubmit = () => {
    const data = {
      printGroupID: selectedPrintGroups[printNumber].id,
      namePendingCollectionList: moment().format("l"),
      pendingCollectionListCommissionedCustomerID: parseInt(
        commissionedCostumerState
      ),
    };
    // API call POST to collectionList
    dispatch(createCollectionListRequest(data));
    if (printNumber !== selectedPrintGroups.length - 1) {
      setPrintNumber(printNumber + 1);
    } else {
      navigate("/");
    }
  };

  return (
    <>
      {messageText ? (
        <MessageNotification
          messageText={messageText}
          messageType={messageType}
        />
      ) : null}
      {loading ? (
        <Loader />
      ) : (
        <>
          {/* Main Container */}
          <div className="print-page-2-container">
            <div className="print-page-2-top-section">
              <div className="print-page-2-back-btn">
                <Button
                  size="small"
                  icon="back-icon"
                  text="Back"
                  onClick={goBack}
                />
              </div>
              <div className="print-page-2-title">Print Receipt</div>
            </div>
            {/* Print Section */}
            <div className="print-page-2-print-section">
              <div className="print-page-2-print-section-title">
                Goods allocation:
                {selectedPrintGroups[printNumber].name}
              </div>
              <div className="print-page-2-print-section-title">
                Server Provider to be commissioned
              </div>
              {commissionedCostumers.map(
                (commissionedCostumer: any, index: any) => (
                  <div className="print-page-2-data" key={index}>
                    <input
                      className="print-page-2-checkbox"
                      type="radio"
                      id={commissionedCostumer.id}
                      value={commissionedCostumer.id}
                      name="pendingCollectionListCommissionedCustomerID"
                      onClick={handleRadioButtonClicked}
                    />

                    {/* <input
                                  type="checkbox"
                                  className="print-page-2-checkbox"
                                  id={commissionedCostumer.id}
                                /> */}
                    <label
                      htmlFor={commissionedCostumer.id}
                      className="print-page-2-checkbox-label"
                    >
                      {commissionedCostumer.name}
                    </label>
                  </div>
                )
              )}
            </div>
            <div className="print-page-2-button">
              {commissionedCostumerState !== 0 ? (
                <Button
                  size="medium"
                  text="Print"
                  icon="print-icon"
                  onClick={onSubmit}
                />
              ) : null}
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default PrintPage2;
