export const GET_ALL_COMMISSIONED_COSTUMER_SUCCESS =
    "GET_ALL_COMMISSIONED_COSTUMER_SUCCESS";
export const getAllCommissionedCostumerSuccess = (commissionedCostumers: any) => ({
    type: GET_ALL_COMMISSIONED_COSTUMER_SUCCESS,
    payload: commissionedCostumers,
})

export const CREATE_COMMISSIONED_COSTUMER_SUCCESS =
    "CREATE_COMMISSIONED_COSTUMER_SUCCESS";
export const createCommissionedCostumerSuccess = (
  commissionedCostumer: any
) => ({
  type: CREATE_COMMISSIONED_COSTUMER_SUCCESS,
  payload: commissionedCostumer,
});

export const EDIT_COMMISSIONED_COSTUMER_SUCCESS =
  "EDIT_COMMISSIONED_COSTUMER_SUCCESS";
export const editCommissionedCostumerSuccess = (updatedCommissionedCostumer: any) => ({
  type: EDIT_COMMISSIONED_COSTUMER_SUCCESS,
  payload: updatedCommissionedCostumer,
});

export const DELETE_COMMISSIONED_COSTUMER_SUCCESS =
  "DELETE_COMMISSIONED_COSTUMER_SUCCESS";
export const deleteCommissionedCostumerSuccess = (commissionedCostumerID: any) => ({
  type: DELETE_COMMISSIONED_COSTUMER_SUCCESS,
  payload: commissionedCostumerID,
});