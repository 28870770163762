export const GET_ALL_TASK_SEQUENCES_SUCCESS = "GET_ALL_TASK_SEQUENCES_SUCCESS";
export const getAllTaskSequencesSuccess = (taskSequences: any) => ({
    type: GET_ALL_TASK_SEQUENCES_SUCCESS,
    payload: taskSequences,
})

export const CREATE_TASK_SEQUENCE_SUCCESS = "CREATE_TASK_SEQUENCE_SUCCESS";
export const createTaskSequenceSuccess = (newTaskSequence: any) => ({
    type: CREATE_TASK_SEQUENCE_SUCCESS,
    payload: newTaskSequence,
})

export const DELETE_TASK_SEQUENCE_SUCCESS = "DELETE_TASK_SEQUENCE_SUCCESS";
export const deleteTaskSequenceSuccess = (taskSequence: any) => ({
    type: DELETE_TASK_SEQUENCE_SUCCESS,
    payload: taskSequence
})