// actions
import {
  GET_TASKS_BY_SEQUENCE_CHOOSERS_SUCCESS,
  GET_ALL_TASKS_SUCCESS,
  CREATE_NEW_TASKS_SUCCESS,
  UPDATE_TASKS_SUCCESS,
  DELETE_TASK_SUCCESS,
  GET_ALL_DISMANTLE_TASKS_SUCCESS
} from "../actions/taskAction";

const initialState = {
  tasks: [],
  tasksImages: [],
  tasksBySequenceChoosers: [],
  tasksBySequenceChoosersImages: [],
  dismantleTasks: [],
  dismantleTasksImages: [],
};

const taskReducer = (state = initialState, action: any) => {
  const { type, payload } = action;
  let tasksArray = [...state.tasks] as any;
  let tasksImagesArray = [...state.tasksImages] as any;

  switch (type) {
    case GET_TASKS_BY_SEQUENCE_CHOOSERS_SUCCESS:
      return {
        ...state,
        tasksBySequenceChoosers: payload.tasksBySequenceChoosers,
        tasksBySequenceChoosersImages: payload.tasksBySequenceChoosersImages
      };

    case GET_ALL_TASKS_SUCCESS:
      return {
        ...state,
        tasks: payload.tasks,
        tasksImages: payload.tasksImages,
      };

    case CREATE_NEW_TASKS_SUCCESS:
      return {
        ...state,
        tasks: [...tasksArray, payload.createdTask],
        tasksImages: [
          ...tasksImagesArray,
          payload.createdTaskImage ? payload.createdTaskImage : null,
        ],
      };

    case UPDATE_TASKS_SUCCESS:
      tasksArray.map((task: any, index: any) => {
        if (task.id === payload.updatedTask?.id) {
          return (tasksArray[index] = payload.updatedTask);
        } else return tasksArray;
      });
      payload.updatedTaskImage !== undefined &&
        tasksImagesArray.push(payload.updatedTaskImage);
      return {
        ...state,
        tasks: tasksArray,
        tasksImages: tasksImagesArray,
      };

    case DELETE_TASK_SUCCESS:
      tasksArray = state.tasks.filter((task: any) => task.id !== payload);
      return {
        ...state,
        tasks: tasksArray,
      };
    case GET_ALL_DISMANTLE_TASKS_SUCCESS:
      return {
        ...state,
        dismantleTasks: payload.dismantleTasks,
        dismantleTasksImages: payload.dismantleTasksImages
      };
    default:
      return state;
  }
};
export default taskReducer;
