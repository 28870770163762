import { httpCommon } from "./http-common";
import authHeader from "./authHeader";

const getCharacteristicGroup = (CharacteristicGroupID: string) => {
  return httpCommon.get(`/CharacteristicsGroup/${CharacteristicGroupID}`, {
    headers: authHeader() as any,
  });
};

const getAllCharacteristicsGroup = () => {
  return httpCommon.get("/CharacteristicsGroup", {
    headers: authHeader() as any,
  });
};

const createCharacteristicGroup = (data: any) => {
  return httpCommon.post("/CharacteristicsGroup", data, {
    headers: authHeader() as any,
  });
};

const updateCharacteristicGroup = (
  CharacteristicGroupID: string,
  data: any
) => {
  return httpCommon.put(
    `/CharacteristicsGroup/${CharacteristicGroupID}`,
    data,
    {
      headers: authHeader() as any,
    }
  );
};

const deleteCharacteristicGroup = (CharacteristicGroupID: number) => {
  return httpCommon.delete(`/CharacteristicsGroup/${CharacteristicGroupID}`, {
    headers: authHeader() as any,
  });
};

const getBestMatchingCharacteristicGroup = (characteristicsIds: any) => {
  var characteristicIdsQuery = characteristicsIds.map(function (item: any) {
    return "characteristicIDs=" + item;
  })
  .join('&')
  return httpCommon.get(
    `/CharacteristicsGroup/GetBestMatchingCharacteristicsGroup?` + characteristicIdsQuery,
    { headers: authHeader() as any, params:{characteristicIdsQuery}, responseType:"json" }
  );
};

const CharacteristicsGroupService = {
  getCharacteristicGroup,
  getAllCharacteristicsGroup,
  createCharacteristicGroup,
  updateCharacteristicGroup,
  deleteCharacteristicGroup,
  getBestMatchingCharacteristicGroup,
};

export default CharacteristicsGroupService;
