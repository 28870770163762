import "./Login.css";
import { useEffect } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// thunks
import { loginRequest } from "store/thunks/authThunk";

// validations
import { LoginSchema } from "validations/loginValidations";

//components
import Input from "components/Input/Input";
import Button from "components/Button/Button";
import Loader from "components/Loader/Loader";
import MessageNotification from "components/MessageNotification/MessageNotification";

function AdminEditorLogin() {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();

  // redux state
  const { loading } = useSelector((state: any) => state.LoadingReducer);
  const { messageText, messageType } = useSelector(
    (state: any) => state.MessageReducer
  );

  // useFormik react hook: to handle the login form:
  const { handleSubmit, handleChange, values, touched, errors, handleBlur } =
    useFormik({
      initialValues: {
        username: "",
        password: "",
      },
      validationSchema: LoginSchema,
      onSubmit: ({ username, password }) => {
        dispatch(loginRequest({ username, password }, navigate));
      },
    });

  useEffect(() => {
    document.title = "Work Assist - Anmelden";
  }, []);

  // go back to previous page
  const goBack = () => {
    navigate(-1);
  };

  return (
    <>
      {messageText ? (
        <MessageNotification
          messageText={messageText}
          messageType={messageType}
        />
      ) : null}

      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="login-back-btn" onClick={goBack}>
            {/* <Button size="small" icon="back-icon" text="Back" /> */}
          </div>
          <div className="login-container">
            <div className="login-inner">
              <div className="login-title">
                <h1>Login</h1>
              </div>
              <div className="login-sub-title">
                <h2>Use local account to log in</h2>
              </div>
              <>
                <form onSubmit={handleSubmit} className="login-form-container">
                  <section>
                    <div className="username-input">
                      <Input
                        className="my-input"
                        name="username"
                        type="text"
                        value={values.username}
                        placeholder="Username"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        autocomplete="on"
                      />
                      {touched.username && errors.username ? (
                        <div className="login-error-message" role="alert">
                          {errors.username}
                        </div>
                      ) : null}
                      <div className="username-icon"></div>
                    </div>
                  </section>

                  <section>
                    <div className="password-input">
                      <Input
                        className="my-input"
                        type="password"
                        name="password"
                        value={values.password}
                        placeholder="* * * * * * * *"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        autocomplete="on"
                      />
                      {touched.password && errors.password ? (
                        <div className="login-error-message" role="alert">
                          {errors.password}
                        </div>
                      ) : null}
                      <div className="password-icon"></div>
                    </div>
                  </section>
                  <>
                    <Button
                      onClick={handleSubmit}
                      size="medium"
                      icon="login-icon"
                      text="Login"
                      type="submit"
                    />
                  </>
                </form>
              </>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default AdminEditorLogin;
