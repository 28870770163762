export const GET_ALL_LICENSES_SUCCESS = "GET_ALL_LICENSES_SUCCESS";
export const getAllLicensesSuccess = (licenses: any) => ({
    type: GET_ALL_LICENSES_SUCCESS,
    payload: licenses
})

export const CREATE_LICENSE_SUCCESS = "CREATE_LICENSE_SUCCESS";
export const createLicenseSuccess = (newLicense: any) => ({
    type: CREATE_LICENSE_SUCCESS,
    payload: newLicense
})

export const EDIT_LICENSE_SUCCESS = "EDIT_LICENSE_SUCCESS";
export const editLicenseSuccess = (updatedLicense: any) => ({
    type: EDIT_LICENSE_SUCCESS,
    payload: updatedLicense
})

export const DELETE_LICENSE_SUCCESS = "DELETE_LICENSE_SUCCESS";
export const deleteLicenseSuccess = (licenseID: any) => ({
    type: DELETE_LICENSE_SUCCESS,
    payload: licenseID
})