// services
import AuthService from "services/authService";

// actions
import { loginSuccess } from "../actions/authAction";
import { setMessage } from "store/actions/messageAction";
import { removeLoading, setLoading } from "store/actions/loadingAction";

export const loginRequest =
  (loginForm: any, navigate: any) => (dispatch: any) => {
    try {
      dispatch(setLoading());
      AuthService.Login(loginForm).then(
        (response: any) => {
          dispatch(loginSuccess(response));
          dispatch(removeLoading());
          //check if the logged is an admin got to categories or if he is a sensrec admin go to the sensrec admin landing page
          if (response.userRole === "Admin") {
            navigate("/categories");
          } else {
            navigate("/")
          }
          dispatch(
            setMessage("Sie haben sich erfolgreich angemeldet.", "success")
          );
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          if (error.message === "Request failed with status code 401") {
            dispatch(setMessage("Ungültige Email oder Passwort", "error"));
          } else dispatch(setMessage(message, "error"));
          dispatch(removeLoading());
        }
      );
    } catch (e) {
      console.log("error", e);
      dispatch(setMessage(e, "error"));
    }
  };
