//services
import DamageTypeService from "services/damageTypeService";
import ImageService from "services/imageService";

//actions
import {
  getAllDamageTypesSuccess,
  createDamageTypeSuccess,
  editDamageTypeSuccess,
  deleteDamageTypeSuccess,
} from "store/actions/damageTypeAction";
import { setMessage } from "store/actions/messageAction";
import { removeLoading, setLoading } from "store/actions/loadingAction";

export const getAllDamageTypesRequest = (background? : string) => (dispatch: any) => {
  try {
    //set Loading
    if (background !== "background") {
      dispatch(setLoading());
    }
    //API call to get All damage types
    DamageTypeService.getAllDamageTypes().then(
      (response: any) => {
        if (response.data !== undefined) {
          let imageIDs = [];
          for (let item of response.data) {
            if (item.imageID !== undefined) {
              imageIDs.push(item.imageID);
            }
          }
          //API to get ALL Images
          ImageService.getAllImages(imageIDs)
            .then((res) => {
              dispatch(getAllDamageTypesSuccess(response.data, res.data));
              dispatch(removeLoading());
            })
            .catch((e: any) => {
              console.log(e);
            });
        }
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        // show the error to the user
        dispatch(setMessage(message, "error"));
        dispatch(removeLoading());
      }
    );
  } catch (e) {
    console.log("error", e);
    dispatch(removeLoading());
    dispatch(setMessage(e, "error"));
  }
};

export const createDamageTypeRequest = (damageType: any) => (dispatch: any) => {
  try {
    //set Loading
    dispatch(setLoading());
    DamageTypeService.createDamageType(damageType).then(
      (response: any) => {
        if (response.data.imageID !== undefined) {
          ImageService.getAllImages([response.data.imageID])
            .then((res) => {
              dispatch(createDamageTypeSuccess(response.data, res.data[0]));
              dispatch(removeLoading());
              dispatch(
                setMessage("Damage type created successfully", "success")
              );
            })
            .catch((e: any) => {
              console.log(e);
            });
        } else {
          dispatch(createDamageTypeSuccess(response.data));
          dispatch(removeLoading());
          dispatch(setMessage("Damage type created successfully", "success"));
        }
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        // show the error to the user
        dispatch(setMessage(message, "error"));
        dispatch(removeLoading());
      }
    );
  } catch (e) {
    console.log("error", e);
    dispatch(removeLoading());
    dispatch(setMessage(e, "error"));
  }
};

export const editDamageTypeRequest =
  (damageTypeId: any, damageType: any) => (dispatch: any) => {
    try {
      dispatch(setLoading());
      DamageTypeService.updateDamageType(damageTypeId, damageType).then(
        (response: any) => {
          if (response.data.imageID !== undefined) {
            ImageService.getAllImages([response.data.imageID])
              .then((res: any) => {
                dispatch(editDamageTypeSuccess(response.data, res.data[0]));
                dispatch(removeLoading());
                dispatch(
                  setMessage("Damage type updated successfully", "success")
                );
              })
              .catch((e: any) => {
                console.log(e);
              });
          } else {
            dispatch(editDamageTypeSuccess(response.data));
            dispatch(removeLoading());
            dispatch(setMessage("Damage type updated successfully", "success"));
          }
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          // show the error to the user
          dispatch(setMessage(message, "error"));
          dispatch(removeLoading());
        }
      ).catch((e: any) => {
        console.log(e)
      });
    } catch (e) {
      console.log("error", e);
      dispatch(removeLoading());
      dispatch(setMessage(e, "error"));
    }
  };

export const deleteDamageTypeRequest =
  (damageTypeId: any) => (dispatch: any) => {
    try {
      dispatch(setLoading());
      DamageTypeService.deleteDamageType(damageTypeId).then(
        (response) => {
          dispatch(deleteDamageTypeSuccess(damageTypeId));
          dispatch(removeLoading());
          dispatch(setMessage("Damage type deleted successfully", "success"));
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          // show the error to the user
          dispatch(setMessage(message, "error"));
          dispatch(removeLoading());
        }
      );
    } catch (e) {
      console.log("error", e);
      dispatch(removeLoading());
      dispatch(setMessage(e, "error"));
    }
  };
