//Services
import UserService from "services/userService";

// actions
import { setMessage } from "store/actions/messageAction";
import { removeLoading, setLoading } from "store/actions/loadingAction";
import {
  getAllUsersSuccess,
  createUserSuccess,
  editUserSuccess,
  deleteUserSuccess,
} from "store/actions/userAction";

export const getAllUsersRequest = () => (dispatch: any) => {
  try {
    dispatch(setLoading());
    UserService.getAllUsers().then(
      (response: any) => {
        if (response.data !== undefined) {
          dispatch(removeLoading());
          dispatch(getAllUsersSuccess(response.data));
        }
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        // show the error to the user
        dispatch(setMessage(message, "error"));
        dispatch(removeLoading());
      }
    );
  } catch (e) {
    console.log("error", e);
    dispatch(removeLoading());
    dispatch(setMessage(e, "error"));
  }
};

export const createUserRequest = (user: any) => (dispatch: any) => {
  try {
    dispatch(setLoading());
    UserService.createUser(user).then(
      (response: any) => {
        if (response.data !== null) {
          dispatch(removeLoading());
          dispatch(createUserSuccess(response.data));
          dispatch(setMessage("User created successfully", "success"));
        }
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        // show the error to the user
        dispatch(setMessage(message, "error"));
        dispatch(removeLoading());
      }
    );
  } catch (e) {
    console.log("error", e);
    dispatch(removeLoading());
    dispatch(setMessage(e, "error"));
  }
};

export const editUserRequest =
  (userID: any, user: any) => (dispatch: any) => {
    try {
      dispatch(setLoading());
      UserService.updateUser(userID, user).then(
        (response: any) => {
          if (response.data !== undefined) {
            dispatch(removeLoading());
            dispatch(editUserSuccess(response.data));
            dispatch(setMessage("User updated successfully", "success"));
          }
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          // show the error to the user
          dispatch(setMessage(message, "error"));
          dispatch(removeLoading());
        }
      );
    } catch (e) {
      console.log("error", e);
      dispatch(removeLoading());
      dispatch(setMessage(e, "error"));
    }
  };

export const deleteUserRequest = (userID: any) => (dispatch: any) => {
  try {
    dispatch(setLoading());
    UserService.deleteUser(userID).then(
      (response: any) => {
          dispatch(removeLoading());
          dispatch(deleteUserSuccess(userID));
          dispatch(setMessage("User deleted successfully", "success"));
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        // show the error to the user
        dispatch(setMessage(message, "error"));
        dispatch(removeLoading());
      }
    );
  } catch (e) {
    console.log("error", e);
    dispatch(removeLoading());
    dispatch(setMessage(e, "error"));
  }
};

export const resetPasswordRequest = (userID: any) => (dispatch: any) => {
  try {
    dispatch(setLoading())
    UserService.resetUserPassword(userID).then(
      (response) => {
        dispatch(removeLoading());
        dispatch(setMessage("Password rested successfully", "success"));
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        // show the error to the user
        dispatch(setMessage(message, "error"));
        dispatch(removeLoading());
      }
    );
  } catch (e) {
    console.log("error", e);
    dispatch(removeLoading());
    dispatch(setMessage(e, "error"));
  }
}
