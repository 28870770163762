import { httpCommon } from "./http-common";
import authHeader from "./authHeader";

const createCollectionList = (data: any) => {
  return httpCommon.post("/CollectionList", data, {
    headers: authHeader() as any,
  });
};

const getCollectionListFilteredByDate = (startDate: any, endDate: any) => {
  return httpCommon.get(
    `/CollectionList/GetCollectionListFilteredByDate?startDate=${startDate}&endDate=${endDate}`, 
    {headers: authHeader() as any}
  );
}

const CollectionListService = {
  createCollectionList,
  getCollectionListFilteredByDate
}

export default CollectionListService;