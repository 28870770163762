export const CREATE_COLLECTION_LIST_SUCCESS = "CREATE_COLLECTION_LIST_SUCCESS";
export const createCollectionListSuccess = (newCollectionList: any) => ({
  type: CREATE_COLLECTION_LIST_SUCCESS,
  payload: newCollectionList,
});

export const GET_COLLECTION_LIST_FILTERED_BY_DATE_SUCCESS =
  "GET_COLLECTION_LIST_FILTERED_BY_DATE_SUCCESS";
export const getCollectionListFilteredByDateSuccess = (
  collectionLists: any
) => ({
  type: GET_COLLECTION_LIST_FILTERED_BY_DATE_SUCCESS,
  payload: collectionLists,
});
