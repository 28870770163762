import "./CategoriesPage.css";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// thunks requests
import {
  getAllCategoriesRequest,
  deleteCategoryRequest,
} from "store/thunks/categoryThunk";

// selectors
import {
  getAllCategories,
  getAllCategoriesImages,
} from "store/selectors/categorySelector";

//components
import Loader from "components/Loader/Loader";
import Button from "components/Button/Button";
import CategoryCard from "./CategoryCard/CategoryCard";
import CategoryPopup from "./CategoryPopup/CategoryPopup";
import DeletePopup from "components/DeletePopup/DeletePopup";
import MessageNotification from "components/MessageNotification/MessageNotification";

function CategoriesPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // redux state
  const categories = useSelector((state: any) => getAllCategories(state));
  const categoriesImages = useSelector((state: any) =>
    getAllCategoriesImages(state)
  );
  const { loading } = useSelector((state: any) => state.LoadingReducer);
  const { messageText, messageType } = useSelector(
    (state: any) => state.MessageReducer
  );

  // local states
  const [isDeletePopup, setDeletePopup] = useState({
    isDeletePopup: false,
    categoryID: "",
  });
  // local state to manage the get api call time
  const [time, setTime] = useState(0);
  // create and edit category popup
  const [isCategoryPopup, setCategoryPopup] = useState({
    isCategoryPopup: false,
    categoryID: "",
  });

  useEffect(() => {
    // change the browser tab title
    document.title = "WA - Categories";

    // limit API calls
    setTimeout(() => {
      // get all categories thunk request
      categories.length < 1 &&
        categoriesImages.length < 1 &&
        dispatch(getAllCategoriesRequest());
    }, time);
    setTime(3000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, categories, categoriesImages]);

  // go back to previous page
  const goBack = () => {
    navigate(-1);
  };

  // this function hide popups
  const hidePopUp = () => {
    // hide the create edit popup
    setCategoryPopup({
      ...isCategoryPopup,
      isCategoryPopup: false,
      categoryID: "",
    });
    // hide the delete popup
    setDeletePopup({
      isDeletePopup: false,
      categoryID: "",
    });
    let popupContainer = document.getElementById(
      "category-popup-main-container"
    ) as any;
    popupContainer && popupContainer.classList.add("hide");
    document.body.classList.remove("category-popup-overflow-hidden");
  };

  //(Jad) function to navigate to characteristics page
  const openCharacteristicsPage = (categoryId: any) => {
    navigate("/characteristics/" + categoryId);
  };

  return (
    <>
      {messageText ? (
        <MessageNotification
          messageText={messageText}
          messageType={messageType}
        />
      ) : null}

      {isDeletePopup.isDeletePopup ? (
        <DeletePopup
          hidePopUp={hidePopUp}
          id={isDeletePopup.categoryID}
          deleteRequest={deleteCategoryRequest}
        />
      ) : null}

      {isCategoryPopup.isCategoryPopup ? (
        <CategoryPopup
          categoryID={isCategoryPopup.categoryID}
          hidePopUp={hidePopUp}
        />
      ) : null}

      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="category-page-container">
            <div className="category-page-top-section">
              {/* Back button */}
              <div className="category-page-back-btn" onClick={goBack}>
                <Button size="small" icon="back-icon" text="Back" />
              </div>
              {/* Page title */}
              <h2 className="category-page-title">Categories</h2>
              {/* Create new button */}
              <div className="create-new-category-div">
                <Button
                  text="New Category"
                  size="medium"
                  icon="add-icon"
                  onClick={() =>
                    setCategoryPopup({
                      ...isCategoryPopup,
                      isCategoryPopup: true,
                    })
                  }
                />
              </div>
            </div>

            {/* Categories list */}
            <div className="category-list-container">
              {categories &&
                categories.map((category: any, index: any) => (
                  <CategoryCard
                    key={index}
                    index={index}
                    category={category}
                    characteristics={category.characteristics}
                    handleEditCategory={() =>
                      setCategoryPopup({
                        ...isCategoryPopup,
                        isCategoryPopup: true,
                        categoryID: category.id,
                      })
                    }
                    handleDeleteCategory={() => {
                      setDeletePopup({
                        isDeletePopup: true,
                        categoryID: category.id,
                      });
                    }}
                    // (Jad) Call Function Open Characteristics Page
                    handleOpenCharacteristicsPage={() => {
                      openCharacteristicsPage(category.id);
                    }}
                    categoriesImages={categoriesImages}
                  />
                ))}
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default CategoriesPage;
