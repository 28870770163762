import { httpCommon } from "./http-common";
import authHeader from "./authHeader";

const getAllPrintGroups = () => {
  return httpCommon.get("/PrintGroup", { headers: authHeader() as any });
};

const createPrintGroup = (data: any) => {
  return httpCommon.post("/PrintGroup", data, {headers: authHeader() as any})
}

const updatePrintGroup = (printGroupID: any, data: any) => {
  return httpCommon.put(`/PrintGroup/${printGroupID}`, data, {headers: authHeader() as any})
}

const deletePrintGroup = (printGroupID: any) => {
  return httpCommon.delete(`/PrintGroup/${printGroupID}`, {headers: authHeader() as any});
}

const printGroupService = {
  getAllPrintGroups,
  createPrintGroup,
  updatePrintGroup,
  deletePrintGroup
};

export default printGroupService;
