import "./Button.css";

// button image
import redBtn from "../../assets/images/red-button.png";
import blueBtn from "../../assets/images/blue-button.png";
import selectedBtn from "../../assets/images/selected-button.png";
// import croppedBtn from "../../assets/images/category-button.png";
import croppedBtn from "../../assets/images/blue-button-cropped.png";

type DefaultButtonProps = {
  onClick?: any;
  icon?: string;
  text?: string;
  selected?: string;
  size?: string;
  type?: any;
  redBtn?: any;
  cropped?: any;
  subMenu?: boolean;
};

function DefaultButton(props: DefaultButtonProps) {
  return (
    <button
      type={props.type}
      className={
        `btn-container ` +
        (props.selected ? props.selected : null) +
        " " +
        (props.subMenu && ` admin-sub-menu`)
      }
      onClick={props.onClick}
    >
      <img
        src={
          props.selected
            ? selectedBtn
            : props.redBtn === true
            ? redBtn
            : props.cropped === true
            ? croppedBtn
            : blueBtn
        }
        alt="button"
        className={props.size}
        title={props.text}
      />
      <div className="btn-text">
        <div className={props.icon}></div>
        <span> {props.text} </span>
      </div>
    </button>
  );
}

export default DefaultButton;
