export const GET_ALL_LOCATIONS_SUCCESS = "GET_ALL_LOCATIONS_SUCCESS"
export const getAllLocationsSuccess = (locations: any) => ({
    type: GET_ALL_LOCATIONS_SUCCESS,
    payload: locations
})

export const CREATE_LOCATION_SUCCESS = "CREATE_LOCATION_SUCCESS"
export const createLocationSuccess = (newLocation: any) => ({
    type: CREATE_LOCATION_SUCCESS,
    payload: newLocation
})

export const EDIT_LOCATION_SUCCESS = "EDIT_LOCATION_SUCCESS"
export const editLocationSuccess = (updatedLocation: any) => ({
    type: EDIT_LOCATION_SUCCESS,
    payload: updatedLocation
})

export const DELETE_LOCATION_SUCCESS = "DELETE_LOCATION_SUCCESS";
export const deleteLocationSuccess = (locationID: any) => ({
    type: DELETE_LOCATION_SUCCESS,
    payload: locationID
})