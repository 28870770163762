// services
// import ImageService from "services/imageService";
// import CategoryService from "services/categoryService";
import DamageItemModelService from "services/damageItemModelService";

// thunks
import { getTasksBySequenceChoosersRequest } from "store/thunks/taskThunk";
// actions
import { setMessage } from "store/actions/messageAction";
import { removeLoading, setLoading } from "store/actions/loadingAction";
import {
  getMultipleDamagedItemModelsSuccess,
  getAllDamagedItemModelsSuccess,
} from "store/actions/damagedItemModelAction";

export const getMultipleDamagedItemModelsRequest =
  (damagedItemModelIDs: any, pageName?: string) => (dispatch: any) => {
    try {
      // set loading indicator
      dispatch(setLoading());
      // API call to get all categories
      DamageItemModelService.getMultipleDamagedItemModelsService(
        damagedItemModelIDs
      ).then(
        (response: any) => {
          let sequenceChooserIDs = response.data.map((item: any) => {
            return item.sequenceChooser.id;
          });
          dispatch(getMultipleDamagedItemModelsSuccess(response.data));
          pageName !== "harm sight" &&
            dispatch(getTasksBySequenceChoosersRequest(sequenceChooserIDs));

          dispatch(removeLoading());
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          // show the error to the user
          dispatch(setMessage(message, "error"));
          dispatch(removeLoading());
        }
      );
    } catch (e) {
      console.log("error", e);
      dispatch(removeLoading());
      dispatch(setMessage(e, "error"));
    }
  };

//used by Jad for the capture article page
export const getDamagedItemModelsRequest =
  (damagedItemModelIDs: any) => (dispatch: any) => {
    try {
      // set loading indicator
      dispatch(setLoading());
      // API call to get all categories
      DamageItemModelService.getMultipleDamagedItemModelsService(
        damagedItemModelIDs
      ).then(
        (response: any) => {
          let sequenceChooserIDs = response.data.map((item: any) => {
            return item.sequenceChooser.id;
          });
          dispatch(getMultipleDamagedItemModelsSuccess(response.data));

          dispatch(removeLoading());
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          // show the error to the user
          dispatch(setMessage(message, "error"));
          dispatch(removeLoading());
        }
      );
    } catch (e) {
      console.log("error", e);
      dispatch(removeLoading());
      dispatch(setMessage(e, "error"));
    }
  };

export const getAllDamagedItemModelsRequest = (background?: string) => (dispatch: any) => {
  try {
    if (background !== "background") {
      dispatch(setLoading());
    }
    DamageItemModelService.getAllDamageItemModels().then(
      (response: any) => {
        if (response.data !== undefined) {
          dispatch(getAllDamagedItemModelsSuccess(response.data));
          dispatch(removeLoading());
        }
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        // show the error to the user
        dispatch(setMessage(message, "error"));
        dispatch(removeLoading());
      }
    );
  } catch (e) {
    console.log("error", e);
    dispatch(removeLoading());
    dispatch(setMessage(e, "error"));
  }
};
