import { httpCommon } from "./http-common";

const API_URL = "User/login";

//add LoginProp
type LoginProps = {
  username: string;
  password: string;
};

const Login = (loginForm: LoginProps) => {
  return httpCommon.post(API_URL, loginForm).then((response: any) => {
    if (response.data.token) {
      localStorage.setItem("user", JSON.stringify(response.data));
    }
    return response.data;
  });
};

export const Logout = () => {
  localStorage.removeItem("user");
};

const AuthService = {
  Login,
  Logout,
};

export default AuthService;
