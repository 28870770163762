export const GET_ALL_CATEGORIES_SUCCESS = "GET_ALL_CATEGORIES_SUCCESS";
export const getAllCategoriesSuccess = (
  categories: any,
  categoriesImages: any
) => ({
  type: GET_ALL_CATEGORIES_SUCCESS,
  payload: { categories, categoriesImages },
});

export const CREATE_CATEGORY_SUCCESS = "CREATE_CATEGORY_SUCCESS";
export const createCategorySuccess = (newCategory: any, newImage?: any) => ({
  type: CREATE_CATEGORY_SUCCESS,
  payload: { newCategory, newImage },
});

export const EDIT_CATEGORY_SUCCESS = "EDIT_CATEGORY_SUCCESS";
export const editCategorySuccess = (
  updatedCategory: any,
  updatedImage?: any
) => ({
  type: EDIT_CATEGORY_SUCCESS,
  payload: { updatedCategory, updatedImage },
});

export const DELETE_CATEGORY_SUCCESS = "DELETE_CATEGORY_SUCCESS";
export const deleteCategorySuccess = (categoryID: any) => ({
  type: DELETE_CATEGORY_SUCCESS,
  payload: categoryID,
});
