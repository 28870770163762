// actions
import {
  GET_ALL_CHARACTERISTICS_GROUP_SUCCESS,
  CREATE_CHARACTERISTICS_GROUP_SUCCESS,
  EDIT_CHARACTERISTICS_GROUP_SUCCESS,
  DELETE_CHARACTERISTICS_GROUP_SUCCESS,
  GET_BEST_MATCHING_CHARACTERISTIC_GROUP_SUCCESS
} from "../actions/characteristicsGroupAction";

const initialState = {
  characteristicGroup: {},
  characteristicGroups: [],
  characteristicsGroupImages: [],
};

const characteristicsGroupReducer = (state = initialState, action: any) => {
  const { type, payload } = action;
  let characteristicsGroupArray: any = [...state.characteristicGroups];
  let characteristicsGroupImagesArray: any = [
    ...state.characteristicsGroupImages,
  ];

  switch (type) {
    case GET_ALL_CHARACTERISTICS_GROUP_SUCCESS:
      return {
        ...state,
        characteristicGroups: payload.characteristicsGroup,
        characteristicsGroupImages: payload.characteristicsGroupImages,
      };

    case CREATE_CHARACTERISTICS_GROUP_SUCCESS:
      characteristicsGroupArray.push(payload.newCharacteristicGroup);
      payload.newImage !== undefined &&
        characteristicsGroupImagesArray.push(payload.newImage);
      return {
        ...state,
        characteristicGroups: characteristicsGroupArray,
        characteristicsGroupImages: characteristicsGroupImagesArray,
      };

    case EDIT_CHARACTERISTICS_GROUP_SUCCESS:
      characteristicsGroupArray.map(
        (characteristicGroupArray: any, index: any) => {
          if (
            characteristicGroupArray.id ===
            payload.updatedCharacteristicGroup.id
          ) {
            return (characteristicsGroupArray[index] =
              payload.updatedCharacteristicGroup);
          } else return characteristicsGroupArray;
        }
      );
      payload.updatedImage !== undefined &&
        characteristicsGroupImagesArray.push(payload.updatedImage);

      return {
        ...state,
        characteristicGroups: characteristicsGroupArray,
        characteristicsGroupImages: characteristicsGroupImagesArray,
      };

    case DELETE_CHARACTERISTICS_GROUP_SUCCESS:
      characteristicsGroupArray = state.characteristicGroups.filter(
        (characteristicGroup: any) => characteristicGroup.id !== payload
      );
      return {
        ...state,
        characteristicGroups: characteristicsGroupArray,
      };
    
    case GET_BEST_MATCHING_CHARACTERISTIC_GROUP_SUCCESS:
      return {
        ...state,
        characteristicGroup:payload
      }

    default:
      return state;
  }
};
export default characteristicsGroupReducer;
