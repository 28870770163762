export const getTasksBySequenceChoosersSelector: any = (state: any) =>
  state.taskReducer.tasksBySequenceChoosers;

export const getAllTasksSelector: any = (state: any) => state.taskReducer.tasks;

export const getAllTasksImagesSelector: any = (state: any) =>
  state.taskReducer.tasksImages;

export const getTasksBySequenceChoosersImagesSelector: any = (state: any) =>
  state.taskReducer.tasksBySequenceChoosersImages;

export const getAllDismantleTasks: any = (state: any) =>
  state.taskReducer.dismantleTasks;

export const getAllDismantleTasksImages: any = (state: any) =>
  state.taskReducer.dismantleTasksImages;
