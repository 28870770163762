import {
  GET_ALL_USERS_SUCCESS,
  CREATE_USER_SUCCESS,
  EDIT_USER_SUCCESS,
  DELETE_USER_SUCCESS,
} from "store/actions/userAction";

const initialState = [] as any

const userReducer = (state = initialState, action: any) => {
    const {type, payload} = action
    let usersArray = [...state]

    switch (type) {
        case GET_ALL_USERS_SUCCESS:
            usersArray = payload
            return usersArray
        case CREATE_USER_SUCCESS:
            usersArray.push(payload)
            return usersArray
        case EDIT_USER_SUCCESS:
            usersArray.map((user: any, index: any) => {
                if (user.id === payload.id) {
                    return usersArray[index] = payload
                } else {
                    return usersArray
                }
            })
            return usersArray
        case DELETE_USER_SUCCESS:
            usersArray = state.filter((user: any) => user.id !== payload)
            return usersArray
        default:
            return state
    }
}

export default userReducer
