import "./CompaniesPage.css";

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

//thunks
import {
  getAllCommissionedCostumerRequest,
  deleteCommissionedCostumerRequest,
} from "store/thunks/commissionedCostumerThunk";

//Selectors
import { getAllCommissionedCostumer } from "store/selectors/commissionedCostumerSelector";

import Loader from "components/Loader/Loader";
import Button from "../../../components/Button/Button";
import DeletePopup from "components/DeletePopup/DeletePopup";
import CompaniesPopup from "./CompaniesPopup/CompaniesPopup";
import MessageNotification from "components/MessageNotification/MessageNotification";

function CompaniesPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //redux state
  const commissionedCostumer = useSelector((state: any) =>
    getAllCommissionedCostumer(state)
  );
  const { loading } = useSelector((state: any) => state.LoadingReducer);
  const { messageText, messageType } = useSelector(
    (state: any) => state.MessageReducer
  );

  //local state to open the Article group popup
  const [companiesPopup, setCompaniesPopup] = useState({
    isCompaniesPopup: false,
    commissionedCostumerId: "",
  });
  //local state to open the Delete Popup
  const [isDeletePopup, setDeletePopup] = useState({
    isDeletePopup: false,
    commissionedCostumerId: "",
  });

  useEffect(() => {
    // change the browser tab title
    document.title = "WA - Companies";
      // get all commissioned costumers thunk request
      commissionedCostumer.length < 1 &&
        dispatch(getAllCommissionedCostumerRequest());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, commissionedCostumer.length]);

  // go back to previous page
  const goBack = () => {
    navigate(-1);
  };

  //function to hide popups
  const hidePopup = () => {
    //hide the create edit popup
    setCompaniesPopup({
      ...companiesPopup,
      isCompaniesPopup: false,
      commissionedCostumerId: "",
    });
    // hide the delete popup
    setDeletePopup({
      isDeletePopup: false,
      commissionedCostumerId: "",
    });

    let popupContainer = document.getElementById(
      "company-popup-main-container"
    ) as any;
    popupContainer && popupContainer.classList.add("hide");
    document.body.classList.remove("company-popup-overflow-hidden");
  };
  return (
    <>
      {messageText ? (
        <MessageNotification
          messageText={messageText}
          messageType={messageType}
        />
      ) : null}

      {companiesPopup.isCompaniesPopup ? (
        <CompaniesPopup
          hidePopUp={hidePopup}
          commissionedCostumerID={companiesPopup.commissionedCostumerId}
        />
      ) : null}

      {isDeletePopup.isDeletePopup ? (
        <DeletePopup
          hidePopUp={hidePopup}
          id={isDeletePopup.commissionedCostumerId}
          deleteRequest={deleteCommissionedCostumerRequest}
        />
      ) : null}

      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="companies-page-container">
            <div className="companies-page-top-section">
              {/* Back button */}
              <div className="companies-page-back-btn" onClick={goBack}>
                <Button size="small" icon="back-icon" text="Back" />
              </div>
              {/* Page title */}
              <h2 className="companies-page-title">Companies</h2>
              {/* Create new button */}
              <div className="create-new-company-div">
                <Button
                  text="Create New Company"
                  size="medium"
                  icon="add-icon"
                  onClick={() =>
                    setCompaniesPopup({
                      ...companiesPopup,
                      isCompaniesPopup: true,
                    })
                  }
                />
              </div>
            </div>

            {/* Companies list container */}
            <div className="companies-list-container">
              {commissionedCostumer.map(
                (commissionedCostumer: any, index: any) => (
                  <div className="companies-list-item" key={index}>
                    {/* Company title section */}
                    <div className="article-groups-item-title">
                      {commissionedCostumer.name}
                    </div>
                    {/* Edit and delete buttons section */}
                    <div className="companies-item-buttons">
                      {/* Edit Button */}
                      <Button
                        text="Edit"
                        size="small"
                        icon="edit-icon"
                        onClick={() =>
                          setCompaniesPopup({
                            ...companiesPopup,
                            isCompaniesPopup: true,
                            commissionedCostumerId: commissionedCostumer.id,
                          })
                        }
                      />
                      {/* Delete Button */}
                      <Button
                        text="Delete"
                        size="small"
                        icon="delete-icon"
                        redBtn={true}
                        onClick={() =>
                          setDeletePopup({
                            ...isDeletePopup,
                            isDeletePopup: true,
                            commissionedCostumerId: commissionedCostumer.id,
                          })
                        }
                      />
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default CompaniesPage;
