import { type } from "os";

export const GET_MULTIPLE_DAMAGED_ITEM_MODEL_SUCCESS =
  "GET_MULTIPLE_DAMAGED_ITEM_MODEL_SUCCESS";
export const getMultipleDamagedItemModelsSuccess = (
  multipleDamagedItemModels: any
) => ({
  type: GET_MULTIPLE_DAMAGED_ITEM_MODEL_SUCCESS,
  payload: multipleDamagedItemModels,
});

//used to get all damaged item models
export const GET_ALL_DAMAGED_ITEM_MODELS_SUCCESS = "GET_ALL_DAMAGED_ITEM_MODELS_SUCCESS";
export const getAllDamagedItemModelsSuccess = (damagedItemModels: any) => ({
  type: GET_ALL_DAMAGED_ITEM_MODELS_SUCCESS,
  payload: damagedItemModels,
});

// export const CREATE_CATEGORY_SUCCESS = "CREATE_CATEGORY_SUCCESS";
// export const createCategorySuccess = (newCategory: any, newImage?: any) => ({
//   type: CREATE_CATEGORY_SUCCESS,
//   payload: { newCategory, newImage },
// });

// export const EDIT_CATEGORY_SUCCESS = "EDIT_CATEGORY_SUCCESS";
// export const editCategorySuccess = (
//   updatedCategory: any,
//   updatedImage?: any
// ) => ({
//   type: EDIT_CATEGORY_SUCCESS,
//   payload: { updatedCategory, updatedImage },
// });

// export const DELETE_CATEGORY_SUCCESS = "DELETE_CATEGORY_SUCCESS";
// export const deleteCategorySuccess = (categoryID: any) => ({
//   type: DELETE_CATEGORY_SUCCESS,
//   payload: categoryID,
// });
