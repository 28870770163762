import { SET_MESSAGE, CLEAR_MESSAGE } from "../actions/messageAction";

const initialState = { messageText: "", messageType: "" };

const MessageReducer = (state = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case SET_MESSAGE:
      return {
        messageText: payload.messageText,
        messageType: payload.messageType,
      };

    case CLEAR_MESSAGE:
      return { messageText: "", messageType: "" };

    default:
      return state;
  }
};
export default MessageReducer;
