export const GET_TASKS_BY_SEQUENCE_CHOOSERS_SUCCESS =
  "GET_TASKS_BY_SEQUENCE_CHOOSERS_SUCCESS";
export const getTasksBySequenceChoosersSuccess = (
  tasksBySequenceChoosers: any,
  tasksBySequenceChoosersImages: any,
  characteristicGroupSequenceChooser?: any
) => ({
  type: GET_TASKS_BY_SEQUENCE_CHOOSERS_SUCCESS,
  payload: {
    tasksBySequenceChoosers,
    tasksBySequenceChoosersImages,
    characteristicGroupSequenceChooser,
  },
});

export const GET_ALL_TASKS_SUCCESS = "GET_ALL_TASKS_SUCCESS";
export const getAllTasksSuccess = (tasks: any, tasksImages: any) => ({
  type: GET_ALL_TASKS_SUCCESS,
  payload: { tasks, tasksImages },
});

export const CREATE_NEW_TASKS_SUCCESS = "CREATE_NEW_TASKS_SUCCESS";
export const createNewTaskSuccess = (
  createdTask: any,
  createdTaskImage?: any
) => ({
  type: CREATE_NEW_TASKS_SUCCESS,
  payload: { createdTask, createdTaskImage },
});

export const UPDATE_TASKS_SUCCESS = "UPDATE_TASKS_SUCCESS";
export const updateTaskSuccess = (
  updatedTask: any,
  updatedTaskImage?: any
) => ({
  type: UPDATE_TASKS_SUCCESS,
  payload: { updatedTask, updatedTaskImage },
});

export const DELETE_TASK_SUCCESS = "DELETE_TASK_SUCCESS";
export const deleteTaskSuccess = (taskID: any) => ({
  type: DELETE_TASK_SUCCESS,
  payload: taskID,
});

export const GET_ALL_DISMANTLE_TASKS_SUCCESS = "GET_ALL_DISMANTLE_TASKS_SUCCESS";
export const getAllDismantleTasksSuccess = (
  dismantleTasks: any,
  dismantleTasksImages: any
) => ({
  type: GET_ALL_DISMANTLE_TASKS_SUCCESS,
  payload: { dismantleTasks, dismantleTasksImages },
});
