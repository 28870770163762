// services
import ImageService from "services/imageService";
import CategoryService from "services/categoryService";

// actions
import { setMessage } from "store/actions/messageAction";
import {
  getAllCategoriesSuccess,
  createCategorySuccess,
  editCategorySuccess,
  deleteCategorySuccess,
} from "store/actions/categoryAction";
import { removeLoading, setLoading } from "store/actions/loadingAction";

export const getAllCategoriesRequest = (background? : string) => (dispatch: any) => {
  try {
    // set loading indicator
    if (background !== "background") {
      dispatch(setLoading());
    } 
    // API call to get all categories
    CategoryService.getAllCategories().then(
      (response: any) => {
        // get the categories imageID's
        if (response.data !== undefined) {
          let imageIDs = [];
          for (let item of response.data) {
            if (item.imageID !== undefined) {
              imageIDs.push(item.imageID);
            }
          }
          // API call to get the images of the categories
          ImageService.getAllImages(imageIDs)
            .then((res) => {
              // now after having the categories and their images we despatch the success action
              dispatch(getAllCategoriesSuccess(response.data, res.data));
              dispatch(removeLoading());
            })
            .catch((e: any) => {
              console.log(e);
            });
        }
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        // show the error to the user
        dispatch(setMessage(message, "error"));
        dispatch(removeLoading());
      }
    );
  } catch (e) {
    console.log("error", e);
    dispatch(removeLoading());
    dispatch(setMessage(e, "error"));
  }
};

export const createNewCategoryRequest = (category: any) => (dispatch: any) => {
  try {
    dispatch(setLoading());
    // API call to get all categories
    CategoryService.createCategory(category).then(
      (response: any) => {
        // check if the created category has image or no
        if (response.data.imageID !== undefined) {
          // API call to get the images of the categories
          ImageService.getAllImages([response.data.imageID])
            .then((res) => {
              // here we dispatch teh create category success action and we send the created category and the image
              dispatch(createCategorySuccess(response.data, res.data[0]));
              dispatch(removeLoading());
              dispatch(setMessage("Category created successfully", "success"));
            })
            .catch((e: any) => {
              console.log(e);
              dispatch(removeLoading());
              dispatch(setMessage(e, "error"));
            });
        } else {
          // here we dispatch teh create category success action and we send the created category
          dispatch(createCategorySuccess(response.data));
          dispatch(removeLoading());
          dispatch(setMessage("Category created successfully", "success"));
        }
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch(setMessage(message, "error"));
        dispatch(removeLoading());
      }
    );
  } catch (e) {
    console.log("error", e);
    dispatch(removeLoading());
    dispatch(setMessage(e, "error"));
  }
};

export const editCategoryRequest =
  (categoryID: any, category: any) => (dispatch: any) => {
    try {
      dispatch(setLoading());
      // API call to get all categories
      CategoryService.updateCategory(categoryID, category).then(
        (response: any) => {
          if (response.data.imageID !== undefined) {
            // API call to get the images of the categories
            ImageService.getAllImages([response.data.imageID])
              .then((res) => {
                // dispatch the edit category success action and returning the edited category with the image
                dispatch(editCategorySuccess(response.data, res.data[0]));
                dispatch(removeLoading());
                // set success message
                dispatch(
                  setMessage("Category updated successfully", "success")
                );
              })
              .catch((e: any) => {
                console.log(e);
                dispatch(removeLoading());
                dispatch(setMessage(e, "error"));
              });
          } else {
            // dispatch the edit category success action and returning the edited category
            dispatch(editCategorySuccess(response.data));
            dispatch(removeLoading());
            // set success message
            dispatch(setMessage("Category updated successfully", "success"));
          }
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          dispatch(setMessage(message, "error"));
          dispatch(removeLoading());
        }
      );
    } catch (e) {
      console.log("error", e);
      dispatch(removeLoading());
      dispatch(setMessage(e, "error"));
    }
  };

export const deleteCategoryRequest = (categoryID: any) => (dispatch: any) => {
  try {
    dispatch(setLoading());
    // API call to get all categories
    CategoryService.deleteCategory(categoryID).then(
      (response: any) => {
        // dispatch the delete category success action and send the category ID for the deleted category
        dispatch(deleteCategorySuccess(categoryID));
        dispatch(removeLoading());
        dispatch(setMessage("Category deleted successfully", "success"));
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch(setMessage(message, "error"));
        dispatch(removeLoading());
      }
    );
  } catch (e) {
    console.log("error", e);
    dispatch(removeLoading());
    dispatch(setMessage(e, "error"));
  }
};
