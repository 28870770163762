import {
    CREATE_LICENSE_SUCCESS,
    GET_ALL_LICENSES_SUCCESS,
    EDIT_LICENSE_SUCCESS,
    DELETE_LICENSE_SUCCESS
} from "store/actions/licenseService";

const initialState = [] as any

const licenseReducer = (state = initialState, action: any) => {
    const { type, payload } = action;
    let licensesArray = [...state]

    switch (type) {
        case GET_ALL_LICENSES_SUCCESS:
            licensesArray = payload
            return licensesArray
        case CREATE_LICENSE_SUCCESS:
            licensesArray.push(payload)
            return licensesArray
        case EDIT_LICENSE_SUCCESS:
            licensesArray.map((license: any, index: any) => {
                if (license.id === payload.id) {
                    return licensesArray[index] = payload
                } else {
                    return licensesArray
                }
            })
            return licensesArray
        case DELETE_LICENSE_SUCCESS:
            licensesArray = state.filter((license: any) => license.id !== payload)
            return licensesArray
        default:
            return state
    }
}

export default licenseReducer
