import './UsersPage.css'
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

//thunks
import { getAllUsersRequest, deleteUserRequest } from 'store/thunks/userThunk';
import { getAllLocationsRequest } from "store/thunks/locationThunk";

//selectors
import { getAllUsersSelector } from 'store/selectors/userSelector';
import { getAllLocationsSelector } from "store/selectors/locationSelector";

import Loader from "components/Loader/Loader";
import UserPopup from './UserPopup/UserPopup';
import Button from "../../../components/Button/Button";
import DeletePopup from "components/DeletePopup/DeletePopup";
import MessageNotification from "components/MessageNotification/MessageNotification";

function UsersPage() {
 const navigate = useNavigate();
 const dispatch = useDispatch();

 //redux state
 const users = useSelector((state: any) => getAllUsersSelector(state));
 const locations = useSelector((state: any) => getAllLocationsSelector(state));
 const { loading } = useSelector((state: any) => state.LoadingReducer);
 const { messageText, messageType } = useSelector(
   (state: any) => state.MessageReducer
 );

 //local state to open the Article group popup
 const [userPopup, setUserPopup] = useState({
   isUserPopup: false,
   userID: "",
 });
 //local state to open the Delete Popup
 const [isDeletePopup, setDeletePopup] = useState({
   isDeletePopup: false,
   userID: "",
 });

 useEffect(() => {
   // change the browser tab title
   document.title = "WA - Users";
     // get all categories users and locations request
     users.length < 1 && dispatch(getAllUsersRequest());
     locations.length < 1 && dispatch(getAllLocationsRequest());
   // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [dispatch, users.length, locations.length]);

 // go back to previous page
 const goBack = () => {
   navigate(-1);
 };

 //function to hide popups
 const hidePopup = () => {
   //hide the create edit popup
   setUserPopup({
     ...userPopup,
     isUserPopup: false,
     userID: "",
   });
   // hide the delete popup
   setDeletePopup({
     isDeletePopup: false,
     userID: "",
   });

   let popupContainer = document.getElementById(
     "user-popup-main-container"
   ) as any;
   popupContainer && popupContainer.classList.add("hide");
   document.body.classList.remove("user-popup-overflow-hidden");
 };
 return (
   <>
     {messageText ? (
       <MessageNotification
         messageText={messageText}
         messageType={messageType}
       />
     ) : null}
     
     {userPopup.isUserPopup ? (
       <UserPopup hidePopUp={hidePopup} userID={userPopup.userID} />
     ) : null}

     {isDeletePopup.isDeletePopup ? (
       <DeletePopup
         hidePopUp={hidePopup}
         id={isDeletePopup.userID}
         deleteRequest={deleteUserRequest}
       />
     ) : null}

     {loading ? (
       <Loader />
     ) : (
       <>
         <div className="user-page-container">
           <div className="user-page-top-section">
             {/* Back button */}
             <div className="user-page-back-btn" onClick={goBack}>
               <Button size="small" icon="back-icon" text="Back" />
             </div>
             {/* Page title */}
             <h2 className="user-page-title">Users</h2>
             {/* Create new button */}
             <div className="create-new-user-div">
               <Button
                 text="Create New User"
                 size="medium"
                 icon="add-icon"
                 onClick={() =>
                   setUserPopup({
                     ...userPopup,
                     isUserPopup: true,
                   })
                 }
               />
             </div>
           </div>

           {/* user list container */}
           <div className="user-list-container">
             {users.map((user: any, index: any) => (
               <div className="user-list-item" key={index}>
                 {/* user title section */}
                 <div className="user-item-title">
                   {user.email} {user.ip}
                 </div>
                 {/* Edit and delete buttons section */}
                 <div className="user-item-buttons">
                   {/* Edit Button */}
                   <Button
                     text="Edit"
                     size="small"
                     icon="edit-icon"
                     onClick={() =>
                       setUserPopup({
                         ...userPopup,
                         isUserPopup: true,
                         userID: user.id,
                       })
                     }
                   />
                   {/* Delete Button */}
                   <Button
                     text="Delete"
                     size="small"
                     icon="delete-icon"
                     redBtn={true}
                     onClick={() =>
                       setDeletePopup({
                         ...isDeletePopup,
                         isDeletePopup: true,
                         userID: user.id,
                       })
                     }
                   />
                 </div>
               </div>
             ))}
           </div>
         </div>
       </>
     )}
   </>
 );
}

export default UsersPage