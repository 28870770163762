import * as Yup from "yup";



export const CategorySchema = Yup.object().shape({
  name: Yup.string().max(50).required("Name is Erforderlich!"),
});

export const CharacteristicSchema = Yup.object().shape({
  name: Yup.string().max(50).required("Name is Erforderlich!"),
  categoryID: Yup.string().required("Category ID is Erforderlich!"),
});

export const CharacteristicGroupSchema = Yup.object().shape({
  name: Yup.string().max(50).required("Name is Erforderlich!"),
  articleNumber: Yup.string().max(20),
  characteristicsIDs: Yup.array()
    .min(1, "Choose at least one characteristic")
    .required("Choose at least one characteristic"),
});

export const PlacementSchema = Yup.object().shape({
  name: Yup.string().max(50).required("Name is Erforderlich!"),
  characteristicGroup: Yup.object().shape({
    name: Yup.string().required("Characteristic Group is Erforderlich!"),
  }),
});

export const DamageTypeSchema = Yup.object().shape({
  name: Yup.string().max(50).required("Name is Erforderlich!"),
  internRepair: Yup.boolean().required("Intern Repair is Erforderlich!"),
});

export const TaskValidation = Yup.object().shape({
  name: Yup.string().max(50).required("Name is Erforderlich!"),
  description: Yup.string().max(250),
});

export const TaskSequenceValidation = Yup.object().shape({
  name: Yup.string().max(50).required("Name is Erforderlich!"),
  sequenceChooserID: Yup.number().required(
    "Sequence Chooser ID is Erforderlich!"
  ),
  tasksID: Yup.array()
    .min(1, "Choose at least one task")
    .required("Choose at least one task"),
});

export const ArticleGroupSchema = Yup.object().shape({
  name: Yup.string().max(50).required("Name is Erforderlich!"),
});

export const CommissionedCostumerSchema = Yup.object().shape({
  name: Yup.string().max(50).required("Name is Erforderlich!"),
  contactPerson: Yup.string()
    .max(50)
    .required("Contact Person is Erforderlich!"),
  department: Yup.string().max(50).required("Department is Erforderlich!"),
  street: Yup.string().max(50).required("Street is Erforderlich!"),
  postalCode: Yup.string().max(50).required("Postal Code is Erforderlich!"),
  city: Yup.string().max(50).required("City is Erforderlich!"),
});

export const WorkplaceSchema = Yup.object().shape({
  ip: Yup.string()

    .matches(/(^(\d{1,3}\.){3}(\d{1,3})$)/, {
      message: "Ungültige IP Adresse",

      excludeEmptyString: true,
    })

    .test(
      "ipAddress",

      "Ungültige IP Adresse: die Komponenten der IP Adresse müssen im Bereich von 0 bis 255 liegen.",

      (value) => {
        if (value === undefined || value.trim() === "") return true;

        return value.split(".").find((i) => parseInt(i) > 255) === undefined;
      }
    )

    .max(50)

    .required("Ip is Erforderlich!"),
});
