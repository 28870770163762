import "./DamageTypeCard.css";
import Button from "components/Button/Button";

//images
import image from "assets/images/defaultPicture.png";

type DamageTypeCardProps = {
  damageTypesImage?: any;
  index?: any;
  damageType?: any;
  handleEditDamageType?: any;
  handleDeleteDamageType?: any;
};

function DamageTypeCard(props: DamageTypeCardProps) {
  return (
    <div className="damage-type-item" key={props.index}>
      <div className="damage-type-item-title">{props.damageType.name}</div>
      {props.damageTypesImage &&
        props.damageTypesImage
          .filter((item: any) => item.imageID === props.damageType.imageID)
          .map(function (image: any) {
            props.damageType.image = image.image;
            return null;
          })}

      {/* card content */}
      <div className="damage-type-card-content" key={props.index + 1}>
        <div className="damage-type-item-image-container">
          <img
            className="damage-type-image"
            src={
              props.damageType.image !== undefined
                ? "data:image/jpeg;base64," + props.damageType.image
                : image
            }
            alt={props.damageType.name}
            title={props.damageType.name}
          />
        </div>
        <div className="damage-type-intern-repair">
          {props.damageType.internRepair ? "Internal Repair" : "External Repair"}
        </div>
        {/* Edit Button */}
        <div className="damage-type-item-buttons-container">
          <Button
            text="Edit"
            size="small"
            icon="edit-icon"
            onClick={() => props.handleEditDamageType(props.damageType.id)}
          />
          {/* Delete Button */}
          <Button
            text="Delete"
            size="small"
            icon="delete-icon"
            redBtn={true}
            onClick={() => props.handleDeleteDamageType(props.damageType.id)}
          />
        </div>
      </div>
    </div>
  );
}

export default DamageTypeCard;
