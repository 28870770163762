// actions
import {
  GET_ALL_WORKPLACE_PENDING_ACTIONS_SUCCESS,
  GET_WORKPLACE_ACTIONS_BY_DATE_SUCCESS,
  DELETE_ACTION_SUCCESS,
  CREATE_PAST_ORDERS_ACTIONS_SUCCESS,
} from "../actions/actionAction";

const initialState = {
  workplacePendingActions: [],
  pastOrdersActions: [],
  actionFilteredByDates: [],
};

const actionReducer = (state = initialState, action: any) => {
  const { type, payload } = action;
  let workplacePendingActionsArray: any = [...state.workplacePendingActions];
  let newPastOrderActionsArray: any = [...state.pastOrdersActions];
  let actionFilteredByDatesArray: any = [...state.actionFilteredByDates]
  switch (type) {
    case GET_ALL_WORKPLACE_PENDING_ACTIONS_SUCCESS:
      return {
        ...state,
        workplacePendingActions: payload,
      };

    case GET_WORKPLACE_ACTIONS_BY_DATE_SUCCESS:
      return {
        ...state,
        actionFilteredByDates: payload,
      };

    case DELETE_ACTION_SUCCESS:
      workplacePendingActionsArray = state.workplacePendingActions.filter(
        (action: any) => action.id !== payload
      );
      actionFilteredByDatesArray = state.actionFilteredByDates.filter(
        (action: any) => action.id !== payload
      );
      console.log(payload)
      return {
        ...state,
        workplacePendingActions: workplacePendingActionsArray,
        actionFilteredByDates: actionFilteredByDatesArray
      };

    case CREATE_PAST_ORDERS_ACTIONS_SUCCESS:
      newPastOrderActionsArray.push(payload);
      return {
        ...state,
        pastOrdersActions: newPastOrderActionsArray,
      };
    default:
      return state;
  }
};
export default actionReducer;
