import "./TaskSequenceCard.css";

//Buttons
import Button from "components/Button/Button";

type TaskSequenceProp = {
  TaskSequence?: any;
  index?: any;
  handleDeleteTaskSequence?: any;
  handleEditTaskSequence?: any;
};

function TaskSequenceCard(props: TaskSequenceProp) {
  return (
    <>
      {/* main container */}
      <div className="task-sequence-card-container">
        {/* Card title */}
        <div className="task-sequence-card-title">
          <h1>{props.TaskSequence.name}</h1>
        </div>

        {/* list of tasks */}
        <div className="task-sequence-tasks-container">
          {props.TaskSequence.taskSequences.map((task: any, index: any) => (
            <div className="task-sequence-tasks-items" key={index}>
             {task.taskName}
            </div>
          ))}
        </div>

        {/* Edit and Delete button container */}
        <div className="task-sequence-buttons-container">
          <Button
            text="Edit"
            size="small"
            icon="edit-icon"
            onClick={() => props.handleEditTaskSequence(props.TaskSequence)}
          />

          <Button
            text="Delete"
            size="small"
            icon="delete-icon"
            redBtn={true}
            onClick={() => props.handleDeleteTaskSequence(props.TaskSequence)}
          />
        </div>

        {/* card footer */}
        <div className="task-sequence-card-footer"></div>
      </div>
    </>
  );
}

export default TaskSequenceCard;
