import { httpCommon } from "./http-common";
import authHeader from "./authHeader";

const getAllLocations = () => {
    return httpCommon.get('/Location', {headers: authHeader() as any})
}

const getLocationById = (locationId: any) => {
    return httpCommon.get(`/Location/${locationId}`, {headers: authHeader() as any})
}

const createLocation = (location: any) => {
    return httpCommon.post('/Location', location, {headers: authHeader() as  any})
}

const updateLocation = (locationId: any, location: any) => {
    return httpCommon.put(`/Location/${locationId}` , location, {headers: authHeader() as any});
}

const deleteLocation = (locationId: any) => {
    return httpCommon.delete(`/Location/${locationId}`, {headers: authHeader() as any});
}

const LocationService = {
    getAllLocations,
    getLocationById,
    createLocation,
    updateLocation,
    deleteLocation
}

export default LocationService