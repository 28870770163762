//services
import ImageService from "services/imageService";
import characteristicService from "services/characteristicService";

//Actions
import { setMessage } from "store/actions/messageAction";
import {
  getAllCharacteristicsSuccess,
  getAllCharacteristicsByCategoryIdSuccess,
  createCharacteristicSuccess,
  editCharacteristicSuccess,
  deleteCharacteristicSuccess,
} from "store/actions/characteristicAction";
import { removeLoading, setLoading } from "store/actions/loadingAction";

export const getAllCharacteristicsRequest = (background? : string) => (dispatch: any) => {
  try {
    //set loading
    if (background !== "background") {
      dispatch(setLoading());
    }
    
    //API call to get all characteristics
    characteristicService.getAllCharacteristic().then(
      (response: any) => {
        //get the characteristics image Ids
        if (response.data !== undefined) {
          let imageIDs = [];
          for (let item of response.data) {
            if (item.imageID !== undefined) {
              imageIDs.push(item.imageID);
            }
          }
          //API call to get the images of the characteristics
          ImageService.getAllImages(imageIDs)
            .then((res) => {
              // now after having the categories and their images we despatch the success action
              dispatch(getAllCharacteristicsSuccess(response.data, res.data));
              dispatch(removeLoading());
            })
            .catch((e: any) => {
              console.log(e);
            });
        }
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        // show the error to the user
        dispatch(setMessage(message, "error"));
        dispatch(removeLoading());
      }
    );
  } catch (e) {
    console.log("error", e);
    dispatch(removeLoading());
    dispatch(setMessage(e, "error"));
  }
};

export const getAllCharacteristicByCategoryIdRequest =
  (categoryId: any) => (dispatch: any) => {
    try {
      //set loading
      dispatch(setLoading());
      //API call to get all characteristics
      characteristicService.getCharacteristicByCategoryId(categoryId).then(
        (response: any) => {
          //get the characteristics image Ids
          if (response.data !== undefined) {
            let imageIDs = [];
            for (let item of response.data) {
              if (item.imageID !== undefined) {
                imageIDs.push(item.imageID);
              }
            }
            //API call to get the images of the characteristics
            ImageService.getAllImages(imageIDs)
              .then((res) => {
                // now after having the categories and their images we despatch the success action
                dispatch(
                  getAllCharacteristicsByCategoryIdSuccess(
                    response.data,
                    res.data
                  )
                );
                dispatch(removeLoading());
              })
              .catch((e: any) => {
                console.log(e);
              });
          }
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          // show the error to the user
          dispatch(setMessage(message, "error"));
          dispatch(removeLoading());
        }
      );
    } catch (e) {
      console.log("error", e);
      dispatch(removeLoading());
      dispatch(setMessage(e, "error"));
    }
  };

export const createNewCharacteristicRequest =
  (characteristic: any) => (dispatch: any) => {
    try {
      //set loading
      dispatch(setLoading());
      //API to create characteristic
      characteristicService.createCharacteristic(characteristic).then(
        (response: any) => {
          //check if the created characteristic have image or no
          if (response.data.imageID !== undefined) {
            //API call to get the images of the characteristics
            ImageService.getAllImages([response.data.imageID])
              .then((res: any) => {
                // here we dispatch the create characteristic success action and we send the created characteristic and the image
                dispatch(
                  createCharacteristicSuccess(response.data, res.data[0])
                );
                dispatch(removeLoading());
                dispatch(
                  setMessage("Characteristic created successfully", "success")
                );
              })
              .catch((e: any) => {
                console.log(e);
                dispatch(removeLoading());
                dispatch(setMessage(e, "error"));
              });
          } else {
            // here we dispatch the create characteristic success action and we send the created characteristic
            dispatch(createCharacteristicSuccess(response.data));
            dispatch(removeLoading());
            dispatch(
              setMessage("Characteristic created successfully", "success")
            );
          }
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          dispatch(setMessage(message, "error"));
          dispatch(removeLoading());
        }
      );
    } catch (e) {
      console.log("error", e);
      dispatch(removeLoading());
      dispatch(setMessage(e, "error"));
    }
  };

export const editCharacteristicRequest =
  (characteristicId: any, characteristic: any) => (dispatch: any) => {
    try {
      //set loading
      dispatch(setLoading());
      //API call to update the characteristic
      characteristicService
        .updateCharacteristic(characteristicId, characteristic)
        .then(
          (response: any) => {
            if (response.data.imageID !== undefined) {
              //API call to get the images of the Characteristics
              ImageService.getAllImages([response.data.imageID])
                .then((res: any) => {
                  //dispatch to edit characteristic success and the return the characteristic with the image
                  dispatch(
                    editCharacteristicSuccess(response.data, res.data[0])
                  );
                  dispatch(removeLoading());
                  // set success message
                  dispatch(
                    setMessage("Characteristic Updated Successfully", "success")
                  );
                })
                .catch((e: any) => {
                  console.log(e);
                  dispatch(removeLoading());
                  dispatch(setMessage(e, "error"));
                });
            } else {
              //dispatch to edit characteristic success and the return the characteristic
              dispatch(editCharacteristicSuccess(response.data));
              dispatch(removeLoading());
              // set success message
              dispatch(
                setMessage("Characteristic Updated Successfully", "success")
              );
            }
          },
          (error) => {
            const message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();

            dispatch(setMessage(message, "error"));
            dispatch(removeLoading());
          }
        )
        .catch((e: any) => {
          console.log(e);
          dispatch(removeLoading());
          dispatch(setMessage(e, "error"));
        });
    } catch (e) {
      console.log("error", e);
      dispatch(removeLoading());
      dispatch(setMessage(e, "error"));
    }
  };

export const deleteCharacteristicRequest =
  (characteristicId: any) => (dispatch: any) => {
    try {
      // set loading
      dispatch(setLoading());
      // API call to delete Characteristic
      characteristicService
        .deleteCharacteristic(characteristicId)
        .then(
          (response: any) => {
            // dispatch the delete characteristic action and send the deleted characteristic ID for the deleted characteristic
            dispatch(deleteCharacteristicSuccess(characteristicId));
            dispatch(removeLoading());
            dispatch(
              setMessage("Characteristic deleted successfully", "success")
            );
          },
          (error) => {
            const message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();

            dispatch(setMessage(message, "error"));
            dispatch(removeLoading());
          }
        )
        .catch((e: any) => {
          console.log(e);
          dispatch(removeLoading());
          dispatch(setMessage(e, "error"));
        });
    } catch (e) {
      console.log("error", e);
      dispatch(removeLoading());
      dispatch(setMessage(e, "error"));
    }
  };
