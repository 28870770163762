import { httpCommon } from "./http-common";
import authHeader from "./authHeader";

const getDamageItemModel = (damagedItemModelID: string) => {
  return httpCommon.get(`/DamageItemModel/${damagedItemModelID}`, {
    headers: authHeader() as any,
  });
};

const getMultipleDamagedItemModelsService = (damagedItemModelIDs: any) => {
  var damagedItemModelIDsQuery = damagedItemModelIDs
    .map(function (item: any) {
      return "damagedItemModelIDs=" + item;
    })
    .join("&");

  return httpCommon.get(
    `/DamagedItemModel/GetMultipleDamagedItemModels?` +
      damagedItemModelIDsQuery,
    {
      headers: authHeader() as any,
      // params: {
      //   damagedItemModelIDsQuery,
      // },
      responseType: "json",
    }
  );
};

const getSingleDamagedItemModelFiltered = (
  damageTypeID: string,
  placementID: string
) => {
  return httpCommon.get(
    `/GetSingleDamagedItemModelFiltered/${damageTypeID}/${placementID}`,
    {
      headers: authHeader() as any,
    }
  );
};

const getAllDamageItemModels = () => {
  return httpCommon.get("/DamagedItemModel", { headers: authHeader() as any });
};

const createDamageItemModel = (data: any) => {
  return httpCommon.post("/DamageItemModel", data, {
    headers: authHeader() as any,
  });
};

const updateDamageItemModel = (damagedItemModelID: string, data: any) => {
  return httpCommon.put(`/DamageItemModel/${damagedItemModelID}`, data, {
    headers: authHeader() as any,
  });
};

const deleteDamageItemModel = (damagedItemModelID: number) => {
  return httpCommon.delete(`/DamageItemModel/${damagedItemModelID}`, {
    headers: authHeader() as any,
  });
};

const DamageItemModelService = {
  getDamageItemModel,
  getMultipleDamagedItemModelsService,
  getSingleDamagedItemModelFiltered,
  getAllDamageItemModels,
  createDamageItemModel,
  updateDamageItemModel,
  deleteDamageItemModel,
};

export default DamageItemModelService;
