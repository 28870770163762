import './WorkplaceSensrecAdminPage.css'

import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

//thunks
import { getAllLocationsRequest } from 'store/thunks/locationThunk'
import { getAllWorkplacesRequest, deleteWorkplaceRequest } from 'store/thunks/workplaceThunk'

//selectors
import { getAllLocationsSelector } from "store/selectors/locationSelector";
import { getAllWorkplacesSelector } from 'store/selectors/workplaceSelector';

import Loader from "components/Loader/Loader";
import Button from "../../../components/Button/Button";
import DeletePopup from "components/DeletePopup/DeletePopup";
import MessageNotification from "components/MessageNotification/MessageNotification";
import WorkplaceSensrecAdminPopup from './WorkplaceSensrecAdminPopup/WorkplaceSensrecAdminPopup'

function WorkplacePage() {
 const navigate = useNavigate();
 const dispatch = useDispatch();

 //redux state
 const workplaces = useSelector((state: any) =>
   getAllWorkplacesSelector(state)
  );
  const locations = useSelector((state: any) => getAllLocationsSelector(state))
 const { loading } = useSelector((state: any) => state.LoadingReducer);
 const { messageText, messageType } = useSelector(
   (state: any) => state.MessageReducer
 );

 //local state to open the Article group popup
 const [workplacePopup, setWorkplacePopup] = useState({
   isWorkplacePopup: false,
   workplaceID: "",
 });
 //local state to open the Delete Popup
 const [isDeletePopup, setDeletePopup] = useState({
   isDeletePopup: false,
   workplaceID: "",
 });

 useEffect(() => {
   // change the browser tab title
   document.title = "WA - Workplaces";
   //Api call to get workplaces and locations
   workplaces.length < 1 && dispatch(getAllWorkplacesRequest())
   locations.length < 1 && dispatch(getAllLocationsRequest())
   // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [dispatch, workplaces.length, locations.length]);

 // go back to previous page
 const goBack = () => {
   navigate(-1);
 };

 //function to hide popups
 const hidePopup = () => {
   //hide the create edit popup
   setWorkplacePopup({
     ...workplacePopup,
     isWorkplacePopup: false,
     workplaceID: "",
   });
   // hide the delete popup
   setDeletePopup({
     isDeletePopup: false,
     workplaceID: "",
   });

   let popupContainer = document.getElementById(
     "workplace-popup-main-container"
   ) as any;
   popupContainer && popupContainer.classList.add("hide");
   document.body.classList.remove("workplace-popup-overflow-hidden");
 };
 return (
   <>
     {messageText ? (
       <MessageNotification
         messageText={messageText}
         messageType={messageType}
       />
     ) : null}

     {workplacePopup.isWorkplacePopup ? (
       <WorkplaceSensrecAdminPopup
         hidePopUp={hidePopup}
         workplaceID={workplacePopup.workplaceID}
       />
     ) : null}

     {isDeletePopup.isDeletePopup ? (
       <DeletePopup
         hidePopUp={hidePopup}
         id={isDeletePopup.workplaceID}
         deleteRequest={deleteWorkplaceRequest}
       />
     ) : null}

     {loading ? (
       <Loader />
     ) : (
       <>
         <div className="workplace-page-container">
           <div className="workplace-page-top-section">
             {/* Back button */}
             <div className="workplace-page-back-btn" onClick={goBack}>
               <Button size="small" icon="back-icon" text="Back" />
             </div>
             {/* Page title */}
             <h2 className="companies-page-title">Workplaces</h2>
             {/* Create new button */}
             <div className="create-new-workplace-div">
               <Button
                 text="Create New Workplace"
                 size="medium"
                 icon="add-icon"
                 onClick={() =>
                   setWorkplacePopup({
                     ...workplacePopup,
                     isWorkplacePopup: true,
                   })
                 }
               />
             </div>
           </div>

           {/* workplaces list container */}
           <div className="workplace-list-container">
             {workplaces.map((workplace: any, index: any) => (
               <div className="workplace-list-item" key={index}>
                 {/* workplace title section */}
                 <div className="article-groups-item-title">
                   {workplace.location.city} {workplace.ip}
                 </div>
                 {/* Edit and delete buttons section */}
                 <div className="workplace-item-buttons">
                   {/* Edit Button */}
                   <Button
                     text="Edit"
                     size="small"
                     icon="edit-icon"
                     onClick={() =>
                       setWorkplacePopup({
                         ...workplacePopup,
                         isWorkplacePopup: true,
                         workplaceID: workplace.id,
                       })
                     }
                   />
                   {/* Delete Button */}
                   <Button
                     text="Delete"
                     size="small"
                     icon="delete-icon"
                     redBtn={true}
                     onClick={() =>
                       setDeletePopup({
                         ...isDeletePopup,
                         isDeletePopup: true,
                         workplaceID: workplace.id,
                       })
                     }
                   />
                 </div>
               </div>
             ))}
           </div>
         </div>
       </>
     )}
   </>
 );
}

export default WorkplacePage