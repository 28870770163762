import "./LicensesPage.css";

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

//thunks
import {
  getAllLicensesRequest,
  deleteLicenseRequest,
} from "store/thunks/licenseThunk";
import { getAllLocationsRequest } from "store/thunks/locationThunk";

//selectors
import { getAllLicensesSelector } from "store/selectors/licenseSelector";
import { getAllLocationsSelector } from "store/selectors/locationSelector";

import Loader from "components/Loader/Loader";
import Button from "../../../components/Button/Button";
import LicensePopup from "./LicensePopup/LicensePopup";
import DeletePopup from "components/DeletePopup/DeletePopup";
import MessageNotification from "components/MessageNotification/MessageNotification";

function LicensesPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //redux state
  const licenses = useSelector((state: any) => getAllLicensesSelector(state));
  const locations = useSelector((state: any) => getAllLocationsSelector(state));
  const { loading } = useSelector((state: any) => state.LoadingReducer);
  const { messageText, messageType } = useSelector(
    (state: any) => state.MessageReducer
  );

  //local state to open the Article group popup
  const [licensePopup, setLicensePopup] = useState({
    isLicensePopup: false,
    licenseID: "",
  });
  //local state to open the Delete Popup
  const [isDeletePopup, setDeletePopup] = useState({
    isDeletePopup: false,
    licenseID: "",
  });

  useEffect(() => {
    // change the browser tab title
    document.title = "WA - Licenses";

    // get licenses and locations 
    licenses.length < 1 && dispatch(getAllLicensesRequest());
    locations.length < 1 && dispatch(getAllLocationsRequest());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, licenses.length, locations.length]);

  // go back to previous page
  const goBack = () => {
    navigate(-1);
  };

  //function to hide popups
  const hidePopup = () => {
    //hide the create edit popup
    setLicensePopup({
      ...licensePopup,
      isLicensePopup: false,
      licenseID: "",
    });
    // hide the delete popup
    setDeletePopup({
      isDeletePopup: false,
      licenseID: "",
    });

    let popupContainer = document.getElementById(
      "license-popup-main-container"
    ) as any;
    popupContainer && popupContainer.classList.add("hide");
    document.body.classList.remove("license-popup-overflow-hidden");
  };
  return (
    <>
      {messageText ? (
        <MessageNotification
          messageText={messageText}
          messageType={messageType}
        />
      ) : null}

      {licensePopup.isLicensePopup ? (
        <LicensePopup
          hidePopUp={hidePopup}
          licenseID={licensePopup.licenseID}
        />
      ) : null}

      {isDeletePopup.isDeletePopup ? (
        <DeletePopup
          hidePopUp={hidePopup}
          id={isDeletePopup.licenseID}
          deleteRequest={deleteLicenseRequest}
        />
      ) : null}

      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="license-page-container">
            <div className="license-page-top-section">
              {/* Back button */}
              <div className="license-page-back-btn" onClick={goBack}>
                <Button size="small" icon="back-icon" text="Back" />
              </div>
              {/* Page title */}
              <h2 className="license-page-title">Licenses</h2>
              {/* Create new button */}
              <div className="create-new-license-div">
                <Button
                  text="Create New License"
                  size="medium"
                  icon="add-icon"
                  onClick={() =>
                    setLicensePopup({
                      ...licensePopup,
                      isLicensePopup: true,
                    })
                  }
                />
              </div>
            </div>

            {/* license list container */}
            <div className="license-list-container">
              {licenses.map((license: any, index: any) => (
                <div className="license-list-item" key={index}>
                  {/* license title section */}
                  <div className="license-item-title">
                    {license.location.city} {license.location.name}{" "}
                    {license.validFrom.slice(0, 10)}{" "}
                    {license.validTo.slice(0, 10)}
                  </div>
                  {/* Edit and delete buttons section */}
                  <div className="license-item-buttons">
                    {/* Edit Button */}
                    <Button
                      text="Edit"
                      size="small"
                      icon="edit-icon"
                      onClick={() =>
                        setLicensePopup({
                          ...licensePopup,
                          isLicensePopup: true,
                          licenseID: license.id,
                        })
                      }
                    />
                    {/* Delete Button */}
                    <Button
                      text="Delete"
                      size="small"
                      icon="delete-icon"
                      redBtn={true}
                      onClick={() =>
                        setDeletePopup({
                          ...isDeletePopup,
                          isDeletePopup: true,
                          licenseID: license.id,
                        })
                      }
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default LicensesPage;
