import "./TaskPopup.css";
import { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import { useDispatch, useSelector } from "react-redux";

// thunk requests
import { createTaskRequest, editTaskRequest } from "store/thunks/taskThunk";
import { getAllDamageTypesRequest } from "store/thunks/damageTypeThunk";

// selectors
import { getAllDamageTypesSelector } from "store/selectors/damageTypeSelector";
import {
  getAllTasksSelector,
  getAllTasksImagesSelector,
} from "store/selectors/taskSelector";

// validations
import { TaskValidation } from "validations/adminValidations";

//images
import close from "assets/icons/close-button.png";
import image from "assets/images/defaultPicture.png";

//components
import Input from "components/Input/Input";
import Button from "components/Button/Button";
// import ImageService from "services/imageService";

type TaskPopUpProps = {
  taskID?: any;
  hidePopUp?: any;
};

function TaskPopup(props: TaskPopUpProps) {
  const dispatch = useDispatch<any>();

  // redux states
  const tasks = useSelector((state: any) => getAllTasksSelector(state));
  const tasksImages = useSelector((state: any) =>
    getAllTasksImagesSelector(state)
  );
  const damageTypes = useSelector((state: any) =>
    getAllDamageTypesSelector(state)
  );

  // local state
  //state for saving preview image when selected:
  const [baseImage, setBaseImage] = useState<any>(image);
  // const [imageID, setImageID] = useState(0);

  const [taskState, setTaskState] = useState<any>({
    name: "",
    description: "",
    damageTypeID: "",
    imageID: "",
    image: image,
  });

  const [imageIsTooLarge, setImageIsTooLarge] = useState(false)

  // if the form is on edit, so we get user data from DB:
  const getTask = (id: string) => {
    // filter the redux tasks array and get the selected
    let task = tasks.filter((task: any) => task.id === props.taskID);

    setTaskState({
      name: task[0]?.name,
      description: task[0]?.description,
      damageTypeID: task[0]?.damageType?.id,
      imageID: task[0]?.imageID,
    });
    // setImageID(task[0].imageID);

    // filter the redux categories images array
    let tasksImage = tasksImages.filter(
      (image: any) => image?.imageID === task[0]?.imageID
    );

    tasksImage[0]?.image &&
      setBaseImage("data:image/jpeg;base64," + tasksImage[0]?.image);
  };

  useEffect(() => {
    damageTypes.length < 1 && dispatch(getAllDamageTypesRequest());
  }, [dispatch, damageTypes.length]);

  // useEffect(() => {
  //   if (imageID) {
  //     ImageService.getAllImages([imageID]).then((res) => {
  //       console.log("res", res);
  //     });
  //   }
  // }, [imageID]);

  useEffect(() => {
    // if there is taskID so we call the get task by ID function
    props.taskID && getTask(props.taskID);
    // because we are in a popup we should stop scrolling so we add this classes
    let popupContainer = document.getElementById(
      "task-popup-main-container"
    ) as any;
    popupContainer && popupContainer.classList.remove("hide");
    document.body.classList.add("task-popup-overflow-hidden");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.taskID]);

  // onSubmit we check if the form is created or edited and gives the correct function:
  function onSubmit(
    fields: any,
    { setStatus, setSubmitting }: { setStatus: any; setSubmitting: any }
  ) {
    const { name, description, damageTypeID, image } = taskState;
    let data = new FormData();
    data.append("name", name);
    description && data.append("description", description);
    data.append("damageTypeID", damageTypeID);
    // damageType?.id && data.append("damageTypeID", damageType?.id);
    data.append("image", image);
    if (!imageIsTooLarge) {
      if (!props.taskID) {
        dispatch(createTaskRequest(data));
        props.hidePopUp();
        // dispatch(categoryAction(data));
      } else {
        dispatch(editTaskRequest(props.taskID, data));
        props.hidePopUp();
        // EditCategory(props.categoryID, data);
      }
    }
  }

  // show image preview when selected:
  const handleImageChange = async (event: any) => {
    const file = event.target.files[0];
    if (file.size > 500000) {
      setImageIsTooLarge(true)
      setBaseImage(image)
    } else {
      const base64 = await convertBase64(file);
      setBaseImage(base64);
      setTaskState({
        ...taskState,
        image: file,
      });
      setImageIsTooLarge(false)
    }
    
  };

  // function to convert selected image to base64 to display it as preview:
  const convertBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleInputChange = (event: any) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setTaskState({ ...taskState, [name]: value });
  };

  const handleSelectChange = (event: any) => {
    let value = event.target.value;
      setTaskState({
        ...taskState,
        damageTypeID: value,
      });
  };

  // const handleSubmit = () => {
  //   document.body.classList.remove("task-popup-overflow-hidden");
  // };

  return (
    <>
      <div id="task-popup-main-container" className="task-popup-main-container">
        <div className="task-popup-overlay" onClick={props.hidePopUp}></div>
        <div className="task-popup-container">
          {/* Header: title and close button */}
          <div className="task-popup-top-section">
            {props.taskID ? <h1>Edit Task</h1> : <h1> Create New Task</h1>}
            <img
              className="task-popup-close"
              src={close}
              onClick={props.hidePopUp}
              alt="close Popup"
              title="close Popup"
            />
          </div>

          <Formik
            initialValues={taskState}
            validationSchema={TaskValidation}
            onSubmit={onSubmit}
            enableReinitialize
          >
            {({ values, errors, touched, isSubmitting, setFieldValue }) => {
              return (
                <>
                  <Form>
                    <div className="task-popup-form-container">
                      {/* Section 1: Category Name + first category checkbox section */}
                      <div className="task-popup-fields-section">
                        {/* Name */}
                        <h3 className="task-popup-input-label">Name</h3>
                        <div className="task-popup-input">
                          <div className="task-popup-input">
                            <Field
                              className="my-input"
                              type="text"
                              id="name"
                              name="name"
                              value={taskState.name}
                              placeholder="Name"
                              onChange={handleInputChange}
                            />
                            {touched.name && errors.name ? (
                              <div
                                className="task-popup-error-message"
                                role="alert"
                              >
                                {errors.name}
                              </div>
                            ) : null}
                          </div>
                          <div className="category-icon"></div>
                        </div>

                        {/* damage type dropdown */}
                        <h3 className="task-popup-input-label">Damage Type</h3>
                        <div className="task-popup-input">
                          <div className="task-popup-input">
                            <Field
                              className="my-input task-select"
                              as="select"
                              type="text"
                              id="damageType"
                              name="damageType.name"
                              value={taskState?.damageType?.id}
                              placeholder="Damage Type"
                              onChange={handleSelectChange}
                            >
                              {props.taskID && taskState.damageTypeID ? (
                                <>
                                  <option value="">Remove damage type</option>
                                  {damageTypes &&
                                    damageTypes.map(
                                      // eslint-disable-next-line array-callback-return
                                      (damageType: any, index: any) =>
                                        damageType.id ===
                                        taskState.damageTypeID ? (
                                          <option
                                            value={damageType.id}
                                            key={index}
                                            selected
                                          >
                                            {damageType.name}
                                          </option>
                                        ) : (
                                          <option
                                            value={damageType.id}
                                            key={index}
                                          >
                                            {damageType.name}
                                          </option>
                                        )
                                    )}
                                </>
                              ) : (
                                <>
                                  <option value="">Select a Damage Type</option>
                                  {damageTypes &&
                                    damageTypes.map(
                                      (damageType: any, index: any) => (
                                        <option
                                          value={damageType.id}
                                          key={index}
                                        >
                                          {damageType.name}
                                        </option>
                                      )
                                    )}
                                </>
                              )}
                            </Field>
                          </div>
                          <div className="category-icon"></div>
                        </div>

                        {/* task description */}
                        <h3 className="task-popup-input-label">Description</h3>
                        <div className="task-popup-input">
                          <div className="task-popup-input">
                            <Field
                              className="my-input task-textarea"
                              as="textarea"
                              type="text"
                              id="name"
                              name="description"
                              value={taskState.description}
                              placeholder="Name"
                              onChange={handleInputChange}
                            />
                            {touched.description && errors.description ? (
                              <div
                                className="task-popup-error-message"
                                role="alert"
                              >
                                {errors.description}
                              </div>
                            ) : null}
                          </div>
                          <div className="category-icon"></div>
                        </div>
                      </div>

                      {/* Section 2: Image Section */}
                      <div className="task-popup-image-section">
                        <img
                          className="task-popup-image"
                          src={baseImage ? baseImage : image}
                          alt="Bild hinzufügen"
                          title="Bild hinzufügen"
                        />
                        <Input
                          type="file"
                          name="image"
                          accept="image/*"
                          placeholder="Durchsuchen.."
                          onChange={handleImageChange}
                        />
                        {imageIsTooLarge ? (
                          <div
                            className="task-popup-error-message-image"
                            role="alert"
                          >
                            Image is Too Large
                          </div>
                        ) : null}
                      </div>
                    </div>

                    {/* Submit Button: Create or Edit */}
                    <div className="task-popup-submit-button-container">
                      {!props.taskID ? (
                        <Button
                          size="small"
                          text="Create"
                          icon="add-icon"
                          type="submit"
                          // onClick={handleSubmit}
                        ></Button>
                      ) : (
                        <Button
                          icon="edit-icon"
                          size="small"
                          text="Edit"
                          type="submit"
                          // onClick={handleSubmit}
                        ></Button>
                      )}
                    </div>
                  </Form>
                </>
              );
            }}
          </Formik>
        </div>
      </div>
    </>
  );
}

export default TaskPopup;
