// actions
import { LOGIN_SUCCESS, LOGOUT } from "../actions/authAction";

const user = JSON.parse(localStorage.getItem("user")!);

const initialState = user
  ? { isLoggedIn: true, user: user }
  : { isLoggedIn: false, user: {} };

const authReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      // create new object
      const newUser = action.payload;
      //then access the state
      return { ...state, isLoggedIn: true, user: newUser };

    case LOGOUT:
      localStorage.removeItem("user");
      return { ...state, isLoggedIn: false, user: {} };

    default:
      return state;
  }
};
export default authReducer;
