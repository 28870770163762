//actions
import {
  GET_ALL_PRINT_GROUP_SUCCESS,
  CREATE_PRINT_GROUP_SUCCESS,
  EDIT_PRINT_GROUP_SUCCESS,
  DELETE_PRINT_GROUP_SUCCESS,
} from "store/actions/printGroupAction";

//state
const initialState = [] as any;

const printGroupReducer = (state = initialState, action: any) => {
  let printGroupsArray = state;
  const { type, payload } = action;
  switch (type) {
    case GET_ALL_PRINT_GROUP_SUCCESS:
      printGroupsArray = payload;
      return printGroupsArray;
    case CREATE_PRINT_GROUP_SUCCESS:
      printGroupsArray.push(payload);
      return printGroupsArray
    case EDIT_PRINT_GROUP_SUCCESS:
      printGroupsArray.map((printGroup: any, index: any) => {
        if (printGroup.id === payload.id) {
          return (printGroupsArray[index] = payload)
        }else return printGroupsArray
      })
      return printGroupsArray
    case DELETE_PRINT_GROUP_SUCCESS:
      printGroupsArray = state.filter((printGroup: any) => printGroup.id !== payload)
      return printGroupsArray
    default:
      return state;
  }
};

export default printGroupReducer;
