import { httpCommon } from "./http-common";
import authHeader from "./authHeader";

const getCharacteristic = (characteristicId: string) => {
    return httpCommon.get(`/Characteristic/${characteristicId}`, { headers: authHeader() as any });
}

const getAllCharacteristic = () => {
    return httpCommon.get("/Characteristic", { headers: authHeader() as any });
}

const getCharacteristicByCategoryId = (categoryId: string) => {
    return httpCommon.get(`/characteristic/GetByCategoryId/${categoryId}`, { headers: authHeader() as any });

}

const createCharacteristic = (data: any) => {
    return httpCommon.post("/Characteristic", data, { headers: authHeader() as any });
}

const updateCharacteristic = (characteristicId: string, data: any) => {
    return httpCommon.put(`/Characteristic/${characteristicId}`, data, { headers: authHeader() as any });
}

const deleteCharacteristic = (characteristicId: string) => {
    return httpCommon.delete(`/Characteristic/${characteristicId}`, { headers: authHeader() as any,});
}

const characteristicService = {
    getCharacteristic,
    getAllCharacteristic,
    getCharacteristicByCategoryId,
    createCharacteristic,
    updateCharacteristic,
    deleteCharacteristic
};

export default characteristicService;