import React from "react";
import "./Input.css";

// Add props for all variables send by props
type InputProps = {
  id?: string;
  name?: string;
  type?: string;
  value?: string;
  placeholder?: string;
  label?: string;
  accept?: any;
  autocomplete?: any;
  className?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

function Input(props: InputProps) {
  return (
    <>
      <input
        className={props.className}
        id={props.id}
        name={props.name}
        type={props.type}
        value={props.value}
        placeholder={props.placeholder}
        onChange={props.onChange}
        onBlur={props.onBlur}
        onFocus={props.onFocus}
        accept={props.accept}
        autoComplete={props.autocomplete}
      />
      {props.type === "checkbox" ? (
        <label className="my-checkbox-label">{props.label}</label>
      ) : (
        ""
      )}
    </>
  );
}

export default Input;
