// services
import ImageService from "services/imageService";
import CharacteristicsGroupService from "services/characteristicsGroupService";

// actions
import { setMessage } from "store/actions/messageAction";
import {
  getAllCharacteristicsGroupSuccess,
  createCharacteristicGroupSuccess,
  editCharacteristicGroupSuccess,
  deleteCharacteristicGroupSuccess,
  getBestMatchingCharacteristicGroupSuccess,
} from "store/actions/characteristicsGroupAction";
import { removeLoading, setLoading } from "store/actions/loadingAction";

import { getPlacementByCharacteristicGroupIdRequest } from "./placementThunk";
import { getAllDismantleTasksRequest } from "./taskThunk";

export const getAllCharacteristicsGroupRequest = (background?: any) => (dispatch: any) => {
  try {
    // set loading indicator
    if (background !== "background") {
      dispatch(setLoading());
    }
    // API call to get all Characteristics Group
    CharacteristicsGroupService.getAllCharacteristicsGroup().then(
      (response: any) => {
        // get the Characteristics Group imageID's
        if (response.data !== undefined) {
          let imageIDs = [];
          for (let item of response.data) {
            if (item.imageID !== undefined) {
              imageIDs.push(item.imageID);
            }
          }
          // API call to get the images of the Characteristics Group
          ImageService.getAllImages(imageIDs)
            .then((res) => {
              // now after having the Characteristics Group and their images we despatch the success action
              dispatch(
                getAllCharacteristicsGroupSuccess(response.data, res.data)
              );
              dispatch(removeLoading());
            })
            .catch((e: any) => {
              console.log(e);
            });
        }
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        // show the error to the user
        dispatch(setMessage(message, "error"));
        dispatch(removeLoading());
      }
    );
  } catch (e) {
    console.log("error", e);
    dispatch(removeLoading());
    dispatch(setMessage(e, "error"));
  }
};

export const createNewCharacteristicGroupRequest =
  (characteristicGroup: any) => (dispatch: any) => {
    try {
      dispatch(setLoading());
      // API call to get all categories
      CharacteristicsGroupService.createCharacteristicGroup(
        characteristicGroup
      ).then(
        (response: any) => {
          // check if the created category has image or no
          if (response.data.imageID !== undefined) {
            // API call to get the images of the categories
            ImageService.getAllImages([response.data.imageID])
              .then((res) => {
                // here we dispatch teh create category success action and we send the created category and the image
                dispatch(
                  createCharacteristicGroupSuccess(response.data, res.data[0])
                );
                dispatch(removeLoading());
                dispatch(
                  setMessage(
                    "Characteristic Group created successfully",
                    "success"
                  )
                );
              })
              .catch((e: any) => {
                console.log(e);
                dispatch(removeLoading());
                dispatch(setMessage(e, "error"));
              });
          } else {
            // here we dispatch teh create category success action and we send the created category
            dispatch(createCharacteristicGroupSuccess(response.data));
            dispatch(removeLoading());
            dispatch(
              setMessage("Characteristic Group created successfully", "success")
            );
          }
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          dispatch(setMessage(message, "error"));
          dispatch(removeLoading());
        }
      );
    } catch (e) {
      console.log("error", e);
      dispatch(removeLoading());
      dispatch(setMessage(e, "error"));
    }
  };

export const editCharacteristicGroupRequest =
  (characteristicGroupID: any, characteristicGroup: any) => (dispatch: any) => {
    try {
      dispatch(setLoading());
      // API call to get all categories
      CharacteristicsGroupService.updateCharacteristicGroup(
        characteristicGroupID,
        characteristicGroup
      ).then(
        (response: any) => {
          if (response.data.imageID !== undefined) {
            // API call to get the images of the categories
            ImageService.getAllImages([response.data.imageID])
              .then((res) => {
                // dispatch the edit category success action and returning the edited category with the image
                dispatch(
                  editCharacteristicGroupSuccess(response.data, res.data[0])
                );
                dispatch(removeLoading());
                // set success message
                dispatch(
                  setMessage(
                    "Characteristic Group updated successfully",
                    "success"
                  )
                );
              })
              .catch((e: any) => {
                console.log(e);
                dispatch(removeLoading());
                dispatch(setMessage(e, "error"));
              });
          } else {
            // dispatch the edit category success action and returning the edited category
            dispatch(editCharacteristicGroupSuccess(response.data));
            dispatch(removeLoading());
            // set success message
            dispatch(
              setMessage("Characteristic Group updated successfully", "success")
            );
          }
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          dispatch(setMessage(message, "error"));
          dispatch(removeLoading());
        }
      );
    } catch (e) {
      console.log("error", e);
      dispatch(removeLoading());
      dispatch(setMessage(e, "error"));
    }
  };

export const deleteCharacteristicGroupRequest =
  (characteristicGroupID: any) => (dispatch: any) => {
    try {
      dispatch(setLoading());
      // API call to get all categories
      CharacteristicsGroupService.deleteCharacteristicGroup(
        characteristicGroupID
      ).then(
        (response: any) => {
          // dispatch the delete category success action and send the category ID for the deleted category
          dispatch(deleteCharacteristicGroupSuccess(characteristicGroupID));
          dispatch(removeLoading());
          dispatch(
            setMessage("Characteristic Group deleted successfully", "success")
          );
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          dispatch(setMessage(message, "error"));
          dispatch(removeLoading());
        }
      );
    } catch (e) {
      console.log("error", e);
      dispatch(removeLoading());
      dispatch(setMessage(e, "error"));
    }
  };

export const getBestMatchingCharacteristicGroupRequest =
  (characteristicsID: any) => (dispatch: any) => {
    try {
      //set loading
      dispatch(setLoading());
      //Api call to get the best match characteristic group
      CharacteristicsGroupService.getBestMatchingCharacteristicGroup(characteristicsID).then(
        (response: any) => {
          if (response.data !== undefined) {
            let sequenceChooserIDS = [] as any
            response.data.sequenceChoosers.map((item: any) => {
              return sequenceChooserIDS.push(item.id)
            })
             dispatch(getAllDismantleTasksRequest(sequenceChooserIDS));
            dispatch(getBestMatchingCharacteristicGroupSuccess(response.data))
            dispatch(
              getPlacementByCharacteristicGroupIdRequest(
                response.data.id,
              )
            );
            //remove loading
            dispatch(removeLoading())
          }
        }, (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          dispatch(setMessage(message, "error"));
          dispatch(removeLoading());
       }
     )
    } catch (e) {
      console.log("error", e);
      dispatch(removeLoading());
      dispatch(setMessage(e, "error"));
    }
  };
