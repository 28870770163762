import './SensrecAdminMenu.css'
import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

//components
import Button from "components/Button/Button";

function SensrecAdminMenu() {
  const navigate = useNavigate();

  //local states
  const [activeMenuName, setActiveMenuName] = useState<any>("");
  const [highlightMenuName, setHighlightMenuName] = useState<any>("Article");

  //function to toggle sub menu of clicked item
  const toggleDrawer = (menuName: any) => {
    setActiveMenuName((prevState: any) => {
      if (prevState === menuName) {
        setActiveMenuName("");
      } else {
        setActiveMenuName(menuName);
      }
    });
  };
  // we get the url
  const currentRoute = useLocation().pathname.toLowerCase();



  return (
    <>
      <nav role="navigation" aria-label="Admin Menu">
        <div className="sensrecadmin-menu-wrapper">
          {/* Location */}
          <div className="menu-item">
            <div
              onClick={() => {
                navigate("/location");
              }}
            >
              <Button
                size="medium"
                text="Locations"
                selected={
                  currentRoute.includes("location") ? "selected" : ""
                }
              />
            </div>
          </div>
          {/* License */}
          <div className="menu-item">
            <div
              onClick={() => {
                navigate("/license");
              }}
            >
              <Button
                size="medium"
                text="Licenses"
                selected={currentRoute.includes("license") ? "selected" : ""}
              />
            </div>
          </div>
          {/* Users */}
          <div className="menu-item">
            <div
              onClick={() => {
                navigate("/users");
              }}
            >
              <Button
                size="medium"
                text="Users"
                selected={currentRoute.includes("users") ? "selected" : ""}
              />
            </div>
          </div>
          {/* Wokrplaces */}
          <div className="menu-item">
            <div
              onClick={() => {
                navigate("/workplace");
              }}
            >
              <Button
                size="medium"
                text="Workplaces"
                selected={currentRoute.includes("workplace") ? "selected" : ""}
              />
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}

export default SensrecAdminMenu