import {
  GET_ALL_LOCATIONS_SUCCESS,
  CREATE_LOCATION_SUCCESS,
  EDIT_LOCATION_SUCCESS,
  DELETE_LOCATION_SUCCESS,
} from "store/actions/locationAction";

const initialState = [] as any;

const locationReducer = (state = initialState, action: any) => {
  const { type, payload } = action;
  let locationsArray = [...state];

  switch (type) {
    case GET_ALL_LOCATIONS_SUCCESS:
      locationsArray = payload;
      return locationsArray;
    case CREATE_LOCATION_SUCCESS:
      locationsArray.push(payload);
      return locationsArray;
    case EDIT_LOCATION_SUCCESS:
      locationsArray.map((location: any, index: any) => {
        if (location.id === payload.id) {
          return (locationsArray[index] = payload);
        } else {
          return locationsArray;
        }
      });
      return locationsArray;
    case DELETE_LOCATION_SUCCESS:
      locationsArray = state.filter((location: any) => location.id !== payload);
      return locationsArray;
    default:
      return state;
  }
};

export default locationReducer;
