//action
import {
  GET_ALL_TASK_SEQUENCES_SUCCESS,
  CREATE_TASK_SEQUENCE_SUCCESS,
  DELETE_TASK_SEQUENCE_SUCCESS,
} from "store/actions/taskSequenceAction";

const initialState = [] as any;

const taskSequenceReducer = (state = initialState, action: any) => {
  let taskSequencesArray = state;
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_TASK_SEQUENCES_SUCCESS:
      taskSequencesArray = payload;
      return taskSequencesArray;
    case CREATE_TASK_SEQUENCE_SUCCESS:
      taskSequencesArray.push(payload);
      return taskSequencesArray;
    case DELETE_TASK_SEQUENCE_SUCCESS:
      taskSequencesArray = state.filter(
        (taskSequence: any) => taskSequence.name !== payload.name
      );
      return taskSequencesArray;
    default:
      return state;
  }
};

export default taskSequenceReducer;
