import "./LicensePopup.css";

import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";

//thunks
import {
  createLicenseRequest,
  editLicenseRequest,
} from "store/thunks/licenseThunk";
//selector
import { getAllLicensesSelector } from "store/selectors/licenseSelector";
import { getAllLocationsSelector } from "store/selectors/locationSelector";

//validation
import { LicenseSchema } from "validations/sensrecAdminValidations";

//date picker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { parseISO } from "date-fns";
import de from "date-fns/locale/de";

//components
import Button from "components/Button/Button";

//images
import close from "assets/icons/close-button.png";
import cityIcon from "assets/icons/city-icon.png";

type LicensePopupProps = {
  hidePopUp?: any;
  licenseID?: any;
};

function LicensePopup(props: LicensePopupProps) {
  const dispatch = useDispatch();

  //redux state
  const locations = useSelector((state: any) => getAllLocationsSelector(state));
  const licenses = useSelector((state: any) => getAllLicensesSelector(state));

  //local state
  const [licenseState, setLicenseState] = useState({
    locationID: "",
    validFrom: new Date(),
    validTo: new Date(),
  });

  useEffect(() => {
    props.licenseID && getLicense(props.licenseID);
    // because we are in a popup we should stop scrolling so we add this classes
    let popupContainer = document.getElementById(
      "license-popup-main-container"
    ) as any;
    popupContainer && popupContainer.classList.remove("hide");
    document.body.classList.add("license-popup-overflow-hidden");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.licenseID]);

  const getLicense = (licenseID: any) => {
    let license = licenses.filter((license: any) => license.id === licenseID);

    setLicenseState({
      ...licenseState,
      locationID: license[0].location.id,
      validFrom: parseISO(license[0].validFrom),
      validTo: parseISO(license[0].validTo),
    });
  };

  //Handle when a locations is selected
  const handleLocationInputChange = (event: any) => {
    setLicenseState({ ...licenseState, locationID: event.target.value });
  };

  // Handle ValidFrom date Change
    const handleValidFromChange = (date: any) => {
    setLicenseState({ ...licenseState, validFrom: date });
  };

  // Handle ValidTo date Change
  const handleValidToChange = (date: any) => {
    setLicenseState({ ...licenseState, validTo: date });
    };
    
  console.log(licenseState)

  const onSubmit = (
    fields: any,
    { setStatus, setSubmitting }: { setStatus: any; setSubmitting: any }
  ) => {
    const data = {
      locationID: parseInt(licenseState.locationID),
      validFrom: licenseState.validFrom.toISOString(),
      validTo: licenseState.validTo.toISOString(),
      };
    if (!props.licenseID) {
      dispatch(createLicenseRequest(data));
      props.hidePopUp();
    } else {
      dispatch(editLicenseRequest(props.licenseID, data));
      props.hidePopUp();
    }
  };

    const handleSubmit = () => {
    document.body.classList.remove("license-popup-overflow-hidden");
  };
  return (
    <>
      <div
        className="license-popup-main-container"
        id="license-popup-main-container"
      >
        <div className="license-popup-overlay" onClick={props.hidePopUp}></div>
        <div className="license-container">
          {/* Header: title and close button */}
          <div className="license-top-section">
            {props.licenseID ? (
              <h1>Edit License</h1>
            ) : (
              <h1> Create New License</h1>
            )}
            <img
              className="license-popup-close"
              src={close}
              onClick={props.hidePopUp}
              alt="close Popup"
              title="close Popup"
            />
          </div>

          <Formik
            initialValues={licenseState}
            validationSchema={LicenseSchema}
            onSubmit={onSubmit}
            enableReinitialize
          >
            {({ values, errors, touched, isSubmitting, setFieldValue }) => {
              return (
                <>
                  <Form>
                    <div className="license-popup-form-container">
                      <div className="license-fields-section">
                        {/* Locations*/}
                        <h3 className="license-popup-input-label">Locations</h3>
                        <div className="license-popup-input">
                          <Field
                            as="select"
                            className="my-input"
                            id="locationID"
                            name="locationID"
                            value={licenseState.locationID}
                            placeholder="Locations"
                            onChange={handleLocationInputChange}
                          >
                            {props.licenseID && licenseState.locationID ? (
                              <>
                                {locations &&
                                  locations.map(
                                    // eslint-disable-next-line array-callback-return
                                    (location: any, index: any) =>
                                      location.id ===
                                      licenseState.locationID ? (
                                        <option
                                          value={location.id}
                                          key={index}
                                          selected
                                        >
                                          {location.name}
                                        </option>
                                      ) : (
                                        <option value={location.id} key={index}>
                                          {location.name}
                                        </option>
                                      )
                                  )}
                              </>
                            ) : (
                              <>
                                <option value="">Select Location</option>
                                {locations &&
                                  locations.map((location: any, index: any) => (
                                    <option value={location.id} key={index}>
                                      {location.name}
                                    </option>
                                  ))}
                              </>
                            )}
                          </Field>
                          {touched.locationID && errors.locationID ? (
                            <div
                              className="license-popup-error-message"
                              role="alert"
                            >
                              {errors.locationID}
                            </div>
                          ) : null}
                          <div className="license-popup-icon">
                            <img
                              src={cityIcon}
                              title="City Icon"
                              alt="City Icon"
                            />
                          </div>
                        </div>

                        {/* Dates section*/}
                        <div className="license-popup-date-picker-section">
                          <div className="license-popup-date-picker-item">
                            <label className="license-popup-date-picker-section-label">
                              Valid From:
                            </label>
                            <DatePicker
                              name="validFrom"
                              selected={licenseState.validFrom}
                              onChange={(date: Date) =>
                                handleValidFromChange(date)
                              }
                              isClearable
                              dateFormat="dd.MM.yyyy"
                              closeOnScroll={true}
                              showYearDropdown
                              scrollableMonthYearDropdown
                              locale={de}
                              
                            />
                          </div>
                          <div className="license-popup-date-picker-item">
                            <label className="license-popup-date-picker-section-label">
                              Valid To:
                            </label>
                            <DatePicker
                              name="validTo"
                              selected={licenseState.validTo}
                              onChange={(date: Date) =>
                                handleValidToChange(date)
                              }
                              isClearable
                              dateFormat="dd.MM.yyyy"
                              closeOnScroll={true}
                              minDate={Date.now() as any}
                              showYearDropdown
                              scrollableMonthYearDropdown
                              locale={de}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Submit Button: Create or Edit */}
                    <div className="license-submit-button-container">
                      {!props.licenseID ? (
                        <Button
                          size="small"
                          text="Create"
                          icon="add-icon"
                          type="submit"
                          onClick={handleSubmit}
                        ></Button>
                      ) : (
                        <Button
                          icon="edit-icon"
                          size="small"
                          text="Edit"
                          type="submit"
                          onClick={handleSubmit}
                        ></Button>
                      )}
                    </div>
                  </Form>
                </>
              );
            }}
          </Formik>
        </div>
      </div>
    </>
  );
}

export default LicensePopup;
