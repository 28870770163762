export const GET_ALL_PRINT_GROUP_SUCCESS = "GET_ALL_PRINT_GROUP_SUCCESS";
export const getAllPrintGroupSuccess = (printGroups: any) => ({
  type: GET_ALL_PRINT_GROUP_SUCCESS,
  payload: printGroups,
});

export const CREATE_PRINT_GROUP_SUCCESS = "CREATE_PRINT_GROUP_SUCCESS";
export const creatPrintGroupSuccess = (printGroup: any) => ({
  type: CREATE_PRINT_GROUP_SUCCESS,
  payload: printGroup
})

export const EDIT_PRINT_GROUP_SUCCESS = "EDIT_PRINT_GROUP_SUCCESS";
export const editPrintGroupSuccess = (updatedPrintGroup: any) => ({
  type: EDIT_PRINT_GROUP_SUCCESS,
  payload: updatedPrintGroup,
});

export const DELETE_PRINT_GROUP_SUCCESS = "DELETE_PRINT_GROUP_SUCCESS";
export const deletePrintGroupSuccess = (printGroupID: any) => ({
  type: DELETE_PRINT_GROUP_SUCCESS,
  payload: printGroupID,
});
