export const SET_MESSAGE = "SET_MESSAGE";
export const setMessage = (messageText: any, messageType: any) => ({
  type: SET_MESSAGE,
  payload: { messageText, messageType },
});

export const CLEAR_MESSAGE = "CLEAR_MESSAGE";
export const clearMessage = () => ({
  type: CLEAR_MESSAGE,
});
