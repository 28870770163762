import "./CompaniesPopup.css";

import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";

//thunk
import {
  createCommissionedCostumerRequest,
  editCommissionedCostumerRequest,
} from "store/thunks/commissionedCostumerThunk";

//selector
import { getAllCommissionedCostumer } from "store/selectors/commissionedCostumerSelector";

//validation
import { CommissionedCostumerSchema } from "validations/adminValidations";

//images
import close from "assets/icons/close-button.png";
import nameIcon from "assets/icons/name-icon.png"
import streetIcon from "assets/icons/street-icon.png"
import cityIcon from "assets/icons/city-icon.png"
import postalCodeIcon from "assets/icons/postal-code-icon.png"
import contactPersonIcon from "assets/icons/contact-person-icon.png"
import departmentIcon from "assets/icons/task-sequence-popup.png"

//components
import Button from "components/Button/Button";

type CompaniesProps = {
  commissionedCostumerID?: any;
  hidePopUp?: any;
};

function CompaniesPopup(props: CompaniesProps) {
  const dispatch = useDispatch<any>();

  //redux state
  let commissionedCostumers = useSelector((state: any) =>
    getAllCommissionedCostumer(state)
  );

  //local state
  const [commissionedCostumerState, setCommissionedCostumerState] = useState({
    name: "",
    contactPerson: "",
    department: "",
    street: "",
    postalCode: "",
    city: "",
  });

  useEffect(() => {
    props.commissionedCostumerID &&
      getCommissionedCostumer(props.commissionedCostumerID);
    // because we are in a popup we should stop scrolling so we add this classes
    let popupContainer = document.getElementById(
      "company-popup-main-container"
    ) as any;
    popupContainer && popupContainer.classList.remove("hide");
    document.body.classList.add("company-popup-overflow-hidden");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.commissionedCostumerID]);

  //in case of edit get the value of the commissioned costumer and save it in the state
  const getCommissionedCostumer = (commissionedCostumerID: any) => {
    let commissionedCostumer = commissionedCostumers.filter(
      (commissionedCostumer: any) =>
        commissionedCostumer.id === commissionedCostumerID
    );

    setCommissionedCostumerState({
      ...commissionedCostumerState,
      name: commissionedCostumer[0].name,
      contactPerson: commissionedCostumer[0].contactPerson,
      department: commissionedCostumer[0].department,
      street: commissionedCostumer[0].street,
      postalCode: commissionedCostumer[0].postalCode,
      city: commissionedCostumer[0].city,
    });
  };

  const handleInputChange = (event: any) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    setCommissionedCostumerState({
      ...commissionedCostumerState,
      [name]: value,
    });
  };

  const onSubmit = (
    fields: any,
    { setStatus, setSubmitting }: { setStatus: any; setSubmitting: any }
  ) => {
      const data = {
          name: commissionedCostumerState.name,
          contactPerson: commissionedCostumerState.contactPerson,
          department: commissionedCostumerState.department,
          street: commissionedCostumerState.street,
          postalCode: commissionedCostumerState.postalCode,
          city: commissionedCostumerState.city
      }
      
      if (!props.commissionedCostumerID) {
          dispatch(createCommissionedCostumerRequest(data))
          props.hidePopUp()
      } else {
          dispatch(editCommissionedCostumerRequest(props.commissionedCostumerID, data))
          props.hidePopUp()
      }
  };

  const handleSubmit = () => {
    document.body.classList.remove("company-popup-overflow-hidden");
  };

  return (
    <>
      <div
        className="company-popup-main-container"
        id="company-popup-main-container"
      >
        <div className="company-popup-overlay" onClick={props.hidePopUp}></div>
        <div className="company-container">
          {/* Header: title and close button */}
          <div className="company-top-section">
            {props.commissionedCostumerID ? (
              <h1>Edit Company</h1>
            ) : (
              <h1> Create New Company</h1>
            )}
            <img
              className="company-popup-close"
              src={close}
              onClick={props.hidePopUp}
              alt="close Popup"
              title="close Popup"
            />
          </div>

          <Formik
            initialValues={commissionedCostumerState}
            validationSchema={CommissionedCostumerSchema}
            onSubmit={onSubmit}
            enableReinitialize
          >
            {({ values, errors, touched, isSubmitting, setFieldValue }) => {
              return (
                <>
                  <Form>
                    <>
                      <div className="company-popup-form-container">
                        {/* Section 1: Company name street and City section */}
                        <div className="company-fields-section">
                          {/* name input */}
                          <h3 className="company-popup-input-label">Name</h3>
                          <div className="company-popup-input">
                            <div className="company-popup-input">
                              <Field
                                className="my-input"
                                type="text"
                                id="name"
                                name="name"
                                value={commissionedCostumerState.name}
                                placeholder="Name"
                                onChange={handleInputChange}
                              />
                              {touched.name && errors.name ? (
                                <div
                                  className="company-popup-error-message"
                                  role="alert"
                                >
                                  {errors.name}
                                </div>
                              ) : null}
                            </div>
                            <div className="company-icon">
                              <img src={nameIcon} alt="Name Icon" />
                            </div>
                          </div>
                          {/* Street input */}
                          <h3 className="company-popup-input-label">Street</h3>
                          <div className="company-popup-input">
                            <div className="company-popup-input">
                              <Field
                                className="my-input"
                                type="text"
                                id="street"
                                name="street"
                                value={commissionedCostumerState.street}
                                placeholder="Street"
                                onChange={handleInputChange}
                              />
                              {touched.street && errors.street ? (
                                <div
                                  className="company-popup-error-message"
                                  role="alert"
                                >
                                  {errors.street}
                                </div>
                              ) : null}
                            </div>
                            <div className="company-icon">
                              <img src={streetIcon} alt="Street Icon" />
                            </div>
                          </div>
                          {/* city input */}
                          <h3 className="company-popup-input-label">City</h3>
                          <div className="company-popup-input">
                            <div className="company-popup-input">
                              <Field
                                className="my-input"
                                type="text"
                                id="city"
                                name="city"
                                value={commissionedCostumerState.city}
                                placeholder="City"
                                onChange={handleInputChange}
                              />
                              {touched.city && errors.city ? (
                                <div
                                  className="company-popup-error-message"
                                  role="alert"
                                >
                                  {errors.city}
                                </div>
                              ) : null}
                            </div>
                            <div className="company-icon">
                              <img src={cityIcon} alt="City Icon" />
                            </div>
                          </div>
                        </div>

                        {/* Section 1: Postal code contact person and department section */}
                        <div className="company-fields-section">
                          {/* Postal code input */}
                          <h3 className="company-popup-input-label">
                            Postal Code
                          </h3>
                          <div className="company-popup-input">
                            <div className="company-popup-input">
                              <Field
                                className="my-input"
                                type="text"
                                id="postalCode"
                                name="postalCode"
                                value={commissionedCostumerState.postalCode}
                                placeholder="Postal Code"
                                onChange={handleInputChange}
                              />
                              {touched.postalCode && errors.postalCode ? (
                                <div
                                  className="company-popup-error-message"
                                  role="alert"
                                >
                                  {errors.postalCode}
                                </div>
                              ) : null}
                            </div>
                            <div className="company-icon">
                              <img src={postalCodeIcon} alt="Postal Code Icon" />
                            </div>
                          </div>
                          {/* Contact person input */}
                          <h3 className="company-popup-input-label">
                            Contact Person
                          </h3>
                          <div className="company-popup-input">
                            <div className="company-popup-input">
                              <Field
                                className="my-input"
                                type="text"
                                id="contactPerson"
                                name="contactPerson"
                                value={commissionedCostumerState.contactPerson}
                                placeholder="Contact Person"
                                onChange={handleInputChange}
                              />
                              {touched.contactPerson && errors.contactPerson ? (
                                <div
                                  className="company-popup-error-message"
                                  role="alert"
                                >
                                  {errors.contactPerson}
                                </div>
                              ) : null}
                            </div>
                            <div className="company-icon">
                              <img src={contactPersonIcon} alt="Contact Person Icon" />
                            </div>
                          </div>
                          {/* Department input */}
                          <h3 className="company-popup-input-label">Department</h3>
                          <div className="company-popup-input">
                            <div className="company-popup-input">
                              <Field
                                className="my-input"
                                type="text"
                                id="department"
                                name="department"
                                value={commissionedCostumerState.department}
                                placeholder="Department"
                                onChange={handleInputChange}
                              />
                              {touched.department && errors.department ? (
                                <div
                                  className="company-popup-error-message"
                                  role="alert"
                                >
                                  {errors.department}
                                </div>
                              ) : null}
                            </div>
                            <div className="company-icon">
                              <img src={departmentIcon} alt="Department Icon" />
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Submit Button: Create or Edit */}
                      <div className="company-submit-button-container">
                        {!props.commissionedCostumerID ? (
                          <Button
                            size="small"
                            text="Create"
                            icon="add-icon"
                            type="submit"
                            onClick={handleSubmit}
                          ></Button>
                        ) : (
                          <Button
                            icon="edit-icon"
                            size="small"
                            text="Edit"
                            type="submit"
                            onClick={handleSubmit}
                          ></Button>
                        )}
                      </div>
                    </>
                  </Form>
                </>
              );
            }}
          </Formik>
        </div>
      </div>
    </>
  );
}

export default CompaniesPopup;
