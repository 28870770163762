import "./RepairOverviewPage.css";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// thunks
import { getWorkplacePendingActionsRequest } from "store/thunks/actionThunk";

// selector
import { getWorkplacePendingActionsSelector } from "store/selectors/actionSelector";

// components
import Button from "components/Button/Button";
import Loader from "components/Loader/Loader";
import MessageNotification from "components/MessageNotification/MessageNotification";

function RepairOverviewPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // redux state
  const workplacePendingActions = useSelector((state: any) =>
    getWorkplacePendingActionsSelector(state)
  );
  const { loading } = useSelector((state: any) => state.LoadingReducer);
  const { messageText, messageType } = useSelector(
    (state: any) => state.MessageReducer
  );

  useEffect(() => {
    // change the browser tab title
    document.title = "WA - Repair Overview";
    // get all workplacePendingActions thunk request
      dispatch(getWorkplacePendingActionsRequest());
  }, [dispatch]);

  // go back to previous page
  const goBack = () => {
    navigate("/");
  };

  return (
    <>
      {messageText ? (
        <MessageNotification
          messageText={messageText}
          messageType={messageType}
        />
      ) : null}

      {loading ? (
        <Loader />
      ) : (
        <div className="repair-overview-page-main-container">
          <div className="repair-overview-page-top-section">
            {/* Back button */}
            <div className="repair-overview-page-back-btn" onClick={goBack}>
              <Button size="small" icon="back-icon" text="Back" />
            </div>
            {/* Page title */}
            <h2 className="repair-overview-page-title">Repair</h2>
          </div>

          {/* table of actions */}
          <div className="repair-overview-page-workplacePendingAction-container">
            <h2 className="repair-overview-page-workplacePendingAction-item-cell-header">
              Damage #
            </h2>

            {/* damage number and characteristics names */}
            {workplacePendingActions.map(
              (workplacePendingAction: any, index: any) => {
                return (
                  <div
                    key={index}
                    className="repair-overview-page-workplacePendingAction-item-container"
                  >
                    {/* number and characteristic names container*/}
                    <div className="repair-overview-page-workplacePendingAction-item-number-name-section">
                      {/* damage numbers */}
                      <h2
                        className={
                          index % 2 === 0
                            ? "repair-overview-page-workplacePendingAction-item-cell  background-blue"
                            : "repair-overview-page-workplacePendingAction-item-cell"
                        }
                      >
                        #{workplacePendingAction.damageNumber}
                      </h2>

                      {/* characteristics names */}
                      <div className="repair-overview-page-workplacePendingAction-characteristic-names-container">
                        {workplacePendingAction.characteristics.map(
                          (characteristic: any, index2: any) => {
                            return (
                              <h2
                                key={index2}
                                className={
                                  index % 2 === 0
                                    ? "repair-overview-page-workplacePendingAction-item-cell background-blue"
                                    : "repair-overview-page-workplacePendingAction-item-cell"
                                }
                              >
                                {characteristic.name}
                              </h2>
                            );
                          }
                        )}
                      </div>
                    </div>

                    {/* buttons container */}
                    <div className="repair-overview-page-buttons-container">
                      <Button
                        text="Harm Sight"
                        size="medium"
                        icon="harm-sight"
                        onClick={() =>
                          navigate(`/harm-sight`, {
                            state: workplacePendingAction,
                          })
                        }
                      />
                      <Button
                        text="Repair"
                        size="medium"
                        icon="small-repair-icon"
                        onClick={() => navigate(`/repair/${index}`)}
                      />
                    </div>
                  </div>
                );
              }
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default RepairOverviewPage;
