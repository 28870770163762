import "./TaskSequencePopup.css";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";

//images
import close from "assets/icons/close-button.png";

//component
import Button from "components/Button/Button";

//validation
import { TaskSequenceValidation } from "validations/adminValidations";

//thunks
import {
  createTaskSequenceRequest,
  editTaskSequenceRequest,
} from "store/thunks/taskSequenceThunk";

//selectors
import { getAllCharacteristicGroups } from "store/selectors/characteristicsGroupSelector";
import { getAllDamagedItemModels } from "store/selectors/damagedItemModelSelector";
import { getAllPlacements } from "store/selectors/placementSelector";
import { getAllTasksSelector } from "store/selectors/taskSelector";

type TaskSequencePopupProps = {
  hidePopUp?: any;
  taskSequence?: any;
};

function TaskSequencePopup(props: TaskSequencePopupProps) {
  const dispatch = useDispatch<any>();

  //redux state
  const characteristicGroups = useSelector((state: any) =>
    getAllCharacteristicGroups(state)
  );
  const damagedItemModels = useSelector((state: any) =>
    getAllDamagedItemModels(state)
  );
  const placements = useSelector((state: any) => getAllPlacements(state));
  const tasks = useSelector((state: any) => getAllTasksSelector(state));

  //local state
  const [taskSequenceState, setTaskSequenceState] = useState({
    name: "",
    sequenceChooserID: "",
    tasksID: [],
  });
  const [taskSequenceTypeState, setTaskSequenceTypeState] = useState("");
  const [assignedTasks, setAssignedTasks] = useState<any>("");

  // here we convert the naming from react select defaults to options as we need the naming in our state
  // and DB
  let options: any;
  // let selectedCharacteristics: any;
  //get characteristics options
  if (tasks) {
    options = tasks.map(function (item: any) {
      return { value: item.id, label: item.name };
    });
  }

  useEffect(() => {
    props.taskSequence.name && getTaskSequenceData();
    // because we are in a popup we should stop scrolling so we add this classes
    let popupContainer = document.getElementById(
      "task-sequence-popup-main-container"
    ) as any;
    popupContainer && popupContainer.classList.remove("hide");
    document.body.classList.add("task-sequence-popup-overflow-hidden");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Get task Sequence Data in case of Edit
  const getTaskSequenceData = () => {
    //get the selected tasks to show them in the dropdown
    const saved_options = props.taskSequence.taskSequences.map(function (
      item: any
    ) {
      return { value: item.taskID, label: item.taskName };
    });
    // convert the data to be accepted by the react select
    const saved_functions = [] as any;
    saved_options.forEach((option: any) => {
      saved_functions.push(option.value);
    });
    // set the data in the react select state
    setAssignedTasks(saved_options);

    let tasks: any = [];
    props.taskSequence.taskSequences.map((task: any) => {
      return tasks.push(task.taskID);
    });

    setTaskSequenceState({
      ...taskSequenceState,
      name: props.taskSequence.name,
      sequenceChooserID: props.taskSequence.taskSequences[0].sequenceChooserID,
      tasksID: tasks,
    });
  };

  // When select multiple tasks we save it in the state
  const handleTasksSelect = (event: any) => {
    let values = Array.isArray(event) ? event.map((x: any) => x) : [];
    // let options: any;
    let test = [] as any;
    if (values) {
      setAssignedTasks(values);
      values.map((val: any) => {
        return test.push(val.value);
      });

      setTaskSequenceState({
        ...taskSequenceState,
        tasksID: test,
      });
    }
  };

  // When selecting a the type we save it in the state
  const handleTaskSequenceTypeChange = (event: any) => {
    setTaskSequenceTypeState(event.target.value);
  };

  const handleSequenceChooserIdChange = (event: any) => {
    setTaskSequenceState({
      ...taskSequenceState,
      sequenceChooserID: event.target.value,
    });
  };

  const handleNameInputChange = (event: any) => {
    setTaskSequenceState({
      ...taskSequenceState,
      name: event.target.value,
    });
  };

  const handleSubmit = () => {
    document.body.classList.remove("task-sequence-popup-overflow-hidden");
  };

  const onSubmit = (
    fields: any,
    { setStatus, setSubmitting }: { setStatus: any; setSubmitting: any }
  ) => {
    let taskSequenceArray: any = [];
    taskSequenceState.tasksID.map((task: any, index: any) => {
      let taskSequence = {
        sequencePosition: index + 1,
        taskID: task,
      };
      return taskSequenceArray.push(taskSequence);
    });
    const data = {
      name: taskSequenceState.name,
      sequenceChooserID: parseInt(taskSequenceState.sequenceChooserID),
      taskSequences: taskSequenceArray,
    };
    if (props.taskSequence.name) {
      dispatch(editTaskSequenceRequest(data));
      props.hidePopUp();
    } else {
      dispatch(createTaskSequenceRequest(data));
      props.hidePopUp();
    }
  };

  return (
    <>
      <div
        className="task-sequence-popup-main-container"
        id="task-sequence-popup-main-container"
      >
        <div
          className="task-sequence-popup-overlay"
          onClick={props.hidePopUp}
        ></div>
        <div className="task-sequence-container">
          {/* Header: title and close button */}
          <div className="task-sequence-top-section">
            {props.taskSequence ? (
              <h1>Edit Task Sequence</h1>
            ) : (
              <h1>Create New Task Sequence</h1>
            )}

            <img
              className="task-sequence-popup-close"
              src={close}
              onClick={props.hidePopUp}
              alt="close Popup"
              title="close Popup"
            />
          </div>

          <Formik
            initialValues={taskSequenceState}
            validationSchema={TaskSequenceValidation}
            onSubmit={onSubmit}
            enableReinitialize
          >
            {({ values, errors, touched, isSubmitting, setFieldValue }) => {
              return (
                <Form>
                  <div className="task-sequence-popup-form-container">
                    {/* Name Section */}
                    <h3 className="task-sequence-popup-input-label">Name</h3>
                    <div className="task-sequence-popup-input">
                      <Field
                        className="name-input"
                        type="text"
                        id="name"
                        name="name"
                        value={taskSequenceState.name}
                        placeholder="Name"
                        onChange={handleNameInputChange}
                      />
                      {touched.name && errors.name ? (
                        <div
                          className="task-sequence-popup-error-message"
                          role="alert"
                        >
                          {errors.name}
                        </div>
                      ) : null}
                      <div className="name-icon"></div>
                    </div>

                    {/* Task sequence type section */}
                    {!props.taskSequence.name ? (
                      <>
                        <h3 className="task-sequence-popup-input-label">
                          Task Sequence Type
                        </h3>
                        <div className="task-sequence-popup-input">
                          <Field
                            as="select"
                            className="my-input"
                            value={taskSequenceTypeState}
                            onChange={handleTaskSequenceTypeChange}
                          >
                            <option value="">
                              Select a task sequence type
                            </option>
                            <option value="dismantle">Dismantle</option>
                            <option value="repair-instruction">
                              Repair Instruction
                            </option>
                            <option value="view-instruction">
                              View Instruction
                            </option>
                          </Field>
                          <div className="category-icon"></div>
                        </div>
                      </>
                    ) : null}

                    {/* Sequence Chooser  */}
                    {!props.taskSequence.name ? (
                      <>
                        {taskSequenceTypeState === "dismantle" ? (
                          <h3 className="task-sequence-popup-input-label">
                            Characteristic Group
                          </h3>
                        ) : taskSequenceTypeState === "repair-instruction" ? (
                          <h3 className="task-sequence-popup-input-label">
                            Damaged Item Model
                          </h3>
                        ) : taskSequenceTypeState === "view-instruction" ? (
                          <h3 className="task-sequence-popup-input-label">
                            Placements
                          </h3>
                        ) : (
                          <h3 className="task-sequence-popup-input-label">
                            &nbsp;
                          </h3>
                        )}
                        <div className="task-sequence-popup-input">
                          <Field
                            as="select"
                            className="my-input"
                            id="sequenceChooserID"
                            name="sequenceChooserID"
                            onChange={handleSequenceChooserIdChange}
                          >
                            {taskSequenceTypeState === "dismantle" ? (
                              <>
                                <option></option>
                                {characteristicGroups &&
                                  characteristicGroups.map(
                                    (characteristicGroup: any, index: any) => (
                                      <>
                                        <option
                                          value={
                                            characteristicGroup
                                              .sequenceChoosers[0].id
                                          }
                                          key={index}
                                        >
                                          {characteristicGroup.name}
                                        </option>
                                      </>
                                    )
                                  )}
                              </>
                            ) : taskSequenceTypeState ===
                              "repair-instruction" ? (
                              <>
                                <option></option>
                                {damagedItemModels.map(
                                  (damagedItemModel: any, index: any) => (
                                    <>
                                      <option
                                        value={
                                          damagedItemModel.sequenceChooser.id
                                        }
                                        key={index}
                                      >
                                        {damagedItemModel.placementName}{" "}
                                        {damagedItemModel.damageType.name}
                                      </option>
                                    </>
                                  )
                                )}
                              </>
                            ) : taskSequenceTypeState === "view-instruction" ? (
                              <>
                                <option></option>
                                {placements.map(
                                  (placement: any, index: any) => (
                                    <>
                                      <option
                                        value={placement.sequenceChooser.id}
                                        key={index}
                                      >
                                        {placement.name}
                                      </option>
                                    </>
                                  )
                                )}
                              </>
                            ) : (
                              <option></option>
                            )}
                          </Field>
                          {touched.sequenceChooserID &&
                          errors.sequenceChooserID ? (
                            <div
                              className="task-sequence-popup-error-message"
                              role="alert"
                            >
                              {errors.sequenceChooserID}
                            </div>
                          ) : taskSequenceTypeState === "" ? (
                            <div
                              className="task-sequence-popup-error-message"
                              role="alert"
                            >
                              Select a task sequence type first
                            </div>
                          ) : null}
                          <div className="sequence-chooser-icon"></div>
                        </div>
                      </>
                    ) : null}

                    {/* Tasks */}
                    <h3 className="task-sequence-popup-input-label">Task</h3>
                    <div className="task-sequence-popup-input">
                      <div className="task-sequence-popup-multiTask-select">
                        <Select
                          name="functions"
                          options={tasks && options}
                          onChange={handleTasksSelect}
                          closeMenuOnSelect={false}
                          value={assignedTasks ? assignedTasks : null}
                          isMulti
                          placeholder="Select Task"
                          maxMenuHeight={200}
                        />
                      </div>
                      {touched.tasksID && errors.tasksID ? (
                        <div
                          className="task-sequence-popup-error-message"
                          role="alert"
                        >
                          {errors.tasksID}
                        </div>
                      ) : null}
                      <div className="task-icon"></div>
                    </div>

                    {/* Submit Button: Create or Edit */}
                    {props.taskSequence.name ? (
                      <div className="task-sequence-submit-button-container">
                        <Button
                          icon="edit-icon"
                          size="small"
                          text="Edit"
                          type="submit"
                          onClick={handleSubmit}
                        ></Button>
                      </div>
                    ) : (
                      <div className="task-sequence-submit-button-container">
                        <Button
                          icon="add-icon"
                          size="small"
                          text="Add"
                          type="submit"
                          onClick={handleSubmit}
                        ></Button>
                      </div>
                    )}
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </>
  );
}

export default TaskSequencePopup;
