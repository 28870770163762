// actions
import {
  GET_ALL_PLACEMENTS_SUCCESS,
  GET_PLACEMENT_BY_CHARACTERISTIC_GROUP_ID_SUCCESS,
  CREATE_PLACEMENT_SUCCESS,
  DELETE_PLACEMENT_SUCCESS,
  UPDATE_PLACEMENT_SUCCESS,
} from "../actions/placementAction";

const initialState = [] as any;

const DamagePlacementReducer = (state = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_PLACEMENTS_SUCCESS:
      return payload;

    case GET_PLACEMENT_BY_CHARACTERISTIC_GROUP_ID_SUCCESS:
      return payload;

    case CREATE_PLACEMENT_SUCCESS:
      return [...state, payload];

    case UPDATE_PLACEMENT_SUCCESS:
      let updatePlacements = [...state];
      updatePlacements.map((placement: any, index: any) => {
        if (placement.id === payload.id) {
          return (updatePlacements[index] = payload);
        } else return updatePlacements;
      });
      return updatePlacements;

    case DELETE_PLACEMENT_SUCCESS:
      let deletePlacements = [...state];
      deletePlacements = state.filter(
        (placement: any) => placement.id !== payload
      );
      return deletePlacements;

    default:
      return state;
  }
};
export default DamagePlacementReducer;
