import { httpCommon } from "./http-common";
import authHeader from "./authHeader";

const getActionsFilteredByDate = (startDate: any, endDate: any) => {
  return httpCommon.get(
    `/Action/GetActionsFilteredByDate?startDate=${startDate}&endDate=${endDate}`,
    {
      headers: authHeader() as any,
    }
  );
};

const getWorkplacePendingActions = () => {
  return httpCommon.get(`/Action/GetWorkplacePendingActions/`, {
    headers: authHeader() as any,
  });
};

const getAllActions = () => {
  return httpCommon.get("/Action", { headers: authHeader() as any });
};

const createAction = (data: any) => {
  return httpCommon.post("/Action", data, { headers: authHeader() as any });
};

const updateAction = (data: any) => {
  return httpCommon.put(`/Action/`, data, {
    headers: authHeader() as any,
  });
};

const deleteAction = (actionID: number) => {
  return httpCommon.delete(`/Action/${actionID}`, {
    headers: authHeader() as any,
  });
};

const ActionService = {
  getActionsFilteredByDate,
  getWorkplacePendingActions,
  getAllActions,
  createAction,
  updateAction,
  deleteAction,
};

export default ActionService;
