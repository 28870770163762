import "./LandingPage.css";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// components
import Button from "components/Button/Button";
import MessageNotification from "components/MessageNotification/MessageNotification";

// version
import { version } from "../../config";

function LandingPage() {
  const navigate = useNavigate();

  // redux states
  const { messageText, messageType } = useSelector(
    (state: any) => state.MessageReducer
  );

  useEffect(() => {
    document.title = "Work Assist - Landing Page";
  }, []);

  return (
    <>
      {messageText ? (
        <MessageNotification
          messageText={messageText}
          messageType={messageType}
        />
      ) : null}

      <div className="landingPage-wrapper">
        <Button
          size="very-large"
          icon="start-icon"
          text="Erfassung Starten"
          onClick={() => navigate("/select-characteristic")}
        />
        <p>{version}</p>
        <Button
          size="very-large"
          icon="repair-icon"
          text="Reparatur"
          onClick={() => navigate("/repair-overview")}
        />
      </div>
    </>
  );
}

export default LandingPage;
