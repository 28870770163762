import "./PlacementPage.css";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// thunks requests
import { getAllDamageTypesRequest } from "store/thunks/damageTypeThunk";
import { getAllCharacteristicsGroupRequest } from "store/thunks/characteristicsGroupThunk";
import {
  getAllPlacementsRequest,
  deletePlacementRequest,
} from "store/thunks/placementThunk";

// selectors
import { getAllPlacements } from "store/selectors/placementSelector";
import { getAllDamageTypesSelector } from "store/selectors/damageTypeSelector";
import { getAllCharacteristicGroups } from "store/selectors/characteristicsGroupSelector";

//components
import Loader from "components/Loader/Loader";
import Button from "components/Button/Button";
import PlacementCard from "./PlacementCard/PlacementCard";
import DeletePopup from "components/DeletePopup/DeletePopup";
import PlacementPopup from "./PlacementPopup/PlacementPopup";
import MessageNotification from "components/MessageNotification/MessageNotification";

function DamagePlacementPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // redux state
  const placements = useSelector((state: any) => getAllPlacements(state));
  const { loading } = useSelector((state: any) => state.LoadingReducer);
  const { messageText, messageType } = useSelector(
    (state: any) => state.MessageReducer
  );
  const characteristicGroups = useSelector((state: any) =>
    getAllCharacteristicGroups(state)
  );
  const damageTypes = useSelector((state: any) =>
    getAllDamageTypesSelector(state)
  );

  // local states
  const [isDeletePopup, setDeletePopup] = useState({
    isDeletePopup: false,
    placementID: "",
  });
  // create and edit category popup
  const [isPlacementPopup, setPlacementPopup] = useState({
    isPlacementPopup: false,
    placementID: "",
  });

  useEffect(() => {
    // change the browser tab title
    document.title = "WA - Placements";
    // get all categories thunk request
    placements.length < 1 && dispatch(getAllPlacementsRequest());
    damageTypes.length < 1 && dispatch(getAllDamageTypesRequest());
    characteristicGroups.length < 1 && dispatch(getAllCharacteristicsGroupRequest())
  }, [dispatch, placements, characteristicGroups, damageTypes]);

  // go back to previous page
  const goBack = () => {
    navigate(-1);
  };

  // this function hide popups
  const hidePopUp = () => {
    // hide the create edit popup
    setPlacementPopup({
      ...isPlacementPopup,
      isPlacementPopup: false,
      placementID: "",
    });
    // hide the delete popup
    setDeletePopup({
      isDeletePopup: false,
      placementID: "",
    });
    let popupContainer = document.getElementById(
      "placement-popup-main-container"
    ) as any;
    popupContainer && popupContainer.classList.add("hide");
    document.body.classList.remove("placement-overflow-hidden");
  };

  return (
    <>
      {messageText ? (
        <MessageNotification
          messageText={messageText}
          messageType={messageType}
        />
      ) : null}

      {isDeletePopup.isDeletePopup ? (
        <DeletePopup
          hidePopUp={hidePopUp}
          id={isDeletePopup.placementID}
          deleteRequest={deletePlacementRequest}
        />
      ) : null}

      {isPlacementPopup.isPlacementPopup ? (
        <PlacementPopup
          placementID={isPlacementPopup.placementID}
          hidePopUp={hidePopUp}
        />
      ) : null}

      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="placement-page-container">
            <div className="placement-page-top-section">
              {/* Back button */}
              <div className="placement-page-back-btn" onClick={goBack}>
                <Button size="small" icon="back-icon" text="Back" />
              </div>

              {/* Page title */}
              <h2 className="placement-page-title">Placements</h2>

              {/* Create new button */}
              <div className="create-new-placement-div">
                <Button
                  text="New Placement"
                  size="medium"
                  icon="add-icon"
                  onClick={() =>
                    setPlacementPopup({
                      ...isPlacementPopup,
                      isPlacementPopup: true,
                    })
                  }
                />
              </div>
            </div>

            {/* Categories list */}
            <div className="placement-list-container">
              {placements &&
                placements.map((placement: any, index: any) => (
                  <PlacementCard
                    key={index}
                    placement={placement}
                    handleEditCategory={() =>
                      setPlacementPopup({
                        ...isPlacementPopup,
                        isPlacementPopup: true,
                        placementID: placement.id,
                      })
                    }
                    handleDeleteCategory={() => {
                      setDeletePopup({
                        isDeletePopup: true,
                        placementID: placement.id,
                      });
                    }}
                  />
                ))}
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default DamagePlacementPage;
