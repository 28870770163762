export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const loginSuccess = (user: any) => ({
  type: LOGIN_SUCCESS,
  payload: user,
});

export const LOGOUT = "LOGOUT";
export const logoutAction = () => ({
  type: LOGOUT,
});
