import "./CharacteristicCard.css";

//images
import image from "assets/images/defaultPicture.png";

//import Button from components
import Button from "../../../../components/Button/Button";

//Props type
type characteristicCardProps = {
  characteristicImage?: any;
  characteristic?: any;
  index?: any;
  key?: any;
  handleEditCharacteristicPopup?: any;
  handleDeleteCharacteristicPopup?: any;
};

function CharacteristicCard(props: characteristicCardProps) {
  return (
    <div className="characteristic-card-item" key={props.index}>
      {/* characteristic title */}
      <div className="characteristic-card-title">
        <h1>{props.characteristic?.name}</h1>
      </div>
      {props.characteristicImage &&
        props.characteristicImage
          .filter(
            (item: any) => item?.imageID === props.characteristic?.imageID
          )
          .map(function (image: any) {
            props.characteristic.image = image.image;
            return null;
          })}

      {/* Card content */}
      <div className="characteristic-item-content">
        {/* characteristic Image */}
        <div className="characteristic-card-image-container">
          <img
            className="characteristic-image"
            src={
              props.characteristic?.image !== undefined
                ? "data:image/jpeg;base64," + props.characteristic?.image
                : image
            }
            alt={props.characteristic?.name}
            title={props.characteristic?.name}
          />
        </div>

        {/* characteristic print group */}
        <div className="characteristic-card-print-group">
          <h3>
            {props.characteristic?.printGroup
              ? props.characteristic?.printGroup?.name
              : "No Print Group"}
          </h3>
        </div>

        {/* edit and delete buttons container */}
        <div className="characteristic-card-buttons-container">
          <Button
            text="Edit"
            size="small"
            icon="edit-icon"
            onClick={() =>
              props.handleEditCharacteristicPopup(props.characteristic.id)
            }
          />
          <Button
            text="Delete"
            size="small"
            icon="delete-icon"
            redBtn={true}
            onClick={() =>
              props.handleDeleteCharacteristicPopup(props.characteristic.id)
            }
          />
        </div>

        {/* characteristic Following category */}
        <div className="characteristic-card-following-category">
          <h2>
            {props.characteristic?.followingCategory
              ? props.characteristic?.followingCategory?.name
              : "No Following Category"}
          </h2>
        </div>
      </div>
    </div>
  );
}

export default CharacteristicCard;
