import { useSelector } from "react-redux";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

// General Pages
import Login from "pages/Login/Login";
import LandingPage from "pages/LandingPage/LandingPage";

// SensrecAdmin Pages
import UsersPage from "pages/SensrecAdmin/UserPage/UsersPage";
import LocationsPage from "pages/SensrecAdmin/LocationPage/LocationsPage";
import LicensesPage from "pages/SensrecAdmin/LicensePage/LicensesPage";
import WorkplaceSensrecAdminPage from "pages/SensrecAdmin/WorkplaceSensrecAdminPage/WorkplaceSensrecAdminPage"


// Admin Pages
import TasksPage from "pages/Admin/TasksPage/TasksPage";
import StatisticPage from "pages/Admin/StatisticPage/StatisticPage";
import WorkplacePage from "pages/Admin/WorkplacePage/WorkplacePage";
import CompaniesPage from "pages/Admin/CompaniesPage/CompaniesPage";
import CategoriesPage from "pages/Admin/CategoriesPage/CategoriesPage";
import DamageTypePage from "pages/Admin/DamageTypePage/DamageTypePage";
import ViewDamagesPage from "pages/Admin/ViewDamagesPage/ViewDamagesPage";
import ArticleGroupPage from "pages/Admin/ArticleGroupPage/ArticleGroupPage";
import TaskSequencePage from "pages/Admin/TaskSequencePage/TaskSequencePage";
import DamagePlacementPage from "pages/Admin/DamagePlacementPage/PlacementPage";
import CharacteristicsPage from "pages/Admin/CharacteristicsPage/CharacteristicsPage";
import AcquisitionOverviewPage from "pages/Admin/AcquisitionOverviewPage/AcquisitionOverviewPage";
import CharacteristicsGroupPage from "pages/Admin/CharacteristicsGroupPage/CharacteristicsGroupPage";


// User Pages
import RepairPage from "pages/User/RepairPage/RepairPage";
import HarmSightPage from "pages/User/HarmSightPage/HarmSightPage";
import RepairOverviewPage from "pages/User/RepairOverviewPage/RepairOverviewPage";
import CaptureArticlePage from "pages/User/CaptureArticlePage/CaptureArticlePage";
import AcquisitionOverview from "pages/User/AcquisitionOverview/AcquisitionOverview";
import SelectCharacteristicPage from "pages/User/SelectCharacteristicPage/SelectCharacteristicPage";
import PrintPage from "pages/User/PrintPage/PrintPage";
import PrintPage2 from "pages/User/PrintPage/PrintPage2/PrintPage2";

// components
import Header from "./components/Header/Header";
import AdminMenu from "components/AdminMenu/AdminMenu";
import SensrecAdminMenu from "components/SensrecAdminMenu/SensrecAdminMenu";

function App() {
  //redux state
  const user = useSelector((state: any) => state.AuthReducer.user);
  const isLoggedIn = useSelector((state: any) => state.AuthReducer.isLoggedIn);

  return (
    <div>
      <BrowserRouter>
        <Header />
        {!isLoggedIn ? (
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/login" element={<Login />} />
            <Route path="/repair-overview" element={<RepairOverviewPage />} />
            <Route path="/harm-sight" element={<HarmSightPage />} />
            <Route path="/repair/:index/" element={<RepairPage />} />
            <Route
              path="/select-characteristic"
              element={<SelectCharacteristicPage />}
            />
            <Route path="/capture-article" element={<CaptureArticlePage />} />
            <Route
              path="/acquisition-overview"
              element={<AcquisitionOverview />}
            />
            <Route path="*" element={<LandingPage />} />
            <Route path="/print-page" element={<PrintPage />} />
            <Route path="/print-page-2" element={<PrintPage2 />} />
          </Routes>
        ) : isLoggedIn && user.userRole === "SensrecAdmin" ? (
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <SensrecAdminMenu />
                </>
              }
            />
            <Route
              path="/location"
              element={
                <>
                  <SensrecAdminMenu />
                  <LocationsPage />
                </>
              }
            />
            <Route
              path="/license"
              element={
                <>
                  <SensrecAdminMenu />
                  <LicensesPage />
                </>
              }
            />
            <Route
              path="/users"
              element={
                <>
                  <SensrecAdminMenu />
                  <UsersPage />
                </>
              }
            />
            <Route
              path="/workplace"
              element={
                <>
                  <SensrecAdminMenu />
                  <WorkplaceSensrecAdminPage />
                </>
              }
            />
            <Route path="*" element={<LandingPage />} />
          </Routes>
        ) : isLoggedIn && user.userRole === "Admin" ? (
          <Routes>
            <Route
              path="/categories"
              element={
                <>
                  <AdminMenu />
                  <CategoriesPage />
                </>
              }
            />
            <Route
              path="/characteristics/:categoryID"
              element={
                <>
                  <AdminMenu />
                  <CharacteristicsPage />
                </>
              }
            />
            <Route
              path="/characteristics-group"
              element={
                <>
                  <AdminMenu />
                  <CharacteristicsGroupPage />
                </>
              }
            />
            <Route
              path="/placements"
              element={
                <>
                  <AdminMenu />
                  <DamagePlacementPage />
                </>
              }
            />
            <Route
              path="/damage-types"
              element={
                <>
                  <AdminMenu />
                  <DamageTypePage />
                </>
              }
            />
            <Route
              path="/tasks"
              element={
                <>
                  <AdminMenu />
                  <TasksPage />
                </>
              }
            />
            <Route
              path="/task-sequence"
              element={
                <>
                  <AdminMenu />
                  <TaskSequencePage />
                </>
              }
            />
            <Route
              path="/article-group"
              element={
                <>
                  <AdminMenu />
                  <ArticleGroupPage />
                </>
              }
            />
            <Route
              path="/companies"
              element={
                <>
                  <AdminMenu />
                  <CompaniesPage />
                </>
              }
            />
            <Route
              path="/acquisition-overview"
              element={
                <>
                  <AdminMenu />
                  <AcquisitionOverviewPage />
                </>
              }
            />
            <Route
              path="/view-damages"
              element={
                <>
                  <AdminMenu />
                  <ViewDamagesPage />
                </>
              }
            />
            <Route
              path="/workplaces"
              element={
                <>
                  <AdminMenu />
                  <WorkplacePage />
                </>
              }
            />
            <Route
              path="/statistic"
              element={
                <>
                  <AdminMenu />
                  <StatisticPage />
                </>
              }
            />
            <Route path="*" element={<Navigate to="/categories" />} />
          </Routes>
        ) : null}
      </BrowserRouter>
    </div>
  );
}

export default App;
