export const GET_ALL_WORKPLACES_SUCCESS = "GET_ALL_WORKPLACES_SUCCESS";
export const getAllWorkplacesSuccess = (workplaces: any) => ({
  type: GET_ALL_WORKPLACES_SUCCESS,
  payload: workplaces,
});

export const CREATE_WORKPLACE_SUCCESS = "CREATE_WORKPLACE_SUCCESS";
export const createWorkplaceSuccess = (workplace: any) => ({
  type: CREATE_WORKPLACE_SUCCESS,
  payload: workplace,
});

export const EDIT_WORKPLACE_SUCCESS = "EDIT_WORKPLACE_SUCCESS";
export const editWorkplaceSuccess = (workplaceID: any, workplace: any) => ({
  type: EDIT_WORKPLACE_SUCCESS,
  payload: { workplaceID, workplace },
});

export const DELETE_WORKPLACE_SUCCESS = "DELETE_WORKPLACE_SUCCESS";
export const deleteWorkplaceSuccess = (workplaceID: any) => ({
  type: DELETE_WORKPLACE_SUCCESS,
  payload: workplaceID,
});
