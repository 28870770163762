export const GET_ALL_PLACEMENTS_SUCCESS = "GET_ALL_PLACEMENTS_SUCCESS";
export const getAllPlacementsSuccess = (placements: any) => ({
  type: GET_ALL_PLACEMENTS_SUCCESS,
  payload: placements,
});

export const GET_PLACEMENT_BY_CHARACTERISTIC_GROUP_ID_SUCCESS =
  "GET_PLACEMENT_BY_CHARACTERISTIC_GROUP_ID";
export const getPlacementByCharacteristicGroupIdSuccess = (placement: any) => ({
  type: GET_PLACEMENT_BY_CHARACTERISTIC_GROUP_ID_SUCCESS,
  payload: placement,
});

export const CREATE_PLACEMENT_SUCCESS = "CREATE_PLACEMENT_SUCCESS";
export const createPlacementSuccess = (placement: any) => ({
  type: CREATE_PLACEMENT_SUCCESS,
  payload: placement,
});

export const UPDATE_PLACEMENT_SUCCESS = "UPDATE_PLACEMENT_SUCCESS";
export const updatePlacementSuccess = (updatedPlacement: any) => ({
  type: UPDATE_PLACEMENT_SUCCESS,
  payload: updatedPlacement,
});

export const DELETE_PLACEMENT_SUCCESS = "DELETE_PLACEMENT_SUCCESS";
export const deletePlacementSuccess = (placementID: any) => ({
  type: DELETE_PLACEMENT_SUCCESS,
  payload: placementID,
});
