import './WorkplacePopup.css'

import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";

//thunks
import { createWorkplaceRequest, editWorkplaceRequest } from 'store/thunks/workplaceThunk';

//selectors
import { getAllWorkplacesSelector } from 'store/selectors/workplaceSelector';
import { user } from 'store/selectors/authSelector';

//validation
import { WorkplaceSchema } from 'validations/adminValidations';

//components
import Button from "components/Button/Button";

//images
import close from "assets/icons/close-button.png";
import ipIcon from "assets/icons/ip-icon.png";

type WorkPlacePopupProps = {
  hidePopUp?: any;
  workplaceID?: any;
};

function WorkplacePopup(props: WorkPlacePopupProps) {
    const dispatch = useDispatch();

    //redux state
    const workplaces = useSelector((state: any) => getAllWorkplacesSelector(state));
    const userLocation = useSelector((state:any) => user(state))

    //local state
    const [workplaceState, setWorkplaceState] = useState({
      ip: "",
    });


    useEffect(() => {
      props.workplaceID && getWorkplace(props.workplaceID);
      // because we are in a popup we should stop scrolling so we add this classes
      let popupContainer = document.getElementById(
        "workplace-popup-main-container"
      ) as any;
      popupContainer && popupContainer.classList.remove("hide");
      document.body.classList.add("workplace-popup-overflow-hidden");
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.workplaceID]);

    const getWorkplace = (workplaceID: any) => {
      let workplace = workplaces.filter(
        (workplace: any) => workplace.id === workplaceID
      );

      setWorkplaceState({
        ...workplaceState,
        ip: workplace[0].ip,
      });
    };

    //Handle when the ip is changed
    const handleIpInputChange = (event: any) => {
      setWorkplaceState({ ...workplaceState, ip: event.target.value });
    };

    const onSubmit = (
      fields: any,
      { setStatus, setSubmitting }: { setStatus: any; setSubmitting: any }
    ) => {
      const data = {
          ip: workplaceState.ip,
          locationID: userLocation.locationId
        };

      if (!props.workplaceID) {
        dispatch(createWorkplaceRequest(data));
        props.hidePopUp();
      } else {
        dispatch(editWorkplaceRequest(props.workplaceID, data));
        props.hidePopUp();
      }
    };

    const handleSubmit = () => {
      document.body.classList.remove("workplace-popup-overflow-hidden");
    };
  return (
    <>
      <div
        className="workplace-popup-main-container"
        id="workplace-popup-main-container"
      >
        <div
          className="workplace-popup-overlay"
          onClick={props.hidePopUp}
        ></div>
        <div className="workplace-container">
          {/* Header: title and close button */}
          <div className="article-group-top-section">
            {props.workplaceID ? (
              <h1>Edit Workplace</h1>
            ) : (
              <h1> Create New Workplace</h1>
            )}
            <img
              className="workplace-popup-close"
              src={close}
              onClick={props.hidePopUp}
              alt="close Popup"
              title="close Popup"
            />
          </div>

          <Formik
            initialValues={workplaceState}
            validationSchema={WorkplaceSchema}
            onSubmit={onSubmit}
            enableReinitialize
          >
            {({ values, errors, touched, isSubmitting, setFieldValue }) => {
              return (
                <>
                  <Form>
                    <div className="workplace-popup-form-container">
                      {/* Workplace ip*/}
                      <div className="workplace-fields-section">
                        <h3 className="workplace-popup-input-label">IP</h3>
                        <div className="workplace-popup-input">
                          <div className="workplace-popup-input">
                            <Field
                              className="my-input"
                              type="text"
                              id="ip"
                              name="ip"
                              value={workplaceState.ip}
                              placeholder="255.255.255.255"
                              onChange={handleIpInputChange}
                            />
                            {touched.ip && errors.ip ? (
                              <div
                                className="workplace-popup-error-message"
                                role="alert"
                              >
                                {errors.ip}
                              </div>
                            ) : null}
                          </div>
                          <div className="workplace-popup-icon">
                            <img
                              src={ipIcon}
                              title="IP Icon"
                              alt="IP Icon"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Submit Button: Create or Edit */}
                    <div className="workplace-submit-button-container">
                      {!props.workplaceID ? (
                        <Button
                          size="small"
                          text="Create"
                          icon="add-icon"
                          type="submit"
                          onClick={handleSubmit}
                        ></Button>
                      ) : (
                        <Button
                          icon="edit-icon"
                          size="small"
                          text="Edit"
                          type="submit"
                          onClick={handleSubmit}
                        ></Button>
                      )}
                    </div>
                  </Form>
                </>
              );
            }}
          </Formik>
        </div>
      </div>
    </>
  );
}

export default WorkplacePopup