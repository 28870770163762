//service
import WorkplaceService from "services/workplaceService";

// actions
import {
  getAllWorkplacesSuccess,
  createWorkplaceSuccess,
  editWorkplaceSuccess,
  deleteWorkplaceSuccess,
} from "store/actions/workplaceAction";
import { setMessage } from "store/actions/messageAction";
import { setLoading, removeLoading } from "store/actions/loadingAction";

export const getAllWorkplacesRequest =
  (background?: string) => (dispatch: any) => {
    try {
      if (background !== "background") {
        dispatch(setLoading());
      }
      WorkplaceService.getAllWorkplaces().then(
        (response: any) => {
          if (response.data !== undefined) {
            dispatch(removeLoading());
            dispatch(getAllWorkplacesSuccess(response.data));
          }
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          // show the error to the user
          dispatch(setMessage(message, "error"));
          dispatch(removeLoading());
        }
      );
    } catch (e) {
      console.log("error", e);
      dispatch(removeLoading());
      dispatch(setMessage(e, "error"));
    }
  };

export const createWorkplaceRequest = (workplace: any) => (dispatch: any) => {
  try {
    dispatch(setLoading());
    WorkplaceService.createWorkplace(workplace).then(
      (response: any) => {
        if (response.data !== undefined) {
          dispatch(removeLoading());
          // dispatch(createWorkplaceSuccess(response.data))
          dispatch(setMessage("Workplace created successfully", "success"));
          dispatch(getAllWorkplacesRequest());
        }
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        // show the error to the user
        dispatch(setMessage(message, "error"));
        dispatch(removeLoading());
      }
    );
  } catch (e) {
    console.log("error", e);
    dispatch(removeLoading());
    dispatch(setMessage(e, "error"));
  }
};

export const editWorkplaceRequest =
  (workplaceID: any, workplace: any) => (dispatch: any) => {
    try {
      dispatch(setLoading());
      WorkplaceService.updateWorkplace(workplaceID, workplace).then(
        (response: any) => {
          if (response.data !== undefined) {
            dispatch(removeLoading());
            dispatch(editWorkplaceSuccess(workplaceID, response.data));
            dispatch(setMessage("Workplace updated successfully", "success"));
          }
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          // show the error to the user
          dispatch(setMessage(message, "error"));
          dispatch(removeLoading());
        }
      );
    } catch (e) {
      console.log("error", e);
      dispatch(removeLoading());
      dispatch(setMessage(e, "error"));
    }
  };

export const deleteWorkplaceRequest = (workplaceID: any) => (dispatch: any) => {
  try {
    dispatch(setLoading());
    WorkplaceService.deleteWorkplace(workplaceID).then(
      (response: any) => {
        dispatch(removeLoading());
        dispatch(deleteWorkplaceSuccess(workplaceID));
        dispatch(setMessage("Workplace deleted successfully", "success"));
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        // show the error to the user
        dispatch(setMessage(message, "error"));
        dispatch(removeLoading());
      }
    );
  } catch (e) {
    console.log("error", e);
    dispatch(removeLoading());
    dispatch(setMessage(e, "error"));
  }
};
