import "./TaskCard.css";
import Button from "components/Button/Button";

//images
import image from "assets/images/defaultPicture.png";

type TaskCardProps = {
  taskImages?: any;
  characteristics?: any;
  index?: any;
  task?: any;
  handleEditCategory?: any;
  handleDeleteCategory?: any;

  //(Jad) handle click to navigate to characteristics page
  handleOpenCharacteristicsPage?: any;
};

function TaskCard(props: TaskCardProps) {
  return (
    <div className="task-item" key={props.index}>
      {/* (Jad) Adding on click handle to navigate to the characteristics page*/}
      <div className="task-item-title">
        <Button
          text={props.task?.name}
          size="medium"
          cropped={true}
          onClick={() => props.handleOpenCharacteristicsPage(props.task.id)}
        />
      </div>
      {props?.taskImages &&
        props?.taskImages
          .filter((item: any) => item?.imageID === props?.task?.imageID)
          .map(function (image: any) {
            props.task.image = image?.image;
            return null;
          })}

      {/* Card content */}
      <div className="task-item-content" key={props.index + 1}>
        <div className="task-image-container">
          <img
            className="task-image"
            src={
              props.task.image !== undefined
                ? "data:image/jpeg;base64," + props.task.image
                : image
            }
            alt={props.task.name}
            title={props.task.name}
          />
        </div>

        <div className="task-damage-type-name">
          <p>
            {props.task?.damageType?.name
              ? props.task?.damageType?.name
              : "No Damage Type Name"}
          </p>
        </div>

        {/* Edit Button */}
        <div className="task-item-buttons-container">
          <Button
            text="Edit"
            size="small"
            icon="edit-icon"
            onClick={() => props.handleEditCategory(props.task.id)}
          />

          {/* Delete Button */}
          <Button
            text="Delete"
            size="small"
            icon="delete-icon"
            redBtn={true}
            onClick={() => props.handleDeleteCategory(props.task.id)}
          />
        </div>

        {/* List of the characteristics */}
        <div className="task-characteristics">
          <p>
            {props.task?.description
              ? props.task?.description
              : "No Description"}
          </p>
        </div>
      </div>
    </div>
  );
}

export default TaskCard;
