import "./LocationsPage.css";

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

//thunks
import {
  getAllLocationsRequest,
  deleteLocationRequest,
} from "store/thunks/locationThunk";

//selectors
import { getAllLocationsSelector } from "store/selectors/locationSelector";

import Loader from "components/Loader/Loader";
import Button from "../../../components/Button/Button";
import DeletePopup from "components/DeletePopup/DeletePopup";
import LocationPopup from "./LocationPopup/LocationPopup";
import MessageNotification from "components/MessageNotification/MessageNotification";

function LocationsPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //redux state
  const locations = useSelector((state: any) => getAllLocationsSelector(state));
  const { loading } = useSelector((state: any) => state.LoadingReducer);
  const { messageText, messageType } = useSelector(
    (state: any) => state.MessageReducer
  );

  //local state to open the Article group popup
  const [locationPopup, setLocationPopup] = useState({
    isLocationPopup: false,
    locationID: "",
  });
  //local state to open the Delete Popup
  const [isDeletePopup, setDeletePopup] = useState({
    isDeletePopup: false,
    locationID: "",
  });

  useEffect(() => {
    // change the browser tab title
    document.title = "WA - Locations";
    // get all categories thunk request
    locations.length < 1 && dispatch(getAllLocationsRequest());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, locations.length]);

  // go back to previous page
  const goBack = () => {
    navigate(-1);
  };

  //function to hide popups
  const hidePopup = () => {
    //hide the create edit popup
    setLocationPopup({
      ...locationPopup,
      isLocationPopup: false,
      locationID: "",
    });
    // hide the delete popup
    setDeletePopup({
      isDeletePopup: false,
      locationID: "",
    });

    let popupContainer = document.getElementById(
      "location-popup-main-container"
    ) as any;
    popupContainer && popupContainer.classList.add("hide");
    document.body.classList.remove("location-popup-overflow-hidden");
  };
  return (
    <>
      {messageText ? (
        <MessageNotification
          messageText={messageText}
          messageType={messageType}
        />
      ) : null}

      {locationPopup.isLocationPopup ? (
        <LocationPopup
          hidePopUp={hidePopup}
          locationID={locationPopup.locationID}
        />
      ) : null}

      {isDeletePopup.isDeletePopup ? (
        <DeletePopup
          hidePopUp={hidePopup}
          id={isDeletePopup.locationID}
          deleteRequest={deleteLocationRequest}
        />
      ) : null}

      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="location-page-container">
            <div className="location-page-top-section">
              {/* Back button */}
              <div className="location-page-back-btn" onClick={goBack}>
                <Button size="small" icon="back-icon" text="Back" />
              </div>
              {/* Page title */}
              <h2 className="location-page-title">Locations</h2>
              {/* Create new button */}
              <div className="create-new-location-div">
                <Button
                  text="Create New Location"
                  size="medium"
                  icon="add-icon"
                  onClick={() =>
                    setLocationPopup({
                      ...locationPopup,
                      isLocationPopup: true,
                    })
                  }
                />
              </div>
            </div>

            {/* locations list container */}
            <div className="location-list-container">
              {locations.map((location: any, index: any) => (
                <div className="location-list-item" key={index}>
                  {/* workplace title section */}
                  <div className="location-item-title">
                    {location.city} {location.name}
                  </div>
                  {/* Edit and delete buttons section */}
                  <div className="location-item-buttons">
                    {/* Edit Button */}
                    <Button
                      text="Edit"
                      size="small"
                      icon="edit-icon"
                      onClick={() =>
                        setLocationPopup({
                          ...locationPopup,
                          isLocationPopup: true,
                          locationID: location.id,
                        })
                      }
                    />
                    {/* Delete Button */}
                    <Button
                      text="Delete"
                      size="small"
                      icon="delete-icon"
                      redBtn={true}
                      onClick={() =>
                        setDeletePopup({
                          ...isDeletePopup,
                          isDeletePopup: true,
                          locationID: location.id,
                        })
                      }
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default LocationsPage;
