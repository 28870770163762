//actions
import {
  GET_ALL_DAMAGE_TYPES_SUCCESS,
  CREATE_DAMAGE_TYPE_SUCCESS,
  EDIT_DAMAGE_TYPE_SUCCESS,
  DELETE_DAMAGE_TYPE_SUCCESS,
} from "store/actions/damageTypeAction";

const initialState = { damageTypes: [], damageTypesImages: [] };

const damageTypeReducer = (state = initialState, action: any) => {
  const { type, payload } = action;
  let damageTypesArray: any = [...state.damageTypes];
  let damageTypesImagesArray: any = [...state.damageTypes];

  switch (type) {
    case GET_ALL_DAMAGE_TYPES_SUCCESS:
      return {
        ...state,
        damageTypes: payload.damageTypes,
        damageTypesImages: payload.damageTypesImages,
      };
    case CREATE_DAMAGE_TYPE_SUCCESS:
      damageTypesArray.push(payload.newDamageType);
      payload.newDamageTypeImage !== undefined &&
        damageTypesImagesArray.push(payload.newDamageTypeImage);
      return {
        ...state,
        damageTypes: damageTypesArray,
        damageTypesImages: damageTypesImagesArray,
      };

    case EDIT_DAMAGE_TYPE_SUCCESS:
      damageTypesArray.map((damageType: any, index: any) => {
        if (damageType.id === payload.updateDamageType.id) {
          return (damageTypesArray[index] = payload.updateDamageType);
        } else return damageTypesArray;
      });
      payload.updateDamageTypeImage !== undefined &&
        damageTypesImagesArray.push(payload.updateDamageTypeImage);
      return {
        ...state,
        damageTypes: damageTypesArray,
        damageTypesImages: damageTypesImagesArray,
      };

    case DELETE_DAMAGE_TYPE_SUCCESS:
      damageTypesArray = state.damageTypes.filter(
        (damageType: any) => damageType.id !== payload
      );
      return {
        ...state,
        damageTypes: damageTypesArray,
      };

    default:
      return state;
  }
};

export default damageTypeReducer;
