import "./CharacteristicsGroupCard.css";
import Button from "components/Button/Button";

//images
import image from "assets/images/defaultPicture.png";

type CharacteristicsGroupCardProps = {
  characteristicsGroupImages?: any;
  // characteristics?: any;
  index?: any;
  // key?: any;
  characteristicGroup?: any;
  handleEditCharacteristicsGroup?: any;
  handleDeleteCharacteristicsGroup?: any;
  // onClick?: any;
  // icon?: string;
  // text?: string;
  // selected?: string;
  // size?: string;
  // type?: any;
};

function CharacteristicsGroupCard(props: CharacteristicsGroupCardProps) {
  return (
    <div className="characteristics-group-item" key={props.index}>
      <Button
        text={props.characteristicGroup?.name}
        size="medium"
        cropped={true}
      />
      {props.characteristicsGroupImages &&
        props.characteristicsGroupImages
          .filter(
            (item: any) => item.imageID === props.characteristicGroup?.imageID
          )
          .map(function (image: any) {
            props.characteristicGroup.image = image.image;
            return null;
          })}
      <>
        <div className="characteristics-group-content" key={props.index + 1}>
          <div className="characteristics-group-image-container">
            <img
              className="characteristics-group-image"
              src={
                props.characteristicGroup.image !== undefined
                  ? "data:image/jpeg;base64," + props.characteristicGroup?.image
                  : image
              }
              alt={props.characteristicGroup?.name}
              title={props.characteristicGroup?.name}
            />
          </div>

          <div className="characteristics-group-manage-buttons">
            <Button
              text="Edit"
              size="small"
              icon="edit-icon"
              onClick={() =>
                props.handleEditCharacteristicsGroup(
                  props.characteristicGroup?.id
                )
              }
            />

            <Button
              text="Delete"
              size="small"
              icon="delete-icon"
              redBtn={true}
              onClick={() =>
                props.handleDeleteCharacteristicsGroup(
                  props.characteristicGroup?.id
                )
              }
            />
          </div>

          {/* List of the characteristics */}
          <div className="characteristics-group-feature"></div>
        </div>
      </>
    </div>
  );
}

export default CharacteristicsGroupCard;
