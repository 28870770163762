// services
import PlacementService from "services/placementService";

// thunks
import { getTasksBySequenceChoosersRequest } from "./taskThunk";
import { getDamagedItemModelsRequest } from "./damagedItemModelThunk";

// actions
import {
  createPlacementSuccess,
  updatePlacementSuccess,
  getAllPlacementsSuccess,
  getPlacementByCharacteristicGroupIdSuccess,
  deletePlacementSuccess,
} from "store/actions/placementAction";
import { setMessage } from "store/actions/messageAction";
import { removeLoading, setLoading } from "store/actions/loadingAction";

export const getAllPlacementsRequest = (background?: string) => (dispatch: any) => {
  try {
    // set loading indicator
    if (background !== "background") {
      dispatch(setLoading());
    }   
    // API call to get all placements
    PlacementService.getAllPlacements().then(
      (response: any) => {
        if (response.data !== undefined) {
          dispatch(getAllPlacementsSuccess(response.data));
          dispatch(removeLoading());
        }
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        // show the error to the user
        dispatch(setMessage(message, "error"));
        dispatch(removeLoading());
      }
    );
  } catch (e) {
    console.log("error", e);
    dispatch(removeLoading());
    dispatch(setMessage(e, "error"));
  }
};

export const getPlacementByCharacteristicGroupIdRequest =
  (characteristicGroupID: number) => (dispatch: any) => {
    try {
      //set loading
      dispatch(setLoading);
      PlacementService.getPlacementByCharacteristicGroupId(
        characteristicGroupID
      ).then(
        (response: any) => {
          if (response.data !== undefined) {
            dispatch(getPlacementByCharacteristicGroupIdSuccess(response.data));
            let sequenceChooserIDs: any = [];
            let damagedItemModelIDs: any = [];
            response.data.map((placement: any) => {
              if (placement.damagedItemModels) {
                placement.damagedItemModels.map((itemModel: any) => {
                  return damagedItemModelIDs.push(itemModel.id);
                });
              }
              if (placement.sequenceChooser) {
                return sequenceChooserIDs.push(placement.sequenceChooser.id);
              }
              return null;
            });
            dispatch(getTasksBySequenceChoosersRequest(sequenceChooserIDs));
            dispatch(getDamagedItemModelsRequest(damagedItemModelIDs));
            dispatch(removeLoading());
          }
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          // show the error to the user
          dispatch(setMessage(message, "error"));
          dispatch(removeLoading());
        }
      );
    } catch (e) {
      console.log("error", e);
      dispatch(removeLoading());
      dispatch(setMessage(e, "error"));
    }
  };

// Create new placement
export const createPlacementRequest = (placement: any) => (dispatch: any) => {
  dispatch(setLoading());
  // API call
  PlacementService.createPlacement(placement)
    .then(
      (response: any) => {
        dispatch(createPlacementSuccess(response.data));
        dispatch(removeLoading());
        dispatch(setMessage("Placement created successfully", "success"));
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        // show the error to the user
        dispatch(setMessage(message, "error"));
        dispatch(removeLoading());
      }
    )
    .catch((error: any) => {
      console.log("error", error);
      dispatch(setMessage(error, "error"));
    });
};

// edit new placement
export const editPlacementRequest =
  (placementID: any, data: any) => (dispatch: any) => {
    dispatch(setLoading());
    // API call
    PlacementService.updatePlacement(placementID, data)
      .then(
        (response: any) => {
          dispatch(updatePlacementSuccess(response.data));
          dispatch(removeLoading());
          dispatch(setMessage("Placement updated successfully", "success"));
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          // show the error to the user
          dispatch(setMessage(message, "error"));
          dispatch(removeLoading());
        }
      )
      .catch((error: any) => {
        console.log("error", error);
        dispatch(setMessage(error, "error"));
      });
  };

// Delete new placement
export const deletePlacementRequest = (placementID: any) => (dispatch: any) => {
  dispatch(setLoading());
  // API call
  PlacementService.deletePlacement(placementID)
    .then(
      (response: any) => {
        dispatch(deletePlacementSuccess(placementID));
        dispatch(removeLoading());
        dispatch(setMessage("Placement deleted successfully", "success"));
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        // show the error to the user
        dispatch(setMessage(message, "error"));
        dispatch(removeLoading());
      }
    )
    .catch((error: any) => {
      console.log("error", error);
      dispatch(setMessage(error, "error"));
    });
};
