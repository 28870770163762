import { httpCommon } from "./http-common";
import authHeader from "./authHeader";

const getAllLicenses = () => {
    return httpCommon.get("/License", {headers: authHeader() as any});
}

const getLicenseById = (licenseID: any) => {
    return httpCommon.get(`/License/${licenseID}`, {headers: authHeader() as any});
}

const createLicense = (license: any) => {
    return httpCommon.post("/License", license, {headers: authHeader() as any});
}

const updateLicense = (licenseID: any, license: any) => {
    return httpCommon.put(`/License/${licenseID}`, license, {headers: authHeader() as any});
}

const deleteLicense = (licenseID: any) => {
    return httpCommon.delete(`/License/${licenseID}`, {headers: authHeader() as any});
}

const LicenseService = {
    getAllLicenses,
    getLicenseById,
    createLicense,
    updateLicense,
    deleteLicense
}

export default LicenseService