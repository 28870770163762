import { httpCommon } from "./http-common";
import authHeader from "./authHeader";

const getAllWorkplaces = () => {
    return httpCommon.get('/Workplace' , {headers: authHeader() as any})
}

const createWorkplace = (data: any) => {
    return httpCommon.post('/Workplace', data, {headers: authHeader() as any})
}

const updateWorkplace = (workplaceID: any, data: any) => {
    return httpCommon.put(`/Workplace/${workplaceID}`, data, {headers: authHeader() as any})
}

const deleteWorkplace = (workplaceID: any) => {
    return httpCommon.delete(`/Workplace/${workplaceID}`, {headers: authHeader() as any})
}

const WorkplaceService = {
    getAllWorkplaces, 
    createWorkplace,
    updateWorkplace,
    deleteWorkplace
}

export default WorkplaceService